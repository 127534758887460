import { Bold } from 'typography';
import EditIcon from 'assets/edit-accordion.svg';
import { Tooltip } from 'antd';

interface IAccordionContentItem {
	question: string;
	response: string | number;
	explanation?: string;
	editFunction?: any;
}

/**
 * This is one row of the AccordionInfoBox
 * @example <AccordionContentItem question={'What is your name?'} response={'Crosby'} />
 * @returns Individual Accordion Info Box Row
 */
const AccordionContentItem = (props: IAccordionContentItem) => {
	return (
		<div className='accordion-content-item'>
			{/* question */}
			<div className='left'>
				<Bold>{props.question}</Bold>
				<br />
				{/* {props?.explanation && (
          <summary>
            (EXPLANATION)
            <p>{props?.explanation}</p>
          </summary>
        )} */}
			</div>

			{/* response */}
			<div className='right flex'>
				<Bold>{props.response}</Bold>
				<Tooltip title={'Edit Response'} placement='bottomRight'>
					{/* <div onClick={handleAccordionEdit} className='btn-edit'> */}
					{process.env.NODE_ENV === 'development' && (
						<div onClick={props.editFunction} className='btn-edit'>
							<img src={EditIcon} alt={props.response.toString()} />
						</div>
					)}
				</Tooltip>
			</div>
		</div>
	);
};

export default AccordionContentItem;
