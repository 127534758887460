import notifycongratsicon from 'assets/notify-congrats.svg';
import notifycompleteicon from 'assets/notify-complete.svg';
import notifydocerroricon from 'assets/notify-doc-error.svg';
import notifyuploadicon from 'assets/notify-upload.svg';
import docediticon from 'assets/document-edit.svg';
import docucheckmarkicon from 'assets/document-checkmark.svg';
import personicon from 'assets/person.svg';

enum NotifyType {
  // @ts-ignore
  NotifyCongrats = notifycongratsicon,
  // @ts-ignore
  NotifyCompleted = notifycompleteicon,
  // @ts-ignore
  NotifyDocError = notifydocerroricon,
  // @ts-ignore
  NotityDocUploaded = docucheckmarkicon,
  // @ts-ignore
  NotityNotCompleted = docediticon,
  // @ts-ignore
  NotityReUpload = notifyuploadicon,
  // @ts-ignore
  NotityUser = personicon,
}

export default NotifyType