import { CleanRadioInput, JourneyNavLine, ProgressBar } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Bold, H4 } from 'typography';

const PropertyNonStandardConstruction = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [isNonStandardConstruction, setNonStandardConstruction] = useState(user?.property?.isNonStandardConstruction);
	const delay = 300;

	const setAnswer = (answer: any) => {
		setNonStandardConstruction(answer);
		
		const currUser = { ...user };
		
		if (!currUser?.property) {
			currUser.property = {};
		}

		currUser.property.isNonStandardConstruction = answer;
		
		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});
		
		setTimeout(() => {
			history.push('/user/property/purchase-property-only-property');
		}, delay);
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={72} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Is the property of non-standard construction?</H4>
					</div>
					<Bold isBold={false} color='#959DA5'>
						Thatched roof, barn conversion etc.
					</Bold>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={(e) => e}>
						<CleanRadioInput
							name={'isNonStandardConstruction'}
							label={'Yes'}
							value={'yes'}
							onClick={() => setAnswer(true)}
							checked={typeof isNonStandardConstruction === 'boolean' && isNonStandardConstruction}
						/>

						<CleanRadioInput
							name={'isNonStandardConstruction'}
							label={'No'}
							onClick={() => setAnswer(false)}
							value={'no'}
							checked={typeof isNonStandardConstruction === 'boolean' && !isNonStandardConstruction}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default PropertyNonStandardConstruction;
