import { Loader } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { Table, OffersTableTop } from '.';

const OffersTable = () => {
	const [{ user }] = useDataLayerValue();

	return (
		<>
			{user ? (
				<div className='centroid offers-table-box'>
					<OffersTableTop />
					<Table />
				</div>
			) : (
				<Loader />
			)}
		</>
	);
};

export default OffersTable;
