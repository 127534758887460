import { useEffect, useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, SelectInput, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';
import { titleOptions } from '../../utils/DropDownList';

const CreateProfilePartnerPersonal = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(75);

	const [title, setTitle] = useState(user?.cases?.[0]?.application?.partner?.title || '');
	const [firstName, setFirstName] = useState(
		user?.cases?.[0].application?.partner?.firstName || '',
	);
	const [lastName, setLastName] = useState(
		user?.cases?.[0].application?.partner?.lastName || '',
	);
	const [birthday, setBirthday] = useState(
		user?.cases?.[0].application?.partner?.birthday || '',
	);
	const [isComplete, setComplete] = useState<boolean>(false);

	useEffect(() => {
		setComplete(!!title && !!firstName && !!lastName && !!birthday);
	}, [title, firstName, lastName, birthday]);

	// end of states that control form data
	const onSubmit = async () => {
		if (!user.cases[0].application.partner) {
			user.cases[0].application.partner = {};
		}

		user.cases[0].application.partner.title = title;
		user.cases[0].application.partner.firstName = firstName;
		user.cases[0].application.partner.lastName = lastName;
		user.cases[0].application.partner.birthday = birthday;

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		history.push('/user/create-profile-partner-email');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} step={1}>
				<CreateProfileContent
					active
					title='Personal details'
					subTitle='Tell us about you and the property to find the most suitable mortgage deal.'
					continueBtn
					onClick={onSubmit}
					buttonDisabled={!isComplete}
				>
					<SelectInput
						name='title'
						autoFocus={true}
						defaultValue={title || null}
						options={titleOptions}
						label='Select Title'
						onSelect={setTitle}
					/>

					<Input
						value={firstName}
						normalInput
						required
						label='First Name'
						placeholder='First Name'
						onChange={(e) => setFirstName(e.target.value)}
					/>

					<Input
						value={lastName}
						normalInput
						required
						label='Last Name'
						placeholder='Last Name'
						onChange={(e) => setLastName(e.target.value)}
					/>

					<Input
						value={birthday}
						required
						label='Birthday'
						placeholder='Birthday'
						dateField
						onChange={(e) => setBirthday(e.target.value)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfilePartnerPersonal;
