import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { FinancialAccordion, PersonalAccordion } from '.';

const personalAccInitialState = {
	personal: false,
	financial: false,
	property: false,
	personalSecond: false,
	financialSecond: false,
	mortgage: false,
	credit: false,
	creditSecond: false,
};

/**
 * Houses all the required accordions for B2C page
 * @returns B2C Application Accordions
 */
const ApplicationB2CAccordions = () => {
	const [{ user, progress }] = useDataLayerValue();
	const [accOpenState, setAccOpenState] = useState(personalAccInitialState);
	const { personal, personal2, financial, financial2, liabilities, liabilities2, expenses, expenses2 } = user || {};
	const { application } = user?.cases?.[0] || {};

	/** enable only one accordion toggle */
	const handleToggle = (filterTitle) => {
		setAccOpenState({
			...personalAccInitialState,
			[filterTitle]: !accOpenState[filterTitle],
		});
	};

	return (
		<div className='application-b2c-acc-box'>
			{/* Personal */}
			<PersonalAccordion
				name='personal'
				personal={personal}
				application={application}
				progress={progress?.personal}
				isOpen={accOpenState.personal}
				handleToggle={handleToggle}
			/>

			{!!application?.partner && (
				<PersonalAccordion
					name='personalSecond'
					personal={personal2}
					application={application?.partner}
					progress={progress?.personal2}
					isSecondApplicant
					isOpen={accOpenState.personalSecond}
					handleToggle={handleToggle}
				/>
			)}

			{/* Mortgage Questions */}

			{/*hiding on request*/}
			{/*<MortgageAccordion*/}
			{/*	name='mortgage'*/}
			{/*	isOpen={accOpenState.mortgage}*/}
			{/*	handleToggle={handleToggle}*/}
			{/*	progress={progress?.mortgage}*/}
			{/*/>*/}

			{/* Financial */}
			<FinancialAccordion
				name='financial'
				financial={financial}
				liabilities={liabilities}
				expenses={expenses}
				application={application}
				progress={progress?.financial}
				isOpen={accOpenState.financial}
				handleToggle={handleToggle}
			/>

			{!!application?.partner && (
				<FinancialAccordion
					name='financialSecond'
					financial={financial2}
					liabilities={liabilities2}
					expenses={expenses2}
					application={application?.partner}
					progress={progress?.financial2}
					isSecondApplicant
					isOpen={accOpenState.financialSecond}
					handleToggle={handleToggle}
				/>
			)}

			{/* Property */}
			{/*<PropertyAccordion*/}
			{/*	name='property'*/}
			{/*	isOpen={accOpenState.property}*/}
			{/*	progress={progress?.property}*/}
			{/*	handleToggle={handleToggle}*/}
			{/*/>*/}
		</div>
	);
};

export default ApplicationB2CAccordions;
