import { SubTitle } from 'typography';

interface IAccordionTitle {
  title: string;
  addSpacing: boolean;
}

/**
 * This component is to be used inside AccordionInfoBox
 * @param title - the text to be shown on the component
 * @param addSpacing - a BOOLEAN state to either add spacing at the top of the label or not
 * @returns - Title Component
 */
const AccordionTitle = (props: IAccordionTitle) => {
  return (
    <div onClick={() => console.log('AC TITLE: ', props.addSpacing)}>
      {props.addSpacing && (
        <>
          <br />
          <br />
        </>
      )}
      <SubTitle>{props.title}</SubTitle>
    </div>
  );
};

export default AccordionTitle;
