import { FC } from 'react';
import { Typography } from '../types/typography';

const H1: FC<Typography> = ({ children, size, isBold, opacity }) => {
  return (
    <h1
      style={{
        fontWeight: isBold === false ? 'normal' : 'bold',
        opacity: opacity ? opacity : 1,
      }}
      className="H1"
    >
      {children}
    </h1>
  );
};

export default H1;
