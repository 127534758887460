import { FC } from 'react';
import { Typography } from '../types/typography';

const H3: FC<Typography> = ({ children, size, isBold, opacity }) => {
  return (
    <h3
      style={{
        fontWeight: isBold === false ? 'normal' : 'bold',
        opacity: opacity ? opacity : 1,
      }}
      className="H3"
    >
      {children}
    </h3>
  );
};

export default H3;
