import { useRouteMatch } from 'react-router-dom';
import 'css/LeftMenu.css';
import LogoSlab from './LogoSlab';
import MenuItem from './MenuItem';
import HomeIcon from 'assets/home.svg';
import HomeFilledIcon from 'assets/home-filled.svg';
import DocumentsIcon from 'assets/documents.svg';
import DocumentsFilledIcon from 'assets/documents-filled.svg';
import SettingsIcon from 'assets/settings.svg';
import SettingsFilledIcon from 'assets/settings-filled.svg';
import LanguageSelection from './LanguageSelection';

function LeftMenu() {
  const { path } = useRouteMatch();

  return (
    <section className="left-menu">
      <LogoSlab />
      <section className="menu-items-list">
        <MenuItem
          to={`${path}/applications`}
          text="My Applications"
          icons={{ normal: HomeIcon, selected: HomeFilledIcon }}
        />
        <MenuItem
          to={`${path}/documents`}
          text="Documents"
          icons={{ normal: DocumentsIcon, selected: DocumentsFilledIcon }}
        />
        <MenuItem
          to={`${path}/settings`}
          text="Settings"
          icons={{ normal: SettingsIcon, selected: SettingsFilledIcon }}
        />
      </section>
        { !process.env.REACT_APP_DEMO_FLAG && <LanguageSelection isDesktop /> }
    </section>
  );
}

export default LeftMenu;
