import React from 'react';
import Avatar from 'assets/avatar-sample.svg';

const REVIEWS = [
	{
		name: 'charlie wells',
		text: "I had some issues when I was trying to remortgage, and fortunately, I got in touch with Krishna (Krish) from accessFS. Let me tell you, Krish really knows his stuff! He's incredibly friendly, genuine, and he immediately put me at ease with his assurance that he could assist. The way he keeps the communication going is just fantastic. He makes sure you're always in the loop, even about the smallest details, which is a big deal when you're dealing with a mortgage. Honestly, I'd give Krish my full recommendation, 100%! Thanks a ton for all your help. AccessFS is definitely lucky to have you on their team.",
		avatar: Avatar,
	},
	{
		name: 'Nina Jhim',
		text: 'Krishna Pattani is phenomenal, such an asset to the company I would highly recommend him. My case for 2 mortgages was Complex & need a specialist knowledge due to my circumstances & given the uncertainty in the market.Krishna made the Impossible possibly & went above & beyond.He converted the negatives into positives. He has expert knowledge of the mortgage market.Krishna took majority of the burden of the mortgage application off my shoulders & made it painless he was efficient & gave me the upmost comprehensive guidance.I would 100% recommend Krishna!',
		avatar: Avatar,
	},
	{
		name: 'chuks uwaechia',
		text: 'As a business Owner trying to find a mortgage broker that would manage your case can be very challenging....but I was introduced to Mr Ali Habib....he demonstrated an exceptional customer service; he explained complex mortgage terms required by business owner in simple easy terms....he was patient....and showed a lot of tenancity in helping me to achieve my goal as a proud Home Owner....my last text message to him....you deserve the Mortgage Broker Award 2023.🏆🏆🏆..What an Awesome & Exceptional Broker..Highly recommended',
		avatar: Avatar,
	},
	{
		name: 'Manbir Singh',
		text: 'Andy Panayi has provided excellent service from start to end of my remortgage journey. Andy provided me with a wide variety of options tailored to my scenario and worked at a fast pace during a time where mortgage products were being updated constantly.My remortgage completed today, and to top it all off, Andy contacted me to check everything had gone through with the solicitors as I expected.Great service all round. Cannot recommend highly enough.',
		avatar: Avatar,
	},
	{
		name: 'Tabita',
		text: 'Isobel Burley ( a.k.a Izzy ) did such an amazing job as a mortgage broker, she was very professional, fast and knowledgeable in sorting out quickly my mortgage fixed rate ( just in time to save me money ££££ from month September onwards ) She kept me informed from the beginning, providing constant updates throughout the process. I would recommend her 100%Thank you Izzy, you made me very happy!! All while I am enjoying my 3 weeks “short” holiday abroad ✈️🇷🇴😍♥️',
		avatar: Avatar,
	},
	{
		name: 'Nisarg Trivedi',
		text: 'Amazing experience at Access Financial Services.Aneela was extremely knowledgeable and keen to go above and beyond to get us the deal most suited to us. It felt like she really cared about getting the best for us.Aneela was polite and prompt and kept us up to date throughout the whole process!Honestly could not have had a more positive experience!We were treated in a friendly and professional manner and felt comfortable with the advice we received.We would definitely recommend Access Financial Services and will use them again in the future.',
		avatar: Avatar,
	}
];

const getInitials = (name: string) => {
	const names = name.split(' ');
	let initials = '';
	names.forEach((n) => (initials += n.charAt(0).toUpperCase()));
	return initials;
}

const Reviews = () => {
	return (
		<div className='reviews'>
			<div className='reviews-header'>reviews</div>
			<div className='reviews-box-wrapper'>
				{REVIEWS.map((review, index) => (
					<div className='review-box' key={`${review.name}-${index}`}>
						<p className='review-text'>{review.text}</p>
						<div className='review-profile'>
							<div className='review-image'>{getInitials(review.name)}</div>
							<div className='uppercase'>{review.name}</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Reviews;
