import { JourneyNavLine, MultiRadioInput, ProgressBar } from 'components/Widgets';
import { H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';

const Liabilities9 = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [isEarlyPaymentPenalty] = useState(
		user?.liabilities?.isEarlyPaymentPenalty || '',
	);

	const delay = 300;

	const timeOutRef = useRef(null);
	const historyRef = useRef(null);

	useEffect(() => {
		return () => clearTimeout(timeOutRef.current);
	}, []);

	const onAnswer = async (answer: any) => {
		historyRef.current = true;

		const currUser = { ...user };
		if (!currUser.liabilities) {
			currUser.liabilities = {};
		}
		currUser.liabilities.isEarlyPaymentPenalty = answer;

		if (String(answer) !== "true") {
			delete currUser.liabilities.penaltyAmount;
		}

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});


		timeOutRef.current = setTimeout(() => {
			// to prevent history from pushing twice
			if (!historyRef.current) return;

			if (String(answer) === "true") {
				history.push('/user/liabilities/liabilities-10')
			}else{
				history.push('/user/liabilities/liabilities-13')
			}

			historyRef.current = false;
		}, delay);
	};

	return <div className='personal-container'>
		<ProgressBar progress={63} />
		<JourneyNavLine closeBtn />
		<div className='personal-box'>
			<div className='personal-details-header'>
				<div className='personal-main-title'>
					<H4>Are there any penalties if you transfer or repay your existing mortgage now?</H4>
				</div>
			</div>
			<MultiRadioInput
				data={[
					{ label: 'Yes', value: true },
					{ label: 'No', value: false },
					{ label: 'I don\'t know', value: 'DONT_KNOW' },
				]}
				onSelection={value => onAnswer(value)}
				selectedValue={isEarlyPaymentPenalty} />
		</div>
	</div>;
};

export default Liabilities9;
