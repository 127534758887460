/**
 *
 * @returns '/logo' if environment is not development, otherwise returns 'https://v2.devapp.com/logo'
 */

const getLogoSrc = () => {
  return '/access_logo.png';
};

export default getLogoSrc;
