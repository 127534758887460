import DesktopApplicationDetails from './DesktopApplicationDetails';
import MobileApplicationDetails from './MobileApplicationDetails';

const ApplicationDetails = () => {
  return (
    <>
      <DesktopApplicationDetails />
      <MobileApplicationDetails />
    </>
  );
};

export default ApplicationDetails;
