/* eslint-disable no-empty-pattern */
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Input } from 'components/Widgets';
import 'css/Auth.css';
import { Bold, SubTitle } from 'typography';
import { funcCognitoUser } from 'utils/AuthFunctions';
import { TabTitle } from 'utils/GeneralFunctions';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import getLogoSrc from 'utils/getLogoSrc';

const AuthForgotPassword = () => {
  const [{}, dispatch] = useDataLayerValue();
  const history = useHistory();
  const [email, setEmail] = useState('');
  TabTitle('Forgot Password');

  // SEND CODE TO MAIL
  const funcSubmitEmail = (e: any) => {
    e?.preventDefault();

    funcCognitoUser(email).forgotPassword({
      onSuccess: (data) => {
        // successfully initiated reset password request
        console.log('CodeDeliveryData from forgotPassword: ' + data);
      },
      onFailure: (err) => {
        console.log(err.message || JSON.stringify(err));
      },
      inputVerificationCode: (data) => {
        console.log('Input Verification: ', data);

        dispatch({
          type: actionTypes.FORGOT_PASSWORD,
          forgottenPasswordEmail: email,
        });

        history.push(`/auth/reset-password`);
      },
    });
  };

  return (
    <div className="login-auth">
      <aside className="login-holder">
        <div className="app-paper login-paper">
          {/* logo */}
          <div className="login-app-logo">
            <img src={getLogoSrc()} alt="Logo" />
          </div>

          <form method="post" onSubmit={funcSubmitEmail}>
            <Input
              normalInput
              label="Email Address"
              type="email"
              placeholder="email"
              required
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <br />
            <button type="submit" className="btn-submit-loader">
              <SubTitle isBold={false}>Submit</SubTitle>
            </button>
          </form>
        </div>

        <footer className="auth-login-footer">
          <Link className="auth-link" to={`/auth`}>
            <Bold isBold={false}>Back to Log in</Bold>
          </Link>

          <div className="auth-link-bottom">
            <Bold isBold={false}>
              Not a member yet?{' '}
              <Link className="auth-link" to={`/auth/signup`}>
                Create an account
              </Link>
            </Bold>
          </div>
        </footer>
      </aside>
    </div>
  );
};

export default AuthForgotPassword;
