import { CleanRadioInput, JourneyNavLine, ProgressBar, Loader } from 'components/Widgets';
import { H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { PATCHRequest, readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import { useState } from 'react';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';

const IsSecondApplicantHasAddressHistory = () => {
	const FProgress = Number(readFromLocalStorage('_FPersonalPageProgress')) || 0;
	const [{ user }, dispatch] = useDataLayerValue();
	const { personal2, personal } = user || {};
	const { application } = user?.cases?.[0] || {};

	const [progress, setProgress] = useState(FProgress);
	const [isSaving, setIsSaving] = useState(false);
	const history = useHistory();
	const delay = 300;

	const noAnswer = (e: any) => {
		setProgress(Math.floor(progress + 8.3));
		storeInLocalStorage('_FPersonalPageProgress', Math.floor(progress + 8.3));
		setTimeout(() => {
			history.push('/user/personal/personal-secondapp-addresshistory');
		}, delay);
	};

	const yesAnswer = async (e: any) => {
		const currPersonal2 = { ...personal2 };
		currPersonal2.pastAddresses = personal.pastAddresses;
		currPersonal2.presentAddress = personal.presentAddress;
		currPersonal2.presentAddress2 = personal.presentAddress2;
		currPersonal2.presentCity = personal.presentCity;
		currPersonal2.presentPostCode = personal.presentPostCode;
		currPersonal2.presentCountry = personal.presentCountry;
		currPersonal2.presentAddressSinceDate = personal.presentAddressSinceDate;
		currPersonal2.isElectoralRoll = personal.isElectoralRoll;
		currPersonal2.occupantType = personal.occupantType;

		setIsSaving(true);
		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, personal2: currPersonal2 },
		});
		await PATCHRequest(`/users?attribute=personal2`, currPersonal2, history);
		setTimeout(() => {
			history.push('/user/stages');
		});
		setIsSaving(false);
	};

	if (isSaving) return <Loader text='Saving...' />;

	return (
		<div className='personal-container'>
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>{`Same address history for ${
							application?.partner?.firstName?.charAt(0).toUpperCase() + application?.partner?.firstName?.slice(1)
						}?`}</H4>
					</div>
				</div>
			</div>
			<div className='personal-details-body'>
				<form className='central-form-group' onSubmit={(e) => e}>
					<CleanRadioInput
						name={'Yes'}
						label={'Yes'}
						value={'yes'}
						onChange={yesAnswer}
						checked={!personal2?.pastAddresses?.length}
					/>

					<CleanRadioInput
						name={'No'}
						label={'No'}
						onChange={noAnswer}
						width={'360px'}
						value={'no'}
						checked={!!personal2?.pastAddresses?.length}
					/>
				</form>
			</div>
		</div>
	);
};

export default IsSecondApplicantHasAddressHistory;
