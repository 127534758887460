import englishflag from 'assets/flags/gbr.svg';
import polishflag from 'assets/flags/pol.svg';
import turkishflag from 'assets/flags/tur.svg';

export const langs = [
  {
    abbrev: 'EN',
    text: 'English',
    flag: englishflag,
  },
  {
    abbrev: 'PL',
    text: 'Polish',
    flag: polishflag,
  },
  {
    abbrev: 'TR',
    text: 'Turkish',
    flag: turkishflag,
  },
];
