import { Caption } from 'typography';

interface INotificationDate {
  date: string;
}
const NotificationDate = (props: INotificationDate) => {
  return (
    <div className="notification-date">
      <Caption isBold={true} opacity={0.5}>
        {props.date}
      </Caption>
    </div>
  );
};

export default NotificationDate;
