import { CleanRadioInput } from './index';

interface Props {
	data: Array<NameLabel>;
	onSelection: (value: any) => void;
	selectedValue: string
}

interface NameLabel {
	value: any;
	label: string;
}

const MultiRadioInput = (props: Props) => {
	return <div className='personal-details-body central-form-group'>
		{props.data.map(entry => {
			return <CleanRadioInput
				name={'nameNegative'}
				label={entry.label}
				onChange={() => props.onSelection(entry.value)}
				checked={props.selectedValue === entry.value}
			/>;
		})}
	</div>;
};

export default MultiRadioInput;