import { useEffect, useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';

const CreateProfileYearsLeftOnMortgage = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 50 : 75);
	const [yearsLeftOnMortgage, setYearsLeftOnMortgage] = useState(
		user?.application?.yearsLeftOnMortgage || 0
	);
	const [isComplete, setComplete] = useState<boolean>(false);

	useEffect(() => {
		setComplete(
			!!yearsLeftOnMortgage &&
				yearsLeftOnMortgage >= 1 &&
				yearsLeftOnMortgage <= 35
		);
	}, [yearsLeftOnMortgage]);

	// end of states that control form data
	const handleSaveAndContinue = () => {
		const currUser = { ...user };
		currUser.cases[0].application.yearsLeftOnMortgage = yearsLeftOnMortgage;

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		history.push('/user/create-profile-mortgage-lender');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='How many years are left on your mortgage?'
					continueBtn
					onClick={handleSaveAndContinue}
					buttonDisabled={!isComplete}
				>
					<Input
						value={yearsLeftOnMortgage || ''}
						required
						autoFocus={true}
						type='number'
						normalInput
						name='yearsLeftOnMortgage'
						label='1 to 35 years'
						placeholder='1 to 35 years'
						onChange={(e) => {
							if (e.target.value < 1 && e.target.value > 35) return;
							setYearsLeftOnMortgage((e.target.value * 1).toString());
						}}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileYearsLeftOnMortgage;
