import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Liabilities10, Liabilities6, Liabilities7, Liabilities8, Liabilities9 } from '.';
import Liabilities12 from './Liabilities12';
import Liabilities14 from './Liabilities14';
import BooleanView from '../Views/BooleanView';
import { useDataLayerValue } from 'context-api/DataLayer';

const Liabilities = () => {
	const { path } = useRouteMatch();
	const [{ user }] = useDataLayerValue();
	const { personal2 } = user || {};
	const { application } = user?.cases?.[0] || {};
	const secondApplicantName = application?.partner?.firstName;
	const hasPartnerApplicant = application?.partner;

	let secondApplicantGender = 'him/her';
	if (personal2?.gender === 'female') secondApplicantGender = 'her';
	if (personal2?.gender === 'male') secondApplicantGender = 'him';

	let secondApplicantGenderHisHer = 'his/her';
	if (personal2?.gender === 'female') secondApplicantGenderHisHer = 'her';
	if (personal2?.gender === 'male') secondApplicantGenderHisHer = 'his';

	return (
		<div>
			<Switch>
				<Route path={`${path}/liabilities-1`} exact>
					<BooleanView
						routeTo={
							hasPartnerApplicant
								? '/user/liabilities/second-applicant-liabilities-1'
								: '/user/liabilities/liabilities-2'
						}
						section='liabilities'
						property='isCreditIssues'
						progress={7}
						title='Have you ever had any adverse credit issues or mortgage/loan refused?'
					/>
				</Route>

				<Route path={`${path}/second-applicant-liabilities-1`} exact>
					<BooleanView
						routeTo={'/user/liabilities/liabilities-2'}
						section='liabilities2'
						property='isCreditIssues'
						progress={11}
						title={`Has ${secondApplicantName} ever had any adverse credit issues or mortgage/loan refused?`}
					/>
				</Route>

				<Route path={`${path}/liabilities-2`} exact>
					<BooleanView
						routeTo={
							hasPartnerApplicant
								? '/user/liabilities/second-applicant-liabilities-2'
								: '/user/liabilities/liabilities-3'
						}
						section='liabilities'
						property='isDebtJudgement'
						progress={14}
						title='Have you ever had a judgment for debt or a loan default registered against you?'
					/>
				</Route>

				<Route path={`${path}/second-applicant-liabilities-2`} exact>
					<BooleanView
						routeTo='/user/liabilities/liabilities-3'
						section='liabilities2'
						property='isDebtJudgement'
						progress={18}
						title={`Has ${secondApplicantName} ever had a judgment for debt or a loan default registered against ${secondApplicantGender}?`}
					/>
				</Route>

				<Route path={`${path}/liabilities-3`} exact>
					<BooleanView
						routeTo={
							hasPartnerApplicant
								? '/user/liabilities/second-applicant-liabilities-4'
								: '/user/liabilities/liabilities-4'
						}
						section='liabilities'
						property='isEverBankrupt'
						progress={21}
						title='Have you ever been declared bankrupt or made an arrangement with your creditors?'
					/>
				</Route>

				<Route path={`${path}/second-applicant-liabilities-4`} exact>
					<BooleanView
						routeTo='/user/liabilities/liabilities-4'
						section='liabilities2'
						property='isEverBankrupt'
						progress={25}
						title={`Has ${secondApplicantName} ever been declared bankrupt or made an arrangement with ${secondApplicantGenderHisHer} creditors?`}
					/>
				</Route>

				<Route path={`${path}/liabilities-4`} exact>
					<BooleanView
						routeTo={
							hasPartnerApplicant
								? '/user/liabilities/second-applicant-liabilities-5'
								: user.cases[0].application.isLookingTo === 'remortgage' ? '/user/liabilities/liabilities-6' : '/user/liabilities/liabilities-5'
						}
						section='liabilities'
						property='isFailedToPayEver'
						progress={28}
						title='Have you ever failed to keep up your payments under any previous or other mortgage, rental or loan agreement?'
					/>
				</Route>

				<Route path={`${path}/second-applicant-liabilities-5`} exact>
					<BooleanView
						routeTo={
							user.cases[0].application.isLookingTo === 'remortgage' ?
								'/user/liabilities/liabilities-6' : '/user/liabilities/liabilities-5'
						}
						section='liabilities2'
						saveData
						property='isFailedToPayEver'
						progress={32}
						title={`Has ${secondApplicantName} ever failed to keep up ${secondApplicantGenderHisHer} payments under any previous or other mortgage, rental or loan agreement?`}
					/>
				</Route>

				<Route path={`${path}/liabilities-5`} exact>
					<BooleanView
						routeTo='/user/liabilities/liabilities-6'
						routeIfFalse='/user/liabilities/liabilities-12'
						section='liabilities'
						property='hasAnotherMortgage'
						title='Do you or your co-applicant have any other mortgage?'
						subtitle='(Not including the mortgage this application is for)'
						progress={35}
						deleteOnTrue={['currentRentAmount']}
						deleteOnFalse={[
							'outstandingLoanAmount',
							'loanRemainingYears',
							'mortgageInterestRate',
							'isBuyToLet',
							'mortgageMonthlyAmount',
							'mortgageAccountNumber',
							'currentMortgageCountry',
							'propertyPostCode',
							'propertyStreet',
							'propertyCity',
							'propertyAddress',
							'propertyAddress2',
							'currentMortgageName',
							'mortgageLender',
							'propertySalePrice',
						]}
					/>
				</Route>
				<Route path={`${path}/liabilities-6`} exact>
					<Liabilities6 />
				</Route>

				<Route path={`${path}/liabilities-7`} exact>
					<Liabilities7 />
				</Route>
				<Route path={`${path}/liabilities-8`} exact>
					<Liabilities8 />
				</Route>
				<Route path={`${path}/liabilities-9`} exact>
					<Liabilities9 />
				</Route>
				<Route path={`${path}/liabilities-10`} exact>
					<Liabilities10 />
				</Route>

				<Route path={`${path}/liabilities-11`} exact>
					<BooleanView
						routeTo='/user/liabilities/liabilities-13'
						section='liabilities'
						property='isPreparedForPenalty'
						title='Are you prepared to pay these if you transfer/repay your other mortgage?'
						progress={77}
					/>
				</Route>

				<Route path={`${path}/liabilities-12`} exact>
					{user?.userStatus === 'Journey completed' ?
						<Liabilities12 />
						: <Redirect to='/user/liabilities/liabilities-13' />
					}
				</Route>

				<Route path={`${path}/liabilities-13`} exact>
					<BooleanView
						routeTo='/user/liabilities/liabilities-14'
						routeIfFalse='/user/stages'
						section='liabilities'
						property='hasOthers'
						title='Would you like to add other loans or financial commitments?'
						showOnly={false}
						saveData
						progress={91}
						deleteOnFalse={['others']}
					/>
				</Route>

				<Route path={`${path}/liabilities-14`} exact>
					<Liabilities14 />
				</Route>

				<Route path={`${path}/`}>
					<Redirect to='/user/liabilities/liabilities-1' />
				</Route>
			</Switch>
		</div>
	);
};

export default Liabilities;
