import {
	BorderButton,
	Input,
	JourneyNavLine,
	ProgressBar,
} from 'components/Widgets';
import { H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';

const PersonalDependent = () => {
	const history = useHistory();
	const FProgress = Number(readFromLocalStorage('_FPersonalPageProgress')) || 0;
	const [progress, setProgress] = useState(FProgress);
	const [{ user }, dispatch] = useDataLayerValue();
	const { personal } = user || {};
	const [dependentNumber, setDependentNumber] = useState(
		personal?.dependentNumber
	);

	const handleDependantSubmit = (e) => {
		e?.preventDefault();
		const currUser = { ...user };

		if (!currUser?.personal?.dependentNumber) {
			currUser.personal.dependentNumber = 0;
		}

		currUser.personal.dependentNumber = dependentNumber;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		storeInLocalStorage('dependentNumber', JSON.stringify(dependentNumber));
		setProgress(Math.floor(progress + 8.3));
		storeInLocalStorage('_FPersonalPageProgress', Math.floor(progress + 8.3));
		history.push('/user/personal/personal-dependent-fill-data');
	};

	const handleDependentNumber = (e) => {
		const { value } = e.target;
		if (value < 0) return;

		setDependentNumber(+value);
	};

	return (
		<div className="personal-container">
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className="personal-box">
				<div className="personal-details-header">
					<div className="personal-main-title">
						<H4>How many adult or children dependants do you have?</H4>
					</div>
				</div>
				<div className="personal-details-body">
					<form className="central-form-group" onSubmit={(e) => e}>
						<Input
							name="dependentNumber"
							type="number"
							normalInput
							label=""
							value={dependentNumber || ''}
							onChange={handleDependentNumber}
						/>
					</form>
					<div className="personal-details-btn ">
						<BorderButton
							value="Continue"
							width="368px"
							height="56px"
							backgroundColor="#0968E3"
							color="#fff"
							marginBottom="15%"
							onClick={handleDependantSubmit}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PersonalDependent;
