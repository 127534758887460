import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import Pool from './UserPool';
import { axiosInstance } from './AxiosConfig';

export const onCheckBoxEvent = (e: any, stateUpdater?: any) => {
	stateUpdater(e.target.checked);
};

type LoginResponse = {
	type?: string,
	user?: CognitoUser
}

export const onLoginEvent = (e: any, email: string, password: string, newPassword: string, sessionUser: CognitoUser) => {
	e?.preventDefault();

	if(sessionUser){
		return new Promise<LoginResponse>((resolve, reject) => {
			sessionUser.completeNewPasswordChallenge(newPassword, {  }, {
				onSuccess(e): void {
					resolve({});
				},
				onFailure: (e) => {
					reject(e);
				},
				newPasswordRequired: (userAttributes, requiredAttributes) => {
					// User was signed up by an admin and must provide new
					// password and required attributes, if any, to complete
					// authentication.

					resolve({ type: 'NEW_PASSWORD_REQUIRED' });
				},
			});
		});
	}

	const user = funcCognitoUser(email);
	let details = new AuthenticationDetails({
		Username: email,
		Password: password,
	});

	return new Promise<LoginResponse>((resolve, reject) => {
			user.authenticateUser(details, {
				onSuccess(e): void {
					resolve({});
				},
				onFailure: (e) => {
					reject(e);
				},
				newPasswordRequired: (userAttributes, requiredAttributes) => {
					// User was signed up by an admin and must provide new
					// password and required attributes, if any, to complete
					// authentication.

					// the api doesn't accept this field back
					delete userAttributes.email_verified;

					resolve({ type: 'NEW_PASSWORD_REQUIRED', user });
				},
			});
		},
	);
};

/**
 *
 * @returns true/false to indicate if a user is logged in or not
 */
export const isLoggedIn = () => {
	let isSessionValid = false;
	const cognitoUser = Pool.getCurrentUser();

	if (cognitoUser) {
		cognitoUser.getSession((err: Error, result) => {
			if (!err) {
				isSessionValid = result.isValid();
			}
		});
	}

	return isSessionValid;
};

export const getTokenPayloadDecoded = () => {
	const token = getCurrentUser().getSignInUserSession().getIdToken().getJwtToken();
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
};

export const getCurrentUser = () => {
	const user = Pool.getCurrentUser();
	user.getSession(() => {
	});
	return user;
};

export const funcCognitoUser = (email: string) => {
	return new CognitoUser({
		Username: email.toLowerCase(),
		Pool,
	});
};

export const changeUserEmail = async (newEmail: string) => {
	const attributes = [
		new CognitoUserAttribute({
			Name: 'email',
			Value: newEmail,
		}),
	];
	const cognitoUser = Pool.getCurrentUser();
	cognitoUser.getSession((error, session) => {
	});

	return new Promise((resolve, reject) => {
		cognitoUser.updateAttributes(attributes, (err, data) => {
			if (err) {
				reject(false);
				return;
			}

			resolve(true);
		});
	});
};

export const verifyEmailAttributeChange = async (verificationCode: string) => {
	const currentUser = Pool.getCurrentUser();
	currentUser.getSession(() => {
	});

	return new Promise((resolve, reject) => {
		currentUser.verifyAttribute('email', verificationCode, {
			onSuccess: result => {
				resolve(true);
			},
			onFailure: err => {
				console.error(err.message || JSON.stringify(err));
				reject(false);
			},
		});
	});
};

export const changeLoggedInUserPassword = async (
	oldPassword: string,
	newPassword: string,
) => {

	const cognitoUser = Pool.getCurrentUser();
	cognitoUser.getSession((err: { message: string }) => {
		if (err) return { message: err.message, success: false };
	});

	const changePassword = new Promise((resolve, reject) => {
		cognitoUser.changePassword(
			oldPassword,
			newPassword,
			function(err, result) {
				if (err) return reject(err);

				return resolve(result);
			},
		);
	});

	return changePassword
		.then(() => ({ success: true, message: 'Password changed successfully!' }))
		.catch(({ message }) => ({ success: false, message }));
};

/**
 * Store data in local storage
 * @params key, value
 */
export const storeInLocalStorage = (key: string, value: any) => {
	localStorage.setItem(key, JSON.stringify(value));
	return true;
};

/**
 * Read data from local storage
 * @params key
 */
export const readFromLocalStorage = (key: string) => {
	return localStorage.getItem(key);
};
export const GETRequest = (url: string, history: any) => {
	// Initiate Login
	return axiosInstance
		.get(url)
		.then((response) => {
			return {
				success: true,
				data: response.data,
			};
		})
		.catch((err) => unauthorizedFlow(err, history));
};

export const CreateUser = async (data: any) => {
	const attributeList: CognitoUserAttribute[] = [];

	// Add attributes to the attributeList
	attributeList.push(new CognitoUserAttribute({
		Name: 'email',
		Value: data.email,
	}));

	return new Promise((resolve, reject) => {
		Pool.signUp(data.email, data.password, attributeList, null, (err: { message: any; }, result: any) => {
			if (err) {
				reject({
					success: false,
					message: err.message,
				});
			} else {
				const cognitoUser = (result as { user: any }).user; // Type assertion here
				console.log(`User name is ${cognitoUser.getUsername()}`);
				resolve({
					success: true,
					message: `You have been successfully registered. Please verify your email to continue.`,
				});
			}
		});
	});
};

const unauthorizedFlow = (err, history) => {
	if (err?.response?.status === 401) {
		Pool.getCurrentUser()?.signOut();
		localStorage.clear();
		history.push('/auth');
	}
	return {
		success: false,
		error: err.response.data,
	};
};

export const POSTRequest = (url: string, data: any, history: any) => {
	// Initiate Login
	return axiosInstance
		.post(url, data)
		.then((response) => {
			return {
				success: true,
				data: response.data,
			};
		})
		.catch((err) => unauthorizedFlow(err, history));
};

export const PATCHRequest = (url: string, data: any, history: any) => {
	// Initiate Login
	return axiosInstance
		.patch(url, data)
		.then((response) => {
			return {
				success: true,
				data: response.data,
			};
		})
		.catch((err) => unauthorizedFlow(err, history));
};

export const PUTRequest = (url: string, data: any, history: any) => {
	// Initiate Login
	return axiosInstance
		.put(url, data)
		.then((response) => {
			return {
				success: true,
				data: response.data,
			};
		})
		.catch((err) => unauthorizedFlow(err, history));
};

export const DELETERequest = (url: string, data: any, history: any) => {
	// Initiate Login
	return axiosInstance
		.delete(url, data)
		.then((response) => {
			return {
				success: true,
				data: response.data,
			};
		})
		.catch((err) => unauthorizedFlow(err, history));
};
