export const OfferQuestionData = [
  {
    step: 0,
    id: 'isHighStreetBanks',
    question:
      'What is more important for you, speed of your mortgage completion or variety of options to choose from?',
    answers: [
      {
        value: true,
        text: 'Speed',
        yes: 'isFix5y',
      },
      {
        value: false,
        text: 'Variety',
        no: 'isFix5y',
      },
    ],
    tooltip:
      'If you say that speed is the key, we will only consider lenders with shorter turnaround times, usually, that means high street lenders such as Nationwide, Santander, Halifax etc. ',
  },
  {
    step: 1,
    id: 'isFix5y',
    question:
      'Would you like to have your monthly payment fixed for 5 or more years to be able to easier budget your spending and avoid uncertainty of interest rate changes?',
    answers: [
      {
        value: true,
        text: 'I want certainty',
        yes: 'isLowerMonthlyPayments',
      },
      {
        value: false,
        text: 'I want flexibility',
        no: 'isRefurbishIntent',
      },
    ],
    tooltip:
      'It is a good practice to have your mortgage payments fixed, especially if you are going to live in this is the property. This might slightly increase your monthly payments in comparison to a shorter-term fix but in exchange, you shall have certainty and the changes in the economic climate will not affect your monthly spending on your mortgage.',
  },
  {
    step: 2,
    id: 'isLowerMonthlyPayments',
    question:
      'Would you prefer to pay a product fee to the lender and get better initial interest rate or you do not want to pay any extra costs?',
    answers: [
      {
        value: true,
        text: 'Better rate with fee',
        yes: 'isAddFeesToLoan',
      },
      {
        value: false,
        text: 'No extra cost',
        no: 'isFreeLegals',
      },
    ],
    tooltip:
      'Lenders tend to offer products with and without fees. If you opt to pay the fee as a reward you can get a lower initial monthly interest rate. This fee can be added to the loan, however, this means you shall pay extra interest on the fee for the length of the mortgage which makes it much more expensive. You can choose to pay slightly higher initial interest but this will only be the case for the first few years (the length of your fixed period)',
  },
  {
    step: 3,
    id: 'isAddFeesToLoan',
    question: 'Would you like to add the product fee to your mortgage?',
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isFreeLegals',
      },
      {
        value: false,
        text: 'No',
        no: 'isFreeLegals',
      },
    ],
    tooltip:
      'Adding an initial fee to your mortgage may seem like a good idea if you want to save money but you have to bear in mind that you shall pay back much more in interest as a result. Your fee will be spread across the entire length of the mortgage. ',
  },
  {
    step: 4,
    id: 'isFreeLegals',
    question:
      "Would you like the lender to pay your legal and/or valuation fees if that's possible?",
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isFeeCashBack',
      },
      {
        value: false,
        text: 'No',
        no: 'isFeeCashBack',
      },
    ],
    tooltip:
      'Sometimes lenders offer to cover your fees in relation to valuation or legal work. This however tends to be reflected in higher interest. These fees tend to be in a region of £300 for valuation and £1000 for legal work. ',
  },
  {
    step: 5,
    id: 'isFeeCashBack',
    question:
      "Would you like to receive cash back or have fees refunded from the lender upon completion of your mortgage if that's possible?",
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isPaymentHoliday',
      },
      {
        value: false,
        text: 'No',
        no: 'isPaymentHoliday',
      },
    ],
    tooltip:
      'Sometimes lenders offer cashback or fees refund upon completion. This however may result in a higher interest rate and higher monthly installments',
  },
  {
    step: 6,
    id: 'isPaymentHoliday',
    question: 'Would you like to be able to take payment holidays?',
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isOverpayment',
      },
      {
        value: false,
        text: 'No',
        no: 'isOverpayment',
      },
    ],
    tooltip:
      'This option tends to be widely available. There usually are some set rules regarding when and under what circumstances you can take a payment holiday. Standard payment holiday will last for up to 6 months and your interest will be rolled over so your resumed monthly payments will be higher than before the payment holiday.',
  },
  {
    step: 7,
    id: 'isOverpayment',
    question: 'Would you like to be able to make overpayments?',
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isOffset',
      },
      {
        value: false,
        text: 'No',
        no: 'isOffset',
      },
    ],
    tooltip:
      'This option tends to be widely available. There usually are some set rules regarding how much you can overpay. Usually, that is up to 10% of your mortgage each year. ',
  },
  {
    step: 8,
    id: 'isOffset',
    question:
      'Would you like to be able to offset your mortgage against your current account or savings in order to save in interest?',
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isBorrowBack',
      },
      {
        value: false,
        text: 'No',
        no: 'isBorrowBack',
      },
    ],
    tooltip:
      'If you plan to put down a higher deposit, at least 15% of the property value, you may be eligible for an offset or overdraft mortgage. This means that the lender will take security over your savings account and calculate the interest-only using the amount reduced by the amount of savings you provided as security. In the case of the current account mortgage, the interest will be calculated using the actual balance of your account (overdraft). This can provide significant savings over the term. Bear in mind however you will not be able to use your savings while they are used as a security against your mortgage. ',
  },
  {
    step: 9,
    id: 'isBorrowBack',
    question:
      'Would you like to be able to borrow some money back from the lender after a period of time?',
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isPortable',
      },
      {
        value: false,
        text: 'No',
        no: 'isPortable',
      },
    ],
    tooltip:
      'If you plan to put down a higher deposit, at least 15% of the property value, you may be eligible for a mortgage that offers a borrowing facility. ',
  },
  {
    step: 10,
    id: 'isPortable',
    question:
      'Do you plan to own more than one property in the future or would you rather sell one property in order to buy another one?',
    answers: [
      {
        value: true,
        text: 'Own more than one',
        yes: 'isNonStandardConstruction',
      },
      {
        value: false,
        text: 'Sell before buying new one',
        no: 'isNonStandardConstruction',
      },
    ],
    tooltip:
      'Some products offer a portability option. If your mortgage has one you have an option to carry your mortgage with you to the new property without having to apply again. The difference in the price of the new property and the balance of your mortgage will have to be covered by the deposit. This may prove to be a cost-effective option and can save a lot of hassle while buying a new property. ',
  },
  {
    step: 11,
    id: 'isNonStandardConstruction',
    question:
      'Is the property you intend to buy of a standard construction (brick and mortar)?',
    answers: [
      {
        value: true,
        text: 'Yes',
        // yes: 'isHighStreetBanks',
        yes: 'final',
      },
      {
        value: false,
        text: 'No',
        // no: 'isHighStreetBanks',
        no: 'final',
      },
    ],
    tooltip:
      'The majority of the mortgage products are dedicated to purchasing standard build properties. If the property you intend to buy has some non-standard elements to it like concrete walls or steel elements of construction a standard mortgage product may not be suitable for the purchase. If this is the case you will have to speak to one of our mortgage specialists to find the right product. ',
  },
  {
    step: 12,
    id: 'isRefurbishIntent',
    question:
      'Do you intend to substantially refurbish your house following the purchase? For example, adding a bedroom, making an extension or loft conversion etc?',
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isRemortgageIntent',
      },
      {
        value: false,
        text: 'No',
        no: 'isLowerMonthlyPayments',
      },
    ],
    tooltip:
      'If it is your intention to refurbish your property following the purchase and as a result increase the value of the property we will focus on mortgages that provide the flexibility to remortgage as soon as possible.',
  },
  {
    step: 13,
    id: 'isRemortgageIntent',
    question:
      'Would you like to have an option to remortgage as soon as the refurbishment work has finished to reflect the increase in value of the property and lower your monthly payments?',
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isOverpayment',
      },
      {
        value: false,
        text: 'No',
        no: 'isSellImmediately',
      },
    ],
    tooltip:
      'If you intend to remortgage your property upon completion of your planned refurbishment work, we will focus on mortgages that allow doing so with minimum or no early repayment fees. ',
  },
  {
    step: 14,
    id: 'isSellImmediately',
    question:
      'Do you intend to sell your property immediately upon the completion of the refurbishment work?',
    answers: [
      {
        value: true,
        text: 'Yes',
        yes: 'isHighStreetBanks',
      },
      {
        value: false,
        text: 'No',
        no: 'isHighStreetBanks',
      },
    ],
    tooltip:
      'If you intend to sell your property upon the completion of your planned refurbishment work, we will look for mortgages with no early repayment charges. It is also recommended you speak to one of our mortgage specialists to consider different option available to you to finance the purchase. ',
  },
];
