import { Link } from 'react-router-dom';
import 'css/QuestionHeader.css';
import { BorderButton } from 'components/Widgets';
import getLogoSrc from 'utils/getLogoSrc';

const QuestionHeader = () => {
  return (
    <div className="header-container">
      <div className="header-logo">
        <img src={getLogoSrc()} alt="Logo" />
      </div>
      <div className="header-login-button">
        <Link to={'/auth'}>
          <BorderButton value="Log in" backgroundColor="#0968e3" color="#fff" />
        </Link>
      </div>
    </div>
  );
};

export default QuestionHeader;
