import { useEffect, useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';

const CreateProfileMortgageYears = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 50 : 75);
	const [mortgageTerms, setMortgageTerms] = useState(
		user?.cases?.[0].application?.mortgageTerms || '',
	);
	const [isComplete, setComplete] = useState<boolean>(false);

	useEffect(() => {
		setComplete(!!mortgageTerms && mortgageTerms >= 5 && mortgageTerms <= 35);
	}, [mortgageTerms]);

	// end of states that control form data
	const onSubmit = () => {
		user.cases[0].application.mortgageTerms = mortgageTerms;

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		history.push('/user/create-profile-partner');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='Over how many years do you want to pay back your mortgage?'
					subTitle='This is called the mortgage term. 25 years is a common length for a mortgage term but It can be anything from 5 to 35 years. A longer term usually means lower payments, but more interest to pay.'
					continueBtn
					onClick={onSubmit}
					buttonDisabled={!isComplete}
				>
					<Input
						value={mortgageTerms}
						required
						type='number'
						normalInput
						name='mortgageTerms'
						label='5 to 35 years'
						autoFocus={true}
						placeholder='5 to 35 years'
						onChange={(e) => {
							if (!isNaN(e.target.value)) {
								setMortgageTerms((e.target.value * 1).toString());
							}
						}}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileMortgageYears;
