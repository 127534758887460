import { useEffect, useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, SelectInput, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';
import { titleOptions } from '../../utils/DropDownList';
import { PATCHRequest } from '../../utils/AuthFunctions';

const CreateProfilePersonal = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 8.3 : 12.5);

	const [title, setTitle] = useState(user?.cases?.[0]?.application?.title || '');
	const [firstName, setFirstName] = useState(user?.cases?.[0]?.application?.firstName || '');
	const [lastName, setLastName] = useState(user?.cases?.[0]?.application?.lastName || '');
	const [mobileNumber, setMobileNumber] = useState(user?.cases?.[0]?.application?.mobileNumber || '');
	const [isComplete, setComplete] = useState<boolean>(false);

	useEffect(() => {
		let mobileValid = false;
		if ((mobileNumber || '').length > 1) {
			// eslint-disable-next-line no-useless-escape
			mobileValid = !!String(mobileNumber).match(/^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/);
		}

		setComplete(!!title && !!firstName && !!lastName && mobileValid);
	}, [title, firstName, lastName, mobileNumber]);

	const onSubmit = async () => {
		user.cases[0].application.title = title;
		user.cases[0].application.firstName = firstName;
		user.cases[0].application.lastName = lastName;
		user.cases[0].application.mobileNumber = mobileNumber;
		user.personal = {
			title,
			firstName,
			lastName,
			mobileNumber,
			birthDate: user.cases[0].application.birthday
		};

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		await PATCHRequest(
			`/users?attribute=application`,
			user.cases[0].application,
			history,
		);

		history.push('/user/create-profile-birthday');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} step={0}>
				<CreateProfileContent
					active
					title='Personal details'
					subTitle='Tell us your basic details so we can contact you in case we need some more information to get you the best deal.'
					continueBtn
					onClick={onSubmit}
					buttonDisabled={!isComplete}
				>
					<SelectInput
						name='title'
						autoFocus={true}
						defaultValue={title || null}
						options={titleOptions}
						label='Select Title'
						onSelect={setTitle}
					/>

					<Input
						value={firstName}
						normalInput
						required
						label='First Name'
						placeholder='First Name'
						onChange={(e) => setFirstName(e.target.value)}
					/>

					<Input
						value={lastName}
						normalInput
						required
						label='Last Name'
						placeholder='Last Name'
						onChange={(e) => setLastName(e.target.value)}
					/>

					<Input
						value={mobileNumber || ''}
						name={'mobileNumber'}
						type='number'
						normalInput
						label={'Mobile number'}
						placeholder='Mobile number'
						onChange={(e) => setMobileNumber(e.target.value)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfilePersonal;
