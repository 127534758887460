import 'css/Settings.css';
import { Bold, SubTitle } from 'typography';
import clsx from 'clsx';
interface IInfoBox {
  title?: string;
  description?: string;
  onClick?: () => void;
  buttonText?: string;
  disabled?: boolean;
  textDisabled?: boolean;
  isDesktop?: boolean;
}
const InfoBox = (props: IInfoBox) => {
  return (
    <div className={clsx('info-box', props.isDesktop && 'hidden')}>
      <div className="info-box-content">
        <div className="left-side">
          <div className="info-box-title info-disabled">
            <Bold isBold={false}> {props.title} </Bold>
          </div>
          <div
            className={clsx(
              'info-box-description',
              props.disabled && 'info-disabled'
            )}
          >
            <SubTitle isBold={false}> {props.description} </SubTitle>
          </div>
        </div>

        <button
          onClick={props.onClick}
          className={clsx('change-btn', props.disabled && 'buttonDisabled')}
        >
          <Bold>{props.buttonText}</Bold>
        </button>
      </div>
    </div>
  );
};

export default InfoBox;
