import NavBar from './NavBar';
import WorkSpace from './WorkSpace';

function WorkArea() {
  return (
    <section className="app-workspace">
      <NavBar />
      <WorkSpace />
    </section>
  );
}

export default WorkArea;
