import { useState } from 'react';
import { SmallBold, SubTitle } from 'typography';
import carreticon from 'assets/carret-down.svg';
import { AuthLogout } from 'utils/AxiosConfig';
import { useHistory } from 'react-router-dom';

interface INavProfileAvatar {
  firstname: string;
  lastname: string;
}

const NavProfileAvatar = (props: INavProfileAvatar) => {
  const history = useHistory();
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const handleLogout = () => {
    setShowProfileMenu(!showProfileMenu);
    //do not change this user call to cases...
    AuthLogout();
    history.push('/');
  };

  return (
    <section className="flex navbar-user-profile">
      <div
        onClick={() => setShowProfileMenu(!showProfileMenu)}
        className="flex navbar-profile"
      >
        <div className="navbar-avatar">
          <SubTitle isBold={false}>{`${props.firstname.substr(
            0,
            1
          )}${props.lastname.substr(0, 1)}`}</SubTitle>
          {/* <img
      src="https://media-exp1.licdn.com/dms/image/D4E35AQHxaO3w-swMhQ/profile-framedphoto-shrink_800_800/0/1628001397481?e=1628622000&v=beta&t=u6jH7ND4_65vWqnqjFqV4aFB1aF7LueZZ-4zzdY6zoo"
      alt=""
    /> */}
        </div>
        <div className="navbar-profile-name">
          <SmallBold>{`${props.firstname} ${props.lastname}`}</SmallBold>
        </div>
        <div
          className={
            showProfileMenu ? 'navbar-caret rotate-180' : 'navbar-caret'
          }
        >
          <img src={carreticon} alt="" />
        </div>
      </div>
      {showProfileMenu && (
        <div onClick={handleLogout} className="navbar-logout">
          Log out
        </div>
      )}
    </section>
  );
};

export default NavProfileAvatar;
