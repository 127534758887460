import {
  Caption,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Overline,
  SmallBold,
  SmallSubTitle,
  SubTitle,
} from '.';

function TypoGraphies() {
  return (
    <div>
      <div>
        H1 <br />
        <H1>Hey man</H1>
      </div>
      <div>
        H2 <br />
        <H2>Wanna do this</H2>
      </div>
      <div>
        H3 <br />
        <H3>Wanna do this</H3>
      </div>
      <div>
        H4 <br />
        <H4>Wanna do this</H4>
      </div>
      <div>
        H5 <br />
        <H5>Wanna do this</H5>
      </div>
      <div>
        H6 <br />
        <H6>Wanna do this</H6>
      </div>
      <SubTitle>SubTitle</SubTitle>
      <SmallSubTitle>SmallSubTitle</SmallSubTitle>
      <SmallBold>SmallBold</SmallBold>
      <Caption>Caption</Caption>
      <Overline>Overline</Overline>
    </div>
  );
}

export default TypoGraphies;
