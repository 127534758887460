import { matchPath, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
// icon
import home from 'assets/home.svg';
import homeFilled from 'assets/home-filled.svg';
// doc icon
import navdoc from 'assets/nav-doc.svg';
import navdocblue from 'assets/nav-doc-blue.svg';
// notify icon
// import navnotify from 'assets/nav-notify.svg';
// import navnotifyblue from 'assets/nav-notify-blue.svg';
// person icon
import navperson from 'assets/nav-person.svg';
import navpersonblue from 'assets/nav-person-blue.svg';

const MobileBottomNav = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const funcCheckRouteMatch = (navUrl: string) => {
    return matchPath(history.location.pathname, {
      path: navUrl,
      exact: false,
      strict: false,
    });
  };

  return (
    <div className="flex mobile-bottom-nav">
      {/* Application */}
      <NavLink to={`${path}/applications`} className="mobile-link">
        <img
          className="mobile-app"
          src={
            funcCheckRouteMatch(`${path}/applications`)
              ? homeFilled
              : home
          }
          alt=""
        />
      </NavLink>

      {/* Documents */}
      <NavLink to={`${path}/documents`} exact className="mobile-link">
        <img
          src={funcCheckRouteMatch(`${path}/documents`) ? navdocblue : navdoc}
          alt=""
        />
      </NavLink>

      {/* Notification */}
      {/* <NavLink to={`${path}/notification`} exact className="mobile-link">
        <img
          src={
            funcCheckRouteMatch(`${path}/notification`)
              ? navnotifyblue
              : navnotify
          }
          alt=""
        />
      </NavLink> */}

      {/* Profile / Settings */}
      <NavLink to={`${path}/settings`} className="mobile-link">
        <img
          src={
            funcCheckRouteMatch(`${path}/settings`) ? navpersonblue : navperson
          }
          alt=""
        />
      </NavLink>
    </div>
  );
};

export default MobileBottomNav;
