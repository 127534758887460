/* eslint-disable @typescript-eslint/no-unused-vars */
import { Progress } from 'antd';
import { Loader, OfferSlab } from 'components/Widgets';
import { Bold, H2, H6 } from 'typography';
import { useDataLayerValue } from 'context-api/DataLayer';
import { ApplicationB2CAccordions } from '.';

const DesktopApplication = () => {
	const [{ user }] = useDataLayerValue();
	const offer = user?.productCandidate?.mortgageCalculatedFields;

	return (
		<>
			{user ? (
				<div className='desktop-application'>
					<header className='flex home-header application-header'>
						<span className='home-header-desc'>
							<H2>Welcome, {user?.personal?.firstName}</H2>
						</span>
						<H6>
							Here you can review the information you gave us, upload additional documents, check and download the
							offers, correspondence and other documents created for you.
						</H6>
					</header>
					<footer className='app-paper'>
						<header className='flex application-foot-header'>
							<div className='application-main-content'>
								<div className='application-paper-hero-text'>
									<H6>Mortgage Application</H6>
									{/* <Bold isBold={false} opacity={0.5}>
										Please complete your application
									</Bold> */}
								</div>

								<section onClick={() => console.log(offer)} className='application-grid'>
									{/* {user?.productCandidate?.productMortgageType
										?.displayImageID && (
										<div className='lender-icon'>
											<img
												src={`${process.env.PUBLIC_URL}/bank-logos/${user?.productCandidate?.productMortgageType?.displayImageID}.png`}
												alt='lender logo'
											/>
										</div>
									)} */}

									<OfferSlab
										label={'Property Price'}
										value={`£${user?.cases?.[0]?.application?.propertyValue?.toLocaleString()}`}
									/>
									<OfferSlab
										label={'Loan Amount'}
										value={`£${(
											user?.cases?.[0]?.application?.propertyValue - user?.cases?.[0]?.application?.deposit
										).toLocaleString()}`}
									/>

									{/* <OfferSlab
										label={'APR'}
										value={`${offer?.apr}%`}
										tooltip={
											'The Annual Percentage Rate (APR) is the cost you pay each year to borrow money, including fees, expressed as a percentage.'
										}
									/>

									<OfferSlab
										label={'Monthly Repayment'}
										value={`£${offer?.initialMonthlyPayment}`}
										tooltip={
											'Your monthly repayment during the initial period of the mortgage'
										}
									/> */}
								</section>
							</div>

							<aside className='application-diagram'>
								<Progress type='circle' width={150} percent={100} />
								<div className='is-application-complete'>
									<Bold>Application Completed</Bold>
								</div>
							</aside>
						</header>
						{/* <section className='application-footer'>
							<NavLink to={`${path}details`}>
								<LightBlueButton value='View My Application' />
							</NavLink>
						</section> */}
					</footer>

					{/* ACCORDIONS */}
					<ApplicationB2CAccordions />

					{/* EDIT MODALS */}
					{/* <EditPersonal1 /> */}
				</div>
			) : (
				<Loader />
			)}
		</>
	);
};

export default DesktopApplication;
