import { FC } from 'react';
import { Typography } from '../types/typography';

const H2: FC<Typography> = ({ children, size, isBold, opacity }) => {
  return (
    <h2
      style={{
        fontWeight: isBold === false ? 'normal' : 'bold',
        opacity: opacity ? opacity : 1,
      }}
      className="H2"
    >
      {children}
    </h2>
  );
};

export default H2;
