import React from 'react';
import getLogoSrc from 'utils/getLogoSrc';

const Footer = () => {
	return (
		<div className='all-footer'>
			<div>
				<img src={getLogoSrc()} alt='Logo' width='240' className='margin-bottom' />
				<div className='flex margin-bottom'>
					<div className='flex-column'>
						<div className='uppercase footer-question'>Got a question?</div>
						<div className='uppercase footer-question'>Speak to a mortgage expert today</div>
						<a className='footer-number' href='tel:0800 999 3939'>0800 999 3939</a>
						<a href='mailto:info@accessfs.co.uk' className='footer-links uppercase'>info@accessfs.co.uk</a>
					</div>
					<div className='flex-column legal'>
						<h5>LEGALS</h5>
						<a className='footer-links' href='https://www.accessfs.co.uk/vulnearable-customer-policy' target='_blank' rel='noreferrer'>
							Vulnerable customer policy
						</a>
						<a className='footer-links' href='https://www.accessfs.co.uk/legals/AFS-Terms-of-Business.pdf' target='_blank' rel='noreferrer'>
							Terms of Business
						</a>
						<a className='footer-links' href='https://www.accessfs.co.uk/complaints-policy' target='_blank' rel='noreferrer'>
							Complaints Policy
						</a>
						<a className='footer-links' href='https://www.accessfs.co.uk/legals/Privacy.pdf' target='_blank' rel='noreferrer'>
							Privacy Policy
						</a>
						<a className='footer-links' href='https://www.accessfs.co.uk/cookie-policy.php' target='_blank' rel='noreferrer'>
							Cookie Policy
						</a>
					</div>
				</div>
				<p className='margin-bottom'>
					Access Financial Services Ltd are regulated by the Financial Conduct Authority. The Financial Services
					Register number is 301173. Registered in England No. 04427489. Registered office address for Access Financial
					Services Ltd is 8 St. James’s Square London, SW1Y 4JU. Copyright 2023. All rights reserved.
				</p>
				<p className='foot_er'>powered by Access financial Co &copy;{new Date().getFullYear()}</p>
			</div>
		</div>
	);
};

export default Footer;
