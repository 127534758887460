/* eslint-disable @typescript-eslint/no-unused-vars */
import { Progress } from 'antd';
import { LightBlueButton, OfferSlab, Loader } from 'components/Widgets';
import FloatingActionButton from 'components/Widgets/FloatingActionButton';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useRouteMatch } from 'react-router-dom';
import { Bold, H4, SubTitle } from 'typography';
import { ApplicationB2CAccordions } from '.';

const MobileApplication = () => {
	const { path } = useRouteMatch();
	const [{ user }] = useDataLayerValue();
	const offer = user?.productCandidate?.mortgageCalculatedFields;

	return (
		<>
			{user ? (
				<div className='mobile-application'>
					<div className='header-title'>
						<H4>My Applications</H4>
					</div>

					<div className='mobile-application-header'>
						<Bold isBold={false} opacity={0.5}>
							Mortgage Application
						</Bold>
					</div>

					<div className='mobile-desktop'>
						<header className='mob-application-header'>
							{/* <img
								src={`${process.env.PUBLIC_URL}/bank-logos/${30003}.png`}
								alt='lender logo'
							/> */}

							<SubTitle isBold>Mortgage Application</SubTitle>

							<Progress type='circle' width={60} percent={100} />
						</header>

						<section className='mob-offer-values'>
							<OfferSlab
								label={'Property Price'}
								value={`£${user?.cases?.[0]?.application?.propertyValue?.toLocaleString()}`}
							/>

							<OfferSlab
								label={'Loan Amount'}
								value={`£${(user?.cases?.[0]?.application?.propertyValue - user?.cases?.[0]?.application?.deposit).toLocaleString()}`}
							/>
						</section>
					</div>

					{/* ACCORDIONS */}
					<ApplicationB2CAccordions />
				</div>
			) : (
				<Loader />
			)}
		</>
	);
};

export default MobileApplication;
