import {
	CleanRadioInput,
	JourneyNavLine,
	ProgressBar,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const DepositSource = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [depositSource, setDepositSource] = useState(
		user?.property?.depositSource
	);

	const setAnswer = (answer: any) => {
		setDepositSource(answer);

		const currUser = { ...user };
		if (!currUser?.property) {
			currUser.property = {};
		}

		currUser.property.depositSource = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			history.push('/user/property/purchase-property-have-any-occupants');
		}, 300);
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={84} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>What is the source of the deposit?</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={(e) => e}>
						<CleanRadioInput
							name={'depositSource'}
							label={'Salary'}
							value={'salary'}
							checked={depositSource === 'salary'}
							onClick={() => setAnswer('salary')}
						/>

						<CleanRadioInput
							name={'depositSource'}
							label={'Investment'}
							onClick={() => setAnswer('investment')}
							value={'investment'}
							checked={depositSource === 'investment'}
						/>

						<CleanRadioInput
							name={'depositSource'}
							label={'Gift'}
							onClick={() => setAnswer('gift')}
							value={'gift'}
							checked={depositSource === 'gift'}
						/>

						<CleanRadioInput
							name={'depositSource'}
							label={'Savings'}
							onClick={() => setAnswer('savings')}
							value={'savings'}
							checked={depositSource === 'savings'}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default DepositSource;
