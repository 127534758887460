import { Route, Switch, useRouteMatch } from 'react-router-dom';
import 'css/Application.css';
import { ApplicationHome, ApplicationDetails } from '.';
import { useDataLayerValue } from 'context-api/DataLayer';
import { Loader } from 'components/Widgets';

const Applications = () => {
  const { path } = useRouteMatch();
  const [{ user }] = useDataLayerValue();

  return (
    <section className="application-box">
      <Switch>
        <Route exact path={`${path}/`}>
          <ApplicationHome />
        </Route>

        <Route exact path={`${path}/details`}>
          {!user ? <Loader /> : <ApplicationDetails />}
        </Route>
      </Switch>
    </section>
  );
};

export default Applications;
