import clsx from 'clsx';
import {Bold} from 'typography';

interface Props {
  isChecked?: boolean;
  value?: string;
  onClick: () => void;
  SelectionText?: string;
  register?: any;
  name?: string;
  id?: string;
}
export const RadioInputBoolean = (props: Props) => {
  return (
    <div className="question-container">
      <div className="question-selection">
        <div className="question-select-button" onClick={() => props.onClick()}>
          <input
            type="radio"
            name={props.name}
            id={props.id}
            value={props.value}
            checked={props.isChecked}
            onChange={() => {}}
          />
          <label
            className={clsx('question-label-radio')}
            htmlFor={props.id}
          >
            <Bold isBold={false}> {props.SelectionText}</Bold>
          </label>
        </div>
      </div>
    </div>
  );
};

export default RadioInputBoolean;
