import { Progress } from 'antd';
import { LightBlueButton, OfferSlab } from 'components/Widgets';
// import BorderButton from 'components/Widgets/BorderButton';
import { useRouteMatch } from 'react-router-dom';
import { Bold } from 'typography';
// import AppInfoBox from './AppInfoBox';
// import personblueicon from 'assets/person-blue.svg';
// import bankblueicon from 'assets/bank-blue.svg';
// import propertyicon from 'assets/property.svg';
import FloatingActionButton from 'components/Widgets/FloatingActionButton';
import { useDataLayerValue } from 'context-api/DataLayer';

const MobileApplicationDetails = () => {
  const { path } = useRouteMatch();
  const [{ user }] = useDataLayerValue();
  const offer = user?.productCandidate?.mortgageCalculatedFields;

  return (
    <div className="mobile-application-details">
      <div className="mobile-application-header">
        <Bold isBold={false} opacity={0.5}>
          Full Mortgage Application
        </Bold>
      </div>

      <div className="mobile-desktop">
        <header className="mob-application-header">
          <img
            src={`${process.env.PUBLIC_URL}/bank-logos/${30003}.png`}
            alt="lender logo"
          />

          <Progress type="circle" width={60} percent={100} />
        </header>

        <section className="mob-offer-values">
          <OfferSlab
            label={'Initial rate'}
            value={`${offer?.initialInterestRate}%`}
            tooltip={
              'The initial rate is the rate that is charged during the introductory rate of a mortgage'
            }
          />

          <OfferSlab
            label={'APR'}
            value={`${offer?.apr}%`}
            tooltip={
              'The Annual Percentage Rate (APR) is the cost you pay each year to borrow money, including fees, expressed as a percentage.'
            }
          />

          <OfferSlab
            label={'Follow up rate'}
            value={`${offer?.followOnInterestRate}%`}
            tooltip={'The rate charged after the initial period'}
          />

          <OfferSlab
            label={'Initial monthly payment '}
            value={`£${offer?.initialMonthlyPayment?.toLocaleString()}`}
            tooltip={
              'Your monthly repayment during the initial period of the mortgage'
            }
          />

          <OfferSlab
            label={'Initial period rate'}
            value={`${Math.floor(
              offer?.initialInterestPeriodInDays / 30
            )} months`}
            tooltip={'Length of the initial period'}
          />

          <OfferSlab
            label={'Total fees'}
            value={`£${offer?.totalFees?.toLocaleString()}`}
            tooltip={'Lender fees such as valuation, admin and/or product fee'}
          />

          <OfferSlab
            label={'Follow up monthly payments'}
            value={`£${offer?.followOnMonthlyPayment?.toLocaleString()}`}
            tooltip={'Monthly payment after the initial period has ended'}
          />

          <OfferSlab
            label={'Total amount payable'}
            value={`£${offer?.totalToPay?.toLocaleString()}`}
            tooltip={
              'The total amount you will have to repay to the lender if you keep your deal for the full length'
            }
          />

          <div className="flex apply-btn-list">
            <LightBlueButton
              link={`${path}mortgage-illustration`}
              value="Mortgage Illustration"
            />
            <LightBlueButton
              link={`${path}suitability-report`}
              value="Suitability Report"
            />
            <LightBlueButton
              link={`${path}compare-mortgages`}
              value="Compare Mortgages"
            />
          </div>
        </section>
      </div>

      {/* <div className="mobile-application-header">
        <Bold isBold={false} opacity={0.5}>
          Complete your application
        </Bold>
      </div>

      <div className="apply-infobox-holder">
        <AppInfoBox title="Personal" icon={personblueicon} isComplete={true} />
        <AppInfoBox title="Financial" icon={bankblueicon} isComplete={true} />
        <AppInfoBox
          title="Property"
          icon={propertyicon}
          component={<BorderButton value="Complete" />}
        />
      </div>

      <button disabled className="mob-blue-btn">
        Submit
      </button> */}

      <FloatingActionButton />
    </div>
  );
};

export default MobileApplicationDetails;
