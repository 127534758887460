import { useDataLayerValue } from 'context-api/DataLayer';
import { DocCard, DocEmpty, DocType } from '.';

const MyDocsPage = () => {

	const [{ docs }] = useDataLayerValue();

	return (
		<>
			{docs && Object.keys(docs).length > 0 ? (
				<section className='mydocuments-grid'>
					{docs[DocType.MAIN_ID]?.map((file, id) =>
						<DocCard
							key={file?.fileName}
							description={`Your ID #${id + 1}`}
							link={file?.url}
							fileType={file?.fileType}
							fileName={file?.fileName}
							timestamp={file?.timestamp}
						/>,
					)}

					{docs[DocType.MAIN_PAYSLIPS]?.map((file, id) =>
						<DocCard
							key={file?.fileName}
							description={`Payslips or accounts #${id + 1}`}
							link={file.url}
							fileType={file?.fileType}
							fileName={file?.fileName}
							timestamp={file.timestamp}
						/>,
					)}

					{docs[DocType.MAIN_BANK_STATEMENTS]?.map((file, id) =>
						<DocCard
							key={file?.fileName}
							description={`Bank Statement #${id + 1}`}
							link={file?.url}
							fileType={file?.fileType}
							fileName={file?.fileName}
							timestamp={file?.timestamp}
						/>,
					)}

					{docs[DocType.MAIN_DEPOSIT]?.map((file, id) =>
						<DocCard
							key={file?.fileName}
							description={`Proof of Deposit #${id + 1}`}
							link={file?.url}
							fileType={file?.fileType}
							fileName={file?.fileName}
							timestamp={file?.timestamp}
						/>,
					)}

					{docs[DocType.MAIN_ADDRESS]?.map((file, id) =>
						<DocCard
							key={file?.fileName}
							description={`Proof of Address #${id + 1}`}
							link={file?.url}
							fileType={file?.fileType}
							fileName={file?.fileName}
							timestamp={file?.timestamp}
						/>,
					)}

					{docs[DocType.AGENT]?.map((file, id) =>
						<DocCard
							key={file?.fileName}
							description={`Uploaded by agent #${id + 1}`}
							link={file?.url}
							fileType={file?.fileType}
							fileName={file?.fileName}
							timestamp={file?.timestamp}
						/>,
					)}

					{docs[DocType.OPEN_BANKING]?.filter(file => file.fileName.startsWith('Affordability Report')).map(file =>
						<DocCard
							description='Affordability Report'
							link={file.url}
							fileType={file?.fileType}
							timestamp={file?.timestamp}
						/>,
					)}

					{docs[DocType.OPEN_BANKING]?.filter(file => file.fileName.startsWith('Identity Report')).map(file =>
						<DocCard
							description='Identity Report'
							link={file.url}
							fileType={file?.fileType}
							timestamp={file?.timestamp}
						/>,
					)}
				</section>
			) : (
				<DocEmpty />
			)}
		</>
	);
};

export default MyDocsPage;
