import { BorderButton, CleanInput, Input, JourneyNavLine, ProgressBar, SelectInput } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { BasisOfEmployment } from 'utils/FinancialData';

const Applicant2CurrentEmp1 = () => {
	const history = useHistory();

	const [{ user }, dispatch] = useDataLayerValue();
	const { financial2 } = user || {};
	const { application } = user?.cases?.[0] || {};
	const secondAppData = application?.partner;

	const [employmentType, setEmploymentType] = useState(
		financial2?.employmentBasis || null,
	);
	const [isComplete, setIsComplete] = useState(false);
	const [textInfo, setTextInfo] = useState({
		// employerName: financial2?.employerName || '',
		jobTitle: financial2?.jobTitle || '',
		employmentStartDate: financial2?.employmentStartDate || '',
		plannedRetirement: financial2?.plannedRetirement || '',
	});

	useEffect(() => {
		if (
			// textInfo['employerName'] !== '' &&
			textInfo.jobTitle !== '' &&
			textInfo.employmentStartDate !== '' &&
			employmentType !== ''
		) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [textInfo, employmentType]);

	const onSubmit = (e: any) => {
		e?.preventDefault();
		const currFinancial = {
			...financial2,
			...textInfo,
			employmentBasis: employmentType,
		};

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial2: currFinancial },
		});

		history.push('/user/financial/second-applicant/current-employment/extras');
	};

	return (
		<div className='financial-pages'>
			<ProgressBar progress={40} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>{`${
					secondAppData?.firstName?.charAt(0).toUpperCase() +
					secondAppData?.firstName?.slice(1)
				}'s employment details`}</H4>

				<form className='central-form-group' onSubmit={onSubmit}>
					<SelectInput
						label='Basis of the employment'
						name={'employment-details'}
						options={BasisOfEmployment}
						defaultValue={employmentType || null}
						onSelect={(value: string) => setEmploymentType(value)}
					/>

					{user.userStatus === 'Journey completed' ?
						<CleanInput
							value={textInfo['employerName']}
							label='Name of the employer'
							type='text'
							placeholder='text'
							required
							onChange={(e: any) =>
								setTextInfo((prev) => ({ ...prev, employerName: e.target.value }))
							}
						/> : <></>
					}

					<CleanInput
						value={textInfo['jobTitle']}
						label='Job title'
						type='text'
						placeholder='text'
						required
						onChange={(e: any) =>
							setTextInfo((prev) => ({ ...prev, jobTitle: e.target.value }))
						}
					/>
					<Input
						value={textInfo.employmentStartDate}
						normalInput
						label='Employment start date'
						name='employmentStartDate'
						type='date'
						placeholder='text'
						width='460px'
						onChange={(e: any) =>
							setTextInfo((prev) => ({
								...prev,
								employmentStartDate: e.target.value,
							}))
						}
						required
					/>

					{user.userStatus === 'Journey completed' ?
						<CleanInput
							value={textInfo['plannedRetirement']}
							label='At what age do you plan to retire?'
							type='text'
							placeholder='text'
							required
							onChange={(e: any) =>
								setTextInfo((prev) => ({
									...prev,
									plannedRetirement: e.target.value,
								}))
							}
						/> : <></>
					}

					<div className={`continue-btn ${!isComplete ? 'isDisabled' : ''}`}>
						<BorderButton
							value='Continue'
							width='368px'
							disabled={!isComplete}
							type='submit'
							height='56px'
							backgroundColor='#0968E3'
							color='#fff'
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Applicant2CurrentEmp1;
