import BorderButton from './BorderButton';
import 'css/StepContent.css';
import closeIcn from 'assets/close-gray-icn.svg';
import { useHistory } from 'react-router-dom';
import { AuthLogout } from 'utils/AxiosConfig';
import AdsBanner from './AdsBanner';

interface IStepContentProps {
  onClick?: () => void;
  children?: React.ReactNode;
  step?: number;
  closeBtn?: boolean;
  closeOnclick?: () => void;
  showBackBtn?: boolean;
}
const StepContent = (props: IStepContentProps) => {
  const history = useHistory();

  const handleLogout = () => {
    AuthLogout();
    history.push('/');
  };

  return (
    <div className="step-content-container">
      <div className="step-content-header">
        <AdsBanner />
        <div className="previous-btn-container">
          {props.step !== 0 ? (
            <button
              type="button"
              onClick={() => {
                props.onClick?.();
                history.goBack()
              }}
              className="previous-btn"
            />
          ) : null}
          {props.showBackBtn && props.step === 0 && (
            <button
              type="button"
              onClick={() => {
                props.onClick && props.onClick();
                history.goBack();
              }}
              className="previous-btn"
            />
          )}
        </div>
        <div className="logout-btn-container">
          {props.closeBtn === false && (
            <BorderButton
              value="Log out"
              backgroundColor="transparent"
              border="0"
              color="#191C1F"
              onClick={handleLogout}
            />
          )}
          {props.closeBtn ? (
            <button
              type="button"
              onClick={props.closeOnclick}
              className="close-btn"
            >
              <img src={closeIcn} alt="" />
            </button>
          ) : null}
        </div>
      </div>
      <div className="step-content-body">
        <div className="step-content-body-container">{props.children}</div>
      </div>
    </div>
  );
};

export default StepContent;
