import { ButtonProps, Modal } from 'antd';
import React from "react";
import "css/Modal.css";
import clsx from "clsx";
import CloseIcn from "assets/close-icn.svg";
import BorderButton from "components/Widgets/BorderButton";

interface IModalProps {
  cancelButtonProps?: ButtonProps;
  visible?: boolean;
  isMobile?: boolean;
  isModalVisible?: boolean;
  closable?: boolean;
  onClose?: (arg0: any) => any;
  onConfirm?: (arg0: any) => any;
  onCancel?: (arg0: any) => any;
  title?: string;
  icon?: string;
  okText?: string;
  children?: React.ReactNode;
  okButtonProps?: any;
  width?: string;
  uploadModalheight?: boolean;
  closeIcon?: React.ReactNode;
  footer?: any;
  isWhite?: boolean;
  isPassword?: boolean;
  confirmLoading?: boolean;
  isCloseIcon?: boolean;
  showDoneButton?: boolean;
}

const ModalCustom = (props: IModalProps) => {
  return (
    <>
      <Modal
        title={props.title}
        wrapClassName={clsx(
          "modal-wrapper",
          props.isWhite && "isWhite",
          props.uploadModalheight && "uploadModalheight",
          "mobile-modal",
          props.isMobile && "isMobile"
        )}
        width={props.width}
        visible={props.visible}
        onCancel={props.onClose}
        closable={props.closable}
        closeIcon={props.closeIcon}
        confirmLoading={props.confirmLoading}
        onOk={props.onConfirm}
        okText={props.okText}
        okButtonProps={props.okButtonProps}
        cancelButtonProps={props.cancelButtonProps}
        footer={props.footer}
      >
        {props.children}
        {props.isCloseIcon && (
          <img
            src={CloseIcn}
            className="close-icon"
            onClick={props.onClose}
            alt="Close icon"
          />
        )}
        {/* Looks like this logic (btn-wrapper) is redundant and not working */}
        <div className="btn-wrapper">
          {props.showDoneButton && (
            <BorderButton
              onClick={props.onConfirm}
              value="Done"
              backgroundColor="#0065f3"
              color="#fff"
              width="343px"
              height="54px"
            />
          )}
          {props.isPassword && (
            <BorderButton
              onClick={props.onConfirm}
              value="Change Password"
              backgroundColor="#0065f3"
              color="#fff"
              width="343px"
              height="54px"
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalCustom;
