import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { H6 } from 'typography';
import checkWhiteIcon from 'assets/check-white.svg';

interface IStageCapsule {
	title: string;
	icon: string;
	stage: number;
	to?: string;
	isSubmitCapsule?: boolean;
	onSubmit?: () => void;
	visible: boolean;
	isReadyTOSubmit?: boolean;
	shouldButtonPulse?: boolean;
}

const StageCapsule = (props: IStageCapsule) => {
	const [hasCompleted, setHasCompleted] = useState(false);
	useEffect(() => {
		if (Math.ceil(props.stage) >= 100) setHasCompleted(true);
	}, [props.isSubmitCapsule, props.stage]);

	return props.visible ? (
		<div className='stage-capsule'>
			<div className='stage-capsule-left'>
				<img className={Math.ceil(props.stage) < 100 ? 'apply-capsule-grayscale' : ''} src={props.icon} alt='icon' />
				<div className='stage-capsule-left-title'>
					<H6>{props.title}</H6>
				</div>
			</div>

			<div className='stage-capsule-right'>
				{/* NOT COMPLETED */}
				{!hasCompleted && Math.floor(props.stage) < 100 && props?.isSubmitCapsule === undefined && (
					<Link to={props?.to}>
						<button className={`btn-stage-capsule ${props.shouldButtonPulse ? 'btn-pulse' : ''}`}>Continue</button>
					</Link>
				)}

				{/* COMPLETED */}
				{hasCompleted && Math.floor(props.stage) >= 100 && props?.isSubmitCapsule === undefined && (
					<Link to={props?.to}>
						<button className={'btn-stage-capsule btn-stage-completed'}>
							<span>
								Completed <img src={checkWhiteIcon} alt='' />
							</span>
							<span>Review</span>
						</button>
					</Link>
				)}

				{/* FOR SUBMIT BUTTON */}
				{props.isSubmitCapsule && (
					<button className={`btn-stage-capsule ${props.isReadyTOSubmit ? 'btn-pulse' : ''}`} onClick={props.onSubmit}>
						Submit
					</button>
				)}
			</div>
		</div>
	) : (
		<></>
	);
};

export default StageCapsule;
