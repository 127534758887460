/* eslint-disable @typescript-eslint/no-unused-vars */
import successIcon from 'assets/success.png';
import errorIcon from 'assets/error.png';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { actionTypes } from 'context-api/actionTypes';
import moment from 'moment';

interface AlertProps {
  isSuccess?: boolean;
  message?: string;
  className?: string;
}

/**
 * An alert system for dashboard to show SUCCESS or ERROR message
 * the values are being read from the alert in the CONTEXT API. It is
 * being called once in the App.tsx
 * @param props isSuccess, message and className?
 * @returns component
 */
const Alert = (props: AlertProps) => {
  const [{ alert }, dispatch] = useDataLayerValue();
  const [alerts, setAlerts] = useState(alert);
  const [msg, setMsg] = useState();
  const [totalCount, setTotalCount] = useState();
  const [currentCount, setCurrentCount] = useState();
  const [animateClass, setAnimateClass] = useState('flex app-alert');

  const funcRenderAlert = () => {
    if (alerts.length > 0) {
      setAlerts(alerts.reverse());
      setMsg(alerts[alerts.length - 1]);
      setTotalCount(alerts.length);
      setCurrentCount(alert.indexOf(alerts[alerts.length - 1]) + 1);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimateClass('flex app-alert app-alert-open');
    }, 400);

    if (alerts.length > 0) {
      setAlerts(alert.reverse());
      setMsg(alerts[alerts.length - 1]);
      setTotalCount(alerts.length);
      setCurrentCount(alert.indexOf(alerts[alerts.length - 1]) + 1);
    }
  }, [alerts, alerts.length, alert]);

  const funcSetIcon = () => {
    //@ts-ignore
    return msg?.isSuccess ? successIcon : errorIcon;
  };

  const funcClose = () => {
    setAnimateClass('flex app-alert');
    if (alerts.length > 0) {
      alerts.pop();
      setAlerts(alerts);
      setMsg(alerts[alerts.length - 1]);
      setCurrentCount(alert.indexOf(alerts[alerts.length - 1]) + 1);
      dispatch({
        type: actionTypes.ALERT,
        alert: alerts,
      });
    }
  };

  return (
    <>
      {alerts.length > 0 && (
        <div
          className={
            props.className
              ? `${props.className} flex app-alert`
              : animateClass
          }
        >
          <div className="app-alert-icon">
            <img src={funcSetIcon()} alt="" />
          </div>

          <section className="app-alert-content">
            <header className="app-alert-msg">
              {
                //@ts-ignore
                msg?.message
              }
            </header>
            <footer className="app-alert-extras flex">
              <div className="app-alert-timestamp">
                {
                  //@ts-ignore
                  moment(msg?.timestamp).fromNow()
                }
              </div>
              <div className="app-alert-counter">{`${currentCount} of ${totalCount}`}</div>
            </footer>
          </section>

          <button onClick={funcClose} className="app-alert-close"></button>
        </div>
      )}
    </>
  );
};

export default Alert;
