import ReactSelect from 'react-select';
import 'css/Input.css';
import 'antd/lib/select/style/index.css';

interface ISelectInputProp {
	width?: any;
	label?: string;
	name?: string;
	options: IOption[];
	control?: any;
	value?: any;
	defaultValue?: string;
	onSelect: (value: string) => void;
	showSearch?: boolean;
	autoFocus?: boolean;
	error?: string;
}

export interface IOption {
	value: string;
	label: string;
}

const SelectInput = (props: ISelectInputProp) => {
	const optionValue = props.options.find((option) =>
		props.value ? option.value === props.value : option.value === props.defaultValue
	);

	return (
		<>
			<div className='input-wrapper input-group' style={{ maxWidth: props.width || '460px' }}>
				<div className='select-group'>
					<ReactSelect
						isClearable
						isSearchable={props.showSearch}
						className={props.error ? 'react-select-container input-box-error' : 'react-select-container'}
						classNamePrefix='react-select'
						defaultValue={props.value || ''}
						value={optionValue}
						autoFocus={props.autoFocus}
						onChange={(item) => props.onSelect(item.value || '')}
						options={props.options}
						placeholder={props.label}
						name={props.name}
					/>
				</div>
			</div>
			{props.error && <div className='input-error'>{props.error}</div>}
		</>
	);
};

export default SelectInput;
