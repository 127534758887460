import { useState } from 'react';
import 'css/Settings.css';
import { Bold, H4 } from 'typography';
import InfoBox from 'components/Widgets/InfoBox';
import ModalCustom from 'components/Widgets/ModalCustom';
import { useDataLayerValue } from 'context-api/DataLayer';
import { EmailInputModal, LanguageContent, PasswordInputModal, PhoneInputContent } from 'components/SettingsElements';
import { Loader } from 'components/Widgets';
import { getTokenPayloadDecoded } from '../../utils/AuthFunctions';

const Settings = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEmail, setIsModalVisibleEmail] = useState(false);
  const [isModalVisiblePassword, setIsModalVisiblePassword] = useState(false);
  const [isModalVisibleLanguage, setIsModalVisibleLanguage] = useState(false);
  const [{ user, lang }] = useDataLayerValue();

  return (
    <>
      {user ? (
        <div>
          <div className="header-title">
            <H4>Settings</H4>
          </div>
          <InfoBox
            title="Full Name"
            disabled
            description={`${user?.personal?.firstName} ${user?.personal?.lastName}`}
          />

          <InfoBox
            title="Email"
            description={getTokenPayloadDecoded().email}
            buttonText="Change"
            onClick={() => setIsModalVisibleEmail(true)}
          />
          <InfoBox
            title="Password"
            description="*******"
            buttonText="Change"
            onClick={() => setIsModalVisiblePassword(true)}
          />
          <InfoBox
            title="Phone Number"
            textDisabled
            description={user?.personal?.mobileNumber}
            buttonText="Change"
            onClick={() => setIsModalVisible(true)}
          />
          <InfoBox
            title="Language"
            description={lang}
            buttonText="Change"
            onClick={() => setIsModalVisibleLanguage(true)}
            isDesktop
          />
          <div className="mobile-logout">
            <Bold>Log out</Bold>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <ModalCustom
        visible={isModalVisible}
        okText="Save"
        closable={false}
        onClose={() => setIsModalVisible(false)}
        okButtonProps={{
          size: 'small',
        }}
        isWhite
        isMobile
        showDoneButton
        isCloseIcon
      >
        <PhoneInputContent />
      </ModalCustom>
			<EmailInputModal
				isModalVisibleEmail={isModalVisibleEmail}
				setIsModalVisibleEmail={setIsModalVisibleEmail}
			/>
      <PasswordInputModal
        setIsModalVisiblePassword={setIsModalVisiblePassword}
        isModalVisiblePassword={isModalVisiblePassword}
      />
      <ModalCustom
        visible={isModalVisibleLanguage}
        okText="Save"
        closable={false}
        onClose={() => setIsModalVisibleLanguage(false)}
        okButtonProps={{
          size: 'small',
        }}
        isWhite
        isMobile
        isCloseIcon
      >
        <LanguageContent />
      </ModalCustom>
    </>
  );
};
export default Settings;
