import { Link, useHistory } from 'react-router-dom';
import { Input } from 'components/Widgets';
import 'css/Auth.css';
import { Bold, SubTitle } from 'typography';
import { useEffect, useState } from 'react';
import { funcCognitoUser } from 'utils/AuthFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
import { TabTitle } from 'utils/GeneralFunctions';
import getLogoSrc from 'utils/getLogoSrc';

const AuthResetPassword = () => {
  const [{ forgottenPasswordEmail }] = useDataLayerValue();
  const history = useHistory();
  TabTitle('Reset Password');

  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    const nonEmptyFields = !!token && !!password;
    const passwordsMatch = confirmPassword === password;

    setValid(nonEmptyFields && passwordsMatch);
  }, [token, password, confirmPassword]);

  const funcSubmitReset = (e: any) => {
    e?.preventDefault();
    console.log('TOKEN: ', token);
    console.log('Reset Pass: ', password);
    console.log('Confirm Pass: ', confirmPassword);
    // return `do something on AWS`;

    funcCognitoUser(forgottenPasswordEmail).confirmPassword(token, password, {
      onSuccess: (data: string) => {
        // successfully initiated reset password request
        console.log('Password Reset Success: ' + data);
        history.push(`/app`);
      },
      onFailure: (err: { message: any }) => {
        console.log(err.message || JSON.stringify(err));
      },
    });
  };

  return (
    <div className="login-auth">
      <aside className="login-holder">
        <div className="app-paper login-paper">
          {/* logo */}
          <div className="login-app-logo">
            <img src={getLogoSrc()} alt="logo" />
          </div>

          <Input
            normalInput
            label="Enter Token"
            type="number"
            placeholder="token"
            required
            onChange={(e: any) => setToken(e.target.value)}
          />

          <Input
            normalInput
            label="New Password"
            type="password"
            placeholder="password"
            required
            onChange={(e: any) => setPassword(e.target.value)}
          />

          <Input
            normalInput
            label="Confirm Password"
            type="password"
            placeholder="password"
            required
            onChange={(e: any) => setConfirmPassword(e.target.value)}
          />
          <br />
          <div className={isValid === false && 'isDisabled'}>
            <button
              type="submit"
              className="btn-submit-loader"
              onClick={funcSubmitReset}
            >
              <SubTitle isBold={false}>Submit</SubTitle>
            </button>
          </div>
        </div>

        <footer className="auth-login-footer">
          <Link className="auth-link" to={`/auth`}>
            <Bold isBold={false}>Back to Log in</Bold>
          </Link>

          <div className="auth-link-bottom">
            <Bold isBold={false}>
              Not a member yet?{' '}
              <Link className="auth-link" to={`/auth/signup`}>
                Create an account
              </Link>
            </Bold>
          </div>
        </footer>
      </aside>
    </div>
  );
};

export default AuthResetPassword;
