import 'css/Input.css';

interface InputProps {
  type?: string;
  className?: string;
  required?: boolean;
  label: string;
  placeholder?: string;
  name?: string;
  onChange?: any;
  error?: string;
  width?: string;
  value?: any;
}

const CleanInput = (props: InputProps) => {
  return (
    <div className="input-wrapper" style={{ maxWidth: props.width }}>
      <>
        <div className="input-group">
          <input
            type={props.type}
            className={props.error ? 'input input-box-error' : 'input'}
            required={props.required}
            placeholder={props.placeholder}
            name={props.name}
            id={props.name}
            onChange={props.onChange}
            style={{ minWidth: props.width }}
            value={props.value}
          />
          <label htmlFor={props.name} className="input-label">
            {props.label}
          </label>
        </div>
        {props.error && <div className="input-error">{props.error}</div>}
      </>
    </div>
  );
};

export default CleanInput;
