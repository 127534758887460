import React from 'react';
import HeaderText from './HeaderText';
import DescriptionText from './DescriptionText';

const EligibilityTwo = () => {
	return (
		<div className='journey-bg light-grey-bg eligibility-two-bg-img'>
			<div className='relative'>
				<div className='eligibility-two-bg'>
					<HeaderText>
						<div className='right-align light-grey'>
							Get your <span className='red'>eligibility</span>
						</div>
						<div className='right-align light-grey'>
							<span className='red'>score</span> instantly
						</div>
						<div className='right-align light-grey'>with our</div>
						<div className='right-align light-grey'>calculator</div>
					</HeaderText>
					<DescriptionText>
						<p className='right-align grey'>
							The eligibility calculator will help us decide your mortgage eligibility immediately. You don't have to
							wait for days for your evaluation. 
						</p>
					</DescriptionText>
				</div>
				<p className='grey repossessed-text'>
					*all applications to lenders will be subject to underwriting and the results of the eligibility calculator are
					indicative only Your home may be repossessed if you do not keep up repayments on your mortgage
				</p>
			</div>
		</div>
	);
};

export default EligibilityTwo;
