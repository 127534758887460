import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProgressBar, Loader } from 'components/Widgets';
import 'css/Offer.css';
import { JourneyNavLineOffer, OfferQuestion } from '.';
import { OfferQuestionData } from 'utils/OfferQuestionList';
import { PATCHRequest } from '../../utils/AuthFunctions';
import { useDataLayerValue } from '../../context-api/DataLayer';
import AdsBanner from 'components/Widgets/AdsBanner';

const OfferPage = () => {
	const history = useHistory();
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [question, setQuestion] = useState({});
	const [{ offersData }] = useDataLayerValue();
	const [progress, setProgress] = useState(
		(1 / OfferQuestionData.length) * 100
	);
	const [offerHistory, setOfferHistory] = useState([]);
	const [progressHistory, setProgressHistory] = useState([]);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		if (progress > 100) return;

		const currQuestion = OfferQuestionData.find(
			(item) => item.step === currentQuestion
		);
		setQuestion(currQuestion);
		// eslint-disable-next-line
	}, [progress, progressHistory]);

	useEffect(() => {
		const saveData = async () => {
			if (currentQuestion > 14) {
				setIsSaving(true);
				await PATCHRequest(
					`/users?attribute=selectedProduct`,
					{
						query: offersData,
					},
					history
				);
				setIsSaving(false);

				history.push(`/user/stages`);
			}
		};
		saveData();
		// eslint-disable-next-line
	}, [currentQuestion]);

	if (isSaving) return <Loader text='Saving...' />

	return (
		<>
			<div className='offer-container'>
				<ProgressBar progress={progress} />
				<AdsBanner />
				{typeof question === 'object' && Reflect.has(question, 'id') && (
					<>
						<JourneyNavLineOffer
							showClose
							progress={progress}
							funcProgress={setProgress}
							allObject={question}
							oHist={offerHistory}
							funcOHist={setOfferHistory}
							funcSetCurrentQuestion={setCurrentQuestion}
							progressHistory={progressHistory}
							funcProgressHistory={setProgressHistory}
							currentQuestion={currentQuestion}
						/>

						<section className='offer-question-section'>
							<OfferQuestion
								questionId={
									// @ts-ignore
									question.id
								}
								funcOnClick={setCurrentQuestion}
								currentQuestion={currentQuestion}
								funcProgress={setProgress}
								allObject={question}
								funcHistory={setOfferHistory}
								oHist={offerHistory}
								progressHistory={progressHistory}
								setProgressHistory={setProgressHistory}
							/>
						</section>
					</>
				)}
			</div>
		</>
	);
};

export default OfferPage;
