import {useState} from "react";
import {CreateProfileContent} from ".";
import {ProgressBar, StepContent,} from "components/Widgets";
import {useDataLayerValue} from "context-api/DataLayer";
import {actionTypes} from "context-api/actionTypes";
import {useHistory} from "react-router-dom";
import RadioInputBoolean from "./RadioInputBoolean";
import {PATCHRequest} from "../../utils/AuthFunctions";

const CreateProfilePartner = () => {

    const [{ user }, dispatch] = useDataLayerValue();
    const history = useHistory();
    const [progress] = useState(!!user?.cases?.[0]?.application?.partner ? 66.6 : 95);
    const [partnerExists, setPartnerExists] = useState<boolean>(user?.cases?.[0]?.application?.isPartnerExists || '');

    // end of states that control form data
    const handleAnswerClick = (answer) => {
        setPartnerExists(answer);
        const currUser = { ...user };
        currUser.cases[0].application.isPartnerExists = answer

        dispatch({
            type: actionTypes.SET_USER,
            user: currUser
        });

        setTimeout(async () => {
            if (answer) {
                history.push('/user/create-profile-partner-personal');
            } else {
                await PATCHRequest(
                    `/users?attribute=application`,
                    user.cases[0].application,
                    history
                );
                history.push('/user/stages');
            }
        }, 300);
    };

    return (
        <div className="create-profile-container" style={{minHeight: "100vh"}}>
            <ProgressBar progress={progress}/>
            <StepContent
                closeBtn={false}
                showBackBtn
                step={1}
            >
                <CreateProfileContent
                    active
                    title="Do you have a second applicant?"
                    onClick={() => {}}
                    buttonCaption={partnerExists && "Continue to Second Applicant"}
                >
                    <RadioInputBoolean
                        value={"yes"}
                        isChecked={typeof partnerExists === 'boolean' && partnerExists}
                        SelectionText={'Yes'}
                        name={'isPartner'}
                        onClick={() => handleAnswerClick(true)}
                    />
                    <RadioInputBoolean
                        value={"no"}
                        isChecked={typeof partnerExists === 'boolean' && !partnerExists}
                        SelectionText={'No'}
                        name={'isPartner'}
                        onClick={() => handleAnswerClick(false)}
                    />
                </CreateProfileContent>
            </StepContent>
        </div>
    );
};

export default CreateProfilePartner;
