import { useState } from 'react';
import { H4 } from 'typography';
import Input from 'components/Widgets/Input';
import { Checkbox } from 'antd';
import 'css/SignUp.css';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { CreateUser } from 'utils/AuthFunctions';

const SignUpForm = () => {
	const [{ signUpData, alert }, dispatch] = useDataLayerValue();
	const [showLoader, setShowLoader] = useState(false);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [receiveMails, setReceiveMails] = useState(false);
	const [agreedToPolicy, setAgreedToPolicy] = useState(true);
	const [error, setError] = useState('');

	const funcOnReceiveMails = () => {
		// set sign up data values
		setReceiveMails(!receiveMails);
		signUpData['receiveMails'] = !receiveMails;

		// radio button value in context api
		dispatch({
			type: actionTypes.SIGNUP_DATA,
			signUpData,
		});
	};

	const funcOnAgreedToPolicy = () => {
		// set sign up data values
		setAgreedToPolicy(!agreedToPolicy);
		signUpData['agreedToPolicy'] = !agreedToPolicy;

		// radio button value in context api
		dispatch({
			type: actionTypes.SIGNUP_DATA,
			signUpData,
		});
	};

	const funcSubmitSignUp = async (e: { preventDefault: () => void; }) => {
		e?.preventDefault();
	  
		try {
		  setShowLoader(true);
	  
		  const signUpData = {
			email,
			password,
		  };
	  
		  dispatch({
			type: actionTypes.SIGNUP_DATA,
			signUpData,
		  });
	  
		  let signupFeedback;
	  
		  try {
			signupFeedback = await CreateUser(signUpData);
		  } catch (error) {
			signupFeedback = error;
		  }
	  
		  const alertEntry = {
			isSuccess: signupFeedback.success,
			message: signupFeedback.message,
			timestamp: new Date(),
		  };
	  
		  dispatch({
			type: actionTypes.ALERT,
			alert: [...alert, alertEntry],
		  });
	  
		} finally {
		  setShowLoader(false);
		}
	};

	return (
		<div className='sign-up-form-container'>
			<div className='sign-up-form-header'>
				<H4>Let's create your account</H4>
			</div>
			<form onSubmit={funcSubmitSignUp} className='sign-up-form-content'>
				<Input
					normalInput
					label='Email Address'
					type='email'
					placeholder='email'
					required
					onChange={(e: any) => setEmail(e.target.value)}
					error={error && error.split(':')[1]}
				/>

				<Input
					label='New password'
					normalInput
					type='password'
					placeholder='Password'
					required
					onChange={(e: any) => setPassword(e.target.value)}
					error={error && error.split(':')[1]}
				/>
				<div className='sign-up-form-terms'>
					<Checkbox onChange={() => funcOnReceiveMails()}>
						I agree to receive updates and discount offers
					</Checkbox>
					<Checkbox
						checked={agreedToPolicy}
						onChange={() => funcOnAgreedToPolicy()}
					>
						I agree to the <a href="https://www.accessfs.co.uk/legals/Privacy.pdf">privacy policy</a>, <a href="https://www.accessfs.co.uk/legals/AFS-Terms-of-Business.pdf">terms of business</a> & <a href="https://www.accessfs.co.uk/legals/Initial-Disclosure-Document.pdf">initial disclosure document</a>
					</Checkbox>
				</div>
				<div
					className={
						agreedToPolicy ? 'sign-up-form-btn' : 'isDisabled sign-up-form-btn'
					}
				>
					<button type='submit' className='btn-submit-loader'>
						{!showLoader && <span>Create account</span>}
						{showLoader && <div className='btn-spinner'></div>}
					</button>
				</div>
			</form>
		</div>
	);
};

export default SignUpForm;
