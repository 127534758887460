enum DocType {
	MAIN_ID = 'MAIN_ID',
	MAIN_PAYSLIPS = "MAIN_PAYSLIPS",
	MAIN_BANK_STATEMENTS = "MAIN_BANK_STATEMENTS",
	MAIN_DEPOSIT = "MAIN_DEPOSIT",
	MAIN_ADDRESS = "MAIN_ADDRESS",
	OPEN_BANKING = 'OPENBANKING', //be careful when changing, depends on backend
	AGENT = 'AGENT'
}

export default DocType;