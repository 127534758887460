import {
	BorderButton,
	Input,
	JourneyNavLine,
	ProgressBar,
	SelectInput,
} from 'components/Widgets';
import { useEffect, useState } from 'react';
import { Bold, H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { titleOptions } from 'utils/DropDownList';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const SecondApplicantDetails = () => {
	const FProgress = Number(readFromLocalStorage('_FPersonalPageProgress')) || 0;
	const [progress, setProgress] = useState(FProgress);
	const [{ user }, dispatch] = useDataLayerValue();
	const { personal2 } = user || {};
	const { application } = user?.cases?.[0] || {};
	const history = useHistory();
	const [isComplete, setIsComplete] = useState(false);
	const [textInfo, setTextInfo] = useState({
		title: personal2?.title || application?.partner?.title || '',
		firstName:
			personal2?.firstName || application?.partner?.firstName || '',
		lastName: personal2?.lastName || application?.partner?.lastName || '',
		birthDate: personal2?.birthday || application?.partner?.birthday || '',
		email: personal2?.email || application?.partner?.email || '',
	});

	useEffect(() => {
		if (
			textInfo?.title &&
			textInfo?.firstName &&
			textInfo?.lastName &&
			textInfo?.birthDate
		) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [textInfo, history]);

	const onSubmit = async (e: any) => {
		e?.preventDefault();
		setProgress(Math.floor(progress + 8.3));
		storeInLocalStorage('_FPersonalPageProgress', Math.floor(progress + 8.3));

		const currPersonal2 = { ...personal2 };
		currPersonal2.firstName = textInfo['firstName'];
		currPersonal2.lastName = textInfo['lastName'];
		currPersonal2.title = textInfo['title'];
		currPersonal2.birthDate = textInfo['birthDate'];

		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, personal2: currPersonal2 },
		});
		history.push('/user/personal/personal-details-second-applicant-2');
	};

	const onChangeBirthDate = (e) => {
		setTextInfo((prev) => ({
			...prev,
			birthDate: e.target.value,
		}));
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>{textInfo?.firstName || textInfo?.email}'s personal details</H4>
					</div>
					<Bold isBold={false} color='#959DA5'>
						These details must match your passport or other official documents.
					</Bold>
				</div>
				<div className='personal-details-body'>
					<form onSubmit={onSubmit}>
						<SelectInput
							name='title'
							label='Select title'
							options={titleOptions}
							defaultValue={application?.title || personal2?.title || null}
							onSelect={(value: any) =>
								setTextInfo((prev) => ({
									...prev,
									title: value,
								}))
							}
						/>

						<Input
							value={textInfo?.firstName || personal2?.firstName || ''}
							normalInput
							label='First Name'
							placeholder='First Name'
							onChange={(e) =>
								setTextInfo((prev) => ({
									...prev,
									firstName: e.target.value,
								}))
							}
						/>

						<Input
							value={textInfo?.lastName || personal2?.lastName || ''}
							normalInput
							label='Last Name'
							placeholder='Last Name'
							onChange={(e) =>
								setTextInfo((prev) => ({
									...prev,
									lastName: e.target.value,
								}))
							}
						/>
						<Input
							value={textInfo?.birthDate || personal2?.birthDate || ''}
							label='Date of birth'
							placeholder='Date of birth'
							dateField
							required
							onChange={onChangeBirthDate}
						/>

						<div
							className={
								!isComplete && textInfo !== undefined ? 'isDisabled' : ''
							}
						>
							<div className=' personal-details-btn'>
								<BorderButton
									value='Continue'
									width='368px'
									height='56px'
									backgroundColor='#0968E3'
									color='#fff'
									marginBottom='15%'
									onClick={() => 'props.onClick'}
									disabled={!isComplete}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SecondApplicantDetails;
