import { BorderButton, Input, JourneyNavLine, SelectInput } from 'components/Widgets';
import { useState } from 'react';
import { Bold, H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { titleOptions } from 'utils/DropDownList';
import 'css/Personal.css';
import { getRequiredFieldErrors } from 'utils/getRequiredFieldErrors';

const PersonalDetails = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const { personal } = user || {};
	const { application } = user?.cases?.[0] || {};
	const history = useHistory();

	const [title, setTitle] = useState(application?.title || personal?.title || '');
	const [firstName, setFirstName] = useState(application?.firstName || personal?.firstName || '');
	const [lastName, setLastName] = useState(application?.lastName || personal?.lastName || '');
	const [birthDate, setBirthDate] = useState(application?.birthday || personal?.birthDate || '');
	const [errors, setErrors] = useState({ present: false });

	const onSubmit = async (e: any) => {
		e?.preventDefault();
		const requiredFields = [
			{ value: title, name: 'title' },
			{ value: firstName, name: 'firstName' },
			{ value: lastName, name: 'lastName' },
			{ value: birthDate, name: 'birthDate' },
		];
		const errors = getRequiredFieldErrors(requiredFields);
		setErrors(errors);
		if (errors.present) return;

		const currPersonal = { ...personal };
		currPersonal.title = title;
		currPersonal.firstName = firstName;
		currPersonal.lastName = lastName;
		currPersonal.birthDate = birthDate;

		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, personal: currPersonal },
		});

		history.push('/user/personal/personal-details-2');
	};

	return (
		<div className='personal-container'>
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Personal details</H4>
					</div>
					<Bold isBold={false} color='#959DA5'>
						These details must match your passport or other official documents.
					</Bold>
				</div>
				<div className='personal-details-body'>
					<form>
						<SelectInput
							name='title'
							error={title ? '' : errors['title']}
							options={titleOptions}
							defaultValue={title || null}
							onSelect={setTitle}
							label='Select title'
						/>

						<Input
							value={firstName || ''}
							error={firstName ? '' : errors['firstName']}
							normalInput
							required
							label='First Name'
							placeholder='First Name'
							onChange={(e) => setFirstName(e.target.value)}
						/>

						<Input
							value={lastName || ''}
							error={lastName ? '' : errors['lastName']}
							normalInput
							required
							label={'Last Name'}
							placeholder='Last Name'
							onChange={(e) => setLastName(e.target.value)}
						/>

						<Input
							value={birthDate}
							error={birthDate ? '' : errors['birthDate']}
							dateField
							required
							label='Date of birth'
							placeholder='Date of birth'
							onChange={(e) => setBirthDate(e.target.value)}
						/>
					</form>
				</div>

				<div className='personal-details-btn '>
					<BorderButton
						value='Continue'
						width='368px'
						height='56px'
						backgroundColor='#0968E3'
						color='#fff'
						marginBottom='15%'
						onClick={onSubmit}
					/>
				</div>
			</div>
		</div>
	);
};

export default PersonalDetails;
