/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Checkbox } from 'antd';
import { AuthLoginInput } from 'components/Widgets';
import 'css/Auth.css';
import { Bold } from 'typography';
import { GETRequest, onCheckBoxEvent, onLoginEvent, storeInLocalStorage } from 'utils/AuthFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import getLogoSrc from 'utils/getLogoSrc';
import { CognitoUser } from 'amazon-cognito-identity-js';

const AuthLogIn = () => {
	const { path } = useRouteMatch();
	const history = useHistory();

	let [, dispatch] = useDataLayerValue();
	const [showLoader, setShowLoader] = useState(false);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [rememberMe, setRememberMe] = useState(false);
	const [error, setError] = useState('');
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [isValid, setValid] = useState(true);
	const [sessionUser, setSessionUser] = useState<CognitoUser | void>();

	useEffect(() => {
		setValid(newPassword === confirmPassword);
	}, [newPassword, confirmPassword]);

	// PERFORM LOGIN AUTHENTICATION
	const funcSubmitLogin = async (e: any) => {
		setShowLoader(true);
		// let loginFeedback =
		await onLoginEvent(e, email, password, newPassword, sessionUser as CognitoUser)
			.then((login) => {

				if (login.type === 'NEW_PASSWORD_REQUIRED') {
					setShowNewPassword(true);
					setShowLoader(false);
					setSessionUser(login.user);
					return;
				}

				// MAKE USERS API CALL AND ROUTE TO LAST VISITED PAGE
				GETRequest(`/users?extended=true`, history).then(
					(userInfoFeedback) => {
						if (userInfoFeedback.success) {
							// update user info
							dispatch({
								type: actionTypes.SET_USER,
								// @ts-ignore
								user: userInfoFeedback?.data,
							});

							// write into local storage
							storeInLocalStorage(
								'_user',
								// @ts-ignore
								userInfoFeedback?.data,
							);
							storeInLocalStorage('accessMeAccount', true);

							// GET USER PROGRESS TO SET AUTOMATIC ROUTING
							GETRequest(`/progress`, history).then(
								(progressFeedback) => {
									// update user progress
									dispatch({
										type: actionTypes.SET_PROGRESS,
										// @ts-ignore
										progress: progressFeedback?.data,
									});

									if (progressFeedback.success) {
										// @ts-ignore
										const prog = progressFeedback?.data;
										if (
											parseInt(prog?.personal) >= 99 &&
											parseInt(prog?.financial) >= 99 &&
											parseInt(prog?.property) >= 99 &&
											parseInt(prog?.liabilities) >= 99 &&
											parseInt(prog?.expenses) >= 99
										) {
											history.push('/app');
										} else {
											// route to user to create profile if hasnt completed personal details else go to the stages page

											if (parseInt(prog?.general) === 0) {
												history.push('/user/create-profile-what-are-you-looking-for');
											} else {
												history.push('/user/stages');
											}
										}
									}
								},
							);
						}
					},
				);
				//END
			})
			.catch((err) => {
				setShowLoader(false);
				setError(err.message);
			});
	};

	return (
		<div className='login-auth'>
			<aside className='login-holder'>
				<div className='app-paper login-paper'>
					{/* logo */}
					<div className='login-app-logo'>
						<img src={getLogoSrc()} alt='Logo' />
					</div>

					<form method='post' onSubmit={(e: any) => funcSubmitLogin(e)}>
						<AuthLoginInput
							label='Email Address'
							type='email'
							placeholder='email'
							required
							onChange={(e: any) => setEmail(e.target.value)}
							error={error}
						/>

						{showNewPassword &&
							<>
								<AuthLoginInput
									label='New password'
									type='password'
									placeholder='new password'
									required
									onChange={(e: any) => setNewPassword(e.target.value)}
									error={error}
								/>
								<AuthLoginInput
									label='Confirm new password'
									type='password'
									placeholder='confirm password'
									required
									onChange={(e: any) => setConfirmPassword(e.target.value)}
									error={error}
								/>
							</>
						}
						{!showNewPassword &&
							<AuthLoginInput
								label='Password'
								type='password'
								placeholder='password'
								required
								onChange={(e: any) => setPassword(e.target.value)}
								error={error}
							/>
						}
						<br />
						<button type='submit' className='btn-submit-loader' disabled={!isValid}>
							{!showLoader && <span>Log in</span>}
							{showLoader && <div className='btn-spinner'></div>}
						</button>

						<div className='flex auth-other-paper'>
							<Checkbox
								onChange={(e: any) => onCheckBoxEvent(e, setRememberMe)}
							>
								Remember me
							</Checkbox>
							<Link className='auth-link' to={`${path}forgot-password`}>
								Forgot password?
							</Link>
						</div>
					</form>
				</div>

				<footer className='auth-login-footer'>
					<div className='auth-link-bottom'>
						<Bold isBold={false}>
							Not a member yet?{' '}
							<Link className='auth-link' to={`${path}signup`}>
								Create an account
							</Link>
						</Bold>
					</div>
				</footer>
			</aside>
		</div>
	);
};

export default AuthLogIn;
