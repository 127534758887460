// === INITIAL STATE ===
export const initialState = {
  auth: null,
  user: null,
  progress: null,
  docs: null,
  lang: 'EN',
  forgottenPasswordEmail: '',
  isPartnerExists: false,
  signUpData: {
    isLookingTo: '',
    propertyLocation: '',
    currentMortgageStage: '',
    lookingToBuy: '',
    email: '',
    password: '',
    receiveMails: false,
    agreedToPolicy: false,
  },
  alert: [],
  personalData1: {
    title: '',
    firstName: '',
    lastName: '',
    birthday: '',
    grossAnnualSalary: 0,
    propertyValue: 0,
    deposit: 0,
    mortgageTerms: 0,
    isFirstTimeBuyer: false,
    propertyType: '',
  },
  personalData2: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    birthday: '',
    grossAnnualSalary: 0,
  },
  partner: {
    secondApplicant: '',
  },
  offersData: {
    deposit: 0,
    propertyValue: 0,
    isHighStreetBanks: false,
    isFix5y: false,
    isLowerMonthlyPayments: false,
    isAddFeesToLoan: false,
    isFreeLegals: false,
    isFeeCashBack: false,
    isPaymentHoliday: false,
    isOverpayment: false,
    isOffset: false,
    isBorrowBack: false,
    isPortable: false,
    isNonStandardConstruction: false,
    isRefurbishIntent: false,
    isRemortgageIntent: false,
    isSellImmediately: false,
    term: 30,
  },
  correlationId: '',
  offerProductId: 0,
  showOffersModal: false,
  showOfferTable: true,
  offersTableList: [],
  offerDetails: {},
  financialEmployed: {
    employmentStatus: '',
    insuranceNumber: '',
    employmentBasis: '',
    employerName: '',
    jobTitle: '',
    employmentStartDate: '',
    plannedRetirement: '',
    annualSalary: 0,
    annualBonuses: 0,
    additionalAnnual: 0,
    employerPostCode: '',
    employerAddress: '',
    employerStreet: '',
    employerCity: '',
    otherIncome: [
      {
        amount: 0,
        source: '',
        frequency: '',
      },
    ],
  },
  financialSelfEmployed: {
    employmentStatus: '',
    insuranceNumber: '',
    selfEmployStatus: '',
    soleEmployPercent: 0,
    selfEmployLastYearEndDate: '',
    plannedRetirement: '',
    selfEmployNetProfitLastYear: 0,
    selfEmployNetProfitPreviousYear: 0,
    selfEmployNetProfit2YearBefore: 0,
    otherIncome: [
      {
        amount: 0,
        source: '',
        frequency: '',
      },
    ],
  },
  financialApplicant2Employed: {
    employmentStatus: '',
    insuranceNumber: '',
    employmentBasis: '',
    employerName: '',
    jobTitle: '',
    employmentStartDate: '',
    plannedRetirement: '',
    annualSalary: 0,
    annualBonuses: 0,
    additionalAnnual: 0,
    employerPostCode: '',
    employerAddress: '',
    employerStreet: '',
    employerCity: '',
    otherIncome: [
      {
        amount: 0,
        source: '',
        frequency: '',
      },
    ],
  },
  financialApplicant2SelfEmployed: {
    employmentStatus: 'Self employed',
    insuranceNumber: '',
    selfEmployStatus: '',
    soleEmployPercent: 0,
    selfEmployLastYearEndDate: '',
    plannedRetirement: '',
    selfEmployNetProfitLastYear: 0,
    selfEmployNetProfitPreviousYear: 0,
    selfEmployNetProfit2YearBefore: 0,
    otherIncome: [
      {
        amount: 0,
        source: '',
        frequency: '',
      },
    ],
  },
  personal: {
    title: '',
    gender: '',
    firstName: '',
    middleName: '',
    lastName: '',
    residenceCountry: '',
    homeNumber: null,
    mobileNumber: null,
    nationality: '',
    birthDate: '',
    dependentNumber: 0,
    dependants: [],
    presentAddress: '',
    presentStreet: '',
    presentCity: '',
    presentPostCode: '',
    presentAddressSinceDate: '',
    isElectoralRoll: '',
    occupantType: '',
    appCount: '',
    pastAddresses: [
      {
        address: '',
        dateMoved: '',
        street: '',
        city: '',
        postCode: '',
      },
    ],
  },
  personal2: {
    title: '',
    gender: '',
    firstName: '',
    middleName: '',
    lastName: '',
    residenceCountry: '',
    homeNumber: null,
    mobileNumber: null,
    nationality: '',
    birthDate: '',
    presentAddress: '',
    presentStreet: '',
    presentCity: '',
    presentPostCode: '',
    presentAddressSinceDate: '',
    isElectoralRoll: '',
    pastAddresses: [],
  },
  liabilities: {
    currentMortgageName: '',
    isCreditIssues: '',
    isDebtJudgement: '',
    isEverBankrupt: '',
    isFailedToPayEver: '',
    propertyCity: '',
    propertyPostCode: '',
    propertyStreet: '',
    isCurrentMortgage: '',
    currentRentAmount: null,
    mortgageLender: '',
    propertySalePrice: null,
    propertyAddress: '',
    propertyAddress2: '',
    outstandingLoanAmount: null,
    loanRemainingYears: null,
    mortgageInterestRate: null,
    isBuyToLet: '',
    mortgageMonthlyAmount: null,
    mortgageAccountNumber: '',
    isEarlyPaymentPenalty: '',
    isPreparedForPenalty: '',
    penaltyAmount: null,
    others: [
      {
        type: '',
        total: null,
        monthly: null,
        endDate: '',
      },
    ],
  },
  expenses: {
    seasonTicket: 50,
    fuelSpending: 100,
    roadTax: 20,
    insuranceAmount: 40,
    parkingFee: 0,
    otherTransportSpend: 20,
    heatingBill: 50,
    electricityBill: 50,
    waterBill: 20,
    phoneBill: 40,
    tvBill: 15,
    tvLicense: 0,
    councilTax: 150,
    groundRent: 0,
    serviceCharge: 0,
    otherSpending: 350,
    foodCost: 200,
    personalGoods: 50,
    clothing: 50,
    householdSpending: 0,
    mobilePhoneBill: 20,
    schoolingFees: 0,
    repairSpending: 50,
    holidaySpending: 100,
    otherLivingCost: 100,
  },
  property: {
    address: '',
    address2: '',
    street: '',
    city: '',
    postCode: '',
    bedroomCount: 0,
    buildYear: 0,
    tenure: '',
    leaseYears: 0,
    propertyType: '',
    isExLocalAuthority: '',
    isNonStandardConstruction: '',
    isKnownProperty: '',
    isTheOnlyProperty: '',
    isMainResidence: '',
    isSharedOwnershipScheme: '',
    percentToMortgage: 0,
    sharedOwnerBodyName: '',
    monthlyRentPayment: 0,
    repaymentMethod: '',
    depositSource: '',
    isOccupantUnder18: 'false',
    otherOccupantDetails: [
      {
        firstName: '',
        lastName: '',
        birthdate: '',
      },
    ],
  },
};
