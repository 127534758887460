import { Tooltip } from 'antd';
import bestoffericon from 'assets/best-offer.svg';
import tableinfoicon from 'assets/table-info.svg';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { PATCHRequest, POSTRequest } from 'utils/AuthFunctions';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { sendReturnMessage } from 'utils/GeneralFunctions';

interface ITableRowData {
  // key?: any;
  offerId: string;
  lenderIcon: string;
  initialRate: string;
  APR: string;
  monthlyRepayment: string;
  totalAmountPayable: string;
  isBestOffer?: boolean;
}

const TableRowData = (props: ITableRowData) => {
  const [{ offersData, correlationId, offerProductId }, dispatch] =
    useDataLayerValue();
  const history = useHistory();
  const [isApplying, setIsApplying] = useState(false);

  // SHOW OFFERS MODAL
  const funcApply = async (paramProductId: any) => {
    // show modal
    dispatch({
      type: actionTypes.SHOW_OFFERS_MODAL,
      showOffersModal: true,
    });

    // set clicked productId will be used by modal to read details
    dispatch({
      type: actionTypes.SET_OFFER_PRODUCT_ID,
      offerProductId: paramProductId,
    });

    const detailsURL =
      'https://r3y1hmhg8d.execute-api.eu-west-2.amazonaws.com/product';

    const data = {
      productId: paramProductId,
      correlationId: correlationId,
    };

    const detailsFeedback = await POSTRequest(detailsURL, data, history);

    // @ts-ignore
    if (detailsFeedback?.success) {
      dispatch({
        type: actionTypes.SET_OFFER_DETAILS,
        offerDetails: detailsFeedback,
      });
    }
  };

  // MAKE PATCH CALL
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const funcMakePatchCall = async () => {
    setIsApplying(!isApplying);
    const patchURL = `/users?attribute=selectedProduct`;

    const data = {
      productId: offerProductId,
      correlationId: correlationId,
      query: offersData,
    };

    const resp = await PATCHRequest(patchURL, data, history);
    if (resp.success) {
      sendReturnMessage('route_mortgage');
      setIsApplying(!isApplying);
      // history.push('/user/stages');
    } else {
      setIsApplying(!isApplying);
      // @ts-ignore
      console.error('Error:', resp.error);
    }
  };

  return (
    <tr className={props?.isBestOffer ? 'isBestOffer' : ''}>
      <td>
        {props.isBestOffer && (
          <Tooltip title="Best offer for you" placement="bottom">
            <img
              className="bestOfferTag"
              src={bestoffericon}
              alt="best offer"
            />
          </Tooltip>
        )}
      </td>
      <td>
        <img
          className="lenderLogo"
          src={`${process.env.PUBLIC_URL}/bank-logos/${props.lenderIcon}.png`}
          alt=""
        />
      </td>
      <td>{props.initialRate}</td>
      <td>{props.APR}</td>
      <td>{props.monthlyRepayment}</td>
      <td>{props.totalAmountPayable}</td>
      <td>
        <div className="table-buttons">
          <div>
            {!process.env.REACT_APP_DEMO_FLAG && (
              <Tooltip title="View Info" placement="bottom">
                <img
                  // onMouseOver={() => funcApply(props.offerId)}
                  onClick={() => funcApply(props.offerId)}
                  src={tableinfoicon}
                  alt=""
                />
              </Tooltip>
            )}
            <button
              // onClick={funcMakePatchCall}
              onClick={() => funcApply(props.offerId)}
              className="btn-apply"
            >
              {!isApplying && <span>Apply</span>}
              {isApplying && <div className="btn-spinner">..</div>}
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default TableRowData;
