import { BorderButton, Input, JourneyNavLine, ProgressBar } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import closeIcn from 'assets/close-gray-icn.svg';
import DeleteIcon from 'assets/red-delete-icon.svg';

interface Props {
	isPartner: boolean;
}

const PersonalPhoneNumber = (props: Props) => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [showAnotherInput, setShowAnotherInput] = useState<boolean>(true);
	const FProgress = Number(readFromLocalStorage('_FPersonalPageProgress')) || 0;
	const [progress, setProgress] = useState(FProgress);
	const chosenSection = props.isPartner ? 'personal2' : 'personal';
	const [isComplete, setComplete] = useState(false);
	const [textInfo, setTextInfo] = useState({
		mobileNumber: user[props.isPartner ? 'personal2' : 'personal']?.mobileNumber || (props.isPartner ? '' : user?.cases?.[0]?.application.mobileNumber),
		homeNumber: user[props.isPartner ? 'personal2' : 'personal']?.homeNumber,
	});

	useEffect(() => {
		setComplete(!!textInfo.mobileNumber);
	}, [textInfo]);

	const onSubmit = (e: any) => {
		e?.preventDefault();
		setProgress(Math.floor(progress + 8.3));
		storeInLocalStorage('_FPersonalPageProgress', Math.floor(progress + 8.3));

		const currUser = { ...user };
		if (!currUser[chosenSection]) {
			currUser[chosenSection] = {};
		}

		if (!showAnotherInput) {
			if (textInfo.mobileNumber) currUser[chosenSection].mobileNumber = textInfo['mobileNumber'];
			if (textInfo.homeNumber) currUser[chosenSection].homeNumber = textInfo['homeNumber'];
		} else {
			if (textInfo.mobileNumber) currUser[chosenSection].mobileNumber = textInfo['mobileNumber'];
		}

		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		if (user?.application?.partner) {
			if (props.isPartner) {
				history.push('/user/personal/personal-has-dependent');
			} else {
				history.push('/user/personal/personal-details-second-applicant');
			}
		} else {
			history.push('/user/personal/personal-has-dependent');
		}
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						{/* FIXME application logic to point right case */}
						<H4>What’s {props.isPartner ? (user?.personal2?.firstName || user?.cases[0].application.partner?.firstName) + '\'s' : 'your'} phone
							number?</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form onSubmit={onSubmit}>
						<Input
							value={textInfo['mobileNumber'] || ''}
							name={'mobileNumber'}
							type='number'
							normalInput
							label={'Mobile number'}
							placeholder='Mobile number'
							onChange={(e) =>
								setTextInfo((prev) => ({
									...prev,
									mobileNumber: e.target.value,
								}))
							}
						/>
						<br />
						{showAnotherInput === false ? (
							<div className='inner-group-input'>
								<Input
									name={'homeNumber'}
									type='number'
									normalInput
									label={'Home number'}
									placeholder="''"
									onClickIcnClose={() => setShowAnotherInput(true)}
									onChange={(e) =>
										setTextInfo((prev) => ({
											...prev,
											homeNumber: e.target.value,
										}))
									}
								/>
								<button
									type='button'
									onClick={() => setShowAnotherInput(true)}
									className='border-less-btn'
								>
									<img src={closeIcn} alt='close' />
								</button>
							</div>
						) : null}
						<br />
						{!!showAnotherInput ? (
							<div className='add-another-btn'>
								<BorderButton
									isBold={false}
									border='0'
									value='Add another'
									boxShadow='transparent'
									onClick={() => setShowAnotherInput(false)}
								/>
							</div>
						) : null}
						{!showAnotherInput && (
							<button
								type='button'
								onClick={() => setShowAnotherInput(true)}
								className='remove-btn'
							>
								<img src={DeleteIcon} alt='close' />
								<span>Remove</span>
							</button>
						)}
						<div className='personal-details-btn '>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								disabled={!isComplete}
								color='#fff'
								marginBottom='15%'
								onClick={onSubmit}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default PersonalPhoneNumber;
