import React from 'react';
import HeaderText from './HeaderText';
import DescriptionText from './DescriptionText';

const MortgageHassle = () => {
	return (
		<div className='journey-bg hassle-bg'>
			<div className='hassle-mortgage'>
				<HeaderText>
					<span className='grey'>Cut the hassle of a </span>
					<span className='red'>mortgage</span>
				</HeaderText>
				<DescriptionText>
					<p className='grey'>
						Yes, the mortgage is the way to owning your dream home, but it comes with a lot of hassle. The hassle of
						finding the right broker, getting your eligibility evaluated and then choosing among the options. But, with
						our guidance, you'll breeze through the complexities, from selecting suitable mortgage options to securing
						the best deals.
					</p>
				</DescriptionText>
			</div>
		</div>
	);
};

export default MortgageHassle;
