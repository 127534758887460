// === REDUCER ===
import { actionTypes } from './actionTypes';

const reducer = (state: any, action: any) => {
	console.log('action', action);

	switch (action.type) {
		// user
		case actionTypes.SET_USER:
			return {
				...state,
				user: action.user,
			};

		case actionTypes.SET_SECOND_APP_EXISTS:
			return {
				...state,
				isPartnerExists: action.isPartnerExists,
			};

		/** store user progress */
		case actionTypes.SET_PROGRESS:
			const { progress } = action;
			return {
				...state,
				progress,
			};

		/** user docs */
		case actionTypes.SET_DOCS:
			const { docs } = action;
			return {
				...state,
				docs,
			};

		/** language */
		case actionTypes.SET_LANG:
			const { lang } = action;
			return {
				...state,
				lang,
			};

		/** signup data, used on account creation */
		case actionTypes.SIGNUP_DATA:
			const { signUpData } = action;
			return {
				...state,
				signUpData,
			};

		/** forgotten password email, passed between the screens */
		case actionTypes.FORGOT_PASSWORD:
			const { forgottenPasswordEmail } = action;
			return {
				...state,
				forgottenPasswordEmail,
			};

		case actionTypes.PERSONAL1_DATA:
			const { personalData1 } = action;
			return {
				...state,
				personalData1,
			};

		case actionTypes.PERSONAL2_DATA:
			const { personalData2 } = action;
			return {
				...state,
				personalData2,
			};

		case actionTypes.OFFERS_DATA:
			const { offersData } = action;
			return {
				...state,
				offersData,
			};

		// set alert message array
		case actionTypes.ALERT:
			return {
				...state,
				alert: action.alert,
			};

		// set correlation id value
		case actionTypes.SET_CORRELATION_ID:
			return {
				...state,
				correlationId: action.correlationId,
			};

		// set offers product id
		case actionTypes.SET_OFFER_PRODUCT_ID:
			return {
				...state,
				offerProductId: action.offerProductId,
			};

		// set show modal state
		case actionTypes.SHOW_OFFERS_MODAL:
			return {
				...state,
				showOffersModal: action.showOffersModal,
			};

		// set show table state
		case actionTypes.SHOW_OFFERS_TABLE:
			return {
				...state,
				showOfferTable: action.showOfferTable,
			};

		// set show modal state
		case actionTypes.SET_OFFERS_ARRAY:
			return {
				...state,
				offersTableList: action.offersTableList,
			};

		/** stores the details of the offer */
		case actionTypes.SET_OFFER_DETAILS:
			return {
				...state,
				offerDetails: action.offerDetails,
			};

		/** stores the details of the employed details */
		case actionTypes.SET_FINANCIAL_EMPLOYED:
			return {
				...state,
				financialEmployed: action.financialEmployed,
			};

		/** stores the details of the self employed details */
		case actionTypes.SET_FINANCIAL_SELF_EMPLOYED:
			return {
				...state,
				financialSelfEmployed: action.financialSelfEmployed,
			};

		/** stores the details of the 2nd applicant employed details */
		case actionTypes.SET_FINANCIAL_2ND_EMPLOYED:
			return {
				...state,
				financialApplicant2Employed: action.financialApplicant2Employed,
			};

		/** stores the details of the 2nd applicant self employed details */
		case actionTypes.SET_FINANCIAL_2ND_SELF_EMPLOYED:
			return {
				...state,
				financialApplicant2SelfEmployed: action.financialApplicant2SelfEmployed,
			};

		/** stores the details of Property details */
		case actionTypes.SET_PROPERTY_DATA:
			return {
				...state,
				property: action.property,
			};

		/** stores the details of Expenses details */

		case actionTypes.SET_EXPENSES_DATA:
			return {
				...state,
				expenses: action.expenses,
			};
		default:
			return state;
	}
};

export default reducer;
