import AdsBanner from 'components/Widgets/AdsBanner';
import { useState } from 'react';
import { QuestionHeader } from '.';
import SignUpForm from './SignUpForm';

const SignUp = () => {
	let [questionSetup, setQuestionSetup] = useState(1);

	/**
	 * Performs previous step progress for Sign Up Page
	 **/
	const handlePreviousQuestion = () => {
		setQuestionSetup(questionSetup - 1);
	};

	return (
		<div className='sign-up-container'>
			<AdsBanner />
			<div className='header-and-progress'>
				<QuestionHeader />
			</div>
			<div className='questions-container'>
				<SignUpForm />
			</div>
			<div className='change-step-btn'>
				{questionSetup > 1 && <button onClick={handlePreviousQuestion} />}
			</div>
		</div>
	);
};

export default SignUp;
