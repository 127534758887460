import { BorderButton, Input, JourneyNavLine } from 'components/Widgets';
import { useState } from 'react';
import { Bold, H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';

const TransportExpenses = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const FProgress = Number(readFromLocalStorage('_FExpensesPageProgress')) || 0;
	const [progress, setProgress] = useState(FProgress);
	const history = useHistory();
	const [textInfo, setTextInfo] = useState(user?.expenses);

	const onSubmit = (e: any) => {
		e?.preventDefault();
		setProgress(Math.floor(progress + 50));
		storeInLocalStorage('_FExpensesPageProgress', Math.floor(progress + 50));

		if (!user?.expenses){
			user.expenses = {}
		}

		user.expenses = {
			...user.expenses,
			...textInfo
		}

		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user,
		});
		history.push(`/user/expenses/household-expenses`);
	};

	return (
		<div className="personal-container">
			<JourneyNavLine closeBtn />
			<div className="personal-box">
				<div className="personal-details-header">
					<div className="personal-main-title">
						<H4>Transport Expenses</H4>
					</div>
					<Bold isBold={false} color="#959DA5">
						We’ve given you national averages to help you. You can change them
						to reflect your monthly household expenses
					</Bold>
				</div>
				<div className="personal-details-body">
					<form onSubmit={onSubmit}>
						<Input
							value={textInfo?.seasonTicket || ''}
							numberInput
							currency
							label="Fares ( travel card/season ticket)"
							placeholder="Fares ( travel card/season ticket)"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									seasonTicket: Number(value),
								}))
							}
						/>
						<Input
							value={textInfo?.fuelSpending || ''}
							numberInput
							currency
							label="Fuel"
							placeholder="Fares ( travel card/season ticket)"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									fuelSpending: Number(value),
								}))
							}
						/>
						<Input
							value={textInfo?.roadTax || ''}
							numberInput
							currency
							label="Road tax"
							placeholder="Fares ( travel card/season ticket)"
							onChange={(value: number) =>
								setTextInfo((prev) => ({ ...prev, roadTax: Number(value) }))
							}
						/>
						<Input
							value={textInfo?.insuranceAmount || ''}
							numberInput
							currency
							label="Car insurance"
							placeholder="Fares ( travel card/season ticket)"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									insuranceAmount: Number(value),
								}))
							}
						/>
						<Input
							value={textInfo?.parkingFee || ''}
							numberInput
							currency
							label="Parking"
							placeholder="Fares ( travel card/season ticket)"
							onChange={(value: number) =>
								setTextInfo((prev) => ({ ...prev, parkingFee: Number(value) }))
							}
						/>
						<Input
							value={textInfo?.otherTransportSpend || ''}
							numberInput
							currency
							label="Other transport spend"
							placeholder="Fares ( travel card/season ticket)"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									otherTransportSpend: Number(value),
								}))
							}
						/>

						<div>
							<div className="personal-details-btn ">
								<BorderButton
									value="Continue"
									width="368px"
									height="56px"
									backgroundColor="#0968E3"
									color="#fff"
									type="submit"
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default TransportExpenses;
