import { Loader } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import DesktopApplication from './DesktopApplication';
import MobileApplication from './MobileApplication';

const ApplicationHome = () => {
  const [{ user }] = useDataLayerValue();

  return (
    <section className="application-home">
      {user ? (
        <>
          <DesktopApplication />
          <MobileApplication />
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default ApplicationHome;
