/* eslint-disable react/jsx-no-comment-textnodes */
import { Bold, H4 } from "typography";
import "css/CreateProfile.css";
import BorderButton from "components/Widgets/BorderButton";

interface IProps {
    title?: string;
    subTitle?: string;
    continueBtn?: boolean;
    children?: React.ReactNode;
    onClick?: (e: any) => void;
    active?: any;
    buttonDisabled?: boolean
    buttonCaption?: string
}

const CreateProfileContent = (props: IProps) => {

    return (
        <form onSubmit={(e) => onClickWrapper(props, e)}>
            {props.active && (
                <div>
                    <div className="personal-details-header">
                        <div className="personal-main-title">
                            <H4>{props.title}</H4>
                        </div>
                        <Bold isBold={false} color="#959DA5">
                            {props.subTitle}
                        </Bold>
                    </div>
                    <div className="personal-details-body">
                        {props.children}
                    </div>
                    {props.continueBtn &&
                        <div className="personal-details-btn">
                            <BorderButton
                                disabled={props.buttonDisabled || false}
                                value={props.buttonCaption || "Continue"}
                                width="368px"
                                height="56px"
                                backgroundColor="#0968E3"
                                color="#fff"
                                type={"submit"}
                            />
                        </div>}
                </div>
            )}
        </form>
    );
};

const onClickWrapper = (props: IProps, e?: any) => {
    e?.preventDefault();
    props.onClick(e)
}

export default CreateProfileContent;
