import { BorderButton, Input, JourneyNavLine, ProgressBar, SelectInput } from 'components/Widgets';
import { H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { landerNames, MortgagesNameList } from 'utils/DropDownList';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';

const Liabilities6 = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const { application } = user?.cases[0] || {};

	const [currentMortgageName, setCurrentMortgageName] = useState(user?.liabilities?.currentMortgageName);
	const [mortgageLender, setMortgageLender] = useState(application?.mortgageLender || user?.liabilities?.mortgageLender);
	const [propertySalePrice, setPropertySalePrice] = useState(application?.propertyValue || user?.liabilities?.propertySalePrice);
	const [isComplete, setComplete] = useState(false);

	useEffect(() => {
		setComplete(!!currentMortgageName && !!mortgageLender && propertySalePrice > 0);
	}, [currentMortgageName, mortgageLender, propertySalePrice]);

	const onSubmit = () => {
		const currUser = { ...user };

		if (!currUser?.liabilities) {
			currUser.liabilities = {};
		}

		currUser.liabilities.currentMortgageName = currentMortgageName;
		currUser.liabilities.mortgageLender = mortgageLender;
		currUser.liabilities.propertySalePrice = propertySalePrice;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push('/user/liabilities/liabilities-7');
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={42} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Current mortgage details</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={onSubmit}>
						<div className='mb'>
							<SelectInput
								label='Who is named on the mortgage?'
								options={MortgagesNameList}
								onSelect={(value: string) => setCurrentMortgageName(value)}
								defaultValue={currentMortgageName || null}
							/>
						</div>
						<SelectInput
							label='Lender Name'
							options={landerNames}
							onSelect={(value: string) => setMortgageLender(value)}
							defaultValue={mortgageLender || null}
							showSearch
						/>
						<Input
							value={propertySalePrice || 0}
							numberInput
							currency
							label='What is the property value?'
							placeholder='What is the property value?'
							onChange={setPropertySalePrice}
						/>
						<div className='personal-details-btn '>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								disabled={!isComplete}
								color='#fff'
								marginBottom='15%'
								onClick={onSubmit}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Liabilities6;
