import { BorderButton, Input, JourneyNavLine, ProgressBar } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const PropertyYears = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [buildYear, setBuildYear] = useState(user?.property?.buildYear || 0);

	const [isCompleted, setComplete] = useState(false);

	useEffect(() => {
		setComplete(buildYear > 0)
	},[buildYear])

	const onSubmit = (e: any) => {
		e?.preventDefault();
		
		const currUser = { ...user };

		if (!currUser?.property) {
			currUser.property = {};
		}

		currUser.property.buildYear = buildYear;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push(`/user/property/purchase-property-shared-ownership`);
	};

	return (
		<div className="personal-container">
			<ProgressBar progress={24} />
			<JourneyNavLine closeBtn />
			<div className="personal-box">
				<div className="personal-details-header">
					<div className="personal-main-title">
						<H4>What year was the property built?</H4>
					</div>
				</div>
				<div className="personal-details-body">
					<form className="central-form-group" onSubmit={onSubmit}>
						<Input
							type="number"
							name="buildYear"
							value={buildYear}
							normalInput
							label="Built Year?"
							min={0}
							placeholder="Built Year?"
							onChange={(e) => {
								if (e.target.value < 0) return;
								setBuildYear((e.target.value * 1).toString());
							}}
						/>
						<div className="personal-details-btn">
							<BorderButton
								value="Continue"
								width="368px"
								height="56px"
								backgroundColor="#0968E3"
								disabled={!isCompleted}
								color="#fff"
								marginBottom="15%"
								onClick={onSubmit}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default PropertyYears;
