import { Link } from 'react-router-dom';
import { Bold } from 'typography';

interface ButtonProps {
  value?: string;
  icon?: string;
  marginLeft?: string;
  width?: string;
  backgroundColor?: string;
  borderColor?: string;
  onClick?: () => void;
  color?: string;
  link?: string;
}

const LightBlueButton = (props: ButtonProps) => {
  return (
    <>
      {props.link ? (
        <Link
          to={props.link}
          style={{
            backgroundImage: `url(${props.icon})`,
            paddingLeft: props.icon && `40px`,
            marginLeft: props.marginLeft,
            width: props.width && props.width,
            backgroundColor: props.backgroundColor,
            borderColor: props.borderColor,
            color: props.color,
          }}
          className="light-blue-btn"
          onClick={props.onClick}
        >
          <Bold>{props.value}</Bold>
        </Link>
      ) : (
        <button
          style={{
            backgroundImage: `url(${props.icon})`,
            paddingLeft: props.icon && `40px`,
            marginLeft: props.marginLeft,
            width: props.width && props.width,
            backgroundColor: props.backgroundColor,
            borderColor: props.borderColor,
            color: props.color,
          }}
          className="light-blue-btn"
          onClick={props.onClick}
        >
          <Bold>{props.value}</Bold>
        </button>
      )}
    </>
  );
};

export default LightBlueButton;
