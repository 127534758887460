import { BorderButton, Input, JourneyNavLine, ProgressBar } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const Liabilities12 = () => {
	const history = useHistory();
	const [{ user }, dispatch] = useDataLayerValue();
	const [currentRentAmount, setCurrentRentAmount] = useState(user?.liabilities?.currentRentAmount);

	const [isComplete, setComplete] = useState(false);

	useEffect(() => {
		setComplete(currentRentAmount > 0);
	}, [currentRentAmount]);

	const onSubmit = (e: any) => {
		e?.preventDefault();

		const currUser = { ...user };

		if (!currUser?.liabilities) {
			currUser.liabilities = {};
		}

		currUser.liabilities.currentRentAmount = currentRentAmount;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});
		history.push('/user/liabilities/liabilities-13');
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={84} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>What's your current rent per annum?</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={onSubmit}>
						<Input
							name='currentRentAmount'
							value={currentRentAmount || 0}
							type='number'
							numberInput
							currency
							label='Current rent amount'
							onChange={(value) =>
								setCurrentRentAmount(value)
							}
						/>

						<div className='personal-details-btn '>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								disabled={!isComplete}
								color='#fff'
								marginBottom='15%'
								onClick={onSubmit}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Liabilities12;
