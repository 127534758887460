import { ProgressBar, StepContent } from 'components/Widgets';
import RadioInputBoolean from './RadioInputBoolean';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';
import { Loader } from '../Widgets';

const CreateProfileWhatAreYouLookingFor = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [isLookingTo, setIsLookingTo] = useState(
		user?.cases?.[0]?.application?.isLookingTo,
	);

	const handleChoice = (answer: string) => {
		setIsLookingTo(answer);
		saveToContextAndContinue(answer, '/user/create-profile');
	};

	const saveToContextAndContinue = (answer, link) => {
		const currUser = { ...user };
		currUser.cases[0].application.isFirstTimeBuyer = answer === 'buyFirstHome';
		currUser.cases[0].application.isLookingTo = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			history.push(link);
		}, 200);
	};

	return (
		user ?
			<div className='create-profile-container' style={{ minHeight: '100vh' }}>
				<ProgressBar progress={0} />
				<StepContent closeBtn={false} showBackBtn step={1}>
					<CreateProfileContent
						active
						title='What are you looking for?'
						onClick={() => {
						}}
					>
						<RadioInputBoolean
							value='buyFirstHome'
							isChecked={isLookingTo === 'buyFirstHome'}
							SelectionText='I’m looking to buy my first home'
							name='isLookingTo'
							onClick={() => handleChoice('buyFirstHome')}
						/>
						<RadioInputBoolean
							value='buyNextHome'
							isChecked={isLookingTo === 'buyNextHome'}
							SelectionText='I’m looking to buy my next home'
							name='isLookingTo'
							onClick={() => handleChoice('buyNextHome')}
						/>
						<RadioInputBoolean
							value='remortgage'
							isChecked={isLookingTo === 'remortgage'}
							SelectionText='I’m looking to remortgage'
							name='isLookingTo'
							onClick={() => handleChoice('remortgage')}
						/>
					</CreateProfileContent>
				</StepContent>
			</div> : <Loader text={'Loading...'} />
	);
};

export default CreateProfileWhatAreYouLookingFor;
