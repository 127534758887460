import {
	BorderButton,
	Input,
	JourneyNavLine,
	Loader,
	ProgressBar,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { PATCHRequest } from 'utils/AuthFunctions';
import closeIcn from 'assets/close-gray-icn.svg';
import DeleteIcon from 'assets/red-delete-icon.svg';
import clsx from 'clsx';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

const Occupants18AgedDetails = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [isComplete, setComplete] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [textInfo, setTextInfo] = useState(
		user?.property?.otherOccupantDetails || [
			{ firstName: '', lastName: '', birthdate: '' },
		]
	);

	useEffect(() => {
		const checkEmptiness = (field) =>
			textInfo.some((elem) => elem[field]?.trim?.()?.length === 0);
		const allDatesEligible = textInfo.every((elem) => {
			return dayjs(elem['birthdate']).isSameOrBefore(
				dayjs().subtract(18, 'year')
			);
		});

		const emptyFirstName = checkEmptiness('firstName');
		const emptyLastName = checkEmptiness('lastName');
		const emptyBirthdate = checkEmptiness('birthdate');

		if (
			!allDatesEligible ||
			emptyFirstName ||
			emptyLastName ||
			emptyBirthdate
		) {
			setComplete(false);
		} else {
			setComplete(true);
		}
	}, [textInfo]);

	const onSubmit = async (e: any) => {
		e?.preventDefault();

		const currUser = { ...user };
		if (!currUser?.property) {
			currUser.property = {};
		}

		currUser.property.otherOccupantDetails = textInfo;

		setIsSaving(true);
		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		await PATCHRequest(
			`/users?attribute=property`,
			user.property,
			history
		);
		setIsSaving(false);

		history.push('/user/stages');
	};

	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...textInfo];
		list[index][name] = value;
		setTextInfo(list);
	};

	const handleRemoveClick = (index) => {
		const list = [...textInfo];
		list.splice(index, 1);
		setTextInfo(list);
	};

	const handleAddClick = () => {
		setTextInfo([...textInfo, { firstName: '', lastName: '', birthdate: '' }]);
	};

	if (isSaving) return <Loader text='Saving...' />;

	return (
		<div className='personal-container'>
			<ProgressBar progress={100} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Other occupant details</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form onSubmit={onSubmit}>
						{textInfo.map((x, i) => {
							return (
								<React.Fragment key={`${x.firstName}-${i}`}>
									<div
										className={clsx(
											'inner-group-input',
											textInfo.length > 1 && 'margin-top-20'
										)}
									>
										<Input
											normalInput
											label='First Name'
											name='firstName'
											type='text'
											placeholder='text'
											value={textInfo[i].firstName}
											onChange={(e) => handleInputChange(e, i)}
											required
										/>
										{textInfo.length !== 1 && i !== 0 && (
											<button
												type='button'
												onClick={() => handleRemoveClick(i)}
												className='border-less-btn'
											>
												<img src={closeIcn} alt='close-icon' />
											</button>
										)}
									</div>
									<Input
										normalInput
										label='Last Name'
										name='lastName'
										value={textInfo[i].lastName}
										type='text'
										placeholder='text'
										onChange={(e) => handleInputChange(e, i)}
										required
									/>
									<Input
										normalInput
										label='Date of Birth'
										name='birthdate'
										value={textInfo[i].birthdate}
										type='date'
										placeholder='text'
										onChange={(e) => handleInputChange(e, i)}
										required
									/>
									{textInfo.length !== 1 && i !== 0 && (
										<button
											type='button'
											onClick={() => handleRemoveClick(i)}
											className='remove-btn'
										>
											<img src={DeleteIcon} alt='close' />
											<span>Remove</span>
										</button>
									)}
								</React.Fragment>
							);
						})}
						<br />
						<BorderButton
							isBold={false}
							border='0'
							value='Add another'
							boxShadow='transparent'
							onClick={handleAddClick}
						/>

						<div className='personal-details-btn'>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								disabled={!isComplete}
								backgroundColor='#0968E3'
								color='#fff'
								marginBottom='15%'
								onClick={onSubmit}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
export default Occupants18AgedDetails;
