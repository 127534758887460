import 'css/JourneyHeader.css';
import NavProfileAvatar from 'components/WorkArea/NavProfileAvatar';
import { useDataLayerValue } from 'context-api/DataLayer';
import getLogoSrc from 'utils/getLogoSrc';
import AdsBanner from './AdsBanner';

const JourneyHeader = () => {
	const [{ user }] = useDataLayerValue();

	return (
		<header className='journey-header'>
			<AdsBanner />
			<img src={getLogoSrc()} alt='logo' />
			<NavProfileAvatar firstname={user?.personal?.firstName || '.'} lastname={user?.personal?.lastName || '.'} />
		</header>
	);
};

export default JourneyHeader;
