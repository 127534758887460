import { useState } from 'react';
import { Tooltip } from 'antd';
import { Bold, SmallBold } from 'typography';
import mailicon from 'assets/mail.svg';
import mailreadicon from 'assets/mail-read.svg';

interface INotificationTile {
  text?: string;
  timestamp?: string;
  type?: any;
}

function NotificationTile(props: INotificationTile) {
  const [markRead, setMarkRead] = useState(false);

  return (
    <div
      className={
        markRead
          ? 'flex notification-tile'
          : 'flex notification-tile notify-unread-back'
      }
    >
      <div className="notification-icon">
        <img src={props.type} alt="" />
      </div>

      {/* content */}
      <aside onClick={() => setMarkRead(true)} className="notification-details">
        <div className="notification-text">
          <Bold isBold={!markRead}>{props.text}</Bold>
        </div>
        <div className="notification-timestamp">
          <SmallBold isBold={false} opacity={0.5}>
            Monday, July 5, 12:17 am
          </SmallBold>
        </div>
      </aside>

      {/* read receipt */}
      <div
        onClick={() => setMarkRead(!markRead)}
        className="notify-read-receipt"
      >
        {markRead ? (
          <Tooltip title="Mark as unread" placement="left">
            <img src={mailreadicon} alt="mail" />
          </Tooltip>
        ) : (
          <img src={mailicon} alt="mail" />
        )}
      </div>
    </div>
  );
}

export default NotificationTile;
