import getLogoSrc from 'utils/getLogoSrc';

function LogoSlab() {
  return (
    <header className="app-logo">
      <img className="isDesktop" src={getLogoSrc()} alt="Logo" />
      <img className="isTablet" src={getLogoSrc()} alt="Logo" />
    </header>
  );
}

export default LogoSlab;
