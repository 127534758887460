import { AuthForgotPassword, AuthLogIn, AuthResetPassword, SignUp } from 'components/Auth';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { TabTitle } from 'utils/GeneralFunctions';

const AuthRoutes = () => {
  const { path } = useRouteMatch();
  TabTitle('Auth');

  return (
    <Switch>
      <Route exact path={`${path}/signup`}>
        <SignUp />
      </Route>

      <Route exact path={`${path}/forgot-password`}>
        <AuthForgotPassword />
      </Route>

      <Route exact path={`${path}/reset-password`}>
        <AuthResetPassword />
      </Route>

      <Route path={`${path}/`}>
        <AuthLogIn />
      </Route>
    </Switch>
  );
};

export default AuthRoutes;
