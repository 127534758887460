import {
	CleanRadioInput,
	JourneyNavLine,
	ProgressBar,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const SharedOwnerShip = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [isSharedOwnershipScheme, setSharedOwnershipScheme] = useState<boolean>(
		user?.property?.isSharedOwnershipScheme
	);

	const setAnswer = (answer: boolean) => {
		setSharedOwnershipScheme(answer);

		const currUser = { ...user };

		if (!currUser?.property) {
			currUser.property = {};
		}

		currUser.property.isSharedOwnershipScheme = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});
	
		setTimeout(() => {
			if (answer) {
				history.push(
					'/user/property/purchase-property-shared-ownership-details'
				);
			} else {
				delete user.property.percentToMortgage;
				delete user.property.sharedOwnerBodyName;
				delete user.property.monthlyRentPayment;
				history.push('/user/property/purchase-property-type');
			}
		}, 300);
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={30} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Are you buying through a Shared Ownership scheme?</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={(e) => e}>
						<CleanRadioInput
							name={'isSharedOwnershipScheme'}
							label={'Yes'}
							value='true'
							onClick={() => setAnswer(true)}
							checked={
								typeof isSharedOwnershipScheme === 'boolean' &&
								isSharedOwnershipScheme
							}
						/>

						<CleanRadioInput
							name={'isSharedOwnershipScheme'}
							label={'No'}
							onClick={() => setAnswer(false)}
							value='false'
							checked={
								typeof isSharedOwnershipScheme === 'boolean' &&
								!isSharedOwnershipScheme
							}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SharedOwnerShip;
