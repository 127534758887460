import axios from 'axios';
import { storeInLocalStorage } from './AuthFunctions';
import userPool from './UserPool';

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_BASE,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

export const AuthLogout = () => {
	userPool.getCurrentUser().signOut();
	localStorage.clear();
	storeInLocalStorage('accessMeAccount', true);
};

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
	async (config) => {
		config.headers.Authorization = `Bearer ${await getToken()}`
		return config;
	},
	(error) => {
		Promise.reject(error);
	},
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function(error) {
		const originalRequest = error.config;
		let token = await getToken();
		if (error.response.status === 401 && !originalRequest._retry && token?.length > 0) {
			originalRequest._retry = true;
			axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			return axiosInstance(originalRequest);
		}
		return Promise.reject(error);
	},
);

const getToken = async (): Promise<string> => {
	let user = await userPool.getCurrentUser();
	let token;
	if (user) {
		token = await new Promise((resolve) => {
			user.getSession((error, session) => {
				if (error) {
					resolve('');
				} else {
					resolve(session.getIdToken().getJwtToken());
				}
			});
		});
	} else {
		return '';
	}
	return token;
};
