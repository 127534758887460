import { CleanRadioInput } from './index';

interface Props {
	positive: {
		label: string
		value: any
	};
	negative: {
		label: string
		value: any
	};
	value: boolean | null | undefined;
	onClick: (e: any) => void;
}

const RadioBoolean = (props: Props) => {
	return <div className='personal-details-body central-form-group'>
		<CleanRadioInput
			name='namePositive'
			label={props.positive.label}
			onChange={() => props.onClick(props.positive.value)}
			checked={typeof props.value === 'boolean' && props.value}
		/>

		<CleanRadioInput
			name={'nameNegative'}
			label={props.negative.label}
			onChange={() => props.onClick(props.negative.value)}
			checked={typeof props.value === 'boolean' && !props.value}
		/>
	</div>;
};

export default RadioBoolean;
