
import {
	CleanRadioInput,
	JourneyNavLine,
	Loader,
	ProgressBar,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { PATCHRequest } from 'utils/AuthFunctions';

const PreProperty = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const { property } = user;
	const history = useHistory();
	const [isKnownProperty, setIsKnownProperty] = useState<boolean>(property?.isKnownProperty);
	const [isSaving, setIsSaving] = useState(false);
	const historyRef = useRef(null);

	const setAnswer = (answer: boolean) => {
		historyRef.current = true;
		const currUser = { ...user };
		setIsKnownProperty(answer);
		if (answer) {
			currUser.property = { ...property, isKnownProperty: answer };
			dispatch({
				type: actionTypes.SET_USER,
				user: currUser,
			});
			return setTimeout(() => {
				// to prevent history from pushing twice
				if (!historyRef.current) return;

				history.push('/user/property');
				historyRef.current = false;
			}, 300);
		}

		setIsSaving(true);
		currUser.property = { isKnownProperty: answer };
		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});
		PATCHRequest(
			`/users?attribute=property`,
			currUser.property,
			history,
		).then(() => {
			// to prevent history from pushing twice
			if (!historyRef.current) return;

			setIsSaving(false);
			history.push('/user/stages');
			historyRef.current = false;
		});
	};

	if (isSaving) return <Loader text='Saving...' />

	return (
		<div className="personal-container">
			<ProgressBar progress={6} />
			<JourneyNavLine closeBtn />
			<div className="personal-box">
				<div className="personal-details-header">
					<div className="personal-main-title">
						<H4>Do you know the property details that you like to purchase?</H4>
					</div>
				</div>
				<div className="personal-details-body">
					<form
						className="central-form-group"
						onSubmit={(e) => e.preventDefault()}
					>
						<CleanRadioInput
							name="isKnownProperty"
							label="Yes"
							value="yes"
							onChange={() => setAnswer(true)}
							checked={isKnownProperty}
						/>

						<CleanRadioInput
							name="isKnownProperty"
							label="No"
							onChange={() => setAnswer(false)}
							value="no"
							checked={isKnownProperty === false}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default PreProperty;
