import 'css/Loader.css';

interface ILoader {
	showText?: boolean;
	text?: string;
	small?: boolean;
}

const defaultProps = {
	showText: true,
};

const Loader = (props: ILoader) => {
	props = { ...defaultProps, ...props };

	return (
		<div className='preloader-box'>
			<div>
				<div className={`spinner ${props.small ? 'small' : ''}`}>
					<div className={`spinner-inner ${props.small ? 'small' : ''}`} />
				</div>
				{props.showText && (
					<div className='preloader-text'>
						{!props.text ? 'Loading data...' : props.text}
					</div>
				)}
			</div>
		</div>
	);
};

export default Loader;
