/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
import { LeftMenu } from 'components/LeftMenu';
import { WorkArea } from 'components/WorkArea';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import 'css/Dashboard.css';
import { useEffect } from 'react';
import {
	GETRequest,
	PATCHRequest,
	storeInLocalStorage,
} from 'utils/AuthFunctions';
import { useHistory, useLocation } from 'react-router-dom';

const Dashboard = () => {
	const [{}, dispatch] = useDataLayerValue();
	const history = useHistory();

	// PATCH THE LAST VISITED PAGE
	const location = useLocation();
	useEffect(() => {
		PATCHRequest(
			`/users?attribute=lastVisited`,
			{ url: location.pathname },
			history
		);
	}, [location]);

	useEffect(() => {
		GETRequest(`/users?extended=true`, history).then(
			(userInfoFeedback) => {
				if (userInfoFeedback.success) {
					// update user info
					dispatch({
						type: actionTypes.SET_USER,
						// @ts-ignore
						user: userInfoFeedback?.data,
					});

					// write into local storage
					storeInLocalStorage(
						'_user',
						// @ts-ignore
						userInfoFeedback?.data
					);
				}
			}
		);

		/** GET User Progress **/
		GETRequest(`/progress`, history).then(
			(userProgressFeedback) => {
				// store user progress
				dispatch({
					type: actionTypes.SET_PROGRESS,
					// @ts-ignore
					progress: userProgressFeedback?.data,
				});
			}
		);

		GETRequest(`/docs`, history).then(
			(userDocsFeedback) => {
				if (userDocsFeedback.success) {
					// update user info
					dispatch({
						type: actionTypes.SET_DOCS,
						// @ts-ignore
						docs: userDocsFeedback?.data,
					});
				}
			}
		);
	}, []);

	return (
		<div className='app-dashboard'>
			<LeftMenu />
			<WorkArea />
		</div>
	);
};

export default Dashboard;
