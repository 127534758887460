import { BorderButton, CleanInput, JourneyNavLine, ProgressBar, SelectInput } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { FinancialStatusArray } from 'utils/FinancialData';

const Applicant2EmpDetails = () => {
	const history = useHistory();

	const [{ user }, dispatch] = useDataLayerValue();
	const { financial2 } = user || {};
	const { application } = user?.cases?.[0] || {};
	const secondAppData = application?.partner;

	const [selectVal, setSelectVal] = useState(financial2?.employmentStatus);
	const [nationalInsurance, setNationalInsurance] = useState(
		financial2?.insuranceNumber,
	);

	const [isValid, setValid] = useState<boolean>(false)

	useEffect(() => {
		setValid(selectVal)
	}, [selectVal])

	const handleChangeEmploymentStatus = (value) => {
		setSelectVal(value);

		const currFinancial = { ...financial2 };
		// these are the fields we want to keep the same
		const specialFields = [
			'employmentStatus',
			'otherIncome',
			'insuranceNumber',
		];

		Object.keys(currFinancial).forEach((key) => {
			if (specialFields.includes(key)) return;

			delete currFinancial[key];
		});

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial2: currFinancial },
		});
	};

	const onSubmit = (e) => {
		e?.preventDefault();

		const currFinancial = { ...financial2 };
		currFinancial['employmentStatus'] = selectVal;
		nationalInsurance && (currFinancial['insuranceNumber'] = nationalInsurance);

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial2: currFinancial },
		});

		switch (currFinancial.employmentStatus) {
			case 'Retired':
			case 'Not employed':
				history.push('/user/financial/seven');
				break;
			case 'Self employed':
				history.push(`/user/financial/second-applicant/self-employed`);
				break;
			default:
				history.push(`/user/financial/second-applicant/current-employment`);
				break;
		}
	};

	return (
		<div className='financial-pages'>
			<ProgressBar progress={30} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>{`${
					secondAppData?.firstName?.charAt(0).toUpperCase() +
					secondAppData?.firstName?.slice(1)
				}'s employment details`}</H4>

				<form className='central-form-group' onSubmit={onSubmit}>
					<SelectInput
						name='employment-details'
						label='Employment status'
						options={FinancialStatusArray}
						defaultValue={selectVal || null}
						onSelect={(value: string) => handleChangeEmploymentStatus(value)}
					/>

					{user.userStatus === 'Journey completed' ?
						<CleanInput
							value={nationalInsurance || ''}
							label='National insurance number'
							type='text'
							placeholder='text'
							required
							onChange={(e: any) => setNationalInsurance(e.target.value)}
						/> : <></>
					}

					<div className={`continue-btn`} >
						<BorderButton
							disabled={!isValid}
							value='Continue'
							width='368px'
							height='56px'
							type='submit'
							backgroundColor='#0968E3'
							color='#fff'
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Applicant2EmpDetails;
