/* eslint-disable jsx-a11y/iframe-has-title */
import { useState } from 'react';
import pdffilledicon from 'assets/doc-pdf-filled.svg';
import optiondotsicon from 'assets/option-dots.svg';
import { SmallBold } from 'typography';

interface IDocCard {
	link: string;
	description: string;
	timestamp: string;
	fileType?: string;
	fileName?: string;
}

const DocCard = (props: IDocCard) => {
	const [openOption, setOpenOption] = useState(false);

	return (
		<div className='app-paper doc-card'>
			<img className='doc-mobile-pdf' src={pdffilledicon} alt='' />
			{
				(props.fileType === 'pdf') ?
					<header className='doc-preview'>
						<iframe src={props.link}></iframe>
					</header>
					:
					<header className='blank-doc-preview' />
			}
			<footer className='flex doc-footer'>
				<div className='doc-footer-title'>
					<SmallBold
						isBold={false}>{(props.description || '') + ' ' + (props.fileName || '')}</SmallBold>
				</div>
				<div className='flex doc-footer-stamps'>
					{/*<img src={pdficon} alt="" />*/}
					<div className='doc-card-timestamps'>{props.timestamp}</div>
					<img
						onClick={() => setOpenOption(!openOption)}
						className='doc-card-dots'
						src={optiondotsicon}
						alt=''
					/>

					{/* download btn */}
					{
						// eslint-disable-next-line react/jsx-no-target-blank
						<a
							href={props.link}
							download
							target='_blank'
							style={{ display: openOption ? 'block' : 'none' }}
							className='download-btn'
						>
							Download
						</a>
					}
				</div>
			</footer>
		</div>
	);
};

export default DocCard;
