import { BorderButton, Input, JourneyNavLine, ProgressBar } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const TenureRemaining = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [isComplete, setIsComplete] = useState(false);
	const [leaseYears, setLeaseYears] = useState<number>(user?.property?.leaseYears || 0);

	useEffect(() => {
		setIsComplete(leaseYears >= 0);
	}, [leaseYears]);

	const onSubmit = (e: any) => {
		e?.preventDefault();

		const currUser = { ...user };
		if (!currUser?.property) {
			currUser.property = {};
		}

		currUser.property.leaseYears = leaseYears;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push(`/user/property/purchase-property-main-residence`);
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={54} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>If lease, what is the remaining term?</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form onSubmit={onSubmit}>
						<Input
							type='number'
							normalInput
							label={'Remaining years'}
							placeholder='Remaining years'
							value={leaseYears}
							onChange={(e) => {
								if (e.target.value >= 0) {
									setLeaseYears(e.target.value);
								}
							}}
						/>
						<div className={!isComplete ? 'isDisabled' : ''}>
							<div className='personal-details-btn '>
								<BorderButton
									value='Continue'
									width='368px'
									height='56px'
									backgroundColor='#0968E3'
									color='#fff'
									marginBottom='15%'
									onClick={onSubmit}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default TenureRemaining;
