import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DataLayer } from 'context-api/DataLayer';
import { initialState } from 'context-api/initialState';
import reducer from 'context-api/reducer';
import ModalCustom from 'components/Widgets/ModalCustom';
import Modal from 'react-modal';

if (process.env.NODE_ENV === 'production') {
	console.log = () => {};
}

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: 'https://993a72edc8284e1692b3711121613c52@o315890.ingest.sentry.io/4504599359455232',

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		integrations: [new Sentry.Replay(), new BrowserTracing()],
	});
}

Modal.setAppElement('#root');
ReactDOM.render(
	<DataLayer initialState={initialState} reducer={reducer}>
		<React.StrictMode>
			<App />
			<ModalCustom />
		</React.StrictMode>
	</DataLayer>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
