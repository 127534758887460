import {
	JourneyNavLine,
	Loader,
	CleanRadioInput,
	ProgressBar,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import {
	PATCHRequest,
	readFromLocalStorage,
	storeInLocalStorage,
} from 'utils/AuthFunctions';

const Applicant2Financial7 = () => {
	const history = useHistory();
	const [isSaving, setIsSaving] = useState(false);
	const data = JSON.parse(readFromLocalStorage('_extra_income_radio_data_2'));

	const [{ user }, dispatch] = useDataLayerValue();
	const { financial, financial2 } = user || {};
	const { application } = user?.cases?.[0] || {};
	const secondAppData = application?.partner;

	const delay = 300;
	const funcRespondYes = (e) => {
		storeInLocalStorage('_extra_income_radio_data_2', e.target.value);
		setTimeout(() => {
			history.push('/user/financial/second-applicant/eight');
		}, delay);
	};

	const funcRespondNo = (e) => {
		storeInLocalStorage('_extra_income_radio_data_2', e.target.value);
		const currFinancial = { ...financial2 };
		// when user selects no, we no longer need to keep the data
		delete currFinancial.otherIncome;

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial2: currFinancial },
		});

		setTimeout(async () => {
			setIsSaving(true);
			await PATCHRequest(
				'/users?attribute=financial',
				financial,
				history
			);
			await PATCHRequest(
				'/users?attribute=financial2',
				currFinancial,
				history
			);
			setIsSaving(false);
			history.push('/user/stages');
		}, delay);
	};

	if (isSaving) return <Loader text='Saving...' />

	return (
		<div className='financial-pages'>
			<ProgressBar progress={85} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>{`Does ${
					secondAppData?.firstName?.charAt(0).toUpperCase() +
					secondAppData?.firstName?.slice(1)
				} have any additional income?`}</H4>

				<div className='personal-details-body'>
					<form
						className='central-form-group'
						onSubmit={(e) => e.preventDefault()}
					>
						<CleanRadioInput
							name='extra-income'
							label='Yes'
							value='yes'
							onChange={funcRespondYes}
							checked={data === 'yes'}
						/>

						<CleanRadioInput
							name='extra-income'
							label='No'
							value='no'
							onChange={funcRespondNo}
							checked={data === 'no'}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Applicant2Financial7;
