import { Input } from './index';
import { useEffect, useRef } from 'react';

interface PostCodeProps {
	value?: string;
	name?: string;
	label?: string;
	onChange?: (e: any) => void;
	onAddressUpdate: (e: AddressUpdate) => void;
}

export interface AddressUpdate {
	country: string;
	city: string;
	postCode: string;
	address: string;
}

const EnhancedPostCodeInput = (props: PostCodeProps) => {
	const autoCompleteRef = useRef() as any;
	const inputRef = useRef();

	useEffect(() => {
		// This can be updated accordingly
		const options = {
			// componentRestrictions: { country: 'uk' },
			fields: ['address_components'],
			// types: ['all'],
		};

		google.maps.event.addListener(inputRef.current, 'keydown', function(event) {
			if (event.keyCode === 13) {
				event.preventDefault();
			}
		});
		autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
		autoCompleteRef.current.addListener('place_changed', async () => {
			const location = {} as AddressUpdate;
			const place = await autoCompleteRef.current.getPlace();

			if (!place?.address_components){
				return
			}

			const { address_components: addressComponents } = place;
			let address = '';

			addressComponents.forEach((locale) => {
				if (locale.types.includes('street_number')) {
					address += `${locale.long_name}, `;
				} else if (locale.types.includes('country')) {
					location.country = locale.long_name;
				} else if (locale.types.includes('postal_code')) {
					location.postCode = locale.long_name;
					//this sounds a bit wrong, onAddressUpdate should be enough everywhere
					props.onChange({ target: { value: locale.long_name + ' ' } });
				} else if (locale.types.includes('locality') || locale.types.includes('postal_town')) {
					location.city = locale.long_name;
				} else if (locale.types.includes('route')) {
					location.address = address + locale.long_name;
				}
			});
			props.onAddressUpdate(location);
		});
	}, []); // eslint-disable-line

	return (
		<Input
			value={props.value || ''}
			normalInput
			autoComplete={'nope'}
			label={props.label || 'Find your address'}
			type='text'
			placeholder='text'
			onChange={props.onChange}
			required
			reference={inputRef}
		/>
	);
};

export default EnhancedPostCodeInput;
