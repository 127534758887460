import { Bold, H6, SmallSubTitle } from 'typography';
import 'css/Settings.css';
import Input from 'components/Widgets/Input';
import { changeLoggedInUserPassword } from 'utils/AuthFunctions';
import { convertCamelCaseToHumanReadable } from 'utils';
import { useState } from 'react';
import ModalCustom from 'components/Widgets/ModalCustom';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';

interface PasswordDataProps {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const initialPasswordState = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

/**
 * This contains all the change password logic and UI
 * @returns PasswordInputContent for Logged In User on Settings page
 */
const PasswordInputModal = ({
  isModalVisiblePassword,
  setIsModalVisiblePassword,
}) => {
  const [passwordData, setPasswordData] = useState(initialPasswordState);
  const [passwordError, setPasswordError] = useState(initialPasswordState);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const { oldPassword, newPassword, confirmNewPassword } = passwordData;
  const [{ alert }, dispatch] = useDataLayerValue();

  const areFieldsValid = () => {
    let isValid = true;
    Object.keys(passwordData).forEach((key: string) => {
      if (passwordData[key]) return;

      isValid = false;
      setPasswordError((prev) => ({
        ...prev,
        [key]: `${convertCamelCaseToHumanReadable(key)} is required`,
      }));
    });

    if (newPassword !== confirmNewPassword) {
      isValid = false;
      setPasswordError((prev) => ({
        ...prev,
        confirmNewPassword: 'New password and confirm new password must match',
      }));
    }
    return isValid;
  };

  const handleSubmit = async () => {
    if (!areFieldsValid()) return;

    setIsChangingPassword(true);
    const response = await changeLoggedInUserPassword(
      passwordData.oldPassword,
      passwordData.newPassword
    );
    const { message, success } = response;
    setIsChangingPassword(false);

    // if error dispatch error message
    if (!success) {
      return dispatch({
        type: actionTypes.ALERT,
        alert: [
          ...alert,
          {
            isSuccess: success,
            message:
              message === 'Incorrect username or password.'
                ? 'Old password is incorrect'
                : message,
            timestamp: new Date(),
          },
        ],
      });
    }

    dispatch({
      type: actionTypes.ALERT,
      alert: [
        ...alert,
        {
          isSuccess: success,
          message,
          timestamp: new Date(),
        },
      ],
    });
    setPasswordData(initialPasswordState);
    setPasswordError(initialPasswordState);
    setIsModalVisiblePassword(false);
  };

  const handleChange = (name: string) => {
    if (passwordError[name])
      setPasswordError((prev) => ({ ...prev, [name]: '' }));
    if (newPassword === confirmNewPassword) {
      setPasswordError((prev: PasswordDataProps) => ({
        ...prev,
        confirmNewPassword: '',
      }));
    }
  };

  return (
    <ModalCustom
      visible={isModalVisiblePassword}
      okText={isChangingPassword ? 'Changing' : 'Change Password'}
      onConfirm={handleSubmit}
      confirmLoading={isChangingPassword}
      closable={false}
      onClose={() => {
        setIsModalVisiblePassword(false);
        setPasswordData(initialPasswordState);
        setPasswordError(initialPasswordState);
      }}
      okButtonProps={{
        size: 'large',
        block: true,
      }}
      isWhite
      isMobile
      isCloseIcon
    >
      <form>
        <div className="settings-modal-header">
          <div className="settings-modal-title">
            <H6>Change your password</H6>
            <div className="line" />
          </div>
          <div className="settings-modal-title-mobile">
            <SmallSubTitle>Change your password</SmallSubTitle>
            <Bold isBold={false}>Enter your new password</Bold>
          </div>
          <div className="settings-input-container password-input">
            <Input
              label="Old password"
              value={oldPassword}
              onChange={(e: { target: { value: string } }) => {
                handleChange('oldPassword');
                setPasswordData({
                  ...passwordData,
                  oldPassword: e.target.value,
                });
              }}
              normalInput
              type="password"
              placeholder="Password"
              required
              error={passwordError.oldPassword}
            />
            <Input
              label="New password"
              normalInput
              value={newPassword}
              onChange={(e: { target: { value: string } }) => {
                handleChange('newPassword');
                setPasswordData({
                  ...passwordData,
                  newPassword: e.target.value,
                });
              }}
              type="password"
              placeholder="Password"
              required
              error={passwordError.newPassword}
            />
            <Input
              label="Confirm new password"
              value={confirmNewPassword}
              onChange={(e: { target: { value: string } }) => {
                handleChange('confirmNewPassword');
                setPasswordData({
                  ...passwordData,
                  confirmNewPassword: e.target.value,
                });
              }}
              normalInput
              type="password"
              placeholder="Password"
              required
              error={passwordError.confirmNewPassword}
            />
          </div>
        </div>
      </form>
    </ModalCustom>
  );
};

export default PasswordInputModal;
