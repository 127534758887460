import { BorderButton, JourneyNavLine, ProgressBar, SelectInput } from 'components/Widgets';
import { useEffect, useState } from 'react';
import { H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { storeInLocalStorage } from 'utils/AuthFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';

interface Props {
	isPartner: boolean;
}

const PersonalDetails2 = (props: Props) => {
	const [{ user }, dispatch] = useDataLayerValue();
	const { personal } = user || {};
	const FProgress = 8.3;
	const history = useHistory();

	const [isComplete, setComplete] = useState(false);
	const [progress, setProgress] = useState(FProgress);
	const [gender, setGender] = useState(personal?.gender);

	useEffect(() => {
		setComplete(gender?.trim()?.length > 0);
	}, [gender]);

	const onSubmit = async (e: any) => {
		e?.preventDefault();
		setProgress(Math.floor(progress + 8.3));
		storeInLocalStorage('_FPersonalPageProgress', Math.floor(progress + 8.3));

		const currPersonal = { ...personal };
		currPersonal.gender = gender;

		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, personal: currPersonal },
		});

		if (user?.application?.partner) {
			if (props.isPartner) {
				history.push('/user/personal/personal-has-dependent');
			} else {
				history.push('/user/personal/personal-details-second-applicant');
			}
		} else {
			history.push('/user/personal/personal-has-dependent');
		}
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Personal details</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form>
						<div className='mt'>
							<SelectInput
								showSearch
								name={'gender'}
								label='What’s your gender?'
								options={[
									{ value: 'male', label: 'Male' },
									{ value: 'female', label: 'Female' },
								]}
								defaultValue={gender || null}
								onSelect={(value) => setGender(value)}
							/>
						</div>

						<div className={isComplete ? '' : 'isDisabled'}>
							<div className='personal-details-btn '>
								<BorderButton
									value='Continue'
									width='368px'
									height='56px'
									backgroundColor='#0968E3'
									color='#fff'
									marginBottom='15%'
									onClick={onSubmit}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default PersonalDetails2;
