import { useEffect, useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';

const CreateProfileDeposit = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 41.5 : 62.5);
	const [deposit, setDeposit] = useState(user?.cases?.[0]?.application?.deposit || 0);
	const [isComplete, setComplete] = useState<boolean>(false);

	useEffect(() => {
		setComplete(!!deposit && deposit > 0);
	}, [deposit]);

	// end of states that control form data
	const onSubmit = () => {
		user.cases[0].application.deposit = deposit;

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		history.push('/user/create-profile-mortgage');
	};

	return (
		<div className="create-profile-container" style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title="How much deposit do you have?"
					continueBtn
					onClick={onSubmit}
					buttonDisabled={!isComplete}
				>
					<Input
						value={deposit}
						required
						currency
						autoFocus={true}
						type="number"
						numberInput
						name="deposit"
						onChange={(e) => setDeposit(e)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileDeposit;
