import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { TransportExpenses, HouseholdExpenses, GeneralExpenses } from '.';
const Expenses = () => {
	const { path } = useRouteMatch();
	return (
		<div>
			<Switch>
				<Route path={`${path}/transport-expenses`} exact>
					<TransportExpenses />
				</Route>

				<Route path={`${path}/household-expenses`} exact>
					<HouseholdExpenses />
				</Route>

				<Route path={`${path}/general-expenses`} exact>
					<GeneralExpenses />
				</Route>
				<Route path={`${path}/`}>
					<TransportExpenses />
				</Route>
			</Switch>
		</div>
	);
};

export default Expenses;
