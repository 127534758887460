import { FC } from 'react';
import { Typography } from '../types/typography';

const SmallBold: FC<Typography> = ({ children, size, isBold, opacity , color , marginBottom }) => {
  return (
    <div
      style={{
        fontWeight: isBold === false ? 'normal' : 'bold',
        opacity: opacity ? opacity : 1,
        color: color,
        marginBottom: marginBottom,
      }}
      className="SmallBold"
    >
      {children}
    </div>
  );
};

export default SmallBold;
