import { useState } from "react";
import { useDataLayerValue } from "context-api/DataLayer";
import { actionTypes } from "context-api/actionTypes";
import { Bold } from "typography";
import carreticon from "assets/carret-down.svg";
import LangOption from "./LangOption";
import { langs } from "utils/languages";
import clsx from "clsx";
interface LangOptionProps {
  isMobile?: boolean;
  isDesktop?: boolean;
}
function LanguageSelection(props: LangOptionProps) {
  const [showLangMenu, setShowLangMenu] = useState(false);
  let [{ lang }, dispatch] = useDataLayerValue();

  const funcShowSelectedLang = (abbrev: string) => {
    dispatch({
      type: actionTypes.SET_LANG,
      lang: abbrev,
    });
  };

  return (
    <aside className={clsx("flex lang-select-box",  props.isMobile && 'isMobile')}>
      {props.isDesktop && (
        <div
          onClick={() => setShowLangMenu(!showLangMenu)}
          className="lang-select"
        >
          <div className="flex lang-select-inner">
            {
              // eslint-disable-next-line array-callback-return
              langs.map((language, key) => {
                if (language.abbrev === lang) {
                  return (
                    <LangOption
                      key={key}
                      abbrev={language.abbrev}
                      flag={language.flag}
                      text={language.text}
                    />
                  );
                }
              })
            }
            <div
              className={
                showLangMenu ? "navbar-caret rotate-180" : "navbar-caret"
              }
            >
              <Bold opacity={0.5}>
                <img src={carreticon} alt="" />
              </Bold>
            </div>
          </div>
          {showLangMenu && (
            <div className="lang-options-box">
              {
                // eslint-disable-next-line array-callback-return
                langs.map((language, key) => {
                  if (language.abbrev !== lang) {
                    return (
                      <LangOption
                        key={key}
                        abbrev={language.abbrev}
                        flag={language.flag}
                        funcClick={funcShowSelectedLang}
                        text={language.text}
                      />
                    );
                  }
                })
              }
            </div>
          )}
        </div>
      )}
      {props.isMobile && (
        <div className="lang-select">
          <div className="lang-options-box">
            {
              // eslint-disable-next-line array-callback-return
              langs.map((language, key) => {
                if (language.abbrev !== lang) {
                  return (
                    <LangOption
                      key={key}
                      abbrev={language.abbrev}
                      flag={language.flag}
                      funcClick={funcShowSelectedLang}
                      text={language.text}
                    />
                  );
                }
              })
            }
            <div className="flex lang-select-inner">
              {
                // eslint-disable-next-line array-callback-return
                langs.map((language, key) => {
                  if (language.abbrev === lang) {
                    return (
                      <LangOption
                        key={key}
                        abbrev={language.abbrev}
                        flag={language.flag}
                        text={language.text}
                      />
                    );
                  }
                })
              }
            </div>
          </div>
        </div>
      )}
    </aside>
  );
}

export default LanguageSelection;
