import 'css/Input.css';
import { SelectInput } from '.';
import closeIcn from 'assets/close-gray-icn.svg';
import { RefObject, useState } from 'react';
import { randId } from 'utils/GeneralFunctions';

interface InputProps {
	readOnly?: boolean | undefined;
	autoComplete?: 'on' | 'nope' | undefined;
	selectInputOnChange?: (id: any) => void;
	onClickIcnClose?: () => void;
	type?: string;
	className?: string;
	required?: boolean;
	label?: string;
	placeholder?: string;
	name?: string;
	phone?: boolean;
	normalInput?: boolean;
	onChange?: any;
	error?: string;
	width?: string;
	dateField?: boolean;
	options?: any;
	numberInput?: boolean;
	dropdown?: boolean;
	register?: any;
	rest?: any;
	value?: any;
	defaultValue?: string | number;
	birthdayFieldValue?: string;
	selectOptions?: any;
	icon?: boolean;
	currency?: boolean;
	min?: number;
	max?: number;
	autoFocus?: boolean;
	reference?: RefObject<HTMLInputElement>;
}

const Input = (props: InputProps) => {
	const [showRegexUI, setShowRegexUI] = useState(false);

	const [isEightChars, setIsEightChars] = useState(false);
	const [hasCapitalLetters, setHasCapitalLetters] = useState(false);
	const [hasNumber, setHasNumber] = useState(false);
	const [hasSymbol, setHasSymbol] = useState(false);

	const inputId = `input-${randId()}`;

	const funcHasFocus = () => {
		const inputField = document.querySelector(`#${inputId}`);

		if (inputField !== null) {
			// @ts-ignore
			if (inputField.type === 'password') {
				setShowRegexUI(true);
			}
		}
	};

	/**
	 * Handles password verification
	 * @summary Runs on KeyUp, Focus and Paste
	 * @description 8 or more characters, Uppercase & lowercase, at least one Number or Symbol
	 */
	const handlePasswordValidation = (e) => {
		const text = e.target.value;

		// 8 characters
		if (text.length >= 8) {
			setIsEightChars(true);
		} else {
			setIsEightChars(false);
		}

		// capital letters and small letters
		if (text.match(/[A-Z]/) != null && text.match(/[a-z]/) != null) {
			setHasCapitalLetters(true);
		} else {
			setHasCapitalLetters(false);
		}

		// numbers
		if (text.match(/[0-9]/) != null) {
			setHasNumber(true);
		} else {
			setHasNumber(false);
		}

		// symbols
		if (text.match(/[!@#$%^*<>.,-=+:;{}]/) != null) {
			setHasSymbol(true);
		} else {
			setHasSymbol(false);
		}
	};

	return (
		<div className='input-wrapper' style={{ maxWidth: props.width }}>
			{props.normalInput && (
				<>
					<div className='input-group'>
						<input
							type={props.type}
							className='input'
							required={props.required}
							placeholder={props.placeholder}
							autoFocus={props.autoFocus || false}
							readOnly={props.readOnly || false}
							name={props.name}
							id={inputId}
							autoComplete={props.autoComplete}
							onChange={props.onChange}
							onKeyUp={(e: any) => (props.type === 'password' ? handlePasswordValidation(e) : '')}
							onPaste={(e: any) => (props.type === 'password' ? handlePasswordValidation(e) : '')}
							onFocus={(e: any) => (props.type === 'password' ? funcHasFocus() : '')}
							onBlur={(e: any) => (props.type === 'password' ? setShowRegexUI(false) : '')}
							style={{ minWidth: props.width }}
							value={props.value}
							defaultValue={props.defaultValue}
							ref={props.reference}
						/>
						<label htmlFor={props.name} className='input-label'>
							{props.label}
						</label>
						{props.type === 'password' && showRegexUI && (
							<ul className='input-regex-ui'>
								<p>Your password must have:</p>
								<li className={isEightChars ? 'input-regex-ui-valid' : ''}>8 or more characters</li>
								<li className={hasCapitalLetters ? 'input-regex-ui-valid' : ''}>Uppercase &amp; lowercase letters</li>
								<li className={hasNumber ? 'input-regex-ui-valid' : ''}>At least one number</li>
								<li className={hasSymbol ? 'input-regex-ui-valid' : ''}>At least one symbol</li>
							</ul>
						)}
						{props.currency && <div className='currency-tag'>£</div>}
					</div>
					{props.error && <div className='input-error'>{props.error}</div>}
				</>
			)}
			{props.phone && (
				<>
					<div className='input-group'>
						<div className='input-inner'>
							<input
								type='number'
								id={props.name}
								autoFocus={props.autoFocus || false}
								className='input'
								required={props.required}
								placeholder='Phone Number'
								autoComplete={props.autoComplete}
								onChange={props.onChange}
								style={{ minWidth: props.width }}
								defaultValue={props.defaultValue}
							/>
							<label className='input-label'>{props.label}</label>
							{props.icon && (
								<button type='button' onClick={props.onClickIcnClose} className='close-btn-input'>
									<img src={closeIcn} alt='' />
								</button>
							)}
						</div>
					</div>
					{props.error && <div className='input-error'>{props.error}</div>}
				</>
			)}
			{props.dropdown && (
				<>
					<div className='input-group'>
						<SelectInput
							options={props.selectOptions}
							defaultValue={props.label}
							name={props.name}
							onSelect={props.selectInputOnChange}
						/>
					</div>
					{props.error && <div className='input-error'>{props.error}</div>}
				</>
			)}
			{props.dateField && (
				<>
					<div className='input-group'>
						<input
							type='date'
							id={props.name}
							autoFocus={props.autoFocus || false}
							className='input'
							required={props.required}
							autoComplete={props.autoComplete}
							placeholder={props.placeholder}
							name={props.name}
							onChange={props.onChange}
							style={{ minWidth: props.width }}
							value={props.value}
							min={props.min}
							max={props.max}
						/>
						<label htmlFor={props.name} className='input-label'>
							{props.label}
						</label>
					</div>
					{props.error && <div className='input-error'>{props.error}</div>}
				</>
			)}
			{props.numberInput && (
				<>
					<div className='input-group'>
						<input
							type={props.type}
							className={props.error ? 'input' : 'input-number'}
							required={props.required}
							placeholder={''}
							autoFocus={props.autoFocus || false}
							autoComplete={props.autoComplete}
							name={props.name}
							id={props.name}
							min={props.min || 0}
							onChange={(e) => {
								props.onChange((e.currentTarget || e.target).value);
							}}
							style={{ minWidth: props.width }}
							value={props.value || ''}
							defaultValue={props.defaultValue}
						/>
						<label htmlFor={props.name} className='input-label'>
							{props.label}
						</label>
						{props.currency && <div className='currency-tag-input'>£</div>}
					</div>
					{props.error && <div className='input-error'>{props.error}</div>}
				</>
			)}
		</div>
	);
};

export default Input;
