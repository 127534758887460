import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	BorderButton,
	Input,
	JourneyNavLine,
	ProgressBar,
} from 'components/Widgets';
import { Bold, H4 } from 'typography';
import { readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';

const HouseholdExpenses = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const FProgress = Number(readFromLocalStorage('_FExpensesPageProgress')) || 0;
	const [progress, setProgress] = useState(FProgress);
	const history = useHistory();
	const [, setIsComplete] = useState(false);
	const [textInfo, setTextInfo] = useState(
		user?.expenses || {
			heatingBill: 0,
			electricityBill: 0,
			waterBill: 0,
			phoneBill: 0,
			tvBill: 0,
			tvLicense: 0,
			councilTax: 0,
			groundRent: 0,
			serviceCharge: 0,
		}
	);

	useEffect(() => {
		if (
			textInfo['heatingBill'] !== 0 &&
			textInfo['electricityBill'] !== 0 &&
			textInfo['waterBill'] !== 0 &&
			textInfo['phoneBill'] !== 0 &&
			textInfo['tvBill'] !== 0 &&
			textInfo['tvLicense'] !== 0 &&
			textInfo['councilTax'] !== 0 &&
			textInfo['groundRent'] !== 0 &&
			textInfo['serviceCharge'] !== 0
		) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [textInfo]);

	const onSubmit = (e: any) => {
		e?.preventDefault();
		setProgress(Math.floor(progress + 33.33));
		storeInLocalStorage('_FExpensesPageProgress', Math.floor(progress + 33.33));

		if (!user?.expenses){
			user.expenses = {}
		}

		user.expenses = {
			...user.expenses,
			...textInfo
		}

		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user,
		});

		history.push(`/user/expenses/general-expenses`);
	};

	return (
		<div className="personal-container">
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className="personal-box">
				<div className="personal-details-header">
					<div className="personal-main-title">
						<H4>Household Expenses</H4>
					</div>
					<Bold isBold={false} color="#959DA5">
						We’ve given you national averages to help you. You can change them
						to reflect your monthly household expenses
					</Bold>
				</div>
				<div className="personal-details-body">
					<form onSubmit={onSubmit}>
						<Input
							value={textInfo.heatingBill}
							numberInput
							currency
							label="Gas/Heating"
							placeholder="Gas/Heating"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									heatingBill: value,
								}))
							}
						/>
						<Input
							value={textInfo.electricityBill}
							numberInput
							currency
							label="Electricity"
							placeholder="Electricity"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									electricityBill: value,
								}))
							}
						/>
						<Input label={'Water'} placeholder="Water" />
						<Input
							value={textInfo.phoneBill}
							numberInput
							currency
							label="Telephone/Broadband"
							placeholder="Telephone/Broadband"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									phoneBill: value,
								}))
							}
						/>
						<Input
							value={textInfo.tvBill}
							numberInput
							currency
							label="Digital/Cable TV"
							placeholder="Digital/Cable TV"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									tvBill: value,
								}))
							}
						/>
						<Input
							value={textInfo.tvLicense}
							numberInput
							currency
							label="TV Licence"
							placeholder="TV Licence"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									tvLicense: value,
								}))
							}
						/>
						<Input
							value={textInfo.councilTax}
							numberInput
							currency
							label="Council Tax"
							placeholder="Council Tax"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									councilTax: value,
								}))
							}
						/>
						<Input
							value={textInfo.groundRent}
							numberInput
							currency
							label="Ground Rent"
							placeholder="Ground Rent"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									groundRent: value,
								}))
							}
						/>
						<Input
							value={textInfo.serviceCharge}
							numberInput
							currency
							label="Service Charge"
							placeholder="Service Charge"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									serviceCharge: value,
								}))
							}
						/>

						<div>
							<div className="personal-details-btn ">
								<BorderButton
									value="Continue"
									width="368px"
									height="56px"
									backgroundColor="#0968E3"
									color="#fff"
									onClick={onSubmit}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default HouseholdExpenses;
