import { ReactNode } from 'react';
import { Button } from 'antd';
import 'css/AccordionInfoBox.css';
import { SubTitle } from 'typography';
import carretdownicon from 'assets/carret-down.svg';

interface IAccordionInfoBox {
	[x: string]: ReactNode;
	icon: string;
	title: string;
	status: boolean;
	name: string;
	isOpen: boolean;
	handleToggle: any;
}

/**
 * This is where all components for the Accordion Info Box is assembled
 * @param icon - Must be a blue colored icon to be shown on the component
 * @param name - The name to bind the accordion components
 * @param title - The title to be shown
 * @param status - Must be a BOOLEAN value the component will apply the required design based on the value provided
 * @param children - This is the JSX to be shown in the content area, check the example
 * @param isOpen the key value pair reading for the provided name (see example)
 * @param handleToggle the function to perform the toggle 
 * @example <AccordionInfoBox icon={personblueicon} title={'Personal'} status={true}>
              <AccordionContentItem question={'What is your name?'} response={'Crosby'} />
            </AccordionInfoBox>
 * @returns Assembled Components of AccordionInfoBox
 */
const AccordionInfoBox = (props: IAccordionInfoBox) => {
	return (
		<aside className="accordion-info-box">
			<header
				onClick={() => props.handleToggle(props.name)}
				className={'accordion-info-box-header'}
			>
				<div className="left">
					<img
						className={!props.status ? 'gray-out' : ''}
						src={props.icon}
						alt={props.title}
					/>
					<SubTitle>{props.title}</SubTitle>
				</div>

				<div className="right">
					{props.status && <Button type="primary" className='complete-btn'>Completed</Button>}
					{!props.status && (
						<Button className="in-progress" type="primary">
							In progress
						</Button>
					)}

					<img
						className={props.isOpen ? 'rotate-180-deg' : ''}
						src={carretdownicon}
						alt="View content"
					/>
				</div>
			</header>
			<section
				data-name={props.name}
				className={
					!props.isOpen
						? 'accordion-info-box-content'
						: 'accordion-info-box-content open-accordion'
				}
				// className={'accordion-info-box-content'}
			>
				{props.children}
			</section>
		</aside>
	);
};

export default AccordionInfoBox;
