const specialNumbers = [
	'zeroth',
	'first',
	'second',
	'third',
	'fourth',
	'fifth',
	'sixth',
	'seventh',
	'eighth',
	'ninth',
	'tenth',
	'eleventh',
	'twelfth',
	'thirteenth',
	'fourteenth',
	'fifteenth',
	'sixteenth',
	'seventeenth',
	'eighteenth',
	'nineteenth',
];
const decades = [
	'twent',
	'thirt',
	'fort',
	'fift',
	'sixt',
	'sevent',
	'eight',
	'ninet',
];

export const getWordFromNumber = (number) => {
	if (number < 20) return specialNumbers[number];
	if (number % 10 === 0) return decades[Math.floor(number / 10) - 2] + 'ieth';

	return (
		decades[Math.floor(number / 10) - 2] + 'y-' + specialNumbers[number % 10]
	);
};
