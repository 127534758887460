import { H6, SmallSubTitle } from 'typography';
import 'css/Settings.css';
import { Input } from 'components/Widgets';
import { useState } from 'react';
import ModalCustom from 'components/Widgets/ModalCustom';
import { changeUserEmail, getTokenPayloadDecoded, verifyEmailAttributeChange } from '../../utils/AuthFunctions';

interface Props {
	isModalVisibleEmail: boolean;
	setIsModalVisibleEmail: (value: boolean) => void;
}

const EmailInputModal = (props: Props) => {
	const [newEmail, setNewEmail] = useState<string>('');
	const [code, setCode] = useState<string>('');
	const [displayCode, toggleDisplay] = useState(false);
	const [isSuccess, setSuccess] = useState(false);

	const handleEmailChange = async () => {
		if (isSuccess){
			props.setIsModalVisibleEmail(false)
			return
		}

		if (displayCode) {
			const verificationResult = await verifyEmailAttributeChange(code);
			if (verificationResult) {
				setSuccess(true);
			}
		} else {
			const result = await changeUserEmail(newEmail);
			if (result) {
				toggleDisplay(true);
			}
		}
	};

	return (
		<ModalCustom
			visible={props.isModalVisibleEmail}
			okText={isSuccess ? 'Close' : displayCode ? 'Verify' : 'Change'}
			closable={false}
			onClose={() => props.setIsModalVisibleEmail(false)}
			onConfirm={handleEmailChange}
			okButtonProps={{
				size: 'small',
			}}
			cancelButtonProps={{
				hidden: true
			}}
			isWhite
			isMobile
			showDoneButton
			isCloseIcon
		>
			<form>
				<div className='settings-modal-header '>
					{isSuccess ?
						<div className='settings-modal-title'>
							<H6>You have successfully changed your email address!</H6>
						</div>
						:
						<div>
							<div className='settings-modal-title'>
								<H6>Change primary email</H6>
								<div className='line' />
							</div>

							<div className='settings-modal-title-mobile'>
								<SmallSubTitle>What’s your primary email?</SmallSubTitle>
							</div>
							<div className='settings-input-container'>
								<Input
									normalInput
									label='Current Email Address'
									type='email'
									defaultValue={getTokenPayloadDecoded().email}
									readOnly={true}
									placeholder='email'
									required
								/>
								<Input
									normalInput
									label='New Email Address'
									type='email'
									defaultValue={newEmail}
									onChange={(e) => {
										setNewEmail(e.target.value);
									}}
									required
								/>
								{displayCode &&
									<Input
										normalInput
										label='Check your new address for verification code'
										type='text'
										defaultValue={code}
										onChange={(e) => {
											setCode(e.target.value);
										}}
										required
									/>
								}
							</div>
						</div>
					}
				</div>
			</form>
		</ModalCustom>
	);
};

export default EmailInputModal;
