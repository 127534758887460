import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  PersonalAddressHistory,
  PersonalDetails,
  PersonalDetails2,
  PersonalhasDependant,
  PersonalPhoneNumber,
  SecondApplicantDetails2,
  SecondApplicantDetalis,
  SecondApplicantAddressHistory,
  IsSecondApplicantHasAddressHistory,
  PersonalDependentFillData,
} from '.';
import AnotherAddress2AppForm from './AnotherAddress2AppForm';
import AnotherAddressForm from './AnotherAddressForm';
import PersonalDependent from './PersonalDependant';
const Personal = () => {
  const { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/personal-details`} exact>
          <PersonalDetails />
        </Route>

        <Route path={`${path}/personal-details-2`} exact>
          <PersonalDetails2  isPartner={false}/>
        </Route>

        <Route path={`${path}/personal-details-second-applicant`} exact>
          <SecondApplicantDetalis />
        </Route>

        <Route path={`${path}/personal-details-second-applicant-2`} exact>
          <SecondApplicantDetails2 />
        </Route>

				<Route path={`${path}/personal-phone-number-second-applicant`} exact>
					<PersonalPhoneNumber isPartner={true}/>
				</Route>

        <Route path={`${path}/personal-phone-number`} exact>
          <PersonalPhoneNumber isPartner={false} />
        </Route>

        <Route path={`${path}/personal-has-dependent`} exact>
          <PersonalhasDependant />
        </Route>

        <Route path={`${path}/personal-dependent-data`} exact>
          <PersonalDependent />
        </Route>

        <Route path={`${path}/personal-dependent-fill-data`} exact>
          <PersonalDependentFillData />
        </Route>

        <Route path={`${path}/personal-address-history`} exact>
          <PersonalAddressHistory />
        </Route>

        <Route
          path={`${path}/personal-address-history/add-another-address`}
          exact
        >
          <AnotherAddressForm />
        </Route>

        <Route
          path={`${path}/personal-second-applicant-has-addresshistory`}
          exact
        >
          <IsSecondApplicantHasAddressHistory />
        </Route>

        <Route path={`${path}/personal-secondapp-addresshistory`} exact>
          <SecondApplicantAddressHistory />
        </Route>
        <Route
          path={`${path}/personal-secondapp-addresshistory/add-another-address`}
          exact
        >
          <AnotherAddress2AppForm />
        </Route>

        <Route path={`${path}/`}>
          <PersonalDetails2 isPartner={false} />
        </Route>
      </Switch>
    </div>
  );
};

export default Personal;
