import { FC } from 'react';
import { Typography } from '../types/typography';

const H6: FC<Typography> = ({ children, size, isBold, opacity }) => {
  return (
    <h6
      style={{
        fontWeight: isBold === false ? 'normal' : 'bold',
        opacity: opacity ? opacity : 1,
      }}
      className="H6"
    >
      {children}
    </h6>
  );
};

export default H6;
