import { BorderButton, CleanInput, Input, JourneyNavLine, ProgressBar, SelectInput } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { BasisOfEmployment } from 'utils/FinancialData';

const CurrentEmploymentDetails = () => {
	const history = useHistory();
	const [{ user }, dispatch] = useDataLayerValue();
	const { financial } = user || {};

	const [employmentType, setEmploymentType] = useState(
		financial?.employmentBasis,
	);
	const [isComplete, setIsComplete] = useState(false);
	const [textInfo, setTextInfo] = useState({
		employerName: financial?.employerName,
		jobTitle: financial?.jobTitle,
		employmentStartDate: financial?.employmentStartDate,
		plannedRetirement: financial?.plannedRetirement,
	});

	useEffect(() => {
		if (
			// textInfo['employerName'] !== undefined &&
			textInfo['jobTitle'] !== undefined &&
			textInfo['employmentStartDate'] !== undefined //&&
			// textInfo['plannedRetirement'] !== undefined
		) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [textInfo]);

	const onSubmit = (e) => {
		e?.preventDefault?.()

		const currFinancial = {
			...financial,
			employmentBasis: employmentType,
			...textInfo,
		};

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial: currFinancial },
		});

		history.push('/user/financial/current-employment/extras');
	};

	return (
		<div className='financial-pages'>
			<ProgressBar progress={10} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>Current employment details</H4>
				<form onSubmit={onSubmit}>
					<div className='central-form-group'>
						<SelectInput
							label='Basis of the employment'
							name={'employment-details'}
							options={BasisOfEmployment}
							defaultValue={employmentType || null}
							onSelect={(value: string) => setEmploymentType(value)}
						/>

						{user.userStatus === 'Journey completed' ?
							<CleanInput
								value={textInfo.employerName || ''}
								label='Name of the employer'
								type='text'
								placeholder='text'
								onChange={(e: any) =>
									setTextInfo((prev) => ({ ...prev, employerName: e.target.value }))
								}
							/> : <></>}

						<CleanInput
							value={textInfo['jobTitle'] || ''}
							label='Job title'
							type='text'
							placeholder='text'
							required
							onChange={(e: any) =>
								setTextInfo((prev) => ({ ...prev, jobTitle: e.target.value }))
							}
						/>
						<Input
							value={textInfo['employmentStartDate'] || ''}
							normalInput
							label='Employment start date'
							name='employmentStartDate'
							type='date'
							placeholder='text'
							width='460px'
							onChange={(e: any) =>
								setTextInfo((prev) => ({
									...prev,
									employmentStartDate: e.target.value,
								}))
							}
							required
						/>
						{user.userStatus === 'Journey completed' ?
							<CleanInput
								value={textInfo['plannedRetirement'] || ''}
								label='At what age do you plan to retire?'
								type='text'
								placeholder='text'
								onChange={(e: any) =>
									setTextInfo((prev) => ({
										...prev,
										plannedRetirement: e.target.value,
									}))
								}
							/> : <></>}

						<div className={`continue-btn ${!isComplete ? 'isDisabled' : ''}`}>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								color='#fff'
								type='submit'
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default CurrentEmploymentDetails;
