export enum actionTypes {
	/** stores user details */
	SET_USER,

	/** stores user progress */
	SET_PROGRESS,

	/** stores user's uploaded documents */
	SET_DOCS,

	/**  stores the selected language */
	SET_LANG,

	/**  stores the signup data */
	SIGNUP_DATA,

	/** stores the data in password forgotten flow */
	FORGOT_PASSWORD,

	/**  stores success and error messages */
	ALERT,

	/**  stores the user's profile 1 */
	PERSONAL1_DATA,

	/**  stores the user's profile 2 */
	PERSONAL2_DATA,

	/**  store offers response data */
	OFFERS_DATA,

	/**  store correlation id */
	SET_CORRELATION_ID,

	/**  stores correlation id */
	SET_OFFER_PRODUCT_ID,

	/** stores desktop offers modal display state as boolean */
	SHOW_OFFERS_MODAL,

	/** stores desktop offers table display state as boolean
	 *  to show preloader when user clicks
	 * Update button on OffersTable Page */
	SHOW_OFFERS_TABLE,

	/** stores offers array after api returns value */
	SET_OFFERS_ARRAY,

	/** stores the details of the offer */
	SET_OFFER_DETAILS,

	/** stores the details of the employed user */
	SET_FINANCIAL_EMPLOYED,

	/** stores the details of the employed user */
	SET_FINANCIAL_SELF_EMPLOYED,

	/** stores the details of the employed user */
	SET_FINANCIAL_2ND_EMPLOYED,

	/** stores the details of the employed user */
	SET_FINANCIAL_2ND_SELF_EMPLOYED,

	/** stores the details of liablitiies user */
	SET_LIABILITIES_DATA,

	/** stores the details of expenses */
	SET_EXPENSES_DATA,

	/** stores the details of property */
	SET_PROPERTY_DATA,

	/** stores the details of personal page Data */
	SET_PERSONAL_PAGE_DATA,

	/** stores the details of personal page Data app 2*/
	SET_PERSONAL_PAGE_DATA_2,

	/** stores the info of partner existence */
	SET_SECOND_APP_EXISTS,
};
