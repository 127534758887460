import React from 'react';
import HeaderText from './HeaderText';
import DescriptionText from './DescriptionText';
import TrustPilotLogo from 'assets/trust-pilot.svg';
import TrustPilotStarsLogo from 'assets/trust-pilot-stars.svg';

const Broker = () => {
	return (
		<div className='journey-bg broker-bg'>
			<div className='hassle-mortgage'>
				<HeaderText>
					<div className='grey'>get in touch</div>
					<div className='grey'>
						with <span className='red'>broker</span>
					</div>
					<div className='grey'>rightway</div>
				</HeaderText>
				<DescriptionText>
					<p className='grey'>
						Brokers play a key role in getting people the right mortgage. But, finding the right broker could be a
						hassle. Once your eligibility is decided, we allow you to get in touch with a broker right away to get all
						mortgage options that suit your needs and choose the best one. 
					</p>
				</DescriptionText>
			</div>
			<div className='fca'>
				<img src={TrustPilotLogo} alt='trust pilot' className='trust-pilot-logo' />
				<div className='flex-between'>
					<div>
						<div className='flex-between margin-bottom-1'>
							<div className='flex-middle'>
								<div className='fca-text white'>5.0</div>
								<div className='white uppercase'>Trust score</div>
							</div>
							<div className='flex-middle'>
								<div className='fca-text white'>287</div>
								<div className='white uppercase'>Reviews</div>
							</div>
						</div>
						<img className='pilot-stars' src={TrustPilotStarsLogo} alt='trust pilot stars' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Broker;
