import {
	BorderButton,
	EnhancedPostCodeInput,
	Input,
	JourneyNavLine,
	ProgressBar,
	SelectInput,
} from 'components/Widgets';
import { H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { countryList } from 'utils/DropDownList';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { Checkbox } from 'antd';

const Liabilities7 = () => {
	const history = useHistory();
	const [{ user }, dispatch] = useDataLayerValue();
	const [currentMortgageCountry, setCurrentMortgageCountry] = useState(user?.liabilities?.currentMortgageCountry);
	const [propertyPostCode, setPropertyPostCode] = useState(user?.liabilities?.propertyPostCode);
	const [propertyStreet, setPropertyStreet] = useState(user?.liabilities?.propertyStreet);
	const [propertyCity, setPropertyCity] = useState(user?.liabilities?.propertyCity);
	const [propertyAddress, setPropertyAddress] = useState(user?.liabilities?.propertyAddress);
	const [propertyAddress2, setPropertyAddress2] = useState(user?.liabilities?.propertyAddress2);
	const [homeNumber, setHomeNumber] = useState(user?.liabilities?.propertyHomeNumber);
	const [isComplete, setComplete] = useState(false);

	useEffect(() => {
		setComplete(!!currentMortgageCountry && !!propertyPostCode && !!propertyCity && !!propertyAddress);
	}, [currentMortgageCountry, propertyPostCode, propertyStreet, propertyCity, propertyAddress]);

	const onSubmit = (e) => {
		e?.preventDefault();

		const currUser = { ...user };

		if (!currUser?.liabilities) {
			currUser.liabilities = {};
		}

		currUser.liabilities.currentMortgageCountry = currentMortgageCountry;
		currUser.liabilities.propertyPostCode = propertyPostCode;
		currUser.liabilities.propertyStreet = propertyStreet;
		currUser.liabilities.propertyCity = propertyCity;
		currUser.liabilities.propertyAddress = propertyAddress;
		currUser.liabilities.propertyAddress2 = propertyAddress2;
		currUser.liabilities.propertyHomeNumber = homeNumber;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push('/user/liabilities/liabilities-8');
	};

	const defaultChecked = () => {
		return (
			user?.personal?.presentCountry === user?.liabilities?.currentMortgageCountry &&
			user?.personal?.presentPostCode === user?.liabilities?.propertyPostCode &&
			user?.personal?.presentAddress === user?.liabilities?.propertyAddress &&
			user?.personal?.presentAddress2 === user?.liabilities?.propertyAddress2 &&
			user?.personal?.presentCity === user?.liabilities?.propertyCity &&
			user?.personal?.presentHomeNumber === user?.liabilities?.propertyHomeNumber
		);
	};

	const copyAddress = (checked) => {
		if (checked) {
			setCurrentMortgageCountry(user.personal.presentCountry);
			setPropertyPostCode(user.personal.presentPostCode);
			setPropertyStreet(user.personal.presentAddress);
			setPropertyCity(user.personal.presentCity);
			setPropertyAddress(user.personal.presentAddress);
			setPropertyAddress2(user.personal.presentAddress2);
			setHomeNumber(user.personal.presentHomeNumber);
		} else {
			setCurrentMortgageCountry('');
			setPropertyPostCode('');
			setPropertyStreet('');
			setPropertyCity('');
			setPropertyAddress('');
			setPropertyAddress2('');
			setHomeNumber('');
		}
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={49} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>What is the address of your current mortgage?</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={onSubmit}>
						<div className='input-wrapper'>
							<div className='input-group'>
								<Checkbox defaultChecked={defaultChecked()} onChange={(e) => copyAddress(e.target.checked)}>
									Same as main applicant address
								</Checkbox>
							</div>
						</div>
						<Input
							value={homeNumber || ''}
							normalInput
							label='Home number'
							name='homeNumber'
							type='text'
							placeholder='text'
							onChange={(e) => setHomeNumber(e.target.value)}
							required
						/>

						<EnhancedPostCodeInput
							value={propertyPostCode}
							onChange={(e) => setPropertyPostCode(e.target.value)}
							onAddressUpdate={(e) => {
								setPropertyCity(e.city);
								setPropertyStreet(e.address);
								setPropertyPostCode(e.postCode);
								setCurrentMortgageCountry(e.country);
							}}
						/>

						<SelectInput
							label={'Country'}
							options={countryList}
							onSelect={(value: string) => setCurrentMortgageCountry(value)}
							defaultValue={currentMortgageCountry || ''}
							value={currentMortgageCountry || ''}
						/>
						<Input
							value={propertyStreet || ''}
							normalInput
							label={'Street'}
							placeholder='Street'
							onChange={(e) => setPropertyStreet(e.target.value)}
						/>
						<Input
							value={propertyAddress || ''}
							placeholder='Address line 1'
							normalInput
							label={'Address line 1'}
							onChange={(e) => setPropertyAddress(e.target.value)}
						/>
						<Input
							value={propertyAddress2 || ''}
							normalInput
							label={'Address line 2 (Optional)'}
							placeholder='Address line 2 (Optional)'
							onChange={(e) => setPropertyAddress2(e.target.value)}
						/>
						<Input
							value={propertyCity || ''}
							placeholder='City'
							normalInput
							label={'City'}
							onChange={(e) => setPropertyCity(e.target.value)}
						/>
						<div className='personal-details-btn '>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								disabled={!isComplete}
								onClick={onSubmit}
								color='#fff'
								marginBottom='15%'
								type='submit'
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Liabilities7;
