import { Application } from 'components/Application';
import { Documents } from 'components/Documents';
import { NotificationMobilePage } from 'components/Notification';
import { Settings } from 'components/WorkArea';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import CalendarPage from '../components/Calendar/CalendarPage';

const WorkSpaceRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/applications`}>
        <Application />
      </Route>

      <Route path={`${path}/documents`} exact>
        <Documents />
      </Route>

      <Route path={`${path}/notification`} exact>
        <NotificationMobilePage />
      </Route>

      <Route path={`${path}/settings`} exact>
        <Settings />
      </Route>

      <Route exact path={`${path}/calendar`}>
        <CalendarPage />
      </Route>

      <Route>
        <Redirect to={`${path}/applications`} />
      </Route>
    </Switch>
  );
};

export default WorkSpaceRoutes;
