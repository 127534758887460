import { useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';

const CreateProfileMortgageDueToEnd = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [mortgageDueToEnd, setMortgageDueToEnd] = useState(
		user?.application?.mortgageDueToEnd || ''
	);

	// end of states that control form data
	const handleSaveAndContinue = () => {
		const currUser = { ...user };
		currUser.cases[0].application.mortgageDueToEnd = mortgageDueToEnd;

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		history.push('/user/create-profile-erc-to-pay');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={0} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='When is it due to end?'
					continueBtn
					onClick={handleSaveAndContinue}
					buttonDisabled={!mortgageDueToEnd}
				>
					<Input
						value={mortgageDueToEnd}
						autoFocus={true}
						required
						placeholder='When is it due to end?'
						label='When is it due to end?'
						dateField
						onChange={(e) => setMortgageDueToEnd(e.target.value)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileMortgageDueToEnd;
