import { JourneyNavLine, ProgressBar } from 'components/Widgets';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { readFromLocalStorage } from 'utils/AuthFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
import PersonalDependentForm from './PersonalDependentForm';
import { actionTypes } from 'context-api/actionTypes';

const PersonalDependent = () => {
	const history = useHistory();
	const { location } = history || {};
	const { search } = location || {};
	const [, currQueryIndex] = search.split('=');
	const FProgress = Number(readFromLocalStorage('_FPersonalPageProgress')) || 0;
	const [progress] = useState(FProgress);
	const [{ user }, dispatch] = useDataLayerValue();
	const [dependentIndex, setDependentIndex] = useState(
		currQueryIndex ? Number(currQueryIndex) : 0
	);

	useEffect(() => {
		setDependentIndex(currQueryIndex ? Number(currQueryIndex) : 0);
	}, [currQueryIndex]);

	const handleContinueClick = (currentDependentData) => {
		const currUser = { ...user };

		if (!currUser?.personal?.dependants) {
			currUser.personal.dependants = [];
		}
		currUser.personal.dependants[dependentIndex] = currentDependentData;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		if (dependentIndex + 1 < user.personal.dependentNumber) {
			setDependentIndex(dependentIndex + 1);
			history.push(
				`/user/personal/personal-dependent-fill-data?currDependantQuery=${
					dependentIndex + 1
				}`
			);
		} else {
			history.push('/user/personal/personal-address-history');
		}
	};

	return (
		<div className="personal-container">
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className="personal-box">
				<PersonalDependentForm
					dependentIndex={dependentIndex}
					handleContinueClick={handleContinueClick}
				/>
			</div>
		</div>
	);
};

export default PersonalDependent;
