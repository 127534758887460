import { useDataLayerValue } from 'context-api/DataLayer';
import 'css/Documents.css';
import { useState } from 'react';
import { Bold, H4, SubTitle } from 'typography';
import { DocType, DocUploadInfoBox, ModalDocUpload, MyDocsPage, UploadedModal } from '.';

const Documents = () => {
	const [{ docs }] = useDataLayerValue();
	const [isFirstPage, setIsFirstPage] = useState(true);
	const [openUploadedModal, setOpenUploadedModal] = useState(false);

	const [showModalUpload, setShowModalUpload] = useState(false);
	const [docType, setDocType] = useState<DocType>();

	return (
		<>
			<div className='application-apply'>
				{/* header */}
				<header className=' apply-header'>
					<div className='flex apply-back'>
						<H4>Documents</H4>
					</div>
				</header>

				{/* tab nav */}
				<nav className='flex app-tab-nav'>
					<div
						onClick={() => setIsFirstPage(true)}
						className={
							isFirstPage ? 'app-tab-item tab-nav-active' : 'app-tab-item'
						}
					>
						<SubTitle opacity={isFirstPage ? 1 : 0.5}>Upload Document</SubTitle>
					</div>
					<div
						onClick={() => setIsFirstPage(false)}
						className={
							isFirstPage ? 'app-tab-item' : 'app-tab-item tab-nav-active'
						}
					>
						<SubTitle opacity={isFirstPage ? 0.5 : 1}>My Documents</SubTitle>
					</div>
				</nav>

				{/* contents */}
				{isFirstPage && docs ? (
					<section className='documents-grid'>
						<div className='isMobileDoc'>
							<Bold isBold={false} opacity={0.5}>
								Please upload the following documents
							</Bold>
						</div>
						{/* other1_1 */}
						<DocUploadInfoBox
							title='Your ID'
							summary='Your driving licence/passport or national identity card'
							isUploaded={Object.hasOwn(docs, DocType.MAIN_ID)}
							funcClick={() => {
								setShowModalUpload(true);
								setDocType(DocType.MAIN_ID);
							}}
							viewUploaded={() => setIsFirstPage(false)}
						/>

						{/* payslips1_1 */}
						<DocUploadInfoBox
							title='Proof of Income'
							tooltip="Last 3 month's payslip or SA302 for 2 years"
							summary='Payslips or accounts'
							isUploaded={Object.hasOwn(docs, DocType.MAIN_PAYSLIPS)}
							funcClick={() => {
								setShowModalUpload(true);
								setDocType(DocType.MAIN_PAYSLIPS);
							}}
							viewUploaded={() => setIsFirstPage(false)}
						/>

						{/* bankStatement1_1 */}
						<DocUploadInfoBox
							title='Bank Statements'
							summary='Last 3 month’s bank statements'
							isUploaded={Object.hasOwn(docs, DocType.MAIN_BANK_STATEMENTS)}
							funcClick={() => {
								setShowModalUpload(true);
								setDocType(DocType.MAIN_BANK_STATEMENTS);
							}}
							viewUploaded={() => setIsFirstPage(false)}
						/>

						{/* depositStatement1_1 */}
						<DocUploadInfoBox
							title='Proof of Deposit'
							summary='3 month’s bank statement that shows the deposit'
							isUploaded={Object.hasOwn(docs, DocType.MAIN_DEPOSIT)}
							funcClick={() => {
								setShowModalUpload(true);
								setDocType(DocType.MAIN_DEPOSIT);
							}}
							viewUploaded={() => setIsFirstPage(false)}
						/>

						{/* other1_2 */}
						<DocUploadInfoBox
							title='Proof of Address'
							summary='Utility bill'
							isUploaded={Object.hasOwn(docs, DocType.MAIN_ADDRESS)}
							funcClick={() => {
								setShowModalUpload(true);
								setDocType(DocType.MAIN_DEPOSIT);
							}}
							viewUploaded={() => setIsFirstPage(false)}
						/>
					</section>
				) : (
					<MyDocsPage />
				)}

				{/* MODALS */}
				<ModalDocUpload
					isUpload
					docType={docType}
					closeModal={() => setShowModalUpload(false)}
					visible={showModalUpload}
				/>

				<UploadedModal
					isUploaded
					visible={openUploadedModal}
					closeModal={() => setOpenUploadedModal(false)}
				/>
			</div>
		</>
	);
};

export default Documents;
