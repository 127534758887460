import { BorderButton, CleanInput, Input, JourneyNavLine, ProgressBar, SelectInput } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { SelfEmploymentStatus } from 'utils/FinancialData';

const SelfEmployed = () => {
	const history = useHistory();
	const [{ user }, dispatch] = useDataLayerValue();
	const { financial } = user || {};

	const [isComplete, setIsComplete] = useState(false);
	const [selectVal, setSelectVal] = useState(financial?.employmentBasis);
	const [textInfo, setTextInfo] = useState({
		soleEmployPercent: financial?.soleEmployPercent || '',
		selfEmployLastYearEndDate: financial?.selfEmployLastYearEndDate || '',
		plannedRetirement: financial?.plannedRetirement || '',
	});

	useEffect(() => {
		if (
			selectVal !== '' &&
			textInfo['soleEmployPercent'] !== 0 &&
			textInfo['selfEmployLastYearEndDate'] !== 0 &&
			textInfo['plannedRetirement'] !== '' &&
			textInfo.soleEmployPercent <= 100
		) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [selectVal, textInfo]);

	const onSubmit = async (e: any) => {
		e?.preventDefault();
		const currFinancial = {
			...financial,
			...textInfo,
			employmentBasis: selectVal,
		};

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial: currFinancial },
		});

		history.push('/user/financial/self-employed/extras');
	};

	return (
		<div className='financial-pages'>
			<ProgressBar progress={10} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>Current employment details</H4>

				<form className='central-form-group' onSubmit={onSubmit}>
					<SelectInput
						name='employment-details'
						label='Basis of employment'
						options={SelfEmploymentStatus}
						defaultValue={selectVal || null}
						onSelect={(value: string) => setSelectVal(value)}
					/>

					<Input
						value={textInfo['soleEmployPercent'] || ''}
						label='% owned (where relevant)'
						type='number'
						numberInput
						placeholder='% owned (where relevant)'
						required
						onChange={(e: any) =>
							setTextInfo((prev) => ({
								...prev,
								soleEmployPercent: e,
							}))
						}
					/>

					<CleanInput
						value={textInfo['selfEmployLastYearEndDate']}
						label='Last financial year end date'
						type='text'
						placeholder='text'
						required
						onChange={(e: any) =>
							setTextInfo((prev) => ({
								...prev,
								selfEmployLastYearEndDate: e.target.value,
							}))
						}
					/>

					<CleanInput
						value={textInfo['plannedRetirement']}
						label='At what age do you plan to retire?'
						type='text'
						placeholder='text'
						required
						onChange={(e: any) =>
							setTextInfo((prev) => ({
								...prev,
								plannedRetirement: e.target.value,
							}))
						}
					/>

					<div className={`continue-btn ${!isComplete ? 'isDisabled' : ''}`}>
						<BorderButton
							value='Continue'
							width='368px'
							height='56px'
							type='submit'
							backgroundColor='#0968E3'
							color='#fff'
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SelfEmployed;
