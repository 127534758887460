import {
  CleanRadioInput,
  JourneyNavLine,
  ProgressBar,
} from 'components/Widgets';
import { Bold, H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import { useState } from 'react';

const PersonalhasDependant = () => {
  const FProgress = Number(readFromLocalStorage("_FPersonalPageProgress")) || 0;
  const [progress, setProgress] = useState(FProgress);
  const dependentAnswer = JSON.parse(
    readFromLocalStorage("_personalhasDependantAnswer")
  );
  const history = useHistory();
  const delay = 300;
  const noAnswer = (e: any) => {
    setProgress(Math.floor(progress + 8.3));
    storeInLocalStorage("_FPersonalPageProgress", Math.floor(progress + 8.3));
    setTimeout(() => {
      history.push("/user/personal/personal-address-history");
    }, delay);
    storeInLocalStorage("_personalhasDependantAnswer", e.target.value);
  };

  const yesAnswer = (e: any) => {
    setProgress(Math.floor(progress + 8.3));
    storeInLocalStorage("_FPersonalPageProgress", Math.floor(progress + 8.3));
    storeInLocalStorage("_personalhasDependantAnswer", e.target.value);
    setTimeout(() => {
      history.push("/user/personal/personal-dependent-data");
    }, delay);
    storeInLocalStorage("_personalhasDependantAnswer", e.target.value);
  };

  return (
    <div className="personal-container">
      <ProgressBar progress={progress} />
      <JourneyNavLine closeBtn />
      <div className="personal-box">
        <div className="personal-details-header">
          <div className="personal-main-title">
            <H4>Is there anyone who depends on you financially?</H4>
          </div>
          <Bold isBold={false} color="#959DA5">
            For example, children or adults in your care
          </Bold>
        </div>
        <div className="personal-details-body">
          <form className="central-form-group">
            <CleanRadioInput
              name={"Yes"}
              label={"Yes"}
              value={"yes"}
              onChange={yesAnswer}
              checked={dependentAnswer === "yes"}
            />

            <CleanRadioInput
              name={"No"}
              label={"No"}
              onChange={noAnswer}
              value={"no"}
              checked={dependentAnswer === "no"}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PersonalhasDependant;
