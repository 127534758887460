import {
	BorderButton,
	EnhancedPostCodeInput,
	Input,
	JourneyNavLine,
	ProgressBar,
	SelectInput,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { storeInLocalStorage } from 'utils/AuthFunctions';
import { countryList } from 'utils/DropDownList';

const PropertyAddressDetails = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const [progress, setProgress] = useState(5);
	const history = useHistory();
	const [textInfo, setTextInfo] = useState(user?.property ||
		{
			country: '',
			address: '',
			street: '',
			city: '',
			postCode: '',
			address2: '',
			homeNumber: '',
		},
	);

	const [isCompleted, setComplete] = useState(false);

	useEffect(() => {
		if (!textInfo?.country || !textInfo?.city || !textInfo?.address || !textInfo?.postCode) {
			setComplete(false);
		} else {
			setComplete(true);
		}
	}, [textInfo]);

	const onSubmit = (e: any) => {
		e?.preventDefault();
		setProgress(Math.floor(progress + 6.6));
		storeInLocalStorage('_FPropertyPageProgress', Math.floor(progress + 6.6));

		const currUser = { ...user };

		currUser.property = {
			...currUser.property,
			...textInfo,
		};

		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push(`/user/property/purchase-property-bedroom-count`);
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={12} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>What is the address of the property you want to purchase?</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form onSubmit={onSubmit}>
						<Input
							value={textInfo.homeNumber}
							normalInput
							label='Home number'
							name='homeNumber'
							type='text'
							placeholder='text'
							onChange={(e) => {
								setTextInfo((prev) => ({
										...prev,
										homeNumber: e.target.value,
									}),
								);
							}}
							required
						/>

						<EnhancedPostCodeInput
							value={textInfo.postCode}
							onChange={(e) =>
								setTextInfo((prev) => ({
									...prev,
									postCode: e.target.value,
								}))
							}
							onAddressUpdate={e => {
								e.city && (textInfo.employerCity = e.city);
								e.address && (textInfo.employerAddress = e.address);
							}}
						/>
						<SelectInput
							name='country'
							label='Country'
							options={countryList}
							defaultValue={textInfo?.country || null}
							showSearch
							onSelect={(value: any) =>
								setTextInfo((prev) => ({
									...prev,
									country: value,
								}))
							}
						/>
						<Input
							value={textInfo['address']}
							type='text'
							normalInput
							label={'Address line 1'}
							placeholder='Address line 1'
							onChange={(e) =>
								setTextInfo((prev) => ({
									...prev,
									address: e.target.value,
								}))
							}
						/>

						<Input
							value={textInfo['address2']}
							type='text'
							normalInput
							label={'Address line 2'}
							placeholder='Address line 2'
							onChange={(e) =>
								setTextInfo((prev) => ({
									...prev,
									address2: e.target.value,
								}))
							}
						/>

						<Input
							value={textInfo['city']}
							type='text'
							normalInput
							label={'City'}
							placeholder='city'
							onChange={(e) =>
								setTextInfo((prev) => ({
									...prev,
									city: e.target.value,
								}))
							}
						/>

						<div className='personal-details-btn '>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								disabled={!isCompleted}
								color='#fff'
								marginBottom='15%'
								onClick={onSubmit}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default PropertyAddressDetails;
