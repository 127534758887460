import { ProgressBar, StepContent } from 'components/Widgets';
import RadioInputBoolean from './RadioInputBoolean';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfileCurrentDealEnded = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [currentDealEnded, setCurrentDealEnded] = useState(
		user?.application?.currentDealEnded
	);

	const handleAnswer = (answer: boolean) => {
		setCurrentDealEnded(answer);
		const currUser = { ...user };
		currUser.cases[0].application.currentDealEnded = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			history.push(
				answer
					? '/user/create-profile-current-mortgage-value'
					: '/user/create-profile-mortgage-due-to-end'
			);
		}, 300);
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={0} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='Has your current deal ended or due to end in the next 6 months?'
					onClick={() => {}}
				>
					<RadioInputBoolean
						value='yes'
						isChecked={
							typeof currentDealEnded === 'boolean' && currentDealEnded
						}
						SelectionText='Yes'
						name='currentDealEnded'
						onClick={() => handleAnswer(true)}
					/>
					<RadioInputBoolean
						value='no'
						isChecked={
							typeof currentDealEnded === 'boolean' && !currentDealEnded
						}
						SelectionText='No'
						name='currentDealEnded'
						onClick={() => handleAnswer(false)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileCurrentDealEnded;
