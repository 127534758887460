import { Bold } from 'typography';
import clsx from 'clsx';
interface ButtonProps {
  boxShadow?: any;
  marginTop?: string;
  value: string;
  icon?: string;
  color?: string;
  backgroundColor?: string;
  borderRadius?: string;
  onClick?: (arg0: any) => any;
  width?: string;
  height?: string;
  className?: string;
  border?: string;
  isBold?: boolean;
  marginRight?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  marginBottom?: string;
}

const BorderButton = (props: ButtonProps) => {
  return (
    <button
      style={{
        color: props.color && props.color,
        borderColor: props.color && props.color,
        backgroundImage: `url(${props.icon})`,
        paddingLeft: props.icon && `40px`,
        backgroundColor: props.backgroundColor && props.backgroundColor,
        borderRadius: props.borderRadius && props.borderRadius,
        maxWidth: props.width && props.width,
        height: props.height && props.height,
        border: props.border && props.border,
        marginTop: props.marginTop && props.marginTop,
        marginRight: props.marginRight && props.marginRight,
        marginBottom: props.marginBottom && props.marginBottom,
        boxShadow:  props.boxShadow && props.boxShadow,
      }}
      className={clsx('border-btn', props.className , props.disabled && 'disabled')}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
    >
      <Bold isBold={props.isBold}>{props.value}</Bold>
    </button>
  );
};

export default BorderButton;
