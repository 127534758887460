import { BorderButton, Input, SelectInput } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import React, { useEffect, useState } from 'react';
import { H4 } from 'typography';
import { capitalizeFirstLetter } from 'utils';
import { relationship as relationshipOptions } from 'utils/DropDownList';
import { getWordFromNumber } from 'utils/figureToWord';

const initialDependentData = {
	relationship: '',
	firstName: '',
	lastName: '',
	gender: '',
	birthday: '',
	isFinanciallyDependent: '',
};

const PersonalDependentForm = ({ handleContinueClick, dependentIndex }) => {
	const [{ user }] = useDataLayerValue();
	const { personal } = user || {};
	const { dependants = [] } = personal || {};
	const [dependantData, setDependantData] = useState(
		dependants[dependentIndex] || initialDependentData
	);
	const [areFieldsValid, setAreFieldsValid] = useState(false);
	const {
		relationship,
		firstName,
		lastName,
		gender,
		birthday,
		isFinanciallyDependent,
	} = dependantData;

	useEffect(() => {
		setDependantData(dependants[dependentIndex] || initialDependentData);
		// eslint-disable-next-line
	}, [dependentIndex]);

	useEffect(() => {
		setAreFieldsValid(
			relationship &&
				firstName &&
				lastName &&
				gender &&
				birthday &&
				!!isFinanciallyDependent
		);
	}, [
		birthday,
		firstName,
		gender,
		isFinanciallyDependent,
		lastName,
		relationship,
	]);

	const handleSelectInputChange = (value, name) => {
		setDependantData({ ...dependantData, [name]: value });
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setDependantData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		handleContinueClick({ ...dependantData, dependentIndex });
	};

	return (
		<>
			<div className="personal-details-header">
				<div className="personal-main-title">
					<H4>
						{`${capitalizeFirstLetter(
							getWordFromNumber(dependentIndex + 1)
						)} dependant`}
					</H4>
				</div>
			</div>
			<div className="personal-details-body">
				<form className="central-form-group" onSubmit={handleSubmit}>
					<SelectInput
						name="relationship"
						options={relationshipOptions}
						value={dependantData?.relationship || null}
						label="Relationship"
						onSelect={(value) => handleSelectInputChange(value, 'relationship')}
					/>
					<Input
						name="firstName"
						normalInput
						label="First name"
						value={dependantData.firstName}
						placeholder="First name"
						onChange={handleInputChange}
					/>

					<Input
						name="lastName"
						value={dependantData.lastName}
						normalInput
						label={'Last name'}
						placeholder="''"
						onChange={handleInputChange}
					/>
					<div className="mt">
						<SelectInput
							name="gender"
							value={dependantData?.gender || null}
							options={[
								{ value: 'male', label: 'Male' },
								{ value: 'female', label: 'Female' },
							]}
							label="Gender"
							onSelect={(value) => handleSelectInputChange(value, 'gender')}
						/>
					</div>

					<Input
						name="birthday"
						label="Date of birth"
						value={dependantData.birthday}
						placeholder="Date of birth"
						dateField
						onChange={handleInputChange}
					/>

					<div className="mt">
						<SelectInput
							name="isFinanciallyDependent"
							value={dependantData?.isFinanciallyDependent || null}
							options={[
								{ value: 'true', label: 'Yes' },
								{ value: 'false', label: 'No' },
							]}
							label="Financially dependant?"
							onSelect={(value) =>
								handleSelectInputChange(value, 'isFinanciallyDependent')
							}
						/>
					</div>
					<div className="personal-details-btn">
						<BorderButton
							value="Continue"
							width="368px"
							height="56px"
							backgroundColor="#0968E3"
							color="#fff"
							marginBottom="15%"
							disabled={!areFieldsValid}
						/>
					</div>
				</form>
			</div>
		</>
	);
};

export default PersonalDependentForm;
