import { useHistory } from 'react-router-dom';
import { OfferSlab } from 'components/Widgets';
import { H4 } from 'typography';
import mobilebestoffericon from 'assets/mobile-best-offer.svg';
import Modal from 'react-modal';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { PATCHRequest } from 'utils/AuthFunctions';
import { sendReturnMessage } from 'utils/GeneralFunctions';

interface IModalOffersDetails {
  isOpenModal: boolean;
  lenderName: string;
  isBestOffer?: boolean;
}

/**
 * Returns the UI for mobile offers or apply modal
 * @param props
 * @returns component
 */
const ModalOffersDetails = (props: IModalOffersDetails) => {
  const history = useHistory();
  const [
    {
      offersData,
      correlationId,
      offerDetails,
      offerProductId,
      offersTableList,
      showOffersModal,
    },
    dispatch,
  ] = useDataLayerValue();
  const [isApplying, setIsApplying] = useState(false);
  // read token from local storage

  const handleClose = () => {
    dispatch({
      type: actionTypes.SHOW_OFFERS_MODAL,
      showOffersModal: false,
    });
    document.body.style.overflow = 'unset';
  };

  // hide document scroll bar
  useEffect(() => {
    if (showOffersModal) document.body.style.overflow = 'hidden';
  }, [offerDetails, showOffersModal]);

  const funcMakePatchCall = async () => {
    setIsApplying(!isApplying);
    const patchURL = `/users?attribute=selectedProduct`;

    const data = {
      productId: offerProductId,
      correlationId: correlationId,
      query: offersData,
    };

    const resp = await PATCHRequest(patchURL, data, history);
    if (resp.success) {
      sendReturnMessage('route_mortgage');
      setIsApplying(!isApplying);
      history.push('/user/stages');
    } else {
      setIsApplying(!isApplying);
      // @ts-ignore
      console.error('Error:', resp.error);
    }
  };

  return (
    <>
      <Modal
        isOpen={showOffersModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={handleClose}
        overlayClassName="offers-modal-overlay"
        className="offers-modal-content"
        contentLabel="Offers Modal"
        preventScroll
      >
        <div onClick={handleClose} className="btn-modal-close"></div>
        <div className="mobile-tile">
          {offerDetails?.data?.lenderName ? (
            <>
              <header className="mobile-tile-header">
                {offersTableList[0].productId === offerProductId && (
                  <>
                    <img
                      className="modal-best-offer"
                      src={mobilebestoffericon}
                      alt=""
                    />
                    <br />
                    <br />
                  </>
                )}

                <img
                  className="mobileLenderLogo"
                  src={`${process.env.PUBLIC_URL}/bank-logos/${offerDetails?.data?.imageCode}.png`}
                  alt=""
                />
                <H4>{offerDetails?.data?.lenderName}</H4>
              </header>

              <section className="mobile-title-content">
                <OfferSlab
                  label={'Initial monthly payment '}
                  value={`£${offerDetails?.data?.initialMonthlyPayment}`}
                  tooltip={
                    'Your monthly repayment during the initial period of the mortgage'
                  }
                />

                <OfferSlab
                  label={'Initial rate'}
                  value={`${offerDetails?.data?.initialRate}%`}
                  tooltip={
                    'The initial rate is the rate that is charged during the introductory rate of a mortgage'
                  }
                />

                <OfferSlab
                  label={'APR'}
                  value={`${offerDetails?.data?.apr}%`}
                  tooltip={
                    'The Annual Percentage Rate (APR) is the cost you pay each year to borrow money, including fees, expressed as a percentage.'
                  }
                />

                <OfferSlab
                  label={'Product type'}
                  value={`${offerDetails?.data?.productType}`}
                  tooltip={
                    'Product type refers to the type of interest used over the initial payment of your mortgage. You can learn more about different product types here.'
                  }
                />

                <OfferSlab
                  label={'Initial period length'}
                  value={`${Math.floor(
                    offerDetails?.data?.initialPeriodLengthInDays / 30
                  )} days`}
                  tooltip={'Length of the initial period'}
                />

                <OfferSlab
                  label={'Total fees'}
                  value={`£${offerDetails?.data?.totalFees.toLocaleString()}`}
                  tooltip={
                    'Lender fees such as valuation, admin and/or product fee'
                  }
                />

                <OfferSlab
                  label={'Follow up monthly payment'}
                  value={`£${offerDetails?.data?.followUpMonthlyPayment.toLocaleString()}`}
                  tooltip={'Monthly payment after the initial period has ended'}
                />

                <OfferSlab
                  label={'Total amount payable'}
                  value={`£${offerDetails?.data?.mortgageAmount.toLocaleString()}`}
                  tooltip={
                    'The total amount you will have to repay to the lender if you keep your deal for the full length'
                  }
                />
              </section>

              <div className="modal-learn-link">
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  // <a href="#" target="_blank">
                  //   Learn more about your mortgage
                  // </a>
                }
              </div>

              <div className="modal-button-holder">
                <button
                  onClick={funcMakePatchCall}
                  className="btn-modal-act-btn"
                >
                  {!isApplying && <span>Apply</span>}
                  {isApplying && <div className="btn-spinner"></div>}
                </button>
              </div>
            </>
          ) : (
            <>
              <div>Loading...</div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalOffersDetails;
