const TableHeader = () => {
  return (
    <tr>
      <th></th>
      <th>Lender</th>
      <th>Initial Rate</th>
      <th>APR</th>
      <th>
        Monthly <br />
        Repayment
      </th>
      <th>
        Total Amount <br /> Payable
      </th>
      <th></th>
    </tr>
  );
};

export default TableHeader;
