import React, { useEffect, useState } from 'react';
import Banner1 from 'assets/ads-1.png';
import Banner2 from 'assets/ads-2.png';
import Banner3 from 'assets/ads-3.png';
import Banner4 from 'assets/ads-4.png';
import Banner5 from 'assets/ads-5.png';
import 'css/AdsBanner.css';

const ads = [Banner1, Banner2, Banner3, Banner4, Banner5];

function AdsBanner({ top }: { top?: number }) {
	const [banner, setBanner] = useState(ads[0]);

	useEffect(() => {
		let i = 0;
		const interval = setInterval(() => {
			if (i === ads.length - 1) {
				i = 0;
			} else {
				i++;
			}
			setBanner(ads[i]);
		}, 2000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className={`ads-banner ${top === 0 ? 'top-zero' : ''}`}>
			<img src={banner} alt='banner' className='banner' />
		</div>
	);
}

export default AdsBanner;
