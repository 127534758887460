import { JourneyNavLine, Loader, ProgressBar } from 'components/Widgets';
import { Bold, H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import RadioBoolean from '../Widgets/RadioBoolean';
import { PATCHRequest } from '../../utils/AuthFunctions';

interface Props {
	progress: number;
	section: string;
	property: string;
	routeTo: string;
	routeIfFalse?: string;
	title: string;
	subtitle?: string;
	saveData?: boolean;
	showOnly?: boolean;
	deleteOnTrue?: Array<string>;
	deleteOnFalse?: Array<string>;
}

/**
 * General view for display and modification of boolean properties.
 * @param props
 */
const BooleanView = (props: Props) => {
	const [{ user }, dispatch] = useDataLayerValue();
	const [value, setValue] = useState(
		user?.[props.section]?.[props.property] || '',
	);
	const [isSaving, setIsSaving] = useState(false);
	const history = useHistory();
	const delay = 300;

	const timeOutRef = useRef(null);
	const historyRef = useRef(null);

	useEffect(() => {
		setValue(user?.[props.section]?.[props.property]);
		// eslint-disable-next-line
	}, [props.title]);

	useEffect(() => {
		return () => clearTimeout(timeOutRef.current);
	}, []);

	const onAnswer = async (answer: boolean) => {
		historyRef.current = true;
		setValue(answer);

		const currUser = { ...user };
		if (!props.showOnly) {
			if (!currUser[props.section]) {
				currUser[props.section] = {};
			}
			currUser[props.section][props.property] = answer;

			if (answer) {
				props.deleteOnTrue?.forEach(prop => delete currUser[props.section][prop]);
			} else {
				props.deleteOnFalse?.forEach(prop => delete currUser[props.section][prop]);
			}

			dispatch({
				type: actionTypes.SET_USER,
				user: currUser,
			});
		}

		if (props.saveData) {
			setIsSaving(true);
			await PATCHRequest(`/users?attribute=${props.section}`,
				currUser[props.section],
				history,
			);
		}

		timeOutRef.current = setTimeout(() => {
			// to prevent history from pushing twice
			if (!historyRef.current) return;

			if (!answer && props.routeIfFalse) {
				history.push(props.routeIfFalse);
			} else history.push(props.routeTo);
			setIsSaving(false);
			historyRef.current = false;
		}, delay);
	};

	if (isSaving) return <Loader text='Saving...' />;

	return (
		<div className='personal-container'>
			<ProgressBar progress={props.progress} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>{props.title}</H4>
						{props?.subtitle && <Bold isBold={false}>{props.subtitle}</Bold>}
					</div>
				</div>
				<RadioBoolean
					positive={{ label: 'Yes', value: true }}
					negative={{ label: 'No', value: false }}
					value={value}
					onClick={onAnswer}
				/>
			</div>
		</div>
	);
};

export default BooleanView;
