import { NavLink, useRouteMatch } from 'react-router-dom';
// @ts-ignore
import Bold from 'typography/Bold';

interface IMenuItem {
  text: string;
  to: string;
  icons?: {
    normal: string;
    selected?: string;
  };
}

const MenuItem = (props: IMenuItem) => {
  return (
    <NavLink
      to={props.to}
      // exact
      className="menu-item"
      activeClassName="menu-item-selected"
    >
      <div className="menu-logo">
        {useRouteMatch(props.to) ? (
          <img src={props.icons?.selected} alt="" />
        ) : (
          <img src={props.icons?.normal} alt="" />
        )}
      </div>
      <div className="menu-text">
        <Bold>{props.text}</Bold>
      </div>
    </NavLink>
  );
};

export default MenuItem;
