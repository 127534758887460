import {
	BorderButton,
	JourneyNavLine,
	ProgressBar,
	SelectInput,
} from 'components/Widgets';
import { useEffect, useState } from 'react';
import { H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { countryList, nationalityList } from 'utils/DropDownList';

const SecondApplicantDetails2 = () => {
	const FProgress = Number(readFromLocalStorage('_FPersonalPageProgress')) || 0;
	const [progress, setProgress] = useState(FProgress);
	const [{ user }, dispatch] = useDataLayerValue();
	const { personal2 } = user || {};
	const { application } = user?.cases?.[0] || {};
	
	const history = useHistory();

	const [gender, setGender] = useState(personal2?.gender);
	const [residenceCountry, setResidenceCountry] = useState(
		personal2?.residenceCountry
	);
	const [nationality, setNationality] = useState(personal2?.nationality);

	const [isComplete, setComplete] = useState(
		gender && nationality && residenceCountry
	);

	useEffect(() => {
		setComplete(!!gender && !!nationality && !!residenceCountry);
	}, [gender, nationality, residenceCountry]);

	const onSubmit = async (e: any) => {
		e?.preventDefault();
		setProgress(Math.floor(progress + 8.3));
		storeInLocalStorage('_FPersonalPageProgress', Math.floor(progress + 8.3));

		const currPersonal = { ...personal2 };
		currPersonal.gender = gender;
		currPersonal.residenceCountry = residenceCountry;
		currPersonal.nationality = nationality;

		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, personal2: currPersonal },
		});
		history.push('/user/personal/personal-phone-number-second-applicant');
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>
							{application?.partner?.firstName ||
								personal2?.firstName ||
								application?.partner?.email}
							's personal details
						</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form onSubmit={onSubmit}>
						<SelectInput
							showSearch
							defaultValue={residenceCountry || null}
							name={'residenceCountry'}
							options={countryList}
							label='Country of residence'
							onSelect={setResidenceCountry}
						/>

						<div className='mt'>
							<SelectInput
								showSearch
								name={'title'}
								options={nationalityList}
								label='Nationality'
								defaultValue={nationality || null}
								onSelect={setNationality}
							/>
						</div>

						<div className='mt'>
							<SelectInput
								name={'gender'}
								options={[
									{ value: 'male', label: 'Male' },
									{ value: 'female', label: 'Female' },
								]}
								label='What’s your gender'
								defaultValue={gender || null}
								onSelect={setGender}
							/>
						</div>

						<div className={isComplete ? '' : 'isDisabled'}>
							<div className='personal-details-btn '>
								<BorderButton
									value='Continue'
									disabled={!isComplete}
									width='368px'
									height='56px'
									backgroundColor='#0968E3'
									color='#fff'
									marginBottom='15%'
									onClick={() => 'props.onClick'}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SecondApplicantDetails2;
