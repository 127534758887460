export const getRequiredFieldErrors = (fields = []) => {
	const errors = { present: false };
	fields.forEach((field) => {
		if (!field.value) {
			errors.present = true;
			errors[field.name] = `${camelToSentenceCase(field.name)} is required`;
		}
	});

	return errors;
};

const camelToSentenceCase = (camelCase) => {
	if (camelCase) {
		const result = camelCase.replace(/([A-Z])/g, ' $1');
		return result[0].toUpperCase() + result.substring(1).toLowerCase();
	}
	return '';
};
