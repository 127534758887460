import { useEffect, useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';

const CreateProfilePropertyValue = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 33.2 : 50);
	const [propertyValue, setPropertyValue] = useState(
		user?.cases?.[0]?.application?.propertyValue || 0
	);
	const [isComplete, setComplete] = useState<boolean>(false);

	useEffect(() => {
		setComplete(!!propertyValue && propertyValue > 0);
	}, [propertyValue]);

	// end of states that control form data
	const onSubmit = () => {
		user.cases[0].application.propertyValue = propertyValue;

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		history.push('/user/create-profile-deposit');
	};

	return (
		<div className="create-profile-container" style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title="How much will your property cost?"
					continueBtn
					onClick={onSubmit}
					buttonDisabled={!isComplete}
				>
					<Input
						value={propertyValue}
						required
						autoFocus={true}
						currency
						type="number"
						numberInput
						name="propertyValue"
						onChange={(e) => setPropertyValue(e)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfilePropertyValue;
