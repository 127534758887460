import { OfferSlab, LightBlueButton } from 'components/Widgets';
// import BorderButton from 'components/Widgets/BorderButton';
import { useHistory } from 'react-router-dom';
import { H4, H6, SubTitle } from 'typography';
// import AppInfoBox from './AppInfoBox';
// import personblueicon from 'assets/person-blue.svg';
// import bankblueicon from 'assets/bank-blue.svg';
// import propertyicon from 'assets/property.svg';
// import doceidticon from 'assets/document-edit.svg';
import carretlefticon from 'assets/carret-left.svg';
import { useDataLayerValue } from 'context-api/DataLayer';

interface IApplication {
  funcSetRoute?: any;
}

const DesktopApplicationDetails = (props: IApplication) => {
  const history = useHistory();
  const [{ user }] = useDataLayerValue();
  const offer = user?.productCandidate?.mortgageCalculatedFields;

  return (
    <div className="desktop-application-details application-apply">
      <header className=" apply-header">
        {/* <div onClick={() => history.goBack()} className="flex apply-back"> */}
        <div onClick={() => history.goBack()} className="flex apply-back">
          <img src={carretlefticon} alt="" />
          <H4>Applications</H4>
        </div>

        <H6 isBold={false} opacity={0.5}>
          Full Mortgage Application
        </H6>
      </header>

      <section className="app-paper apply-lender-paper">
        <header className="apply-paper-header">
          <div className="apply-lender-icon">
            <img
              src={`${process.env.PUBLIC_URL}/bank-logos/${user?.productCandidate?.productMortgageType?.displayImageID}.png`}
              alt=""
            />
          </div>
          <div className="apply-lender-name">
            <SubTitle>
              {user?.productCandidate?.productMortgageType?.providerName}
            </SubTitle>
          </div>
        </header>
        <div className="application-details-grid">
          <OfferSlab
            label={'Initial rate'}
            value={`${offer?.initialInterestRate}%`}
            tooltip={
              'The initial rate is the rate that is charged during the introductory rate of a mortgage'
            }
          />

          <OfferSlab
            label={'APR'}
            value={`${offer?.apr}%`}
            tooltip={
              'The Annual Percentage Rate (APR) is the cost you pay each year to borrow money, including fees, expressed as a percentage.'
            }
          />

          <OfferSlab
            label={'Follow up rate'}
            value={`${offer?.followOnInterestRate}%`}
            tooltip={'The rate charged after the initial period'}
          />

          <OfferSlab
            label={'Initial monthly payment '}
            value={`£${offer?.initialMonthlyPayment?.toLocaleString()}`}
            tooltip={
              'Your monthly repayment during the initial period of the mortgage'
            }
          />

          <OfferSlab
            label={'Initial period rate'}
            value={`${Math.floor(
              offer?.initialInterestPeriodInDays / 30
            )} months`}
            tooltip={'Length of the initial period'}
          />

          <OfferSlab
            label={'Total fees'}
            value={`£${offer?.totalFees?.toLocaleString()}`}
            tooltip={'Lender fees such as valuation, admin and/or product fee'}
          />

          <OfferSlab
            label={'Follow up monthly payments'}
            value={`£${offer?.followOnMonthlyPayment?.toLocaleString()}`}
            tooltip={'Monthly payment after the initial period has ended'}
          />

          <OfferSlab
            label={'Total amount payable'}
            value={`£${offer?.totalToPay?.toLocaleString()}`}
            tooltip={
              'The total amount you will have to repay to the lender if you keep your deal for the full length'
            }
          />
        </div>

        <div className="flex apply-btn-list">
          <LightBlueButton value="Mortgage Illustration" />
          <LightBlueButton value="Suitability Report" />
          <LightBlueButton value="Compare Mortgages" />
        </div>
      </section>
      {/* 
      <H6 isBold={false} opacity={0.5}>
        Complete your application
      </H6>
      <br />
      <div className="apply-infobox-holder">
        <AppInfoBox title="Personal" icon={personblueicon} isComplete={true} />
        <AppInfoBox title="Financial" icon={bankblueicon} isComplete={true} />
        <AppInfoBox
          title="Property"
          icon={propertyicon}
          component={<BorderButton value="Complete" />}
        />
        <AppInfoBox
          title="Submit your application"
          icon={doceidticon}
          opacity={0.5}
          component={<BorderButton color="var(--gray-90)" value="Submit" />}
        />
      </div> */}
    </div>
  );
};

export default DesktopApplicationDetails;
