import { ProgressBar, StepContent } from 'components/Widgets';
import RadioInputBoolean from './RadioInputBoolean';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfileMortgagePaymentType = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 80 : 94);

	const [mortgagePaymentType, setMortgagePaymentType] = useState(
		user?.cases?.[0].application?.mortgagePaymentType,
	);

	const saveToContextAndContinue = (answer) => {
		setMortgagePaymentType(answer);
		const currUser = { ...user };
		currUser.cases[0].application.mortgagePaymentType = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			history.push('/user/create-profile-partner');
		}, 300);
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='What mortgage payment type do you want?'
					onClick={() => {
					}}
				>
					<RadioInputBoolean
						value='repayment'
						isChecked={mortgagePaymentType === 'repayment'}
						SelectionText='Repayment'
						name='mortgagePaymentType'
						onClick={() => saveToContextAndContinue('repayment')}
					/>
					<RadioInputBoolean
						value='interestOnly'
						isChecked={mortgagePaymentType === 'interestOnly'}
						SelectionText='Interest only'
						name='mortgagePaymentType'
						onClick={() => saveToContextAndContinue('interestOnly')}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileMortgagePaymentType;
