export const FinancialStatusArray = [
  {
    label: 'Employed',
    value: 'Employed',
  },
  {
    label: 'Self employed',
    value: 'Self employed',
  },
  {
    label: 'Retired',
    value: 'Retired',
  },
  {
    label: 'Not employed',
    value: 'Not employed',
  },
];

export const BasisOfEmployment = [
  {
    label: 'Permanent',
    value: 'Permanent',
  },
  {
    label: 'Temporary',
    value: 'Temporary',
  },
  {
    label: 'Contract',
    value: 'Contract',
  },
];

export const SelfEmploymentStatus = [
  {
    label: 'Sole trader',
    value: 'Sole trader',
  },
  {
    label: 'Partnership/LLP',
    value: 'Partnership/LLP',
  },
  {
    label: 'Ltd. company',
    value: 'Ltd. company',
  },
];

export const FinancialSource = [
  {
    label: 'Weekly',
    value: 'Weekly',
  },
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Annually',
    value: 'Annually',
  },
];
