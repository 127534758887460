import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
	JourneyNavLine,
	BorderButton,
	ProgressBar,
	Input,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { H4 } from 'typography';

const SelfProfileData = () => {
	const history = useHistory();

	const [{ user }, dispatch] = useDataLayerValue();
	const { financial } = user || {};
	const { application } = user?.cases?.[0] || {};

	const [isComplete, setIsComplete] = useState(false);
	const [textInfo, setTextInfo] = useState({
		selfEmployNetProfitLastYear: financial?.selfEmployNetProfitLastYear,
		selfEmployNetProfitPreviousYear: financial?.selfEmployNetProfitPreviousYear,
	});

	useEffect(() => {
		if (
			textInfo['selfEmployNetProfitLastYear'] !== 0 &&
			textInfo['selfEmployNetProfitPreviousYear'] !== ''
		) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [textInfo]);

	const funcSetSelfEmployedInfo = async (e: any) => {
		e?.preventDefault();
		const currFinancial = { ...financial };
		for (var key in textInfo) {
			currFinancial[key] = textInfo[key];
		}

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial: currFinancial },
		});

		if (application?.partner) {
			history.push('/user/financial/second-applicant-experian');
		} else {
			history.push('/user/financial/seven');
		}
	};

	return (
		<div className='financial-pages'>
			<ProgressBar progress={20} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>Profit details</H4>

				<form
					className='central-form-group'
					onSubmit={(e) => funcSetSelfEmployedInfo(e)}
				>
					<Input
						value={textInfo['selfEmployNetProfitLastYear'] || ''}
						label='Net profit last year (Turnover minus costs)'
						type='number'
						numberInput
						autoFocus={true}
						currency
						placeholder='text'
						required
						onChange={(value: any) =>
							setTextInfo((prev) => ({
								...prev,
								selfEmployNetProfitLastYear: value,
							}))
						}
					/>

					<Input
						value={textInfo['selfEmployNetProfitPreviousYear'] || ''}
						label='Net profit previous year'
						type='number'
						numberInput
						currency
						placeholder='text'
						required
						onChange={(value: any) =>
							setTextInfo((prev) => ({
								...prev,
								selfEmployNetProfitPreviousYear: value,
							}))
						}
					/>

					<div className={`continue-btn ${!isComplete ? 'isDisabled' : ''}`}>
						<BorderButton
							value='Continue'
							width='368px'
							height='56px'
							backgroundColor='#0968E3'
							color='#fff'
							onClick={() => 'props.onClick'}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SelfProfileData;
