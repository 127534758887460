import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
	EmploymentDetails,
	CurrentEmploymentDetails,
	SelfEmployed,
	CurrentEmplomentPage2,
	SelfProfileData,
	Applicant2EmpDetails,
	Applicant2CurrentEmp1,
	Applicant2CurrentEmp2,
	Applicant2SelfEmp1,
	Applicant2SelfEmp2,
	Financial7,
	Applicant2Financial7,
	Applicant2Financial8,
	Financial8,
} from '.';
import 'css/Financial.css';

const Financial = () => {
	const { path } = useRouteMatch();

	return (
		<div>
			<Switch>
				<Route path={`${path}/employment-details`} exact>
					<EmploymentDetails />
				</Route>

				<Route path={`${path}/current-employment`} exact>
					<CurrentEmploymentDetails />
				</Route>

				<Route path={`${path}/current-employment/extras`} exact>
					<CurrentEmplomentPage2 />
				</Route>

				<Route path={`${path}/self-employed`} exact>
					<SelfEmployed />
				</Route>

				<Route path={`${path}/self-employed/extras`} exact>
					<SelfProfileData />
				</Route>

				<Route path={`${path}/seven`} exact>
					<Financial7 />
				</Route>

				<Route path={`${path}/eight`} exact>
					<Financial8 />
				</Route>

				{/* === 2ND APPLICANT ROUTES === */}
				<Route path={`${path}/second-applicant/employment-details`} exact>
					<Applicant2EmpDetails />
				</Route>

				<Route path={`${path}/second-applicant/current-employment`} exact>
					<Applicant2CurrentEmp1 />
				</Route>

				<Route
					path={`${path}/second-applicant/current-employment/extras`}
					exact
				>
					<Applicant2CurrentEmp2 />
				</Route>

				<Route path={`${path}/second-applicant/self-employed`} exact>
					<Applicant2SelfEmp1 />
				</Route>

				<Route path={`${path}/second-applicant/self-employed/extras`} exact>
					<Applicant2SelfEmp2 />
				</Route>

				<Route path={`${path}/second-applicant/seven`} exact>
					<Applicant2Financial7 />
				</Route>

				<Route path={`${path}/second-applicant/eight`} exact>
					<Applicant2Financial8 />
				</Route>

				<Route path={`${path}/second-applicant-experian`} exact>
					<Applicant2EmpDetails />
				</Route>

				<Route path={`${path}/`}>
					<EmploymentDetails />
				</Route>
			</Switch>
		</div>
	);
};

export default Financial;
