import { ProgressBar, StepContent } from 'components/Widgets';
import RadioInputBoolean from './RadioInputBoolean';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfilePreviousMortgagePaymentType = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 75 : 92);

	const [previousMortgagePaymentType, setPreviousMortgagePaymentType] = useState(
		user?.cases?.[0]?.application?.previousMortgagePaymentType,
	);

	const saveToContextAndContinue = (answer) => {
		setPreviousMortgagePaymentType(answer);
		const currUser = { ...user };
		currUser.cases[0].application.previousMortgagePaymentType = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			history.push('/user/create-profile-mortgage-payment-type');
		}, 300);
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='What is your current mortgage payment type?'
					onClick={() => {
					}}
				>
					<RadioInputBoolean
						value='repayment'
						isChecked={previousMortgagePaymentType === 'repayment'}
						SelectionText='Repayment'
						name='previousMortgagePaymentType'
						onClick={() => saveToContextAndContinue('repayment')}
					/>
					<RadioInputBoolean
						value='interestOnly'
						isChecked={previousMortgagePaymentType === 'interestOnly'}
						SelectionText='Interest only'
						name='previousMortgagePaymentType'
						onClick={() => saveToContextAndContinue('interestOnly')}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfilePreviousMortgagePaymentType;
