import ModalCustom from 'components/Widgets/ModalCustom';
import BorderButton from 'components/Widgets/BorderButton';
import LightBlueButton from 'components/Widgets/LightBlueButton';
import docbulleticon from 'assets/document-bullet-list.svg';
import uploadIcon from 'assets/upload_cloud_icon.svg';
import addIcon from 'assets/add-blue.svg';
import { Caption, Bold, SmallBold } from 'typography';
import clsx from 'clsx';
import 'css/UploadedModal.css';
import { randId } from 'utils/GeneralFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
interface IUploadedModalProps {
  visible?: boolean;
  closeModal?: () => any;
  isUploaded?: boolean;
  isUpload?: boolean;
}

const UploadedModal = (props: IUploadedModalProps) => {
  const generatedId = `btn-${randId()}`;
  const [{ docs }] = useDataLayerValue();

  const handleDocUpload = async (e) => {
    const url = docs.bankStatement1_1;
    const file = e.target.files[0];
    const size = parseFloat(Math.abs(file.size / 1024 / 1024).toFixed(3));
    if (size < 10) {
      // upload
      await fetch(url, { method: 'PUT', body: file })
        .then((e) => {
          window.parent.postMessage({ isSuccess: true }, '*');
        })
        .catch((e) => {
          console.error(e);
          window.parent.postMessage({ isSuccess: false }, '*');
        });
    }
    console.log('Files: ', file);
    console.log('URL: ', docs.bankStatement1_1);
    console.log('Size: ', size);
  };

  return (
    <div className={clsx('modal-container', props.isUpload && 'isUpload')}>
      <ModalCustom
        onClose={props.closeModal}
        uploadModalheight={true}
        footer={null}
        visible={props.visible}
      >
        <div className="modal-content">
          {props.isUploaded && (
            <div className="modal-header">
              <div className="modal-btn">
                <BorderButton
                  color="#FFFFFF"
                  borderRadius="40px"
                  backgroundColor="#191C1F"
                  value="Uploaded"
                />
              </div>
            </div>
          )}
          {props.isUpload && (
            <div
              className={clsx(
                'modal-header',
                props.isUpload && 'is-upload-modal-header'
              )}
            >
              <div className="modal-btn">
                <BorderButton
                  color="#FFFFFF"
                  borderRadius="40px"
                  backgroundColor="#191C1F"
                  value="New Upload"
                />
              </div>
            </div>
          )}

          {props.isUploaded && (
            <>
              <div className="modal-title-mobile">
                <Bold color="#959DA5"> Uploaded Files </Bold>
              </div>
              <div className="modal-body">
                <div className="modal-body-content">
                  <div className="doc-name-wrapper">
                    <div className="doc-icon">
                      <img src={docbulleticon} alt="icon" />
                    </div>
                    <div className="doc-name-info">
                      <Bold isBold={false}>ID-Front.jpg</Bold>
                      <Caption isBold={false}>2m ago</Caption>
                    </div>
                  </div>
                  <div className="doc-size">
                    <button disabled>
                      <Caption isBold={true}>1.5 MB</Caption>
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="modal-body-content">
                  <div className="doc-name-wrapper">
                    <div className="doc-icon">
                      <img src={docbulleticon} alt="icon" />
                    </div>
                    <div className="doc-name-info">
                      <Bold isBold={false}>ID-Back.jpg</Bold>
                      <Caption isBold={false}>2m ago</Caption>
                    </div>
                  </div>
                  <div className="doc-size">
                    <button disabled>
                      <Caption isBold={true}>1.5 MB</Caption>
                    </button>
                  </div>
                </div>
              </div>

              <div className="modal-body">
                <div className="modal-body-content">
                  <div className="doc-name-wrapper">
                    <div className="doc-icon">
                      <img src={docbulleticon} alt="icon" />
                    </div>
                    <div className="doc-name-info">
                      <Bold isBold={false}>Passport-Front.jpg</Bold>
                      <Caption isBold={false}>2m ago</Caption>
                    </div>
                  </div>
                  <div className="doc-size is-error">
                    <button disabled>
                      <Caption isBold={true}>Error</Caption>
                    </button>
                  </div>
                </div>
              </div>

              <div className="button-group">
                <LightBlueButton
                  backgroundColor="#0968E3"
                  color="#FFFFFF"
                  onClick={props.closeModal}
                  value="Done"
                />
                <LightBlueButton
                  marginLeft={'8px'}
                  icon={addIcon}
                  width="174px"
                  value="Add More Files"
                />
              </div>
            </>
          )}
          {props.isUpload && (
            <>
              <div className="modal-upload-section">
                <div className="modal-upload-section-content">
                  <div className="upload-info-text-mobile">
                    <Bold isBold={false}>Your driving licence/passport or</Bold>
                    <Bold isBold={false}>national identity card</Bold>
                  </div>

                  <div className="upload-icon-section">
                    <img src={uploadIcon} alt="upload-icon" />
                    <Caption isBold={false}>
                      JPG, PNG or PDF, smaller than 10MB
                    </Caption>
                  </div>
                  <div className="upload-button-section">
                    <SmallBold isBold={true} marginBottom="8px" color="#717A85">
                      Drag and drop your image here or
                    </SmallBold>

                    <label htmlFor={generatedId} className="btn-doc-upload">
                      <span>Choose a file</span>
                      <input
                        onChange={handleDocUpload}
                        type="file"
                        name="upload"
                        id={generatedId}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ModalCustom>
    </div>
  );
};

export default UploadedModal;
