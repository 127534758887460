import { useHistory } from 'react-router-dom';
import AdsBanner from './AdsBanner';

interface IStepContentProps {
	onClick?: () => void;
	children?: React.ReactNode;
	closeBtn?: boolean;
	closeOnclick?: () => void;
	backBtnClick?: () => void;
}

const JourneyNavLine = (props: IStepContentProps) => {
	const history = useHistory();
	const handleGoBackClick = () => {
		props.backBtnClick ? props.backBtnClick() : history.goBack();
	};

	return (
		<div>
			<div className='step-content-header'>
				<AdsBanner top={0} />
				<div className='journey-navline'>
					<button type='button' onClick={handleGoBackClick} className='previous-btn' />
					{props.closeBtn && (
						<button
							type='button'
							onClick={!props.closeOnclick ? () => history.push('/user/stages') : () => props.closeOnclick()}
							className='close-btn journey-close'
						>
							Back to stages
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default JourneyNavLine;
