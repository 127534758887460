import { useState } from 'react';
import { useDataLayerValue } from 'context-api/DataLayer';
import { H5 } from 'typography';
import { OffersParamsInputs } from '.';
import { useHistory } from 'react-router-dom';
import { POSTRequest, readFromLocalStorage } from 'utils/AuthFunctions';
import { actionTypes } from 'context-api/actionTypes';

const OffersTableTop = () => {
  const [{ user }, dispatch] = useDataLayerValue();
  const { REACT_APP_OFFERS_API_BASE } = process.env;
  const history = useHistory();
  const [disableBtn, setDisableBtn] = useState(false);

  const [propertyValue, setPropertyValue] = useState(
    user?.cases[0].application?.propertyValue
  );
  const [deposit, setDeposit] = useState(user?.application?.deposit);
  const [term, setTerm] = useState(user?.application?.mortgageTerms);

  // PARAMS UPDATER
  const funcUpdateQuery = (e: any) => {
    e?.preventDefault();
    // disbale update button
    setDisableBtn(true);

    // show table preloader
    dispatch({
      type: actionTypes.SHOW_OFFERS_TABLE,
      showOfferTable: false,
    });

    // update the offersData in the context api
    let xenAns: any = readFromLocalStorage('_offerAnswers');
    xenAns = JSON.parse(xenAns);

    xenAns = {
      ...xenAns,
      propertyValue: Number(propertyValue),
      deposit: Number(deposit),
      term: Number(term),
    };

    // MAKE POST CALL TO UPDATE TABLE
    POSTRequest(`${REACT_APP_OFFERS_API_BASE}`, xenAns, history).then(
      (offersFeedback) => {
        if (offersFeedback?.success) {
          // update the correlationId
          dispatch({
            type: actionTypes.SET_CORRELATION_ID,
            // @ts-ignore
            correlationId: offersFeedback?.data?.correlationId,
          });

          // update the offers table list
          dispatch({
            type: actionTypes.SET_OFFERS_ARRAY,
            // @ts-ignore
            offersTableList: offersFeedback?.data?.offers.slice(0, 10),
          });

          // hide table preloader
          dispatch({
            type: actionTypes.SHOW_OFFERS_TABLE,
            showOfferTable: true,
          });

          setDisableBtn(false);
        } else {
          setDisableBtn(false);
        }
      }
    );
  };

  return (
    <div className="offers-params">
      <H5>
        You can change property value, deposit and term before you select the
        mortgage
      </H5>

      <form
        onSubmit={(e: any) => funcUpdateQuery(e)}
        className="flex offers-params-form"
      >
        <OffersParamsInputs
          field="propertyValue"
          currency
          label="Property Value"
          defaultValue={propertyValue}
          onChangeFunc={setPropertyValue}
        />
        <OffersParamsInputs
          field={'deposit'}
          currency
          label="Deposit Amount"
          defaultValue={deposit}
          onChangeFunc={setDeposit}
        />
        <OffersParamsInputs
          field="term"
          label="Mortgage Term"
          defaultValue={term}
          onChangeFunc={setTerm}
        />
        <OffersParamsInputs
          className={disableBtn && 'isDisabled'}
          hasButton
          label={''}
          defaultValue={'Update'}
        />
      </form>
    </div>
  );
};

export default OffersTableTop;
