import { FC } from 'react';
import { Typography } from '../types/typography';

const Caption: FC<Typography> = ({ children, size, isBold, opacity , color }) => {
  return (
    <div
      style={{
        fontWeight: isBold === false ? 'normal' : 'bold',
        opacity: opacity ? opacity : 1,
        color: color ,
      }}
      className="Caption"
    >
      {children}
    </div>
  );
};

export default Caption;
