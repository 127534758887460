import { Bold } from 'typography';
import { randId } from 'utils/GeneralFunctions';

interface ICleanRadioInput {
	defaultValue?: string | number | readonly string[];
	value?: string | number | readonly string[];
	name: string;
	label: string;
	onChange?: (e: any) => void;
	width?: string;
	checked?: boolean;
	onClick?: () => void
}

const CleanRadioInput = (props: ICleanRadioInput) => {
	const generatedId = randId();
	const voidFunction = () => {};

	return (
		<div className='clean-radio-input' style={{ width: props.width && props.width }}>
			<input
				onChange={props.onChange || voidFunction}
				type='radio'
				name={props.name}
				id={`radio-${generatedId}`}
				value={props.value}
				defaultValue={props.defaultValue}
				onClick={(e) => {
					props.onChange?.(e)
					props.onClick?.()
				}}
				checked={props.checked}
			/>
			<label
				htmlFor={`radio-${generatedId}`}
				className={'lbl-clean-radio'}
			>
				<div className={'circle'} />
				<Bold isBold={false}>{props.label}</Bold>
			</label>
		</div>
	);
};

export default CleanRadioInput;
