import React from 'react'
import { SmallSubTitle } from "typography";
import LanguageSelection from 'components/LeftMenu/LanguageSelection';

const LanguageContent = () => {
    return (
        <div className="settings-modal-header ">
        <div className="settings-modal-title-mobile">
          <SmallSubTitle>Choose your primary language</SmallSubTitle>
        </div>
        <div className="settings-input-container" >
            <LanguageSelection isMobile />
        </div>
      </div>
    )
}

export default LanguageContent
