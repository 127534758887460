import { BorderButton, Input, JourneyNavLine, ProgressBar } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const BedroomCount = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [bedroomCount, setBedroomCount] = useState(user?.property?.bedroomCount || 0);

	const onSubmit = (e: any) => {
		e?.preventDefault();

		if (!user?.property) {
			user.property = {};
		}

		const currUser = { ...user };
		currUser.property.bedroomCount = bedroomCount;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push(`/user/property/purchase-property-years`);
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={18} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>How many bedroom does it have?</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form onSubmit={onSubmit}>
						<Input
							name={'bedroomCount'}
							value={bedroomCount}
							type='number'
							normalInput
							min={0}
							label={'Number of bedrooms'}
							placeholder='Number of bedrooms'
							onChange={e => {
								if (e.target.value < 0) return;
								setBedroomCount((e.target.value * 1).toString());
							}}
						/>
						<div className='personal-details-btn '>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								color='#fff'
								marginBottom='15%'
								onClick={onSubmit}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default BedroomCount;
