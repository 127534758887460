import docaddicon from 'assets/document-add.svg';
import checkwhiteicon from 'assets/check-white.svg';
import circlecheckicon from 'assets/cirlce-check-blue.svg';
import checkblueicon from 'assets/check-blue.svg';
import { SmallBold } from 'typography';
import BorderButton from 'components/Widgets/BorderButton';

interface IDocUploadCard {
  title: string;
  summary: string;
  tooltip?: string;
  isUploaded?: boolean;
  funcClick?: any;
  viewUploaded: () => void;
}

const DocUploadInfoBox = (props: IDocUploadCard) => {
  return (
    <aside className="app-paper docupload-box">
      <div className="docupload-icon">
        <img src={props.isUploaded ? circlecheckicon : docaddicon} alt="" />
      </div>

      <div className="flex docupload-infobx">
        <div className="docupload-title">{props.title}</div>
        <div className="docupload-summary">
          <SmallBold isBold={false}>{props.summary}</SmallBold>
        </div>
        {props.isUploaded && (
          <>
            <div
              onClick={props.viewUploaded}
              className="btn-uploaded doc-mobile-hide"
            >
              <span>View uploaded files</span>
              <img src={checkwhiteicon} alt="" />
            </div>
            <img
              onClick={props.viewUploaded}
              className="doc-mobile-checked"
              src={checkblueicon}
              alt=""
            />
          </>
        )}
        <div
          className="doc-btn-holder"
          onClick={() => props.funcClick && props.funcClick()}
        >
          <BorderButton value="Upload" />
        </div>
      </div>
    </aside>
  );
};

export default DocUploadInfoBox;
