import {
	BorderButton,
	CleanInput,
	EnhancedPostCodeInput,
	Input,
	JourneyNavLine,
	ProgressBar,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const Applicant2CurrentEmp2 = () => {
	const history = useHistory();
	const [{ user }, dispatch] = useDataLayerValue();
	const { financial2 } = user || {};

	const [isComplete, setIsComplete] = useState(false);
	const [textInfo, setTextInfo] = useState({
		annualSalary: financial2?.annualSalary || null,
		annualBonuses: financial2?.annualBonuses || null,
		additionalAnnual: financial2?.additionalAnnual || null,
		employerPostCode: financial2?.employerPostCode || '',
		employerAddress: financial2?.employerAddress || '',
		employerStreet: financial2?.employerStreet || '',
		employerCity: financial2?.employerCity || '',
		homeNumber: financial2?.homeNumber || '',
	});

	useEffect(() => {
		if (
			textInfo['annualSalary'] !== 0 //&&
			// textInfo['annualBonuses'] !== 0 &&
			// textInfo['employerPostCode'] !== '' &&
			// textInfo['employerAddress'] !== '' &&
			// textInfo['employer'] !== '' &&
			// textInfo['employerCity'] !== ''
		) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [textInfo]);

	const onSubmit = async (e: any) => {
		e?.preventDefault();

		const currFinancial = { ...financial2, ...textInfo };

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial2: currFinancial },
		});

		history.push('/user/financial/seven');
	};

	return (
		<div className='financial-pages'>
			<ProgressBar progress={50} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>Employment details</H4>

				<form className='central-form-group' onSubmit={onSubmit}>
					<Input
						value={textInfo['annualSalary'] || ''}
						label='Basic salary per annum'
						numberInput
						currency
						placeholder='text'
						required
						onChange={(value: any) =>
							setTextInfo((prev) => ({ ...prev, annualSalary: value }))
						}
					/>

					<Input
						value={textInfo['annualBonuses'] || ''}
						label='Guaranteed additional p.a. (bonus, London weighting, etc.)'
						numberInput
						currency
						min={0}
						placeholder='text'
						onChange={(value: any) =>
							setTextInfo((prev) => ({
								...prev,
								annualBonuses: value,
							}))
						}
					/>

					<Input
						value={textInfo['additionalAnnual'] || ''}
						label='Regular additional p.a. (overtime, etc.)'
						numberInput
						currency
						min={0}
						placeholder='text'
						onChange={(value: any) =>
							setTextInfo((prev) => ({
								...prev,
								additionalAnnual: value,
							}))
						}
					/>

					{user.userStatus === 'Journey completed' ?
						<>
							<Input
								value={textInfo.homeNumber}
								normalInput
								label='Home number'
								name='homeNumber'
								type='text'
								placeholder='text'
								onChange={(e) => {
									setTextInfo((prev) => ({
										...prev,
										postCode: e.target.value,
									}));
								}}
								required
							/>

							<EnhancedPostCodeInput
								value={textInfo.employerPostCode}
								label='Postcode'
								onChange={(e: any) =>
									setTextInfo((prev) => ({
										...prev,
										employerPostCode: e.target.value,
									}))
								}
								onAddressUpdate={e => {
									setTextInfo((prev) => ({
										...prev,
										employerCity: e.city,
										employerAddress: e.address,
										employerPostCode: e.postCode,
									}));
								}}
							/>

							<CleanInput
								value={textInfo['employerAddress']}
								label='Current employer address line 1'
								type='text'
								placeholder='text'
								required
								onChange={(e: any) =>
									setTextInfo((prev) => ({
										...prev,
										employerAddress: e.target.value,
									}))
								}
							/>

							<CleanInput
								value={textInfo['employerStreet']}
								label='Current employer address line 2 ( optional )'
								type='text'
								placeholder='text'
								// required
								onChange={(e: any) =>
									setTextInfo((prev) => ({
										...prev,
										employerStreet: e.target.value,
									}))
								}
							/>

							<CleanInput
								value={textInfo.employerCity}
								label='City'
								type='text'
								placeholder='text'
								required
								onChange={(e: any) =>
									setTextInfo((prev) => ({
										...prev,
										employerCity: e.target.value,
									}))
								}
							/>
						</> : <></>
					}

					<div className={`continue-btn ${!isComplete ? 'isDisabled' : ''}`}>
						<BorderButton
							value='Continue'
							width='368px'
							height='56px'
							backgroundColor='#0968E3'
							color='#fff'
							onClick={() => 'props.onClick'}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Applicant2CurrentEmp2;
