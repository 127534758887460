/* eslint-disable @typescript-eslint/no-unused-vars */
import 'css/NavBar.css';
import { useState } from 'react';
import { SmallBold, SubTitle } from '../../typography';
import carreticon from 'assets/carret-down.svg';
import { useDataLayerValue } from 'context-api/DataLayer';
import { AuthLogout } from 'utils/AxiosConfig';
import { useHistory } from 'react-router-dom';

const NavBar = () => {
  const history = useHistory();
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const [{ user }] = useDataLayerValue();
  const Initials = user
		? `${(user?.personal?.firstName || user?.cases[0]?.application?.firstName || ' ').substring(
				0,
				1
		  )}${(user?.personal?.lastName || user?.cases[0]?.application?.lastName || ' ').substring(
				0,
				1
		  )}`
		: '';

  const handleLogout = () => {
    setShowProfileMenu(!showProfileMenu);
		AuthLogout();
    history.push('/');
  };

  return (
    <>
      {user && (
        <header className="centroid">
          <header className="app-navbar">
            <div></div>

            <aside className="flex navbar-list">
              {/* notification */}
              {/* <div className="navbar-notify-holder">
                <div
                  onClick={() => setShowNotification(!showNotification)}
                  className="navbar-notification nabvar-notify-alert"
                ></div>
                {showNotification && <NotificationBag />}
              </div> */}

              {/* avatar */}
              <section className="flex navbar-user-profile">
                <div
                  onClick={() => setShowProfileMenu(!showProfileMenu)}
                  className="flex navbar-profile"
                >
                  <div className="navbar-avatar">
                    <SubTitle isBold={false}>{Initials}</SubTitle>
                    {/* <img
              src="https://media-exp1.licdn.com/dms/image/D4E35AQHxaO3w-swMhQ/profile-framedphoto-shrink_800_800/0/1628001397481?e=1628622000&v=beta&t=u6jH7ND4_65vWqnqjFqV4aFB1aF7LueZZ-4zzdY6zoo"
              alt=""
            /> */}
                  </div>
                  <div className="navbar-profile-name">
                    <SmallBold>{`${user?.personal?.firstName} ${user?.personal?.lastName}`}</SmallBold>
                  </div>
                  <div
                    className={
                      showProfileMenu
                        ? 'navbar-caret rotate-180'
                        : 'navbar-caret'
                    }
                  >
                    <img src={carreticon} alt="" />
                  </div>
                </div>
                {showProfileMenu && (
                  <div onClick={handleLogout} className="navbar-logout">
                    Log out
                  </div>
                )}
              </section>
            </aside>
          </header>
        </header>
      )}
    </>
  );
};

export default NavBar;
