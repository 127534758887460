import React from 'react';
import HeaderText from './HeaderText';
import DescriptionText from './DescriptionText';

const Eligibility = () => {
	return (
		<div className='journey-bg dark-blue-bg eligibility-bg'>
			<HeaderText>
				<div className='white'>Fill in the check boxes</div>
			</HeaderText>
			<div className='eligibility-text'>
				<HeaderText>
					<div className='white'>and get to</div>
					<div className='white'>
						the <span className='red'>eligibility</span>
					</div>
					<div className='white'>calculator</div>
				</HeaderText>
				<DescriptionText>
					<p className='white'>
						We have developed a questionnaire that will help us know your financial details better. Filling out the form
						would lead you to the eligibility calculator. Fill it out and get your score. 
					</p>
				</DescriptionText>
			</div>
		</div>
	);
};

export default Eligibility;
