import { BorderButton, Input, JourneyNavLine, ProgressBar } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const SharedOwnerShipDetails = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [isComplete, setComplete] = useState(false);

	const [percentToMortgage, setPercentToMortgage] = useState(user?.property?.percentToMortgage);
	const [sharedOwnerBodyName, setSharedOwnerBodyName] = useState(user?.property?.sharedOwnerBodyName);
	const [monthlyRentPayment, setMonthlyRentPayment] = useState(user?.property?.monthlyRentPayment);

	const onSubmit = (e: any) => {
		e?.preventDefault();

		const currUser = { ...user };

		if (!currUser?.property) {
			currUser.property = {};
		}

		currUser.property.percentToMortgage = percentToMortgage;
		currUser.property.sharedOwnerBodyName = sharedOwnerBodyName;
		currUser.property.monthlyRentPayment = monthlyRentPayment;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push('/user/property/purchase-property-type');
	};

	useEffect(() => {
		if (monthlyRentPayment >= 0 &&
			sharedOwnerBodyName !== '' &&
			sharedOwnerBodyName?.trim()?.length > 0 &&
			percentToMortgage > 0
		) {
			setComplete(true);
		} else {
			setComplete(false);
		}
	}, [percentToMortgage, sharedOwnerBodyName, monthlyRentPayment]);

	return (
		<div className='personal-container'>
			<ProgressBar progress={36} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Shared Ownership Details</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form onSubmit={onSubmit}>
						<Input
							type='text'
							normalInput
							value={sharedOwnerBodyName}
							label='Which shared ownership body are you buying from?'
							placeholder='Which shared ownership body are you buying from?'
							onChange={(e) => setSharedOwnerBodyName(e.target.value)}
						/>
						<Input
							type='number'
							numberInput
							value={percentToMortgage}
							label='What is the percentage of the property to be purchased?'
							placeholder='What is the percentage of the property to be purchased?'
							onChange={(e) => setPercentToMortgage(e.target.value)}
						/>

						<Input
							type='number'
							numberInput
							currency
							value={monthlyRentPayment}
							label='What is the monthly rent payment?'
							placeholder='What is the monthly rent payment?'
							onChange={(value) => setMonthlyRentPayment(value)}
						/>
						<div className={!isComplete ? 'isDisabled' : ''}>
							<div className='personal-details-btn '>
								<BorderButton
									value='Continue'
									width='368px'
									height='56px'
									backgroundColor='#0968E3'
									color='#fff'
									marginBottom='15%'
									onClick={onSubmit}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SharedOwnerShipDetails;
