interface IOffersParamsInputs {
  label: string;
  defaultValue?: any;
  hasButton?: boolean;
  currency?: any;
  field?: string;
  onChangeFunc?: any;
  className?: string;
}

/**
 * Returns an input button if hasButton is false.
 * @param props label, value?, currency?, hasButton?, field?
 * @description field key is the value that needs to be updated
 * in the context for the offers data structure
 * @description currency - if this key is added it will add the pound symbol to input field
 * @returns component
 */
const OffersParamsInputs = (props: IOffersParamsInputs) => {
  return (
    <div className={`offers-params-item ${props.className}`}>
      <div className="offers-params-label">{props.label}</div>
      {props.hasButton ? (
        <button className="btn-offers-params">{props.defaultValue}</button>
      ) : (
        <>
          <input
            type="number"
            name={props?.field}
            className=""
            onChange={(e: any) => {
              props.onChangeFunc(e.target.value);
            }}
            defaultValue={props.defaultValue}
          />
          {props.currency && <div className="currency-tag">£</div>}
        </>
      )}
    </div>
  );
};

export default OffersParamsInputs;
