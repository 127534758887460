/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom';
import 'css/StagesPage.css';
import { EventScheduledEvent, InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useDataLayerValue } from 'context-api/DataLayer';
import { BorderButton, Loader } from '../Widgets';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { getTokenPayloadDecoded } from '../../utils/AuthFunctions';

const CalendarPage = () => {
	const history = useHistory();
	const [{ user }] = useDataLayerValue();
	const [displayButton, setDisplayButton] = useState(true);
	const [show, setShow] = useState(true);
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleWindowResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleWindowResize);
		console.log({ width });

		// clear event listener
		return () => window.removeEventListener('resize', handleWindowResize);
	}, [window.innerWidth]);

	const getCalendarHeight = () => {
		if (!displayButton) return '900px';

		if (width > 1024) {
			return '800px';
		} else if (width < 600) {
			return '420px';
		} else {
			return '620px';
		}
	};

	useCalendlyEventListener({
		onProfilePageViewed: () => {
			console.log('calendly: onProfilePageViewed');
			if (!displayButton) {
				setDisplayButton(true);
			}
		},
		onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
		onEventTypeViewed: () => {
			console.log('calendly: onEventTypeViewed');
			setDisplayButton(false);
		},
		onEventScheduled: (e: EventScheduledEvent) => {
			onSubmit();
		},
	});

	const onSubmit = () => {
		history.push('/app/applications');
	};

	return (
		<>
			{user ? (
				<div>
					<Modal
						isOpen={show}
						overlayClassName='offers-modal-overlay'
						className='offers-modal-content'
						onRequestClose={() => setShow(false)}
					>
						<p className='congrats'>
							Congratulations!!! You have now completed your mortgage journey. If you would like to speak to our adviser
							right away just click continue and we will be in touch shortly. If you would prefer to speak to an adviser
							at the specific date and time please choose one from the calendar. After clicking continue you will be
							taken to your dashboard, where you will be able to see the details you provided us with, upload and
							download documents and see the offers available to you once you have spoken to the mortgage adviser.
						</p>
						<BorderButton
							value='Continue'
							width='368px'
							height='56px'
							backgroundColor='#0968E3'
							color='#fff'
							marginTop='1rem'
							onClick={() => setShow(false)}
						/>
					</Modal>
					<InlineWidget
						url='https://calendly.com/access_fs'
						styles={{
							height: getCalendarHeight(),
						}}
						pageSettings={{
							hideGdprBanner: true,
						}}
						prefill={{
							firstName: user?.personal?.firstName,
							lastName: user?.personal?.lastName,
							name: user?.personal?.firstName + ' ' + user?.personal?.lastName,
							email: getTokenPayloadDecoded().email,
							...(user.cases[0].application.partner && { guests: [user.cases[0].application.partner.email] }),
						}}
					/>
					{displayButton && (
						<div className='btnntest'>
							<BorderButton
								disabled={!displayButton}
								value='Skip scheduling'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								color='#fff'
								marginBottom='15%'
								onClick={onSubmit}
							/>
						</div>
					)}
				</div>
			) : (
				<Loader />
			)}
		</>
	);
};

export default CalendarPage;
