import React, { useState } from 'react';
import {
	CleanRadioInput,
	JourneyNavLine,
	ProgressBar,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useHistory } from 'react-router-dom';
import { Bold, H4 } from 'typography';

const PropertyTenure = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [tenure, setTenure] = useState(user?.property?.tenure);
	const delay = 300;

	const setAnswer = (answer: 'Freehold' | 'Leasehold') => {
		setTenure(answer);

		const currUser = { ...user };

		if (!currUser?.property) {
			currUser.property = {};
		}

		currUser.property.tenure = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			if (user.property.tenure === 'Leasehold') {
				history.push('/user/property/purchase-property-lease');
			} else {
				history.push('/user/property/purchase-property-main-residence');
			}
		}, delay);
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={48} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>What’s the property’s tenure?</H4>
					</div>
					<Bold isBold={false} color='#959DA5'>
						You can find out from the estate agent, seller or the advert for the
						property.
					</Bold>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={(e) => e}>
						<CleanRadioInput
							name={'tenure'}
							label={'Freehold'}
							value={'Freehold'}
							onClick={() => setAnswer('Freehold')}
							checked={tenure === 'Freehold'}
						/>

						<CleanRadioInput
							name={'tenure'}
							label={'Leasehold'}
							onClick={() => setAnswer('Leasehold')}
							value={'Leasehold'}
							checked={tenure === 'Leasehold'}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default PropertyTenure;
