import { H4 } from 'typography';
import { NotificationTile } from '.';
import NotifyIcon from 'types/notification';
import NotificationDate from './NotificationDate';

const NotificationMobilePage = () => {
  return (
    <div className="notification-mobile">
      <div className="header-title">
        <H4> Notification</H4>
      </div>

      <div className="notification-content">
        {/* notification group */}
        <NotificationDate date="27 July" />
        <section className="notification-group">
          <NotificationTile
            text={'Congratulations, your mortgage offer accepted!'}
            type={NotifyIcon.NotifyCongrats}
          />

          <NotificationTile
            text={'Your application is completed'}
            type={NotifyIcon.NotifyCompleted}
          />

          <NotificationTile
            text={'Your documents are uploaded'}
            type={NotifyIcon.NotityDocUploaded}
          />
        </section>

        {/* notification group */}
        <NotificationDate date="23 July" />
        <section className="notification-group">
          <NotificationTile
            text={'Please re-upload your ID!'}
            type={NotifyIcon.NotityReUpload}
          />
          <NotificationTile
            text={'Please upload your documents'}
            type={NotifyIcon.NotifyDocError}
          />
        </section>

        {/* notification group */}
        <NotificationDate date="22 July" />
        <section className="notification-group">
          <NotificationTile
            text={'Application is not completed'}
            type={NotifyIcon.NotityNotCompleted}
          />

          <NotificationTile
            text={'Setup your password'}
            type={NotifyIcon.NotityUser}
          />
        </section>
      </div>
    </div>
  );
};

export default NotificationMobilePage;
