/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tooltip } from 'antd';
import { JourneyNavLine, Loader, SelectInput, CleanInput, BorderButton, ProgressBar, Input } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Bold, H4 } from 'typography';
import { PATCHRequest } from 'utils/AuthFunctions';
import { FinancialSource } from 'utils/FinancialData';
import { randId } from 'utils/GeneralFunctions';

interface IOtherIncome {
	id: number;
	amount: number;
	source: string;
	frequency: string;
}

const Financial8 = () => {
	const history = useHistory();

	const [{ user }, dispatch] = useDataLayerValue();
	const { financial } = user || {};
	const { application } = user?.cases?.[0] || {};

	const [isSaving, setIsSaving] = useState(false);
	const [isComplete, setIsComplete] = useState(false);

	const [otherIncome, setOtherIncome] = useState<IOtherIncome[]>(
		financial?.otherIncome || [
			{
				id: randId(),
				amount: null,
				source: '',
				frequency: '',
			},
		]
	);

	useEffect(() => {
		if (otherIncome[0]['amount'] !== 0 && otherIncome[0]['source'] !== '' && otherIncome[0]['frequency'] !== '') {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [otherIncome]);

	const funcSubmitFinancial8 = async (e: any) => {
		e?.preventDefault();
		const currFinancial = { ...financial };
		currFinancial['otherIncome'] = otherIncome;

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial: currFinancial },
		});

		if (currFinancial['selfEmployLastYearEndDate'] === '' || application?.partner) {
			history.push('/user/financial/second-applicant/seven');
		} else {
			setIsSaving(true);
			// make patch call
			const selfEmployedFeedback = await PATCHRequest(`/users?attribute=financial`, currFinancial, history);
			setIsSaving(false);
			if (selfEmployedFeedback.success) {
				history.push('/user/stages');
			}
		}
	};

	const generateNewFields = () => {
		setOtherIncome((initIncome) => [
			...initIncome,
			{
				id: randId(),
				amount: null,
				source: '',
				frequency: '',
			},
		]);
	};

	const removeFieldSet = (income) => {
		setOtherIncome((initIncome) => initIncome.filter((x) => x.id !== income.id));
	};

	if (isSaving) return <Loader text='Saving...' />;

	return (
		<div className='financial-pages'>
			<ProgressBar progress={70} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>Please list the details below</H4>

				<form className='central-form-group' onSubmit={(e) => funcSubmitFinancial8(e)}>
					{otherIncome?.map((income, index) => {
						return (
							<div className='fieldset-holder' key={`${income.id}-${index}`}>
								{index !== 0 && (
									<Tooltip title={'Remove set'} placement='bottomRight'>
										<div className='remove-fieldset' onClick={() => removeFieldSet(income)}></div>
									</Tooltip>
								)}
								<CleanInput
									label='Please specify the source'
									type='text'
									placeholder='text'
									name='source'
									value={income.source}
									required
									onChange={(e: any) => {
										const source = e.target.value;
										setOtherIncome((initIncome) => {
											return initIncome.map((otherIncome) =>
												otherIncome.id === income.id
													? {
															...otherIncome,
															source,
													  }
													: otherIncome
											);
										});
									}}
								/>

								<Input
									label='Income amount in £'
									numberInput
									currency
									value={income.amount}
									placeholder='text'
									name='amount'
									required
									onChange={(value: number) => {
										const amount = value;
										setOtherIncome((initIncome) => {
											return initIncome.map((otherIncome) =>
												otherIncome.id === income.id
													? {
															...otherIncome,
															amount,
													  }
													: otherIncome
											);
										});
									}}
								/>

								<div className='mt'>
									<SelectInput
										name={'frequency'}
										options={FinancialSource}
										label='Frequency'
										defaultValue={income?.frequency || null}
										onSelect={(value: string) => {
											const frequency = value;
											setOtherIncome((initIncome) => {
												return initIncome.map((otherIncome) =>
													otherIncome.id === income.id
														? {
																...otherIncome,
																frequency,
														  }
														: otherIncome
												);
											});
										}}
									/>
								</div>
							</div>
						);
					})}

					<div onClick={generateNewFields} className='financial-add-another'>
						<Bold isBold={false}>Add another</Bold>
					</div>

					<div className={`continue-btn ${!isComplete ? 'isDisabled' : ''}`}>
						<BorderButton
							value='Continue'
							width='368px'
							height='56px'
							backgroundColor='#0968E3'
							color='#fff'
							onClick={() => 'props.onClick'}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Financial8;
