import { Suspense, lazy } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Dashboard } from 'components/Dashbord';
import AuthRoutes from 'routes/AuthRoutes';
import LoggedUserRoutes from 'routes/LoggedUserRoutes';
import { TypoGraphies } from 'typography';
import 'css/App.css';
import { Alert, Loader } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { readFromLocalStorage } from 'utils/AuthFunctions';
import { Welcome } from 'components/Auth/Welcome';

// LAZY LOAD COMPONENTS
const MobileBottomNav = lazy(() => import('components/WorkArea/MobileBottomNav'));

const App = () => {
	const [{ alert }] = useDataLayerValue();
	return (
		<div className='App'>
			{alert.length > 0 && <Alert />}
			<Router>
				<Switch>
					<Route path='/app'>
						<Suspense fallback={<Loader />}>
							<Dashboard />
							<MobileBottomNav />
						</Suspense>
					</Route>

					<Route path='/welcome'>
						<Welcome />
					</Route>

					<Route path='/auth'>
						<AuthRoutes />
					</Route>

					<Route path='/user'>
						<LoggedUserRoutes />
					</Route>

					<Route exact path='/typography'>
						<TypoGraphies />
					</Route>

					<Route exact path='/'>
						<Redirect to={readFromLocalStorage('accessMeAccount') ? '/auth' : '/welcome'} />
					</Route>
				</Switch>
			</Router>
		</div>
	);
};

export default Sentry.withProfiler(App);
