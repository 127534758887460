import { CleanRadioInput, Loader, JourneyNavLine, ProgressBar } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { PATCHRequest } from 'utils/AuthFunctions';

const Occupants18Aged = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [isSaving, setIsSaving] = useState(false);

	const [isOccupantUnder18, setOccupantUnder18] = useState(user?.property?.isOccupantUnder18);
	const delay = 300;

	const setAnswer = (answer: boolean) => {
		setOccupantUnder18(answer);

		setTimeout(() => {
			const currUser = { ...user };
			if (!currUser?.property) {
				currUser.property = {};
			}

			currUser.property.isOccupantUnder18 = answer;

			if (answer) {
				setIsSaving(true);
				delete currUser.property.otherOccupantDetails;
				PATCHRequest(
					`/users?attribute=property`,
					currUser.property,
					history,
				).then(() => {
					setIsSaving(false);
					history.push('/user/stages');
				});
			} else {
				history.push('/user/property/purchase-property-occupants-details');
			}

			// set current expenses details
			dispatch({
				type: actionTypes.SET_USER,
				user: currUser,
			});

		}, delay);
	};

	if (isSaving) return <Loader text='Saving...' />

	return (
		<div className='personal-container'>
			<ProgressBar progress={95} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>
							Are there going to be any occupants aged 18 or above who are not
							the part of the mortgage?
						</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={(e) => e}>
						<CleanRadioInput
							name={'isOccupantUnder18'}
							label={'Yes'}
							onClick={() => setAnswer(false)}
							checked={String(isOccupantUnder18) === 'false'}
						/>

						<CleanRadioInput
							name={'isOccupantUnder18'}
							label={'No'}
							onClick={() => setAnswer(true)}
							checked={String(isOccupantUnder18) === 'true'}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Occupants18Aged;
