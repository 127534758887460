import React from 'react';
import 'css/Welcome.css';
import AccessLogo from 'assets/access-logo-white.svg';
import { useHistory } from 'react-router-dom';
import HeaderText from './HeaderText';
import DescriptionText from './DescriptionText';

const Journey = () => {
	const history = useHistory();

	return (
		<div className='journey-bg journey-bg-img bg-tint'>
			<div className='welcome-page_container'>
				<img className='logo' src={AccessLogo} alt='access logo' />
				<HeaderText>
					<div className='white right-align'>Your journey to become</div>
					<div className='white right-align'>a homeowner starts here</div>
				</HeaderText>
				<DescriptionText>
					<p className='white right-align'>
						Everyone dreams of owning a home. But, not everybody finds the right place to start from. You are lucky to
						have landed here. We specialise in providing top-notch consultation services that transform the arduous
						mortgage process into a breeze. Take our advice and start it right!
					</p>
				</DescriptionText>
				<button className='start-btn btn-pulse' onClick={() => history.push('/auth/signup')}>
					Start the journey
				</button>
			</div>
		</div>
	);
};

export default Journey;
