import { ProgressBar, StepContent } from 'components/Widgets';
import RadioInputBoolean from './RadioInputBoolean';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfileWillingToPay = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [willingToPay, setWillingToPay] = useState(
		user?.application?.willingToPay
	);

	const handleAnswer = (answer: boolean) => {
		setWillingToPay(answer);
		const currUser = { ...user };
		currUser.cases[0].application.willingToPay = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			history.push('/user/create-profile-current-mortgage-value');
		}, 300);
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={0} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='Are you willing to pay it?'
					onClick={() => {}}
				>
					<RadioInputBoolean
						value='yes'
						isChecked={typeof willingToPay === 'boolean' && willingToPay}
						SelectionText='Yes'
						name='willingToPay'
						onClick={() => handleAnswer(true)}
					/>
					<RadioInputBoolean
						value='no'
						isChecked={typeof willingToPay === 'boolean' && !willingToPay}
						SelectionText='No'
						name='willingToPay'
						onClick={() => handleAnswer(false)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileWillingToPay;
