/* eslint-disable react-hooks/exhaustive-deps */
// @ts-ignore
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'css/StagesPage.css';
import { GETRequest, POSTRequest } from 'utils/AuthFunctions';
import { StageCapsule } from '.';
import { JourneyHeader } from 'components/Widgets';
import { H6 } from 'typography';
import personBlueIcon from 'assets/person-blue.svg';
import bankBlueIcon from 'assets/bank-blue.svg';
import liabilitiesBlueIcon from 'assets/liabilities-blue.svg';
import expensesBlueIcon from 'assets/expenses-blue.svg';
import propertyBlueIcon from 'assets/property-blue.svg';
import mortgageBlueIcon from 'assets/mortgage-blue.svg';
import docEditIcon from 'assets/document-edit.svg';
import { useDataLayerValue } from 'context-api/DataLayer';

interface StagesResponseProps {
	liabilities: number;
	liabilities2: number;
	property: number;
	expenses: number;
	personal: number;
	personal2?: number;
	financial: number;
	financial2?: number;
	mortgage: number;
	general: number;
}

const StagesPage = () => {
	const history = useHistory();
	const [{ user }] = useDataLayerValue();
	const { application } = user?.cases?.[0] || {};

	const [showHigherStages, setShowHigherStages] = useState(true);
	const [stages, setStages] = useState<StagesResponseProps>();
	const [isReadyTOSubmit, setIsReadyTOSubmit] = useState(false);
	const [hasSecondApplicant] = useState(!!application?.partner);
	const funcGETUsersInfo = async () => {
		const usersFeedback = await GETRequest(`/progress`, history);
		// @ts-ignore
		const response = usersFeedback?.data as StagesResponseProps;
		setStages(response);

		if (Math.floor(stages?.personal) < 100) {
			setShowHigherStages(false);
		}

		if (Math.floor(stages?.liabilities) >= 100) {
			setShowHigherStages(true);
		}

		if (
			Math.floor(response?.liabilities) >= 100 &&
			// Math.floor(response?.mortgage) >= 100 &&
			// Math.floor(response?.property) >= 100 &&
			Math.floor(response?.personal) >= 100 &&
			// Math.floor(response?.expenses) >= 100 &&
			Math.floor(response?.financial) >= 100
		) {
			if (hasSecondApplicant) {
				Math.floor(response?.liabilities2) >= 100 &&
				Math.floor(response?.personal2) >= 100 &&
				Math.floor(response?.financial2) >= 100
					? setIsReadyTOSubmit(true)
					: setIsReadyTOSubmit(false);
				return;
			}

			setIsReadyTOSubmit(true);
		}
	};

	useEffect(() => {
		funcGETUsersInfo();
	}, []);

	const flowBody = {
		property: 'mortgage_journey',
		value: 'FMA Submitted',
		title: 'Dashboard Submitted',
	};

	const onSubmitApplication = async () => {
		await POSTRequest(`/flow`, flowBody, history);
		history.push('/app/calendar');
	};

	let personalStage = stages?.personal || 0;
	if (hasSecondApplicant) {
		personalStage += stages?.personal2 || 0;
		personalStage /= 2;
	}
	let financialStage = stages?.financial || 0;
	if (hasSecondApplicant) {
		financialStage += stages?.financial2 || 0;
		financialStage /= 2;
	}
	let liabilitiesStage = stages?.liabilities || 0;
	if (hasSecondApplicant) {
		liabilitiesStage += stages?.liabilities2 || 0;
		liabilitiesStage /= 2;
	}

	const stagesValues = {
		personal: Math.ceil(personalStage || 0),
		mortgage: Math.ceil(stages?.mortgage || 0),
		financial: Math.ceil(financialStage || 0),
		liabilities: Math.ceil(liabilitiesStage || 0),
		expenses: Math.ceil(stages?.expenses || 0),
		property: Math.ceil(stages?.property || 0),
	};

	const shouldButtonPulse = (title) => {
		if (stagesValues[title] >= 100) return false;

		switch (title) {
			case 'personal':
				return true;
			case 'mortgage':
				return stagesValues.personal >= 100;
			case 'financial':
				return user.userStatus === 'Journey completed'
					? stagesValues.personal >= 100 && stagesValues.mortgage >= 100
					: stagesValues.personal >= 100;
			case 'property':
				return user.userStatus === 'Journey completed'
					? stagesValues.personal >= 100 && stagesValues.mortgage >= 100 && stagesValues.financial >= 100
					: stagesValues.personal >= 100 && stagesValues.financial >= 100;
			case 'liabilities':
				return user.userStatus === 'Journey completed'
					? stagesValues.personal >= 100 &&
							stagesValues.mortgage >= 100 &&
							stagesValues.financial >= 100 &&
							stagesValues.property >= 100
					: stagesValues.personal >= 100 && stagesValues.financial >= 100;
			case 'expenses':
				return user.userStatus === 'Journey completed'
					? stagesValues.personal >= 100 &&
							stagesValues.mortgage >= 100 &&
							stagesValues.financial >= 100 &&
							stagesValues.property >= 100 &&
							stagesValues.liabilities >= 100
					: stagesValues.personal >= 100 && stagesValues.financial >= 100 && stagesValues.liabilities >= 100;
			default:
				return false;
		}
	};

	return (
		<div className='stages-page'>
			<JourneyHeader />

			<div className='stages-content'>
				<H6>Your application stages</H6>
				<br />
				<StageCapsule
					title='Personal'
					icon={personBlueIcon}
					to='/user/personal'
					stage={personalStage}
					visible
					shouldButtonPulse={shouldButtonPulse('personal')}
				/>

				<StageCapsule
					title='Mortgage'
					to='/user/mortgage'
					icon={mortgageBlueIcon}
					stage={stages?.mortgage || 0}
					visible={user.userStatus === 'Journey completed'}
					shouldButtonPulse={shouldButtonPulse('mortgage')}
				/>

				<StageCapsule
					title='Financial'
					to='/user/financial'
					icon={bankBlueIcon}
					stage={financialStage}
					visible
					shouldButtonPulse={shouldButtonPulse('financial')}
				/>

				<StageCapsule
					title='Property'
					to='/user/property/pre-property'
					icon={propertyBlueIcon}
					stage={stages?.property || 0}
					visible={user.userStatus === 'Journey completed'}
					shouldButtonPulse={shouldButtonPulse('property')}
				/>

				{showHigherStages && (
					<>
						<StageCapsule
							title='Liabilities'
							to='/user/liabilities'
							icon={liabilitiesBlueIcon}
							stage={liabilitiesStage}
							visible
							shouldButtonPulse={shouldButtonPulse('liabilities')}
						/>
						<StageCapsule
							title='Expenses'
							to='/user/expenses'
							icon={expensesBlueIcon}
							stage={stages?.expenses || 0}
							visible={user.userStatus === 'Journey completed'}
							shouldButtonPulse={shouldButtonPulse('expenses')}
						/>
					</>
				)}

				<div className={!isReadyTOSubmit ? 'isDisabled' : ''}>
					<StageCapsule
						title={'Submit your application'}
						icon={docEditIcon}
						stage={0}
						isSubmitCapsule
						onSubmit={onSubmitApplication}
						visible
						isReadyTOSubmit={isReadyTOSubmit}
					/>
				</div>
			</div>
		</div>
	);
};

export default StagesPage;
