import React, { useState } from 'react';
import { CleanRadioInput, JourneyNavLine, ProgressBar } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const PropertyType = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [propertyType, setPropertyType] = useState(user?.property?.propertyType);

	const setAnswer = (propertyType: 'House' | 'Flat') => {
		setPropertyType(propertyType);
		
		const currUser = { ...user };
		
		if (!currUser?.property) {
			currUser.property = {};
		}
		
		currUser.property.propertyType = propertyType;
		
		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});
		
		setTimeout(() => {
			history.push('/user/property/purchase-property-tenure');
		}, 300);
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={42} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>What type of property is it?</H4>
					</div>
				</div>
				<div className='personal-details-body central-form-group'>
					<CleanRadioInput
						name={'propertyType'}
						label={'Flat'}
						value={'Flat'}
						onClick={() => setAnswer('Flat')}
						checked={propertyType === 'Flat'}
					/>

					<CleanRadioInput
						name={'propertyType'}
						label={'House'}
						onClick={() => setAnswer('House')}
						value={'House'}
						checked={propertyType === 'House'}
					/>
				</div>
			</div>
		</div>
	);
};

export default PropertyType;
