export const maritalStatus: Array<SelectItem> = [
	{ value: 'Single', label: 'Single' },
	{ value: 'Married', label: 'Married' },
	{ value: 'Divorced', label: 'Divorced' },
	{ value: 'Widowed', label: 'Widowed' },
];

export type SelectItem = {
	value: string
	label: string
}

export const titleOptions: Array<SelectItem> = [
	{ value: 'Mr', label: 'Mr' },
	{ value: 'Mrs', label: 'Mrs' },
	{ value: 'Miss', label: 'Miss' },
	{ value: 'Ms', label: 'Ms' },
];

export const countryList: Array<SelectItem> = [
	{
		label: 'United Kingdom',
		value: 'United Kingdom',
	},
	{
		label: 'Afghanistan',
		value: 'Afghanistan',
	},
	{
		label: 'Åland Islands',
		value: 'Åland Islands',
	},
	{
		label: 'Albania',
		value: 'Albania',
	},
	{
		label: 'Algeria',
		value: 'Algeria',
	},
	{
		label: 'American Samoa',
		value: 'American Samoa',
	},
	{
		label: 'Andorra',
		value: 'Andorra',
	},
	{
		label: 'Angola',
		value: 'Angola',
	},
	{
		label: 'Anguilla',
		value: 'Anguilla',
	},
	{
		label: 'Antarctica',
		value: 'Antarctica',
	},
	{
		label: 'Antigua and Barbuda',
		value: 'Antigua and Barbuda',
	},
	{
		label: 'Argentina',
		value: 'Argentina',
	},
	{
		label: 'Armenia',
		value: 'Armenia',
	},
	{
		label: 'Aruba',
		value: 'Aruba',
	},
	{
		label: 'Australia',
		value: 'Australia',
	},
	{
		label: 'Austria',
		value: 'Austria',
	},
	{
		label: 'Azerbaijan',
		value: 'Azerbaijan',
	},
	{
		label: 'Bahamas',
		value: 'Bahamas',
	},
	{
		label: 'Bahrain',
		value: 'Bahrain',
	},
	{
		label: 'Bangladesh',
		value: 'Bangladesh',
	},
	{
		label: 'Barbados',
		value: 'Barbados',
	},
	{
		label: 'Belarus',
		value: 'Belarus',
	},
	{
		label: 'Belgium',
		value: 'Belgium',
	},
	{
		label: 'Belize',
		value: 'Belize',
	},
	{
		label: 'Benin',
		value: 'Benin',
	},
	{
		label: 'Bermuda',
		value: 'Bermuda',
	},
	{
		label: 'Bhutan',
		value: 'Bhutan',
	},
	{
		label: 'Bolivia',
		value: 'Bolivia',
	},
	{
		label: 'Bosnia and Herzegovina',
		value: 'Bosnia and Herzegovina',
	},
	{
		label: 'Botswana',
		value: 'Botswana',
	},
	{
		label: 'Bouvet Island',
		value: 'Bouvet Island',
	},
	{
		label: 'Brazil',
		value: 'Brazil',
	},
	{
		label: 'British Indian Ocean Territory',
		value: 'British Indian Ocean Territory',
	},
	{
		label: 'Brunei Darussalam',
		value: 'Brunei Darussalam',
	},
	{
		label: 'Bulgaria',
		value: 'Bulgaria',
	},
	{
		label: 'Burkina Faso',
		value: 'Burkina Faso',
	},
	{
		label: 'Burundi',
		value: 'Burundi',
	},
	{
		label: 'Cambodia',
		value: 'Cambodia',
	},
	{
		label: 'Cameroon',
		value: 'Cameroon',
	},
	{
		label: 'Canada',
		value: 'Canada',
	},
	{
		label: 'Cape Verde',
		value: 'Cape Verde',
	},
	{
		label: 'Cayman Islands',
		value: 'Cayman Islands',
	},
	{
		label: 'Central African Republic',
		value: 'Central African Republic',
	},
	{
		label: 'Chad',
		value: 'Chad',
	},
	{
		label: 'Chile',
		value: 'Chile',
	},
	{
		label: 'China',
		value: 'China',
	},
	{
		label: 'Christmas Island',
		value: 'Christmas Island',
	},
	{
		label: 'Cocos (Keeling) Islands',
		value: 'Cocos (Keeling) Islands',
	},
	{
		label: 'Colombia',
		value: 'Colombia',
	},
	{
		label: 'Comoros',
		value: 'Comoros',
	},
	{
		label: 'Congo',
		value: 'Congo',
	},
	{
		label: 'Congo, The Democratic Republic of the',
		value: 'Congo, The Democratic Republic of the',
	},
	{
		label: 'Cook Islands',
		value: 'Cook Islands',
	},
	{
		label: 'Costa Rica',
		value: 'Costa Rica',
	},
	{
		label: 'Cote D"Ivoire',
		value: 'Cote D"Ivoire',
	},
	{
		label: 'Croatia',
		value: 'Croatia',
	},
	{
		label: 'Cuba',
		value: 'Cuba',
	},
	{
		label: 'Cyprus',
		value: 'Cyprus',
	},
	{
		label: 'Czech Republic',
		value: 'Czech Republic',
	},
	{
		label: 'Denmark',
		value: 'Denmark',
	},
	{
		label: 'Djibouti',
		value: 'Djibouti',
	},
	{
		label: 'Dominica',
		value: 'Dominica',
	},
	{
		label: 'Dominican Republic',
		value: 'Dominican Republic',
	},
	{
		label: 'Ecuador',
		value: 'Ecuador',
	},
	{
		label: 'Egypt',
		value: 'Egypt',
	},
	{
		label: 'El Salvador',
		value: 'El Salvador',
	},
	{
		label: 'Equatorial Guinea',
		value: 'Equatorial Guinea',
	},
	{
		label: 'Eritrea',
		value: 'Eritrea',
	},
	{
		label: 'Estonia',
		value: 'Estonia',
	},
	{
		label: 'Ethiopia',
		value: 'Ethiopia',
	},
	{
		label: 'Falkland Islands (Malvinas)',
		value: 'Falkland Islands (Malvinas)',
	},
	{
		label: 'Faroe Islands',
		value: 'Faroe Islands',
	},
	{
		label: 'Fiji',
		value: 'Fiji',
	},
	{
		label: 'Finland',
		value: 'Finland',
	},
	{
		label: 'France',
		value: 'France',
	},
	{
		label: 'French Guiana',
		value: 'French Guiana',
	},
	{
		label: 'French Polynesia',
		value: 'French Polynesia',
	},
	{
		label: 'French Southern Territories',
		value: 'French Southern Territories',
	},
	{
		label: 'Gabon',
		value: 'Gabon',
	},
	{
		label: 'Gambia',
		value: 'Gambia',
	},
	{
		label: 'Georgia',
		value: 'Georgia',
	},
	{
		label: 'Germany',
		value: 'Germany',
	},
	{
		label: 'Ghana',
		value: 'Ghana',
	},
	{
		label: 'Gibraltar',
		value: 'Gibraltar',
	},
	{
		label: 'Greece',
		value: 'Greece',
	},
	{
		label: 'Greenland',
		value: 'Greenland',
	},
	{
		label: 'Grenada',
		value: 'Grenada',
	},
	{
		label: 'Guadeloupe',
		value: 'Guadeloupe',
	},
	{
		label: 'Guam',
		value: 'Guam',
	},
	{
		label: 'Guatemala',
		value: 'Guatemala',
	},
	{
		label: 'Guernsey',
		value: 'Guernsey',
	},
	{
		label: 'Guinea',
		value: 'Guinea',
	},
	{
		label: 'Guinea-Bissau',
		value: 'Guinea-Bissau',
	},
	{
		label: 'Guyana',
		value: 'Guyana',
	},
	{
		label: 'Haiti',
		value: 'Haiti',
	},
	{
		label: 'Heard Island and Mcdonald Islands',
		value: 'Heard Island and Mcdonald Islands',
	},
	{
		label: 'Holy See (Vatican City State)',
		value: 'Holy See (Vatican City State)',
	},
	{
		label: 'Honduras',
		value: 'Honduras',
	},
	{
		label: 'Hong Kong',
		value: 'Hong Kong',
	},
	{
		label: 'Hungary',
		value: 'Hungary',
	},
	{
		label: 'Iceland',
		value: 'Iceland',
	},
	{
		label: 'India',
		value: 'India',
	},
	{
		label: 'Indonesia',
		value: 'Indonesia',
	},
	{
		label: 'Iran, Islamic Republic Of',
		value: 'Iran, Islamic Republic Of',
	},
	{
		label: 'Iraq',
		value: 'Iraq',
	},
	{
		label: 'Ireland',
		value: 'Ireland',
	},
	{
		label: 'Isle of Man',
		value: 'Isle of Man',
	},
	{
		label: 'Israel',
		value: 'Israel',
	},
	{
		label: 'Italy',
		value: 'Italy',
	},
	{
		label: 'Jamaica',
		value: 'Jamaica',
	},
	{
		label: 'Japan',
		value: 'Japan',
	},
	{
		label: 'Jersey',
		value: 'Jersey',
	},
	{
		label: 'Jordan',
		value: 'Jordan',
	},
	{
		label: 'Kazakhstan',
		value: 'Kazakhstan',
	},
	{
		label: 'Kenya',
		value: 'Kenya',
	},
	{
		label: 'Kiribati',
		value: 'Kiribati',
	},
	{
		label: 'Korea, Democratic People"S Republic of',
		value: 'Korea, Democratic People"S Republic of',
	},
	{
		label: 'Korea, Republic of',
		value: 'Korea, Republic of',
	},
	{
		label: 'Kuwait',
		value: 'Kuwait',
	},
	{
		label: 'Kyrgyzstan',
		value: 'Kyrgyzstan',
	},
	{
		label: 'Lao People"S Democratic Republic',
		value: 'Lao People"S Democratic Republic',
	},
	{
		label: 'Latvia',
		value: 'Latvia',
	},
	{
		label: 'Lebanon',
		value: 'Lebanon',
	},
	{
		label: 'Lesotho',
		value: 'Lesotho',
	},
	{
		label: 'Liberia',
		value: 'Liberia',
	},
	{
		label: 'Libyan Arab Jamahiriya',
		value: 'Libyan Arab Jamahiriya',
	},
	{
		label: 'Liechtenstein',
		value: 'Liechtenstein',
	},
	{
		label: 'Lithuania',
		value: 'Lithuania',
	},
	{
		label: 'Luxembourg',
		value: 'Luxembourg',
	},
	{
		label: 'Macao',
		value: 'Macao',
	},
	{
		label: 'Macedonia, The Former Yugoslav Republic of',
		value: 'Macedonia, The Former Yugoslav Republic of',
	},
	{
		label: 'Madagascar',
		value: 'Madagascar',
	},
	{
		label: 'Malawi',
		value: 'Malawi',
	},
	{
		label: 'Malaysia',
		value: 'Malaysia',
	},
	{
		label: 'Maldives',
		value: 'Maldives',
	},
	{
		label: 'Mali',
		value: 'Mali',
	},
	{
		label: 'Malta',
		value: 'Malta',
	},
	{
		label: 'Marshall Islands',
		value: 'Marshall Islands',
	},
	{
		label: 'Martinique',
		value: 'Martinique',
	},
	{
		label: 'Mauritania',
		value: 'Mauritania',
	},
	{
		label: 'Mauritius',
		value: 'Mauritius',
	},
	{
		label: 'Mayotte',
		value: 'Mayotte',
	},
	{
		label: 'Mexico',
		value: 'Mexico',
	},
	{
		label: 'Micronesia, Federated States of',
		value: 'Micronesia, Federated States of',
	},
	{
		label: 'Moldova, Republic of',
		value: 'Moldova, Republic of',
	},
	{
		label: 'Monaco',
		value: 'Monaco',
	},
	{
		label: 'Mongolia',
		value: 'Mongolia',
	},
	{
		label: 'Montserrat',
		value: 'Montserrat',
	},
	{
		label: 'Morocco',
		value: 'Morocco',
	},
	{
		label: 'Mozambique',
		value: 'Mozambique',
	},
	{
		label: 'Myanmar',
		value: 'Myanmar',
	},
	{
		label: 'Namibia',
		value: 'Namibia',
	},
	{
		label: 'Nauru',
		value: 'Nauru',
	},
	{
		label: 'Nepal',
		value: 'Nepal',
	},
	{
		label: 'Netherlands',
		value: 'Netherlands',
	},
	{
		label: 'Netherlands Antilles',
		value: 'Netherlands Antilles',
	},
	{
		label: 'New Caledonia',
		value: 'New Caledonia',
	},
	{
		label: 'New Zealand',
		value: 'New Zealand',
	},
	{
		label: 'Nicaragua',
		value: 'Nicaragua',
	},
	{
		label: 'Niger',
		value: 'Niger',
	},
	{
		label: 'Nigeria',
		value: 'Nigeria',
	},
	{
		label: 'Niue',
		value: 'Niue',
	},
	{
		label: 'Norfolk Island',
		value: 'Norfolk Island',
	},
	{
		label: 'Northern Mariana Islands',
		value: 'Northern Mariana Islands',
	},
	{
		label: 'Norway',
		value: 'Norway',
	},
	{
		label: 'Oman',
		value: 'Oman',
	},
	{
		label: 'Pakistan',
		value: 'Pakistan',
	},
	{
		label: 'Palau',
		value: 'Palau',
	},
	{
		label: 'Palestinian Territory, Occupied',
		value: 'Palestinian Territory, Occupied',
	},
	{
		label: 'Panama',
		value: 'Panama',
	},
	{
		label: 'Papua New Guinea',
		value: 'Papua New Guinea',
	},
	{
		label: 'Paraguay',
		value: 'Paraguay',
	},
	{
		label: 'Peru',
		value: 'Peru',
	},
	{
		label: 'Philippines',
		value: 'Philippines',
	},
	{
		label: 'Pitcairn',
		value: 'Pitcairn',
	},
	{
		label: 'Poland',
		value: 'Poland',
	},
	{
		label: 'Portugal',
		value: 'Portugal',
	},
	{
		label: 'Puerto Rico',
		value: 'Puerto Rico',
	},
	{
		label: 'Qatar',
		value: 'Qatar',
	},
	{
		label: 'Reunion',
		value: 'Reunion',
	},
	{
		label: 'Romania',
		value: 'Romania',
	},
	{
		label: 'Russian Federation',
		value: 'Russian Federation',
	},
	{
		label: 'RWANDA',
		value: 'RWANDA',
	},
	{
		label: 'Saint Helena',
		value: 'Saint Helena',
	},
	{
		label: 'Saint Kitts and Nevis',
		value: 'Saint Kitts and Nevis',
	},
	{
		label: 'Saint Lucia',
		value: 'Saint Lucia',
	},
	{
		label: 'Saint Pierre and Miquelon',
		value: 'Saint Pierre and Miquelon',
	},
	{
		label: 'Saint Vincent and the Grenadines',
		value: 'Saint Vincent and the Grenadines',
	},
	{
		label: 'Samoa',
		value: 'Samoa',
	},
	{
		label: 'San Marino',
		value: 'San Marino',
	},
	{
		label: 'Sao Tome and Principe',
		value: 'Sao Tome and Principe',
	},
	{
		label: 'Saudi Arabia',
		value: 'Saudi Arabia',
	},
	{
		label: 'Senegal',
		value: 'Senegal',
	},
	{
		label: 'Serbia and Montenegro',
		value: 'Serbia and Montenegro',
	},
	{
		label: 'Seychelles',
		value: 'Seychelles',
	},
	{
		label: 'Sierra Leone',
		value: 'Sierra Leone',
	},
	{
		label: 'Singapore',
		value: 'Singapore',
	},
	{
		label: 'Slovakia',
		value: 'Slovakia',
	},
	{
		label: 'Slovenia',
		value: 'Slovenia',
	},
	{
		label: 'Solomon Islands',
		value: 'Solomon Islands',
	},
	{
		label: 'Somalia',
		value: 'Somalia',
	},
	{
		label: 'South Africa',
		value: 'South Africa',
	},
	{
		label: 'South Georgia and the South Sandwich Islands',
		value: 'South Georgia and the South Sandwich Islands',
	},
	{
		label: 'Spain',
		value: 'Spain',
	},
	{
		label: 'Sri Lanka',
		value: 'Sri Lanka',
	},
	{
		label: 'Sudan',
		value: 'Sudan',
	},
	{
		label: 'Suriname',
		value: 'Suriname',
	},
	{
		label: 'Svalbard and Jan Mayen',
		value: 'Svalbard and Jan Mayen',
	},
	{
		label: 'Swaziland',
		value: 'Swaziland',
	},
	{
		label: 'Sweden',
		value: 'Sweden',
	},
	{
		label: 'Switzerland',
		value: 'Switzerland',
	},
	{
		label: 'Syrian Arab Republic',
		value: 'Syrian Arab Republic',
	},
	{
		label: 'Taiwan, Province of China',
		value: 'Taiwan, Province of China',
	},
	{
		label: 'Tajikistan',
		value: 'Tajikistan',
	},
	{
		label: 'Tanzania, United Republic of',
		value: 'Tanzania, United Republic of',
	},
	{
		label: 'Thailand',
		value: 'Thailand',
	},
	{
		label: 'Timor-Leste',
		value: 'Timor-Leste',
	},
	{
		label: 'Togo',
		value: 'Togo',
	},
	{
		label: 'Tokelau',
		value: 'Tokelau',
	},
	{
		label: 'Tonga',
		value: 'Tonga',
	},
	{
		label: 'Trinidad and Tobago',
		value: 'Trinidad and Tobago',
	},
	{
		label: 'Tunisia',
		value: 'Tunisia',
	},
	{
		label: 'Turkey',
		value: 'Turkey',
	},
	{
		label: 'Turkmenistan',
		value: 'Turkmenistan',
	},
	{
		label: 'Turks and Caicos Islands',
		value: 'Turks and Caicos Islands',
	},
	{
		label: 'Tuvalu',
		value: 'Tuvalu',
	},
	{
		label: 'Uganda',
		value: 'Uganda',
	},
	{
		label: 'Ukraine',
		value: 'Ukraine',
	},
	{
		label: 'United Arab Emirates',
		value: 'United Arab Emirates',
	},
	{
		label: 'United States',
		value: 'United States',
	},
	{
		label: 'United States Minor Outlying Islands',
		value: 'United States Minor Outlying Islands',
	},
	{
		label: 'Uruguay',
		value: 'Uruguay',
	},
	{
		label: 'Uzbekistan',
		value: 'Uzbekistan',
	},
	{
		label: 'Vanuatu',
		value: 'Vanuatu',
	},
	{
		label: 'Venezuela',
		value: 'Venezuela',
	},
	{
		label: 'Viet Nam',
		value: 'Viet Nam',
	},
	{
		label: 'Virgin Islands, British',
		value: 'Virgin Islands, British',
	},
	{
		label: 'Virgin Islands, U.S.',
		value: 'Virgin Islands, U.S.',
	},
	{
		label: 'Wallis and Futuna',
		value: 'Wallis and Futuna',
	},
	{
		label: 'Western Sahara',
		value: 'Western Sahara',
	},
	{
		label: 'Yemen',
		value: 'Yemen',
	},
	{
		label: 'Zambia',
		value: 'Zambia',
	},
	{
		label: 'Zimbabwe',
		value: 'Zimbabwe',
	},
];

export const landerNames: Array<SelectItem> = [
	{
		label: 'Accord Mortgages',
		value: 'Accord Mortgages',
	},
	{
		label: '3mc',
		value: '3mc',
	},
	{
		label: 'Abbey',
		value: 'Abbey',
	},

	{
		label: 'Airdrie Savings',
		value: 'Airdrie Savings',
	},
	{
		label: 'Barclays',
		value: 'Barclays',
	},
	{
		label: 'Aldermore',
		value: 'Aldermore',
	},
	{
		label: 'HSBC',
		value: 'HSBC',
	},
	{
		label: 'Santander',
		value: 'Santander',
	},
	{
		label: 'Natwest',
		value: 'Natwest',
	},
	{
		label: 'Nationwide',
		value: 'Nationwide',
	},
	{
		label: 'Metro Bank',
		value: 'Metro Bank',
	},
	{
		label: 'TSB',
		value: 'TSB',
	},
	{
		label: 'Halifax',
		value: 'Halifax',
	},
	{
		label: 'RBS',
		value: 'RBS',
	},
	{
		label: 'Skipton BS',
		value: 'Skipton BS',
	},
	{
		label: 'Bank of Ireland',
		value: 'Bank of Ireland',
	},
	{
		label: 'Central Trust',
		value: 'Central Trust',
	},
	{
		label: 'Catalyst Property Finance',
		value: 'Catalyst Property Finance',
	},

	{
		label: 'Castle Trust',
		value: 'Castle Trust',
	},

	{
		label: 'Canada Life',
		value: 'Canada Life',
	},

	{
		label: 'CAF Bank',
		value: 'CAF Bank',
	},


	{
		label: 'Brightstar',
		value: 'Brightstar',
	},


	{
		label: 'Buckinghamshire Building Society',
		value: 'Buckinghamshire Building Society',
	},

	{
		label: 'The Nottingham',
		value: 'The Nottingham',
	},

	{
		label: 'BuildLoan',
		value: 'BuildLoan',
	},

	{
		label: 'BM Solutions',
		value: 'BM Solutions',
	},

	{
		label: 'Bluestone Mortgages',
		value: 'Bluestone Mortgages',
	},

	{
		label: 'Beverley Building Society',
		value: 'Beverley Building Society',
	},

	{
		label: 'Bath Building Society',
		value: 'Bath Building Society',
	},

	{
		label: 'Chorley Building Society',
		value: 'Chorley Building Society',
	},

	{
		label: 'Clydesdale Bank',
		value: 'Clydesdale Bank',
	},

	{
		label: 'Coventry for Intermediaries',
		value: 'Coventry for Intermediaries',
	},

	{
		label: 'Darlington Building Society',
		value: 'Darlington Building Society',
	},

	{
		label: 'Dudley Building Society',
		value: 'Dudley Building Society',
	},


	{
		label: 'Earl Shilton Building Society',
		value: 'Earl Shilton Building Society',
	},


	{
		label: 'Family Building Society',
		value: 'Family Building Society',
	},

	{
		label: 'Fleet Mortgages',
		value: 'Fleet Mortgages',
	},

	{
		label: 'Furness Building Society',
		value: 'Furness Building Society',
	},


	{
		label: 'Gatehouse Bank',
		value: 'Gatehouse Bank',
	},


	{
		label: 'Greenfield Mortgages',
		value: 'Greenfield Mortgages',
	},


	{
		label: 'Hampshire Trust Bank',
		value: 'Hampshire Trust Bank',
	},
	{
		label: 'Hanley Building Society',
		value: 'Hanley Building Society',
	},
	{
		label: 'Halifax Intermediaries',
		value: 'Halifax Intermediaries',
	},
	{
		label: 'Harpenden Building Society',
		value: 'Harpenden Building Society',
	},
	{
		label: 'Hinckley & Rugby Building Society',
		value: 'Hinckley & Rugby Building Society',
	},
	{
		label: 'Hodge',
		value: 'Hodge',
	},
	{
		label: 'InterBay Commercial',
		value: 'InterBay Commercial',
	},
	{
		label: 'Ipswich Building Society',
		value: 'Ipswich Building Society',
	},
	{
		label: 'Kensington',
		value: 'Kensington',
	},

	{
		label: 'Kent Reliance',
		value: 'Kent Reliance',
	},
	{
		label: 'Keystone',
		value: 'Keystone',
	},
	{
		label: 'Landbay',
		value: 'Landbay',
	},
	{
		label: 'Leeds Building Society',
		value: 'Leeds Building Society',
	},
	{
		label: 'Leek United',
		value: 'Leek United',
	},

	{
		label: 'Lend Invest',
		value: 'Lend Invest',
	},
	{
		label: 'L&G Home Finance',
		value: 'L&G Home Finance',
	},
	{
		label: 'Mansfield Building Society',
		value: 'Mansfield Building Society',
	},
	{
		label: 'Marsden Building Society',
		value: 'Marsden Building Society',
	},
	{
		label: 'Masthaven Finance',
		value: 'Masthaven Finance',
	},
	{
		label: 'MBS Lending',
		value: 'MBS Lending',
	},

	{
		label: 'Nationwide for Intermediaries',
		value: 'Nationwide for Intermediaries',
	},

	{
		label: 'Octopus',
		value: 'Octopus',
	},

	{
		label: 'Positive Lending',
		value: 'Positive Lending',
	},

	{
		label: 'Positive Lending',
		value: 'Positive Lending',
	},

	{
		label: 'Progressive Building Society',
		value: 'Progressive Building Society',
	},


	{
		label: 'Progressive Building Society',
		value: 'Progressive Building Society',
	},


	{
		label: 'Saffron for Intermediaries',
		value: 'Saffron for Intermediaries',
	},

	{
		label: 'Swansea Building Society',
		value: 'Swansea Building Society',
	},

	{
		label: 'United Trust Bank',
		value: 'United Trust Bank',
	},

];

export const loansList: Array<SelectItem> = [
	{ value: 'creditCard', label: 'Credit card' },
	{ value: 'Overdraft', label: 'Overdraft' },
	{
		value: 'hirePurchase',
		label: 'Hire purchase/Personal Contact Payment',
	},
	{ value: 'personalLoan', label: 'Personal loan' },
];

export const MortgagesNameList: Array<SelectItem> = [
	{ value: 'Myself', label: 'Myself' },
	{ value: 'Mycoapplicant', label: 'My co-applicant' },
	{ value: 'Shared', label: 'Shared' },
];

export const relationship: Array<SelectItem> = [
	{ value: 'Daughter', label: 'Daughter' },
	{ value: 'Son', label: 'Son' },
	{ value: 'Aunt/Uncle', label: 'Aunt/Uncle' },
	{ value: 'Cousin', label: 'Cousin' },
	{ value: 'step-Sibling', label: 'Step-Sibling' },
	{ value: 'Half-Sibling', label: 'Half-Sibling' },
	{ value: 'Sibling', label: 'Sibling' },
	{ value: 'Nephew/Niece', label: 'Nephew/Niece' },
	{ value: 'Adopted Child', label: 'Adopted Child' },
	{ value: 'Adopted Parent', label: 'Adopted Parent' },
	{ value: 'Adopted Sibling', label: 'Adopted Sibling' },
	{ value: 'Aunt/Uncle Child', label: 'Aunt/Uncle Child' },
	{ value: 'Child (Guardian)', label: 'Child (Guardian)' },
	{ value: 'Child(In-law)', label: 'Child(In-law)' },
	{ value: 'Ex-Spouse', label: 'Ex-Spouse' },
	{ value: 'Fiance', label: 'Fiance ' },
	{ value: 'Fiancee Foster', label: 'Fiancee Foster ' },
	{ value: 'Child Foster', label: 'Child Foster ' },
	{ value: 'Parent Foster', label: 'Parent Foster ' },
	{ value: 'Sibling Friend', label: 'Sibling Friend' },
	{ value: 'God child', label: 'God child' },
	{ value: 'God parent', label: 'God parent ' },
	{ value: 'Grand Aunt/Uncle', label: 'Grand Aunt/Uncle' },
	{ value: 'Grand Nephew/Niece', label: 'Grand Nephew/Niece' },
	{ value: 'Grand-daughter', label: 'Grand-daughter' },
	{ value: 'Grand-son', label: 'Grand-son' },
	{ value: 'Grand Child', label: 'Grand Child' },
	{ value: 'Great Grand Parent', label: 'Great Grand Parent' },
	{ value: 'Half-Sibling', label: 'Half-Sibling ' },
	{ value: 'Living Spouse ', label: 'Living Spouse ' },
	{ value: 'Nephew/Niece', label: 'Nephew/Niece' },
	{ value: 'Other Parent', label: 'Other Parent ' },
	{ value: 'Parent-In-Law', label: 'Parent-In-Law ' },
	{ value: 'Servant', label: 'Servant' },
	{ value: 'Step-Child', label: 'Step-Child' },
	{ value: 'Step-Grand Child', label: 'Step-Grand Child ' },
	{ value: 'Step-Grand Parent', label: 'Step-Grand Parent' },
	{ value: 'Step-Parent', label: 'Step-Parent' },
	{ value: 'Step-Sibling', label: 'Step-Sibling' },
	{ value: 'Step-daughter', label: 'Step-daughter' },
	{ value: 'Step-son', label: 'Step-son' },
];

export const nationalityList = [
	{
		label: 'British',
		value: 'British',
	},
	{
		num_code: '4',
		alpha_2_code: 'AF',
		alpha_3_code: 'AFG',
		label: 'Afghan',
		value: 'Afghan',
	},
	{
		num_code: '248',
		alpha_2_code: 'AX',
		alpha_3_code: 'ALA',
		label: '\u00c5land Island',
		value: '\u00c5land Island',
	},
	{
		num_code: '8',
		alpha_2_code: 'AL',
		alpha_3_code: 'ALB',
		label: 'Albanian',
		value: 'Albanian',
	},
	{
		num_code: '12',
		alpha_2_code: 'DZ',
		alpha_3_code: 'DZA',
		label: 'Algerian',
		value: 'Algerian',
	},
	{
		num_code: '16',
		alpha_2_code: 'AS',
		alpha_3_code: 'ASM',
		label: 'American Samoan',
		value: 'American Samoan',
	},
	{
		num_code: '20',
		alpha_2_code: 'AD',
		alpha_3_code: 'AND',
		label: 'Andorran',
		value: 'Andorran',
	},
	{
		num_code: '24',
		alpha_2_code: 'AO',
		alpha_3_code: 'AGO',
		label: 'Angolan',
		value: 'Angolan',
	},
	{
		num_code: '660',
		alpha_2_code: 'AI',
		alpha_3_code: 'AIA',
		label: 'Anguillan',
		value: 'Anguillan',
	},
	{
		num_code: '10',
		alpha_2_code: 'AQ',
		alpha_3_code: 'ATA',
		label: 'Antarctic',
		value: 'Antarctic',
	},
	{
		num_code: '28',
		alpha_2_code: 'AG',
		alpha_3_code: 'ATG',
		label: 'Antiguan or Barbudan',
		value: 'Antiguan or Barbudan',
	},
	{
		num_code: '32',
		alpha_2_code: 'AR',
		alpha_3_code: 'ARG',
		label: 'Argentine',
		value: 'Argentine',
	},
	{
		num_code: '51',
		alpha_2_code: 'AM',
		alpha_3_code: 'ARM',
		label: 'Armenian',
		value: 'Armenian',
	},
	{
		num_code: '533',
		alpha_2_code: 'AW',
		alpha_3_code: 'ABW',
		label: 'Aruban',
		value: 'Aruban',
	},
	{
		num_code: '36',
		alpha_2_code: 'AU',
		alpha_3_code: 'AUS',
		label: 'Australian',
		value: 'Australian',
	},
	{
		num_code: '40',
		alpha_2_code: 'AT',
		alpha_3_code: 'AUT',
		label: 'Austrian',
		value: 'Austrian',
	},
	{
		num_code: '31',
		alpha_2_code: 'AZ',
		alpha_3_code: 'AZE',
		label: 'Azerbaijani, Azeri',
		value: 'Azerbaijani, Azeri',
	},
	{
		num_code: '44',
		alpha_2_code: 'BS',
		alpha_3_code: 'BHS',
		label: 'Bahamian',
		value: 'Bahamian',
	},
	{
		num_code: '48',
		alpha_2_code: 'BH',
		alpha_3_code: 'BHR',
		label: 'Bahraini',
		value: 'Bahraini',
	},
	{
		num_code: '50',
		alpha_2_code: 'BD',
		alpha_3_code: 'BGD',
		label: 'Bangladeshi',
		value: 'Bangladeshi',
	},
	{
		num_code: '52',
		alpha_2_code: 'BB',
		alpha_3_code: 'BRB',
		label: 'Barbadian',
		value: 'Barbadian',
	},
	{
		num_code: '112',
		alpha_2_code: 'BY',
		alpha_3_code: 'BLR',
		label: 'Belarusian',
		value: 'Belarusian',
	},
	{
		num_code: '56',
		alpha_2_code: 'BE',
		alpha_3_code: 'BEL',
		label: 'Belgian',
		value: 'Belgian',
	},
	{
		num_code: '84',
		alpha_2_code: 'BZ',
		alpha_3_code: 'BLZ',
		label: 'Belizean',
		value: 'Belizean',
	},
	{
		num_code: '204',
		alpha_2_code: 'BJ',
		alpha_3_code: 'BEN',
		label: 'Beninese, Beninois',
		value: 'Beninese, Beninois',
	},
	{
		num_code: '60',
		alpha_2_code: 'BM',
		alpha_3_code: 'BMU',
		label: 'Bermudian, Bermudan',
		value: 'Bermudian, Bermudan',
	},
	{
		num_code: '64',
		alpha_2_code: 'BT',
		alpha_3_code: 'BTN',
		label: 'Bhutanese',
		value: 'Bhutanese',
	},
	{
		num_code: '68',
		alpha_2_code: 'BO',
		alpha_3_code: 'BOL',
		label: 'Bolivian',
		value: 'Bolivian',
	},
	{
		num_code: '535',
		alpha_2_code: 'BQ',
		alpha_3_code: 'BES',
		label: 'Bonaire',
		value: 'Bonaire',
	},
	{
		num_code: '70',
		alpha_2_code: 'BA',
		alpha_3_code: 'BIH',
		label: 'Bosnian or Herzegovinian',
		value: 'Bosnian or Herzegovinian',
	},
	{
		num_code: '72',
		alpha_2_code: 'BW',
		alpha_3_code: 'BWA',
		label: 'Motswana, Botswanan',
		value: 'Motswana, Botswanan',
	},
	{
		num_code: '74',
		alpha_2_code: 'BV',
		alpha_3_code: 'BVT',
		label: 'Bouvet Island',
		value: 'Bouvet Island',
	},
	{
		num_code: '76',
		alpha_2_code: 'BR',
		alpha_3_code: 'BRA',
		label: 'Brazilian',
		value: 'Brazilian',
	},
	{
		num_code: '86',
		alpha_2_code: 'IO',
		alpha_3_code: 'IOT',
		label: 'BIOT',
		value: 'BIOT',
	},
	{
		num_code: '96',
		alpha_2_code: 'BN',
		alpha_3_code: 'BRN',
		label: 'Bruneian',
		value: 'Bruneian',
	},
	{
		num_code: '100',
		alpha_2_code: 'BG',
		alpha_3_code: 'BGR',
		label: 'Bulgarian',
		value: 'Bulgarian',
	},
	{
		num_code: '854',
		alpha_2_code: 'BF',
		alpha_3_code: 'BFA',
		label: 'Burkinab\u00e9',
		value: 'Burkinab\u00e9',
	},
	{
		num_code: '108',
		alpha_2_code: 'BI',
		alpha_3_code: 'BDI',
		label: 'Burundian',
		value: 'Burundian',
	},
	{
		num_code: '132',
		alpha_2_code: 'CV',
		alpha_3_code: 'CPV',
		label: 'Cabo Verdean',
		value: 'Cabo Verdean',
	},
	{
		num_code: '116',
		alpha_2_code: 'KH',
		alpha_3_code: 'KHM',
		label: 'Cambodian',
		value: 'Cambodian',
	},
	{
		num_code: '120',
		alpha_2_code: 'CM',
		alpha_3_code: 'CMR',
		label: 'Cameroonian',
		value: 'Cameroonian',
	},
	{
		num_code: '124',
		alpha_2_code: 'CA',
		alpha_3_code: 'CAN',
		label: 'Canadian',
		value: 'Canadian',
	},
	{
		num_code: '136',
		alpha_2_code: 'KY',
		alpha_3_code: 'CYM',
		label: 'Caymanian',
		value: 'Caymanian',
	},
	{
		num_code: '140',
		alpha_2_code: 'CF',
		alpha_3_code: 'CAF',
		label: 'Central African',
		value: 'Central African',
	},
	{
		num_code: '148',
		alpha_2_code: 'TD',
		alpha_3_code: 'TCD',
		label: 'Chadian',
		value: 'Chadian',
	},
	{
		num_code: '152',
		alpha_2_code: 'CL',
		alpha_3_code: 'CHL',
		label: 'Chilean',
		value: 'Chilean',
	},
	{
		num_code: '156',
		alpha_2_code: 'CN',
		alpha_3_code: 'CHN',
		label: 'Chinese',
		value: 'Chinese',
	},
	{
		num_code: '162',
		alpha_2_code: 'CX',
		alpha_3_code: 'CXR',
		label: 'Christmas Island',
		value: 'Christmas Island',
	},
	{
		num_code: '166',
		alpha_2_code: 'CC',
		alpha_3_code: 'CCK',
		label: 'Cocos Island',
		value: 'Cocos Island',
	},
	{
		num_code: '170',
		alpha_2_code: 'CO',
		alpha_3_code: 'COL',
		label: 'Colombian',
		value: 'Colombian',
	},
	{
		num_code: '174',
		alpha_2_code: 'KM',
		alpha_3_code: 'COM',
		label: 'Comoran, Comorian',
		value: 'Comoran, Comorian',
	},
	{
		num_code: '178',
		alpha_2_code: 'CG',
		alpha_3_code: 'COG',
		label: 'Congolese',
		value: 'Congolese',
	},
	{
		num_code: '180',
		alpha_2_code: 'CD',
		alpha_3_code: 'COD',
		label: 'Congolese',
		value: 'Congolese',
	},
	{
		num_code: '184',
		alpha_2_code: 'CK',
		alpha_3_code: 'COK',
		label: 'Cook Island',
		value: 'Cook Island',
	},
	{
		num_code: '188',
		alpha_2_code: 'CR',
		alpha_3_code: 'CRI',
		label: 'Costa Rican',
		value: 'Costa Rican',
	},
	{
		num_code: '384',
		alpha_2_code: 'CI',
		alpha_3_code: 'CIV',
		label: 'Ivorian',
		value: 'Ivorian',
	},
	{
		num_code: '191',
		alpha_2_code: 'HR',
		alpha_3_code: 'HRV',
		label: 'Croatian',
		value: 'Croatian',
	},
	{
		num_code: '192',
		alpha_2_code: 'CU',
		alpha_3_code: 'CUB',
		label: 'Cuban',
		value: 'Cuban',
	},
	{
		num_code: '531',
		alpha_2_code: 'CW',
		alpha_3_code: 'CUW',
		label: 'Cura\u00e7aoan',
		value: 'Cura\u00e7aoan',
	},
	{
		num_code: '196',
		alpha_2_code: 'CY',
		alpha_3_code: 'CYP',
		label: 'Cypriot',
		value: 'Cypriot',
	},
	{
		num_code: '203',
		alpha_2_code: 'CZ',
		alpha_3_code: 'CZE',
		label: 'Czech',
		value: 'Czech',
	},
	{
		num_code: '208',
		alpha_2_code: 'DK',
		alpha_3_code: 'DNK',
		label: 'Danish',
		value: 'Danish',
	},
	{
		num_code: '262',
		alpha_2_code: 'DJ',
		alpha_3_code: 'DJI',
		label: 'Djiboutian',
		value: 'Djiboutian',
	},
	{
		num_code: '212',
		alpha_2_code: 'DM',
		alpha_3_code: 'DMA',
		label: 'Dominican',
		value: 'Dominican',
	},
	{
		num_code: '214',
		alpha_2_code: 'DO',
		alpha_3_code: 'DOM',
		label: 'Dominican',
		value: 'Dominican',
	},
	{
		num_code: '218',
		alpha_2_code: 'EC',
		alpha_3_code: 'ECU',
		label: 'Ecuadorian',
		value: 'Ecuadorian',
	},
	{
		num_code: '818',
		alpha_2_code: 'EG',
		alpha_3_code: 'EGY',
		label: 'Egyptian',
		value: 'Egyptian',
	},
	{
		num_code: '222',
		alpha_2_code: 'SV',
		alpha_3_code: 'SLV',
		label: 'Salvadoran',
		value: 'Salvadoran',
	},
	{
		num_code: '226',
		alpha_2_code: 'GQ',
		alpha_3_code: 'GNQ',
		label: 'Equatorial Guinean, Equatoguinean',
		value: 'Equatorial Guinean, Equatoguinean',
	},
	{
		num_code: '232',
		alpha_2_code: 'ER',
		alpha_3_code: 'ERI',
		label: 'Eritrean',
		value: 'Eritrean',
	},
	{
		num_code: '233',
		alpha_2_code: 'EE',
		alpha_3_code: 'EST',
		label: 'Estonian',
		value: 'Estonian',
	},
	{
		num_code: '231',
		alpha_2_code: 'ET',
		alpha_3_code: 'ETH',
		label: 'Ethiopian',
		value: 'Ethiopian',
	},
	{
		num_code: '238',
		alpha_2_code: 'FK',
		alpha_3_code: 'FLK',
		label: 'Falkland Island',
		value: 'Falkland Island',
	},
	{
		num_code: '234',
		alpha_2_code: 'FO',
		alpha_3_code: 'FRO',
		label: 'Faroese',
		value: 'Faroese',
	},
	{
		num_code: '242',
		alpha_2_code: 'FJ',
		alpha_3_code: 'FJI',
		label: 'Fijian',
		value: 'Fijian',
	},
	{
		num_code: '246',
		alpha_2_code: 'FI',
		alpha_3_code: 'FIN',
		label: 'Finnish',
		value: 'Finnish',
	},
	{
		num_code: '250',
		alpha_2_code: 'FR',
		alpha_3_code: 'FRA',
		label: 'French',
		value: 'French',
	},
	{
		num_code: '254',
		alpha_2_code: 'GF',
		alpha_3_code: 'GUF',
		label: 'French Guianese',
		value: 'French Guianese',
	},
	{
		num_code: '258',
		alpha_2_code: 'PF',
		alpha_3_code: 'PYF',
		label: 'French Polynesian',
		value: 'French Polynesian',
	},
	{
		num_code: '260',
		alpha_2_code: 'TF',
		alpha_3_code: 'ATF',
		label: 'French Southern Territories',
		value: 'French Southern Territories',
	},
	{
		num_code: '266',
		alpha_2_code: 'GA',
		alpha_3_code: 'GAB',
		label: 'Gabonese',
		value: 'Gabonese',
	},
	{
		num_code: '270',
		alpha_2_code: 'GM',
		alpha_3_code: 'GMB',
		label: 'Gambian',
		value: 'Gambian',
	},
	{
		num_code: '268',
		alpha_2_code: 'GE',
		alpha_3_code: 'GEO',
		label: 'Georgian',
		value: 'Georgian',
	},
	{
		num_code: '276',
		alpha_2_code: 'DE',
		alpha_3_code: 'DEU',
		label: 'German',
		value: 'German',
	},
	{
		num_code: '288',
		alpha_2_code: 'GH',
		alpha_3_code: 'GHA',
		label: 'Ghanaian',
		value: 'Ghanaian',
	},
	{
		num_code: '292',
		alpha_2_code: 'GI',
		alpha_3_code: 'GIB',
		label: 'Gibraltar',
		value: 'Gibraltar',
	},
	{
		num_code: '300',
		alpha_2_code: 'GR',
		alpha_3_code: 'GRC',
		label: 'Greek, Hellenic',
		value: 'Greek, Hellenic',
	},
	{
		num_code: '304',
		alpha_2_code: 'GL',
		alpha_3_code: 'GRL',
		label: 'Greenlandic',
		value: 'Greenlandic',
	},
	{
		num_code: '308',
		alpha_2_code: 'GD',
		alpha_3_code: 'GRD',
		label: 'Grenadian',
		value: 'Grenadian',
	},
	{
		num_code: '312',
		alpha_2_code: 'GP',
		alpha_3_code: 'GLP',
		label: 'Guadeloupe',
		value: 'Guadeloupe',
	},
	{
		num_code: '316',
		alpha_2_code: 'GU',
		alpha_3_code: 'GUM',
		label: 'Guamanian, Guambat',
		value: 'Guamanian, Guambat',
	},
	{
		num_code: '320',
		alpha_2_code: 'GT',
		alpha_3_code: 'GTM',
		label: 'Guatemalan',
		value: 'Guatemalan',
	},
	{
		num_code: '831',
		alpha_2_code: 'GG',
		alpha_3_code: 'GGY',
		label: 'Channel Island',
		value: 'Channel Island',
	},
	{
		num_code: '324',
		alpha_2_code: 'GN',
		alpha_3_code: 'GIN',
		label: 'Guinean',
		value: 'Guinean',
	},
	{
		num_code: '624',
		alpha_2_code: 'GW',
		alpha_3_code: 'GNB',
		label: 'Bissau-Guinean',
		value: 'Bissau-Guinean',
	},
	{
		num_code: '328',
		alpha_2_code: 'GY',
		alpha_3_code: 'GUY',
		label: 'Guyanese',
		value: 'Guyanese',
	},
	{
		num_code: '332',
		alpha_2_code: 'HT',
		alpha_3_code: 'HTI',
		label: 'Haitian',
		value: 'Haitian',
	},
	{
		num_code: '334',
		alpha_2_code: 'HM',
		alpha_3_code: 'HMD',
		label: 'Heard Island or McDonald Islands',
		value: 'Heard Island or McDonald Islands',
	},
	{
		num_code: '336',
		alpha_2_code: 'VA',
		alpha_3_code: 'VAT',
		label: 'Vatican',
		value: 'Vatican',
	},
	{
		num_code: '340',
		alpha_2_code: 'HN',
		alpha_3_code: 'HND',
		label: 'Honduran',
		value: 'Honduran',
	},
	{
		num_code: '344',
		alpha_2_code: 'HK',
		alpha_3_code: 'HKG',
		label: 'Hong Kong, Hong Kongese',
		value: 'Hong Kong, Hong Kongese',
	},
	{
		num_code: '348',
		alpha_2_code: 'HU',
		alpha_3_code: 'HUN',
		label: 'Hungarian, Magyar',
		value: 'Hungarian, Magyar',
	},
	{
		num_code: '352',
		alpha_2_code: 'IS',
		alpha_3_code: 'ISL',
		label: 'Icelandic',
		value: 'Icelandic',
	},
	{
		num_code: '356',
		alpha_2_code: 'IN',
		alpha_3_code: 'IND',
		label: 'Indian',
		value: 'Indian',
	},
	{
		num_code: '360',
		alpha_2_code: 'ID',
		alpha_3_code: 'IDN',
		label: 'Indonesian',
		value: 'Indonesian',
	},
	{
		num_code: '364',
		alpha_2_code: 'IR',
		alpha_3_code: 'IRN',
		label: 'Iranian, Persian',
		value: 'Iranian, Persian',
	},
	{
		num_code: '368',
		alpha_2_code: 'IQ',
		alpha_3_code: 'IRQ',
		label: 'Iraqi',
		value: 'Iraqi',
	},
	{
		num_code: '372',
		alpha_2_code: 'IE',
		alpha_3_code: 'IRL',
		label: 'Irish',
		value: 'Irish',
	},
	{
		num_code: '833',
		alpha_2_code: 'IM',
		alpha_3_code: 'IMN',
		label: 'Manx',
		value: 'Manx',
	},
	{
		num_code: '376',
		alpha_2_code: 'IL',
		alpha_3_code: 'ISR',
		label: 'Israeli',
		value: 'Israeli',
	},
	{
		num_code: '380',
		alpha_2_code: 'IT',
		alpha_3_code: 'ITA',
		label: 'Italian',
		value: 'Italian',
	},
	{
		num_code: '388',
		alpha_2_code: 'JM',
		alpha_3_code: 'JAM',
		label: 'Jamaican',
		value: 'Jamaican',
	},
	{
		num_code: '392',
		alpha_2_code: 'JP',
		alpha_3_code: 'JPN',
		label: 'Japanese',
		value: 'Japanese',
	},
	{
		num_code: '832',
		alpha_2_code: 'JE',
		alpha_3_code: 'JEY',
		label: 'Channel Island',
		value: 'Channel Island',
	},
	{
		num_code: '400',
		alpha_2_code: 'JO',
		alpha_3_code: 'JOR',
		label: 'Jordanian',
		value: 'Jordanian',
	},
	{
		num_code: '398',
		alpha_2_code: 'KZ',
		alpha_3_code: 'KAZ',
		label: 'Kazakhstani, Kazakh',
		value: 'Kazakhstani, Kazakh',
	},
	{
		num_code: '404',
		alpha_2_code: 'KE',
		alpha_3_code: 'KEN',
		label: 'Kenyan',
		value: 'Kenyan',
	},
	{
		num_code: '296',
		alpha_2_code: 'KI',
		alpha_3_code: 'KIR',
		label: 'I-Kiribati',
		value: 'I-Kiribati',
	},
	{
		num_code: '408',
		alpha_2_code: 'KP',
		alpha_3_code: 'PRK',
		label: 'North Korean',
		value: 'North Korean',
	},
	{
		num_code: '410',
		alpha_2_code: 'KR',
		alpha_3_code: 'KOR',
		label: 'South Korean',
		value: 'South Korean',
	},
	{
		num_code: '414',
		alpha_2_code: 'KW',
		alpha_3_code: 'KWT',
		label: 'Kuwaiti',
		value: 'Kuwaiti',
	},
	{
		num_code: '417',
		alpha_2_code: 'KG',
		alpha_3_code: 'KGZ',
		label: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
		value: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
	},
	{
		num_code: '418',
		alpha_2_code: 'LA',
		alpha_3_code: 'LAO',
		label: 'Lao, Laotian',
		value: 'Lao, Laotian',
	},
	{
		num_code: '428',
		alpha_2_code: 'LV',
		alpha_3_code: 'LVA',
		label: 'Latvian',
		value: 'Latvian',
	},
	{
		num_code: '422',
		alpha_2_code: 'LB',
		alpha_3_code: 'LBN',
		label: 'Lebanese',
		value: 'Lebanese',
	},
	{
		num_code: '426',
		alpha_2_code: 'LS',
		alpha_3_code: 'LSO',
		label: 'Basotho',
		value: 'Basotho',
	},
	{
		num_code: '430',
		alpha_2_code: 'LR',
		alpha_3_code: 'LBR',
		label: 'Liberian',
		value: 'Liberian',
	},
	{
		num_code: '434',
		alpha_2_code: 'LY',
		alpha_3_code: 'LBY',
		label: 'Libyan',
		value: 'Libyan',
	},
	{
		num_code: '438',
		alpha_2_code: 'LI',
		alpha_3_code: 'LIE',
		label: 'Liechtenstein',
		value: 'Liechtenstein',
	},
	{
		num_code: '440',
		alpha_2_code: 'LT',
		alpha_3_code: 'LTU',
		label: 'Lithuanian',
		value: 'Lithuanian',
	},
	{
		num_code: '442',
		alpha_2_code: 'LU',
		alpha_3_code: 'LUX',
		label: 'Luxembourg, Luxembourgish',
		value: 'Luxembourg, Luxembourgish',
	},
	{
		num_code: '446',
		alpha_2_code: 'MO',
		alpha_3_code: 'MAC',
		label: 'Macanese, Chinese',
		value: 'Macanese, Chinese',
	},
	{
		num_code: '807',
		alpha_2_code: 'MK',
		alpha_3_code: 'MKD',
		label: 'Macedonian',
		value: 'Macedonian',
	},
	{
		num_code: '450',
		alpha_2_code: 'MG',
		alpha_3_code: 'MDG',
		label: 'Malagasy',
		value: 'Malagasy',
	},
	{
		num_code: '454',
		alpha_2_code: 'MW',
		alpha_3_code: 'MWI',
		label: 'Malawian',
		value: 'Malawian',
	},
	{
		num_code: '458',
		alpha_2_code: 'MY',
		alpha_3_code: 'MYS',
		label: 'Malaysian',
		value: 'Malaysian',
	},
	{
		num_code: '462',
		alpha_2_code: 'MV',
		alpha_3_code: 'MDV',
		label: 'Maldivian',
		value: 'Maldivian',
	},
	{
		num_code: '466',
		alpha_2_code: 'ML',
		alpha_3_code: 'MLI',
		label: 'Malian, Malinese',
		value: 'Malian, Malinese',
	},
	{
		num_code: '470',
		alpha_2_code: 'MT',
		alpha_3_code: 'MLT',
		label: 'Maltese',
		value: 'Maltese',
	},
	{
		num_code: '584',
		alpha_2_code: 'MH',
		alpha_3_code: 'MHL',
		label: 'Marshallese',
		value: 'Marshallese',
	},
	{
		num_code: '474',
		alpha_2_code: 'MQ',
		alpha_3_code: 'MTQ',
		label: 'Martiniquais, Martinican',
		value: 'Martiniquais, Martinican',
	},
	{
		num_code: '478',
		alpha_2_code: 'MR',
		alpha_3_code: 'MRT',
		label: 'Mauritanian',
		value: 'Mauritanian',
	},
	{
		num_code: '480',
		alpha_2_code: 'MU',
		alpha_3_code: 'MUS',
		label: 'Mauritian',
		value: 'Mauritian',
	},
	{
		num_code: '175',
		alpha_2_code: 'YT',
		alpha_3_code: 'MYT',
		label: 'Mahoran',
		value: 'Mahoran',
	},
	{
		num_code: '484',
		alpha_2_code: 'MX',
		alpha_3_code: 'MEX',
		label: 'Mexican',
		value: 'Mexican',
	},
	{
		num_code: '583',
		alpha_2_code: 'FM',
		alpha_3_code: 'FSM',
		label: 'Micronesian',
		value: 'Micronesian',
	},
	{
		num_code: '498',
		alpha_2_code: 'MD',
		alpha_3_code: 'MDA',
		label: 'Moldovan',
		value: 'Moldovan',
	},
	{
		num_code: '492',
		alpha_2_code: 'MC',
		alpha_3_code: 'MCO',
		label: 'Mon\u00e9gasque, Monacan',
		value: 'Mon\u00e9gasque, Monacan',
	},
	{
		num_code: '496',
		alpha_2_code: 'MN',
		alpha_3_code: 'MNG',
		label: 'Mongolian',
		value: 'Mongolian',
	},
	{
		num_code: '499',
		alpha_2_code: 'ME',
		alpha_3_code: 'MNE',
		label: 'Montenegrin',
		value: 'Montenegrin',
	},
	{
		num_code: '500',
		alpha_2_code: 'MS',
		alpha_3_code: 'MSR',
		label: 'Montserratian',
		value: 'Montserratian',
	},
	{
		num_code: '504',
		alpha_2_code: 'MA',
		alpha_3_code: 'MAR',
		label: 'Moroccan',
		value: 'Moroccan',
	},
	{
		num_code: '508',
		alpha_2_code: 'MZ',
		alpha_3_code: 'MOZ',
		label: 'Mozambican',
		value: 'Mozambican',
	},
	{
		num_code: '104',
		alpha_2_code: 'MM',
		alpha_3_code: 'MMR',
		label: 'Burmese',
		value: 'Burmese',
	},
	{
		num_code: '516',
		alpha_2_code: 'NA',
		alpha_3_code: 'NAM',
		label: 'Namibian',
		value: 'Namibian',
	},
	{
		num_code: '520',
		alpha_2_code: 'NR',
		alpha_3_code: 'NRU',
		label: 'Nauruan',
		value: 'Nauruan',
	},
	{
		num_code: '524',
		alpha_2_code: 'NP',
		alpha_3_code: 'NPL',
		label: 'Nepali, Nepalese',
		value: 'Nepali, Nepalese',
	},
	{
		num_code: '528',
		alpha_2_code: 'NL',
		alpha_3_code: 'NLD',
		label: 'Dutch, Netherlandic',
		value: 'Dutch, Netherlandic',
	},
	{
		num_code: '540',
		alpha_2_code: 'NC',
		alpha_3_code: 'NCL',
		label: 'New Caledonian',
		value: 'New Caledonian',
	},
	{
		num_code: '554',
		alpha_2_code: 'NZ',
		alpha_3_code: 'NZL',
		label: 'New Zealand, NZ',
		value: 'New Zealand, NZ',
	},
	{
		num_code: '558',
		alpha_2_code: 'NI',
		alpha_3_code: 'NIC',
		label: 'Nicaraguan',
		value: 'Nicaraguan',
	},
	{
		num_code: '562',
		alpha_2_code: 'NE',
		alpha_3_code: 'NER',
		label: 'Nigerien',
		value: 'Nigerien',
	},
	{
		num_code: '566',
		alpha_2_code: 'NG',
		alpha_3_code: 'NGA',
		label: 'Nigerian',
		value: 'Nigerian',
	},
	{
		num_code: '570',
		alpha_2_code: 'NU',
		alpha_3_code: 'NIU',
		label: 'Niuean',
		value: 'Niuean',
	},
	{
		num_code: '574',
		alpha_2_code: 'NF',
		alpha_3_code: 'NFK',
		label: 'Norfolk Island',
		value: 'Norfolk Island',
	},
	{
		num_code: '580',
		alpha_2_code: 'MP',
		alpha_3_code: 'MNP',
		label: 'Northern Marianan',
		value: 'Northern Marianan',
	},
	{
		num_code: '578',
		alpha_2_code: 'NO',
		alpha_3_code: 'NOR',
		label: 'Norwegian',
		value: 'Norwegian',
	},
	{
		num_code: '512',
		alpha_2_code: 'OM',
		alpha_3_code: 'OMN',
		label: 'Omani',
		value: 'Omani',
	},
	{
		num_code: '586',
		alpha_2_code: 'PK',
		alpha_3_code: 'PAK',
		label: 'Pakistani',
		value: 'Pakistani',
	},
	{
		num_code: '585',
		alpha_2_code: 'PW',
		alpha_3_code: 'PLW',
		label: 'Palauan',
		value: 'Palauan',
	},
	{
		num_code: '275',
		alpha_2_code: 'PS',
		alpha_3_code: 'PSE',
		label: 'Palestinian',
		value: 'Palestinian',
	},
	{
		num_code: '591',
		alpha_2_code: 'PA',
		alpha_3_code: 'PAN',
		label: 'Panamanian',
		value: 'Panamanian',
	},
	{
		num_code: '598',
		alpha_2_code: 'PG',
		alpha_3_code: 'PNG',
		label: 'Papua New Guinean, Papuan',
		value: 'Papua New Guinean, Papuan',
	},
	{
		num_code: '600',
		alpha_2_code: 'PY',
		alpha_3_code: 'PRY',
		label: 'Paraguayan',
		value: 'Paraguayan',
	},
	{
		num_code: '604',
		alpha_2_code: 'PE',
		alpha_3_code: 'PER',
		label: 'Peruvian',
		value: 'Peruvian',
	},
	{
		num_code: '608',
		alpha_2_code: 'PH',
		alpha_3_code: 'PHL',
		label: 'Philippine, Filipino',
		value: 'Philippine, Filipino',
	},
	{
		num_code: '612',
		alpha_2_code: 'PN',
		alpha_3_code: 'PCN',
		label: 'Pitcairn Island',
		value: 'Pitcairn Island',
	},
	{
		num_code: '616',
		alpha_2_code: 'PL',
		alpha_3_code: 'POL',
		label: 'Polish',
		value: 'Polish',
	},
	{
		num_code: '620',
		alpha_2_code: 'PT',
		alpha_3_code: 'PRT',
		label: 'Portuguese',
		value: 'Portuguese',
	},
	{
		num_code: '630',
		alpha_2_code: 'PR',
		alpha_3_code: 'PRI',
		label: 'Puerto Rican',
		value: 'Puerto Rican',
	},
	{
		num_code: '634',
		alpha_2_code: 'QA',
		alpha_3_code: 'QAT',
		label: 'Qatari',
		value: 'Qatari',
	},
	{
		num_code: '638',
		alpha_2_code: 'RE',
		alpha_3_code: 'REU',
		label: 'R\u00e9unionese, R\u00e9unionnais',
		value: 'R\u00e9unionese, R\u00e9unionnais',
	},
	{
		num_code: '642',
		alpha_2_code: 'RO',
		alpha_3_code: 'ROU',
		label: 'Romanian',
		value: 'Romanian',
	},
	{
		num_code: '643',
		alpha_2_code: 'RU',
		alpha_3_code: 'RUS',
		label: 'Russian',
		value: 'Russian',
	},
	{
		num_code: '646',
		alpha_2_code: 'RW',
		alpha_3_code: 'RWA',
		label: 'Rwandan',
		value: 'Rwandan',
	},
	{
		num_code: '652',
		alpha_2_code: 'BL',
		alpha_3_code: 'BLM',
		label: 'Barth\u00e9lemois',
		value: 'Barth\u00e9lemois',
	},
	{
		num_code: '654',
		alpha_2_code: 'SH',
		alpha_3_code: 'SHN',
		label: 'Saint Helenian',
		value: 'Saint Helenian',
	},
	{
		num_code: '659',
		alpha_2_code: 'KN',
		alpha_3_code: 'KNA',
		label: 'Kittitian or Nevisian',
		value: 'Kittitian or Nevisian',
	},
	{
		num_code: '662',
		alpha_2_code: 'LC',
		alpha_3_code: 'LCA',
		label: 'Saint Lucian',
		value: 'Saint Lucian',
	},
	{
		num_code: '663',
		alpha_2_code: 'MF',
		alpha_3_code: 'MAF',
		label: 'Saint-Martinoise',
		value: 'Saint-Martinoise',
	},
	{
		num_code: '666',
		alpha_2_code: 'PM',
		alpha_3_code: 'SPM',
		label: 'Saint-Pierrais or Miquelonnais',
		value: 'Saint-Pierrais or Miquelonnais',
	},
	{
		num_code: '670',
		alpha_2_code: 'VC',
		alpha_3_code: 'VCT',
		label: 'Saint Vincentian, Vincentian',
		value: 'Saint Vincentian, Vincentian',
	},
	{
		num_code: '882',
		alpha_2_code: 'WS',
		alpha_3_code: 'WSM',
		label: 'Samoan',
		value: 'Samoan',
	},
	{
		num_code: '674',
		alpha_2_code: 'SM',
		alpha_3_code: 'SMR',
		label: 'Sammarinese',
		value: 'Sammarinese',
	},
	{
		num_code: '678',
		alpha_2_code: 'ST',
		alpha_3_code: 'STP',
		label: 'S\u00e3o Tom\u00e9an',
		value: 'S\u00e3o Tom\u00e9an',
	},
	{
		num_code: '682',
		alpha_2_code: 'SA',
		alpha_3_code: 'SAU',
		label: 'Saudi, Saudi Arabian',
		value: 'Saudi, Saudi Arabian',
	},
	{
		num_code: '686',
		alpha_2_code: 'SN',
		alpha_3_code: 'SEN',
		label: 'Senegalese',
		value: 'Senegalese',
	},
	{
		num_code: '688',
		alpha_2_code: 'RS',
		alpha_3_code: 'SRB',
		label: 'Serbian',
		value: 'Serbian',
	},
	{
		num_code: '690',
		alpha_2_code: 'SC',
		alpha_3_code: 'SYC',
		label: 'Seychellois',
		value: 'Seychellois',
	},
	{
		num_code: '694',
		alpha_2_code: 'SL',
		alpha_3_code: 'SLE',
		label: 'Sierra Leonean',
		value: 'Sierra Leonean',
	},
	{
		num_code: '702',
		alpha_2_code: 'SG',
		alpha_3_code: 'SGP',
		label: 'Singaporean',
		value: 'Singaporean',
	},
	{
		num_code: '534',
		alpha_2_code: 'SX',
		alpha_3_code: 'SXM',
		label: 'Sint Maarten',
		value: 'Sint Maarten',
	},
	{
		num_code: '703',
		alpha_2_code: 'SK',
		alpha_3_code: 'SVK',
		label: 'Slovak',
		value: 'Slovak',
	},
	{
		num_code: '705',
		alpha_2_code: 'SI',
		alpha_3_code: 'SVN',
		label: 'Slovenian, Slovene',
		value: 'Slovenian, Slovene',
	},
	{
		num_code: '90',
		alpha_2_code: 'SB',
		alpha_3_code: 'SLB',
		label: 'Solomon Island',
		value: 'Solomon Island',
	},
	{
		num_code: '706',
		alpha_2_code: 'SO',
		alpha_3_code: 'SOM',
		label: 'Somali, Somalian',
		value: 'Somali, Somalian',
	},
	{
		num_code: '710',
		alpha_2_code: 'ZA',
		alpha_3_code: 'ZAF',
		label: 'South African',
		value: 'South African',
	},
	{
		num_code: '239',
		alpha_2_code: 'GS',
		alpha_3_code: 'SGS',
		label: 'South Georgia or South Sandwich Islands',
		value: 'South Georgia or South Sandwich Islands',
	},
	{
		num_code: '728',
		alpha_2_code: 'SS',
		alpha_3_code: 'SSD',
		label: 'South Sudanese',
		value: 'South Sudanese',
	},
	{
		num_code: '724',
		alpha_2_code: 'ES',
		alpha_3_code: 'ESP',
		label: 'Spanish',
		value: 'Spanish',
	},
	{
		num_code: '144',
		alpha_2_code: 'LK',
		alpha_3_code: 'LKA',
		label: 'Sri Lankan',
		value: 'Sri Lankan',
	},
	{
		num_code: '729',
		alpha_2_code: 'SD',
		alpha_3_code: 'SDN',
		label: 'Sudanese',
		value: 'Sudanese',
	},
	{
		num_code: '740',
		alpha_2_code: 'SR',
		alpha_3_code: 'SUR',
		label: 'Surinamese',
		value: 'Surinamese',
	},
	{
		num_code: '744',
		alpha_2_code: 'SJ',
		alpha_3_code: 'SJM',
		label: 'Svalbard',
		value: 'Svalbard',
	},
	{
		num_code: '748',
		alpha_2_code: 'SZ',
		alpha_3_code: 'SWZ',
		label: 'Swazi',
		value: 'Swazi',
	},
	{
		num_code: '752',
		alpha_2_code: 'SE',
		alpha_3_code: 'SWE',
		label: 'Swedish',
		value: 'Swedish',
	},
	{
		num_code: '756',
		alpha_2_code: 'CH',
		alpha_3_code: 'CHE',
		label: 'Swiss',
		value: 'Swiss',
	},
	{
		num_code: '760',
		alpha_2_code: 'SY',
		alpha_3_code: 'SYR',
		label: 'Syrian',
		value: 'Syrian',
	},
	{
		num_code: '158',
		alpha_2_code: 'TW',
		alpha_3_code: 'TWN',
		label: 'Chinese, Taiwanese',
		value: 'Chinese, Taiwanese',
	},
	{
		num_code: '762',
		alpha_2_code: 'TJ',
		alpha_3_code: 'TJK',
		label: 'Tajikistani',
		value: 'Tajikistani',
	},
	{
		num_code: '834',
		alpha_2_code: 'TZ',
		alpha_3_code: 'TZA',
		label: 'Tanzanian',
		value: 'Tanzanian',
	},
	{
		num_code: '764',
		alpha_2_code: 'TH',
		alpha_3_code: 'THA',
		label: 'Thai',
		value: 'Thai',
	},
	{
		num_code: '626',
		alpha_2_code: 'TL',
		alpha_3_code: 'TLS',
		label: 'Timorese',
		value: 'Timorese',
	},
	{
		num_code: '768',
		alpha_2_code: 'TG',
		alpha_3_code: 'TGO',
		label: 'Togolese',
		value: 'Togolese',
	},
	{
		num_code: '772',
		alpha_2_code: 'TK',
		alpha_3_code: 'TKL',
		label: 'Tokelauan',
		value: 'Tokelauan',
	},
	{
		num_code: '776',
		alpha_2_code: 'TO',
		alpha_3_code: 'TON',
		label: 'Tongan',
		value: 'Tongan',
	},
	{
		num_code: '780',
		alpha_2_code: 'TT',
		alpha_3_code: 'TTO',
		label: 'Trinidadian or Tobagonian',
		value: 'Trinidadian or Tobagonian',
	},
	{
		num_code: '788',
		alpha_2_code: 'TN',
		alpha_3_code: 'TUN',
		label: 'Tunisian',
		value: 'Tunisian',
	},
	{
		num_code: '792',
		alpha_2_code: 'TR',
		alpha_3_code: 'TUR',
		label: 'Turkish',
		value: 'Turkish',
	},
	{
		num_code: '795',
		alpha_2_code: 'TM',
		alpha_3_code: 'TKM',
		label: 'Turkmen',
		value: 'Turkmen',
	},
	{
		num_code: '796',
		alpha_2_code: 'TC',
		alpha_3_code: 'TCA',
		label: 'Turks and Caicos Island',
		value: 'Turks and Caicos Island',
	},
	{
		num_code: '798',
		alpha_2_code: 'TV',
		alpha_3_code: 'TUV',
		label: 'Tuvaluan',
		value: 'Tuvaluan',
	},
	{
		num_code: '800',
		alpha_2_code: 'UG',
		alpha_3_code: 'UGA',
		label: 'Ugandan',
		value: 'Ugandan',
	},
	{
		num_code: '804',
		alpha_2_code: 'UA',
		alpha_3_code: 'UKR',
		label: 'Ukrainian',
		value: 'Ukrainian',
	},
	{
		num_code: '784',
		alpha_2_code: 'AE',
		alpha_3_code: 'ARE',
		label: 'Emirati, Emirian, Emiri',
		value: 'Emirati, Emirian, Emiri',
	},
	{
		num_code: '581',
		alpha_2_code: 'UM',
		alpha_3_code: 'UMI',
		label: 'American',
		value: 'American',
	},
	{
		num_code: '840',
		alpha_2_code: 'US',
		alpha_3_code: 'USA',
		label: 'American',
		value: 'American',
	},
	{
		num_code: '858',
		alpha_2_code: 'UY',
		alpha_3_code: 'URY',
		label: 'Uruguayan',
		value: 'Uruguayan',
	},
	{
		num_code: '860',
		alpha_2_code: 'UZ',
		alpha_3_code: 'UZB',
		label: 'Uzbekistani, Uzbek',
		value: 'Uzbekistani, Uzbek',
	},
	{
		num_code: '548',
		alpha_2_code: 'VU',
		alpha_3_code: 'VUT',
		label: 'Ni-Vanuatu, Vanuatuan',
		value: 'Ni-Vanuatu, Vanuatuan',
	},
	{
		num_code: '862',
		alpha_2_code: 'VE',
		alpha_3_code: 'VEN',
		label: 'Venezuelan',
		value: 'Venezuelan',
	},
	{
		num_code: '704',
		alpha_2_code: 'VN',
		alpha_3_code: 'VNM',
		label: 'Vietnamese',
		value: 'Vietnamese',
	},
	{
		num_code: '92',
		alpha_2_code: 'VG',
		alpha_3_code: 'VGB',
		label: 'British Virgin Island',
		value: 'British Virgin Island',
	},
	{
		num_code: '850',
		alpha_2_code: 'VI',
		alpha_3_code: 'VIR',
		label: 'U.S. Virgin Island',
		value: 'U.S. Virgin Island',
	},
	{
		num_code: '876',
		alpha_2_code: 'WF',
		alpha_3_code: 'WLF',
		label: 'Wallis and Futuna, Wallisian or Futunan',
		value: 'Wallis and Futuna, Wallisian or Futunan',
	},
	{
		num_code: '732',
		alpha_2_code: 'EH',
		alpha_3_code: 'ESH',
		label: 'Sahrawi, Sahrawian, Sahraouian',
		value: 'Sahrawi, Sahrawian, Sahraouian',
	},
	{
		num_code: '887',
		alpha_2_code: 'YE',
		alpha_3_code: 'YEM',
		label: 'Yemeni',
		value: 'Yemeni',
	},
	{
		num_code: '894',
		alpha_2_code: 'ZM',
		alpha_3_code: 'ZMB',
		label: 'Zambian',
		value: 'Zambian',
	},
	{
		num_code: '716',
		alpha_2_code: 'ZW',
		alpha_3_code: 'ZWE',
		label: 'Zimbabwean',
		value: 'Zimbabwean',
	},
];
