import {
	JourneyNavLine,
	CleanRadioInput,
	ProgressBar,
	Loader,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { PATCHRequest, readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';

const Financial7 = () => {
	const history = useHistory();
	const [isSaving, setIsSaving] = useState(false);
	const data = JSON.parse(readFromLocalStorage('_extra_income_radio_data'));

	const [{ user }, dispatch] = useDataLayerValue();
	const { financial } = user || {};
	const { application } = user?.cases?.[0] || {};

	const delay = 300;
	const funcRespondYes = (e: any) => {
		storeInLocalStorage('_extra_income_radio_data', e.target.value);
		setTimeout(() => {
			history.push('/user/financial/eight');
		}, delay);
	};

	const funcRespondNo = async (e: any) => {
		storeInLocalStorage('_extra_income_radio_data', e.target.value);
    const currFinancial = { ...financial };
    // when user selects no, we no longer need to keep the data
    delete currFinancial.otherIncome

    dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial: currFinancial },
		});

		setTimeout(async () => {
			if (application?.partner) {
				history.push('/user/financial/second-applicant/seven');
			} else {
				setIsSaving(true);
				await PATCHRequest(
					`/users?attribute=financial`,
					currFinancial,
					history
				);
				setIsSaving(false);
				history.push('/user/stages');
			}
		}, delay);
	};

	if (isSaving) return <Loader text='Saving...' />

	return (
		<div className='financial-pages'>
			<ProgressBar progress={60} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>Do you have any additional income?</H4>

				<div className='personal-details-body'>
					<form
						className='central-form-group'
						onSubmit={(e) => e.preventDefault()}
					>
						<CleanRadioInput
							name={'extra-income'}
							label={'Yes'}
							value={'yes'}
							onChange={funcRespondYes}
							checked={data === 'yes'}
						/>

						<CleanRadioInput
							name={'extra-income'}
							label={'No'}
							value={'no'}
							onChange={funcRespondNo}
							checked={data === 'no'}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Financial7;
