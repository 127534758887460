import { Loader } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useHistory } from 'react-router-dom';
import { storeInLocalStorage } from 'utils/AuthFunctions';
import {
	MobileOfferCard,
	ModalOffersDetails,
	TableHeader,
	TableRowData,
} from '.';

const Table = () => {
	const [{ showOffersModal, showOfferTable, offersTableList }] =
		useDataLayerValue();
	const history = useHistory();

	if (offersTableList && offersTableList.length < 1) {
		history.push('/user/offers');
		storeInLocalStorage('_FOfferStep', 0);
		storeInLocalStorage('_FProgress', 0);
	}
	return (
		<>
			{!showOfferTable ? (
				<Loader />
			) : (
				<>
					<div className='mobile-offers-holder'>
						{offersTableList.slice(0, 10).map((offer: any, index: any) => {
							return (
								<MobileOfferCard
									key={index}
									isBestOffer={index === 0 && true}
									lenderIcon={offer.imageCode}
									lenderName={offer.name}
									offerId={offer.productId}
									initialRate={`${offer.initialRate}%`}
									APR={`${offer.apr}%`}
									monthlyRepayment={`£${offer.monthlyPayment.toLocaleString()}`}
								/>
							);
						})}
					</div>
					<div className='offers-table-holder'>
						<table>
							<thead>
								<TableHeader />
							</thead>

							<tbody>
								{offersTableList.slice(0, 10).map((offer: any, index: any) => {
									return (
										<TableRowData
											key={index}
											isBestOffer={index === 0 && true}
											offerId={offer.productId}
											lenderIcon={offer.imageCode}
											initialRate={`${offer.initialRate}%`}
											APR={`${offer.apr}%`}
											monthlyRepayment={`£${offer.monthlyPayment.toLocaleString()}`}
											totalAmountPayable={`£${offer.totalRepayable.toLocaleString()}`}
										/>
									);
								})}
							</tbody>
						</table>
						<br />

						{showOffersModal && (
							<ModalOffersDetails
								isOpenModal={showOffersModal}
								lenderName={'HSBC'}
							/>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default Table;
