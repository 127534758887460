import { Bold } from 'typography';

interface ILangTab {
  abbrev: string;
  flag: any;
  funcClick?: any;
  text: string;
}

const LangOption = (props: ILangTab) => {
  return (
    <div
      onClick={() => props.funcClick && props.funcClick(props.abbrev)}
      className="flex lang-option"
    >
      <div className="lang-flag">
        <img src={props.flag} alt="language" />
      </div>
      <div className="lang-text">
        <Bold isBold={false} opacity={0.5}>
          {props.text}
        </Bold>
      </div>
    </div>
  );
};

export default LangOption;
