import {
	BorderButton,
	CleanInput,
	EnhancedPostCodeInput,
	Input,
	JourneyNavLine,
	ProgressBar,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const CurrentEmploymentPage2 = () => {
	const history = useHistory();

	const [{ user }, dispatch] = useDataLayerValue();
	const { financial } = user || {};
	const { application } = user?.cases?.[0] || {};

	const [isComplete, setIsComplete] = useState(false);
	const [textInfo, setTextInfo] = useState({
		annualBonuses: financial?.annualBonuses,
		additionalAnnual: financial?.additionalAnnual,
		employerPostCode: financial?.employerPostCode,
		employerAddress: financial?.employerAddress,
		employerStreet: financial?.employerStreet,
		employerCity: financial?.employerCity,
		homeNumber: financial?.homeNumber,
	});

	const [annualSalary, setSalary] = useState(financial?.annualSalary);

	useEffect(() => {
		if (annualSalary >= 0 // &&
			// textInfo['employerPostCode'] !== undefined &&
			// textInfo['employerAddress'] !== undefined &&
			// textInfo['employerCity'] !== undefined
		) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [annualSalary]);

	const onSubmit = (e) => {
		e?.preventDefault?.();

		const currFinancial = {
			...financial,
			...textInfo,
			annualSalary,
		};

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial: currFinancial },
		});

		if (application?.partner) {
			history.push('/user/financial/second-applicant-experian');
		} else {
			history.push('/user/financial/seven');
		}
	};

	return (
		<div className='financial-pages'>
			<ProgressBar progress={20} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>Employment details</H4>
				<form onSubmit={onSubmit}>
					<div className='central-form-group'>
						<Input
							value={annualSalary}
							label='Basic salary per annum'
							type='number'
							numberInput
							currency
							placeholder='text'
							required
							onChange={(value: any) => {
								setSalary(value);
							}}
						/>

						<Input
							value={textInfo['annualBonuses'] || ''}
							label='Guaranteed additional p.a. (bonus, London weighting, etc.)'
							type='number'
							numberInput
							currency
							placeholder='text'
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									annualBonuses: value,
								}))
							}
						/>

						<Input
							value={textInfo['additionalAnnual'] || ''}
							label='Regular additional p.a. (overtime, etc.)'
							type='number'
							numberInput
							currency
							placeholder='text'
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									additionalAnnual: value,
								}))
							}
						/>
						{user.userStatus === 'Journey completed' ?
							<>
								<Input
									value={textInfo.homeNumber}
									normalInput
									label='Home number'
									name='homeNumber'
									type='text'
									placeholder='text'
									onChange={(e) => {
										setTextInfo((prev) => ({
											...prev,
											postCode: e.target.value,
										}));
									}}
									required
								/>

								<EnhancedPostCodeInput
									value={textInfo['employerPostCode'] || ''}
									label='Postcode'
									onChange={(e: any) =>
										setTextInfo((prev) => ({
											...prev,
											employerPostCode: e.target.value,
										}))
									}
									onAddressUpdate={e => {
										textInfo.employerCity = e.city;
										textInfo.employerAddress = e.address;
									}}
								/>

								<CleanInput
									value={textInfo['employerAddress'] || ''}
									label='Current employer address line 1'
									type='text'
									placeholder='text'
									onChange={(e: any) =>
										setTextInfo((prev) => ({
											...prev,
											employerAddress: e.target.value,
										}))
									}
								/>

								<CleanInput
									value={textInfo['employerStreet'] || ''}
									label='Current employer address line 2 ( optional )'
									type='text'
									placeholder='text'
									onChange={(e: any) =>
										setTextInfo((prev) => ({
											...prev,
											employerStreet: e.target.value,
										}))
									}
								/>

								<CleanInput
									value={textInfo['employerCity'] || ''}
									label='City'
									type='text'
									placeholder='text'
									onChange={(e: any) =>
										setTextInfo((prev) => ({
											...prev,
											employerCity: e.target.value,
										}))
									}
								/>
							</> : <></>
						}
						<div className={`continue-btn`}>
							<BorderButton
								value='Continue'
								type='submit'
								disabled={!isComplete}
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								color='#fff'
							/>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default CurrentEmploymentPage2;
