import { FormatDate } from 'utils/GeneralFunctions';
import { AccordionContentItem, AccordionInfoBox, AccordionTitle } from '.';
import bankBlueIcon from 'assets/bank-blue.svg';

interface OtherIncomeProps {
	source?: string;
	amount?: string;
}

interface FinancialProps {
	insuranceNumber?: string;
	employmentStatus?: string;
	employmentBasis?: string;
	employerName?: string;
	jobTitle?: string;
	employmentStartDate?: string;
	plannedRetirement?: string;
	annualSalary?: string;
	annualBonuses?: string;
	additionalAnnual?: string;
	employerPostCode?: string;
	employerAddress?: string;
	employerStreet?: string;
	employerCity?: string;
	selfEmployStatus?: string;
	soleEmployPercent?: string;
	selfEmployAccountYears?: string;
	selfEmployNetProfitLastYear?: string;
	selfEmployNetProfitPreviousYear?: string;
	otherIncome: OtherIncomeProps[];
}

interface OtherLiabilities {
	type?: string;
	total?: number;
	monthly?: number;
	endDate?: string;
}

interface LiabilitiesProps {
	isCurrentMortgage?: boolean;
	currentMortgageName?: string;
	mortgageLender?: string;
	propertySalePrice?: number;
	currentMortgageCountry?: string;
	propertyPostCode?: string;
	propertyAddress?: string;
	propertyAddress2?: string;
	propertyStreet?: string;
	propertyCity?: string;
	outstandingLoanAmount?: number;
	loanRemainingYears?: number;
	mortgageInterestRate?: number;
	isBuyToLet?: boolean;
	mortgageMonthlyAmount?: number;
	mortgageAccountNumber?: number;
	isEarlyPaymentPenalty?: boolean;
	isPreparedForPenalty;
	penaltyAmount?: number;
	currentRentAmount?: number;
	others?: Array<OtherLiabilities>;
}

interface ExpensesProps {
	seasonTicket?: number;
	fuelSpending?: number;
	roadTax?: number;
	insuranceAmount?: number;
	parkingFee?: number;
	otherTransportSpend?: number;
	heatingBill?: number;
	electricityBill?: number;
	waterBill?: number;
	phoneBill?: number;
	tvBill?: number;
	tvLicense?: number;
	councilTax?: number;
	groundRent?: number;
	serviceCharge?: number;
	otherSpending?: number;
	foodCost?: number;
	personalGoods?: number;
	clothing?: number;
	householdSpending?: number;
	mobilePhoneBill?: number;
	schoolingFees?: number;
	repairSpending?: number;
	holidaySpending?: number;
	otherLivingCost?: number;
}

interface ApplicationProps {
	firstName?: string;
	lastName?: string;
	birthDate?: string;
	title?: string;
	birthday?: string;
	isLookingTo?: string;
}

interface IFinancialAccordion {
	name: string;
	isSecondApplicant?: boolean;
	progress: string;
	financial: FinancialProps;
	application: ApplicationProps;
	liabilities: LiabilitiesProps;
	expenses: ExpensesProps;
	isOpen: boolean;
	handleToggle: any;
}

const FinancialAccordion = (props: IFinancialAccordion) => {
	const { isSecondApplicant, financial, liabilities, expenses, progress, application } =
		props;

	return (
		<AccordionInfoBox
			icon={bankBlueIcon}
			title={isSecondApplicant ? 'Financial 2nd' : 'Financial'}
			status={Number(progress) >= 99}
			name={props.name}
			isOpen={props.isOpen}
			handleToggle={props.handleToggle}
		>
			<div>
				<AccordionContentItem
					question={'National insurance number'}
					response={financial?.insuranceNumber || '-'}
				/>

				<AccordionContentItem
					question={'Employment status'}
					response={financial?.employmentStatus || '-'}
				/>
			</div>

			{financial?.employmentStatus === 'Employed' ? (
				<div>
					<AccordionTitle title={'Employed Options'} addSpacing />

					<AccordionContentItem
						question={'Basis of the employment'}
						response={financial?.employmentBasis || '-'}
					/>

					<AccordionContentItem
						question={'Name of the employer'}
						response={financial?.employerName || '-'}
					/>

					<AccordionContentItem
						question={'Job title'}
						response={financial?.jobTitle || '-'}
					/>

					<AccordionContentItem
						question={'Employment start date'}
						response={
							financial?.employmentStartDate
								? FormatDate(financial?.employmentStartDate)
								: '-'
						}
					/>

					<AccordionContentItem
						question={'What age are you planing to retire?'}
						response={financial?.plannedRetirement || '-'}
					/>

					<AccordionContentItem
						question={'Basic salary per annum'}
						response={
							financial?.annualSalary
								? `£${financial?.annualSalary?.toLocaleString()}`
								: '-'
						}
					/>

					<AccordionContentItem
						question={
							'Guaranteed additional p.a. (Bonus, London weighting, etc.)'
						}
						response={
							financial?.annualBonuses
								? `£${financial?.annualBonuses?.toLocaleString()}`
								: '-'
						}
					/>

					<AccordionContentItem
						question={'Regular additional p.a. (overtime, etc.)'}
						response={
							financial?.additionalAnnual
								? `£${financial?.additionalAnnual?.toLocaleString()}`
								: '-'
						}
					/>

					<AccordionContentItem
						question={'Postcode'}
						response={financial?.employerPostCode || '-'}
					/>

					<AccordionContentItem
						question={'Current employer address line 1'}
						response={financial?.employerAddress || '-'}
					/>

					<AccordionContentItem
						question={'Current employer address line 2'}
						response={financial?.employerStreet || '-'}
					/>

					<AccordionContentItem
						question={'City'}
						response={financial?.employerCity || '-'}
					/>
				</div>
			) : (
				<div>
					<AccordionTitle title={'Self-employed Options'} addSpacing />

					<AccordionContentItem
						question={'Self-employment status'}
						response={financial?.selfEmployStatus || '-'}
					/>

					<AccordionContentItem
						question={'% owned (where relevant)'}
						response={
							financial?.soleEmployPercent
								? `${financial?.soleEmployPercent}%`
								: '-'
						}
					/>

					<AccordionContentItem
						question={'Last financial year end date'}
						response={
							financial?.selfEmployAccountYears
								? `${financial?.selfEmployAccountYears} years`
								: '-'
						}
					/>

					<AccordionContentItem
						question={'What age are you planing to retire?'}
						response={financial?.plannedRetirement || '-'}
					/>

					<AccordionContentItem
						question={'Net profit last year (Turnover minus costs)'}
						response={
							financial?.selfEmployNetProfitLastYear
								? `£${financial?.selfEmployNetProfitLastYear?.toLocaleString()}`
								: '-'
						}
					/>

					<AccordionContentItem
						question={'Net profit previous year'}
						response={
							financial?.selfEmployNetProfitPreviousYear
								? financial?.selfEmployNetProfitPreviousYear
								: '-'
						}
					/>
				</div>
			)}

			{financial?.otherIncome && (
				<>
					<AccordionTitle title={'Additional Income'} addSpacing />

					<AccordionContentItem
						question={'Do you have any additional income ?'}
						response={financial?.otherIncome?.length > 0 ? 'Yes' : 'No'}
					/>

					{financial?.otherIncome?.map((income, id) => {
						return (
							<div key={id}>
								<AccordionContentItem
									question={'Please specify the source'}
									response={income?.source || '-'}
								/>

								<AccordionContentItem
									question={'Income amount'}
									response={
										income?.amount
											? `£${income?.amount?.toLocaleString()}`
											: '-'
									}
								/>
							</div>
						);
					})}
				</>
			)}

			<AccordionTitle title={'Current Mortgage/Rent'} addSpacing />

			<AccordionContentItem
				question={'Do you have any other mortgage?'}
				response={application?.isLookingTo === 'remortgage' ? 'Yes' : 'No'}
			/>

			<AccordionContentItem
				question={'Who is named on the mortgage?'}
				response={liabilities?.currentMortgageName || '-'}
			/>

			<AccordionContentItem
				question={'Lender Name'}
				response={liabilities?.mortgageLender || '-'}
			/>

			<AccordionContentItem
				question={'Property Value'}
				response={liabilities?.propertySalePrice || '-'}
			/>

			<AccordionContentItem
				question={'Country'}
				response={liabilities?.currentMortgageCountry || '-'}
			/>

			<AccordionContentItem
				question={'Postcode'}
				response={liabilities?.propertyPostCode || '-'}
			/>

			<AccordionContentItem
				question={'Address line 1'}
				response={liabilities?.propertyAddress || '-'}
			/>

			<AccordionContentItem
				question={'Address line 2'}
				response={liabilities?.propertyAddress2 || '-'}
			/>

			<AccordionContentItem
				question={'Street'}
				response={liabilities?.propertyStreet || '-'}
			/>

			<AccordionContentItem
				question={'City'}
				response={liabilities?.propertyCity || '-'}
			/>

			<AccordionContentItem
				question={'What is your outstanding loan amount?'}
				response={liabilities?.outstandingLoanAmount || '-'}
			/>

			<AccordionContentItem
				question={'How many years remaining?'}
				response={
					liabilities?.loanRemainingYears
						? `${liabilities?.loanRemainingYears} years`
						: '-'
				}
			/>

			<AccordionContentItem
				question={'Current interest rate?'}
				response={
					liabilities?.mortgageInterestRate
						? `${liabilities?.mortgageInterestRate}%`
						: '-'
				}
			/>

			<AccordionContentItem
				question={'Buy to let?'}
				response={
					liabilities?.isBuyToLet
						? Boolean(liabilities?.isBuyToLet) === true
							? 'Yes'
							: 'No'
						: '-'
				}
			/>

			<AccordionContentItem
				question={'What is your monthly mortgage payment?'}
				response={
					liabilities?.mortgageMonthlyAmount
						? `£${liabilities?.mortgageMonthlyAmount?.toLocaleString()}`
						: '-'
				}
			/>

			<AccordionContentItem
				question={'Account number'}
				response={liabilities?.mortgageAccountNumber || '-'}
			/>

			<AccordionContentItem
				question={
					'Are there any penalties if you transfer or repay your existing mortgage now?'
				}
				response={
					liabilities?.isEarlyPaymentPenalty
						? liabilities?.isPreparedForPenalty === true
							? 'Yes'
							: 'No'
						: '-'
				}
			/>

			<AccordionContentItem
				question={'How much is the penalty (ERC)?'}
				response={
					liabilities?.penaltyAmount
						? `£${liabilities?.penaltyAmount?.toLocaleString()}`
						: '-'
				}
			/>

			<AccordionContentItem
				question={
					'Are you prepared to pay these if you transfer/repay your current mortgage?'
				}
				response={
					liabilities?.isPreparedForPenalty
						? liabilities?.isPreparedForPenalty === true
							? 'Yes'
							: 'No'
						: '-'
				}
			/>

			<AccordionContentItem
				question={'What is your current rent?'}
				response={
					liabilities?.currentRentAmount
						? `£${liabilities?.currentRentAmount?.toLocaleString()}`
						: '-'
				}
			/>

			<AccordionContentItem
				question={'Would you like to add any other financial commitments?'}
				response={
					liabilities?.others
						? liabilities?.others?.length > 0
							? 'Yes'
							: 'No'
						: '-'
				}
			/>

			{liabilities?.others?.length > 0 && (
				<>
					{liabilities?.others?.map((commitment, id) => {
						return (
							<div key={id}>
								<AccordionContentItem
									question={'Please specify'}
									response={commitment?.type || '-'}
								/>

								<AccordionContentItem
									question={'Total outstanding amount'}
									response={
										commitment?.total
											? `£${commitment?.total?.toLocaleString()}`
											: '-'
									}
								/>

								<AccordionContentItem
									question={'Monthly payment amount'}
									response={
										commitment?.monthly
											? `£${commitment?.monthly?.toLocaleString()}`
											: '-'
									}
								/>

								<AccordionContentItem
									question={'End date'}
									response={
										commitment?.endDate ? FormatDate(commitment?.endDate) : '-'
									}
								/>

								<AccordionContentItem
									question={'Total outstanding amount'}
									response={
										commitment?.total
											? `£${commitment?.total?.toLocaleString()}`
											: '-'
									}
								/>
							</div>
						);
					})}
				</>
			)}

			<AccordionTitle title={'Travel Expenses'} addSpacing />
			<div>
				<AccordionContentItem
					question={'Fares ( travel card/season ticket)'}
					response={
						expenses?.seasonTicket
							? `£${expenses?.seasonTicket?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Fuel'}
					response={
						expenses?.fuelSpending
							? `£${expenses?.fuelSpending?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Road Tax'}
					response={
						expenses?.roadTax ? `£${expenses?.roadTax?.toLocaleString()}` : '-'
					}
				/>

				<AccordionContentItem
					question={'Car Insurance'}
					response={
						expenses?.insuranceAmount
							? `£${expenses?.insuranceAmount?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Parking'}
					response={
						expenses?.parkingFee
							? `£${expenses?.parkingFee?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Other transports spend'}
					response={
						expenses?.otherTransportSpend
							? `£${expenses?.otherTransportSpend?.toLocaleString()}`
							: '-'
					}
				/>
			</div>

			<AccordionTitle title={'Utility Bills'} addSpacing />
			<div>
				<AccordionContentItem
					question={'Gas/Heating'}
					response={
						expenses?.heatingBill
							? `£${expenses?.heatingBill?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Electricity'}
					response={
						expenses?.electricityBill
							? `£${expenses?.electricityBill?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Water'}
					response={
						expenses?.waterBill
							? `£${expenses?.waterBill?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Telephone/Broadband'}
					response={
						expenses?.phoneBill
							? `£${expenses?.phoneBill?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Digital/Cable TV'}
					response={
						expenses?.tvBill ? `£${expenses?.tvBill?.toLocaleString()}` : '-'
					}
				/>

				<AccordionContentItem
					question={'TV License'}
					response={
						expenses?.tvLicense
							? `£${expenses?.tvLicense?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Council Tax'}
					response={
						expenses?.councilTax
							? `£${expenses?.councilTax?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Ground Rent'}
					response={
						expenses?.groundRent
							? `£${expenses?.groundRent?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Service Charge'}
					response={
						expenses?.serviceCharge
							? `£${expenses?.serviceCharge?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Other Total'}
					response={
						expenses?.otherSpending
							? `£${expenses?.otherSpending?.toLocaleString()}`
							: '-'
					}
				/>
			</div>

			<AccordionTitle title={'General Living Expenses'} addSpacing />
			<div>
				<AccordionContentItem
					question={'Food/Washing'}
					response={
						expenses?.foodCost
							? `£${expenses?.foodCost?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Personal goods'}
					response={
						expenses?.personalGoods
							? `£${expenses?.personalGoods?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Clothing '}
					response={
						expenses?.clothing
							? `£${expenses?.clothing?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Household goods'}
					response={
						expenses?.householdSpending
							? `£${expenses?.householdSpending?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Mobile phone'}
					response={
						expenses?.mobilePhoneBill
							? `£${expenses?.mobilePhoneBill?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'School/Childminding fees'}
					response={
						expenses?.schoolingFees
							? `£${expenses?.schoolingFees?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Repairs'}
					response={
						expenses?.repairSpending
							? `£${expenses?.repairSpending?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Holiday/Entertainment'}
					response={
						expenses?.holidaySpending
							? `£${expenses?.holidaySpending?.toLocaleString()}`
							: '-'
					}
				/>

				<AccordionContentItem
					question={'Other Total'}
					response={
						expenses?.otherLivingCost
							? `£${expenses?.otherLivingCost?.toLocaleString()}`
							: '-'
					}
				/>
			</div>
		</AccordionInfoBox>
	);
};

export default FinancialAccordion;
