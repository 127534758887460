import {
	BorderButton,
	CleanInput,
	JourneyNavLine,
	SelectInput,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';
import { FinancialStatusArray } from 'utils/FinancialData';

const EmploymentDetails = () => {
	const history = useHistory();
	const [{ user }, dispatch] = useDataLayerValue();
	const { financial } = user || {};
	const { application } = user?.cases?.[0] || {};

	const [selectVal, setSelectVal] = useState(financial?.employmentStatus);
	const [nationalInsurance, setNationalInsurance] = useState(
		financial?.insuranceNumber
	);
	const [disableButton, setDisableButton] = useState(true);

	useEffect(() => {
		const isStatusSelected = FinancialStatusArray.map((o) => o.value).includes(
			selectVal
		);
		// const insuranceSet = nationalInsurance?.length === 9;

		setDisableButton(isStatusSelected === false //|| insuranceSet === false
		);
	}, [selectVal, nationalInsurance]);

	const handleChangeEmploymentStatus = (value) => {
		setSelectVal(value);

		const currFinancial = { ...financial };
		// these are the fields we want to keep the same
		const specialFields = [
			'employmentStatus',
			'otherIncome',
			'insuranceNumber',
		];

		Object.keys(currFinancial).forEach((key) => {
			if (specialFields.includes(key)) return;

			delete currFinancial[key];
		});

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial: currFinancial },
		});
	};

	const funcSetDetailsPage1 = () => {
		const currFinancial = {
			...financial,
			...selectVal && { employmentStatus: selectVal },
			...nationalInsurance && { insuranceNumber: nationalInsurance }
		};

		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial: currFinancial },
		});

		switch (currFinancial.employmentStatus) {
			case 'Retired':
			case 'Not employed':
				application?.partner
					? history.push('/user/financial/second-applicant-experian')
					: history.push('/user/financial/seven');
				break;
			case 'Self employed':
				history.push(`/user/financial/self-employed`);
				break;
			default:
				history.push(`/user/financial/current-employment`);
				break;
		}
	};

	return (
		<div className='financial-pages'>
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>Employment details</H4>

				<form className='central-form-group'>
					<SelectInput
						name={'employment-details'}
						options={FinancialStatusArray}
						defaultValue={selectVal || null}
						label='Employment status'
						onSelect={handleChangeEmploymentStatus}
					/>
					{ user.userStatus === "Journey completed" ?
						<CleanInput
							value={nationalInsurance || ''}
							label='National insurance number'
							type='text'
							placeholder='text'
							required
							onChange={(e: any) => setNationalInsurance(e.target.value)}
						/> : <></> }

					<div className={`continue-btn ${disableButton ? 'isDisabled' : ''}`}>
						<BorderButton
							value='Continue'
							width='368px'
							height='56px'
							backgroundColor='#0968E3'
							color='#fff'
							onClick={funcSetDetailsPage1}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default EmploymentDetails;
