import { BorderButton, Input, JourneyNavLine, ProgressBar, SelectInput } from 'components/Widgets';
import { H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';

const Liabilities8 = () => {
	const history = useHistory();
	const [{ user }, dispatch] = useDataLayerValue();
	const {application} = user?.cases[0] || {}

	const [outstandingLoanAmount, setOutstandingLoanAmount] = useState(application?.loanAmount || user?.liabilities?.outstandingLoanAmount);
	const [loanRemainingYears, setLoanRemainingYears] = useState(application?.yearsLeftOnMortgage || user?.liabilities?.loanRemainingYears);
	const [mortgageInterestRate, setMortgageInterestRate] = useState(user?.liabilities?.mortgageInterestRate);
	const [mortgageMonthlyAmount, setMortgageMonthlyAmount] = useState(application?.monthlyMortgagePayment || user?.liabilities?.mortgageMonthlyAmount);
	const [mortgageAccountNumber, setMortgageAccountNumber] = useState(user?.liabilities?.mortgageAccountNumber);
	const [isBuyToLet, setBuyToLet] = useState<boolean>(user?.liabilities?.isBuyToLet);

	const [isComplete, setComplete] = useState(false);

	useEffect(() => {
		setComplete(outstandingLoanAmount > 0 && loanRemainingYears > 0 && mortgageMonthlyAmount > 0 && typeof isBuyToLet === 'boolean');
	}, [outstandingLoanAmount, loanRemainingYears, mortgageMonthlyAmount, isBuyToLet]);

	const onSubmit = (e) => {
		e.preventDefault();

		const currUser = { ...user };

		if (!currUser?.liabilities) {
			currUser.liabilities = {};
		}

		currUser.liabilities.outstandingLoanAmount = outstandingLoanAmount;
		currUser.liabilities.loanRemainingYears = loanRemainingYears;
		currUser.liabilities.mortgageInterestRate = mortgageInterestRate;
		currUser.liabilities.isBuyToLet = isBuyToLet;
		currUser.liabilities.mortgageMonthlyAmount = mortgageMonthlyAmount;
		currUser.liabilities.mortgageAccountNumber = mortgageAccountNumber;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push('/user/liabilities/liabilities-9');
	};

	const buyToLetOptions = [
		{ value: 'true', label: 'Yes' },
		{ value: 'false', label: 'No' },
	];

	return (
		<div className='personal-container'>
			<ProgressBar progress={56} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Current mortgage details</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={onSubmit}>
						<Input
							numberInput
							value={outstandingLoanAmount}
							type='number'
							currency
							min={1}
							label='What is your outstanding loan amount?'
							placeholder='What is your outstanding loan amount?'
							onChange={value => {
								setOutstandingLoanAmount(value);
							}}
						/>
						<Input
							normalInput
							value={loanRemainingYears || ''}
							type='number'
							label={'How many years remain?'}
							placeholder='How many years remain?'
							onChange={(e) => {
								if (e.target.value >= 0) {
									setLoanRemainingYears(Number(e.target.value));
								}
							}}
						/>
						<div className='mb'>
							<Input
								numberInput
								type="number"
								value={mortgageInterestRate}
								label='Current interest rate'
								placeholder='Current interest rate'
								onChange={e => {
									setMortgageInterestRate(e);
								}}
							/>
						</div>

						<SelectInput
							options={buyToLetOptions}
							onSelect={(value: string) =>
								setBuyToLet(Boolean(value))
							}
							label='Buy to let'
							defaultValue={String(isBuyToLet || '') || null}
						/>
						<Input
							numberInput
							currency
							value={mortgageMonthlyAmount}
							label='What is your monthly mortgage payment?'
							placeholder='What is your monthly mortgage payment?'
							onChange={(value) => {
								setMortgageMonthlyAmount(value);
							}}
						/>
						<Input
							normalInput
							value={mortgageAccountNumber || ''}
							label={'Account number'}
							placeholder='Account number'
							onChange={(e) =>
								setMortgageAccountNumber(e.target.value)
							}
						/>
						<div className='personal-details-btn '>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								disabled={!isComplete}
								color='#fff'
								marginBottom='15%'
								onClick={onSubmit}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Liabilities8;
