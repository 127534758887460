import clsx from 'clsx';
import ModalCustom from 'components/Widgets/ModalCustom';
import { useDataLayerValue } from 'context-api/DataLayer';
import { Bold, Caption, SmallBold } from 'typography';
import { randId } from 'utils/GeneralFunctions';
import uploadIcon from 'assets/upload_cloud_icon.svg';
import { BorderButton } from 'components/Widgets';
import { useState } from 'react';
import { actionTypes } from 'context-api/actionTypes';
import { GETRequest, POSTRequest } from 'utils/AuthFunctions';
import { useHistory } from 'react-router-dom';
import { DocType } from './index';

interface IModalDocUpload {
	docType?: DocType;
	isUpload?: boolean;
	closeModal: (arg0: any) => any;
	visible: boolean;
}

const ModalDocUpload = (props: IModalDocUpload) => {
	const generatedId = `btn-${randId()}`;
	const [{ alert }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [error, setError] = useState('');
	const [isUploading, setIsUploading] = useState(false);

	//FIXME add DocType as key
	const successMsg = {
		[DocType.MAIN_ID]: 'Uploaded Your ID successfully',
		[DocType.MAIN_PAYSLIPS]: 'Proof of Income uploaded',
		[DocType.MAIN_BANK_STATEMENTS]: 'Bank Statements uploaded successfully',
		[DocType.MAIN_DEPOSIT]: 'Proof of Deposit uploaded',
		[DocType.MAIN_ADDRESS]: 'Proof of Address (utility bill) uploaded successfully',
	};

	const handleDocUpload = async (e) => {

		for (let filesKey of e.target.files) {
			const size = parseFloat(Math.abs(filesKey.size / 1024 / 1024).toFixed(3));
			if (size >= 10) {
				setError('Only files less than 10MB can be uploaded, contact us if you need to upload bigger files.');
				setIsUploading(false);
				return;
			}
		}

		setIsUploading(true);
		//get the signed upload links
		//@ts-ignore
		const fileNames = Array.from(e.target.files).map(e => e.name);
		POSTRequest(`/uploadSigned?type=${props.docType}`, fileNames, history)
			.then((userDocsUploadFeedback) => {
				if (userDocsUploadFeedback.success) {
					//@ts-ignore
					return userDocsUploadFeedback?.data;
				}
			}).then(links => {
			return Promise.all(links.map((urlRelation) => {
				//@ts-ignore
				const file = Array.from(e.target.files).find(e => e.name === urlRelation.name);
				return performFileUpload(urlRelation.url, file);
			}));
		}).then((responses) => {
			//after upload success, refresh the file state
			GETRequest(`/docs`, history).then(
				(userDocsFeedback) => {
					if (userDocsFeedback.success) {
						// update user info
						dispatch({
							type: actionTypes.SET_DOCS,
							// @ts-ignore
							docs: userDocsFeedback?.data,
						});
					}
				},
			);

			dispatch({
				type: actionTypes.ALERT,
				alert: [
					...alert,
					{
						isSuccess: true,
						//@ts-ignore
						message: successMsg[props.docType],
						timestamp: new Date(),
					},
				],
			});

			setIsUploading(false);
			handleCloseModal();
		});
	};

	const performFileUpload = async (targetUrl, file) => {
		// in the future PDF and images with type PNG and JPG will be accepted
		return await fetch(targetUrl, { method: 'PUT', body: file })
			.then((e) => {
				window.parent.postMessage({ isSuccess: true }, '*');
				console.log('UPLOAD: ', e);
				if (e.ok) {
				} else {
					dispatch({
						type: actionTypes.ALERT,
						alert: [
							...alert,
							{
								isSuccess: false,
								//@ts-ignore
								message: e.statusText,
								timestamp: new Date(),
							},
						],
					});
				}
			})
			.catch((e) => {
				console.error(e);
				window.parent.postMessage({ isSuccess: false }, '*');
				setIsUploading(false);
				setError(e);
			});
	};

	const handleCloseModal = () => {
		props.closeModal(1);
		setError('');
		setIsUploading(false);
	};

	return (
		<div className={clsx('modal-container', props.isUpload && 'isUpload')}>
			<ModalCustom
				onClose={handleCloseModal}
				uploadModalheight={true}
				footer={null}
				visible={props.visible}
			>
				<div
					className={clsx(
						'modal-header',
						props.isUpload && 'is-upload-modal-header',
					)}
				>
					<div className='modal-btn'>
						<BorderButton
							color='#FFFFFF'
							borderRadius='40px'
							backgroundColor='#191C1F'
							value='New Upload'
						/>
					</div>
				</div>

				<div className='modal-upload-section'>
					<div className='modal-upload-section-content'>
						<div className='upload-info-text-mobile'>
							<Bold isBold={false}>Your driving licence/passport or</Bold>
							<Bold isBold={false}>national identity card</Bold>
						</div>

						<div className='upload-icon-section'>
							<img src={uploadIcon} alt='upload-icon' />
							<Caption isBold={false}>
								{/* JPG, PNG or PDF, smaller than 10MB */}
								Files smaller than 10MB, contact us in case of bigger files.
							</Caption>
						</div>
						<div className='upload-button-section'>
							<SmallBold isBold={true} marginBottom='8px' color='#717A85'>
								Drag and drop your image here or
							</SmallBold>
							{error.length > 2 && <div className='input-error'>{error}</div>}
							{/* <BorderButton
                      backgroundColor="#0968E3"
                      className="btn-mobile"
                      color="#ffff"
                      value="Choose a file"
                    /> */}

							{isUploading && (
								<>
									<br />
									<div className='offer-spinner'></div>
								</>
							)}
							{!isUploading && (
								<label htmlFor={generatedId} className='btn-doc-upload'>
									<span>Choose a file</span>
									<input
										onChange={handleDocUpload}
										multiple
										type='file'
										name='upload'
										id={generatedId}
									/>
								</label>
							)}
						</div>
					</div>
				</div>
			</ModalCustom>
		</div>
	);
};

export default ModalDocUpload;
