import { BorderButton, OfferSlab } from 'components/Widgets';
import { H5 } from 'typography';
import { useState } from 'react';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { OfferQuestionData } from 'utils/OfferQuestionList';

interface IOfferQuestion {
	questionId: string;
	/** receives the setState of CURRENT QUESTION state */
	funcOnClick: any;
	/** receives the setState of PROGRESS state */
	funcProgress: any;
	/** accepts the full question object */
	allObject: any;
	/** state */
	oHist: any;
	/** func */
	funcHistory: any;
	/** accepts the value of progressHistory */
	progressHistory: any;
	/** receives the setState of progressHistory state */
	setProgressHistory: any;
	currentQuestion: number;
}

const OfferQuestion = (props: IOfferQuestion) => {
	const [{ user, offersData }, dispatch] = useDataLayerValue();
	const [offersAnswers, setOffersAnswers] = useState(offersData);

	/** MAKE PATCH CALL BASED ON RESPONSE FROM ANSWERS  */
	const funcGetResponse = async (
		id: string,
		answer: boolean,
	) => {
		// set history
		props.oHist.push(props.questionId);
		props.funcHistory(props.oHist);

		//FIXME all these lines should have logic to point case by id
		offersAnswers['propertyValue'] = user.cases[0].application.propertyValue;
		offersAnswers['deposit'] = user.cases[0].application.deposit;
		offersAnswers['term'] = user.cases[0].application.mortgageTerms;

		offersAnswers[id] = answer;
		setOffersAnswers(offersAnswers);

		// update the offers data
		dispatch({
			type: actionTypes.OFFERS_DATA,
			offersData: offersAnswers,
		});

		props.funcOnClick(props.currentQuestion + 1);
		const prog = Math.ceil(
			((props.currentQuestion + 2) / OfferQuestionData.length) * 100,
		);
		props.funcProgress(prog);

		// set progress history
		props.progressHistory.push(prog);
		props.setProgressHistory(props.progressHistory);
	};

	return (
		<>
			{
				<div className='offer-question-wrapper'>
					<div className='offer-question-content'>
						<H5>{props.allObject.question}</H5>
						<div className='offer-question-tooltip'>
							<OfferSlab
								tooltip={props.allObject.tooltip}
								tooltipWidth={'20px'}
							/>
						</div>
					</div>
					<div className='offer-buttons'>
						{
							// eslint-disable-next-line array-callback-return
							props.allObject.answers?.map((answer: any, index: any) => {
								if (answer.yes) {
									return (
										<BorderButton
											value={answer.text}
											backgroundColor='#0968E3'
											width='285px'
											color='#fff'
											key={index}
											onClick={() =>
												funcGetResponse(
													props.questionId,
													answer?.value,
												)
											}
										/>
									);
								}

								if (answer.no) {
									return (
										<BorderButton
											value={answer.text}
											backgroundColor='#0968E3'
											width='285px'
											color='#fff'
											key={index}
											onClick={() =>
												funcGetResponse(
													props.questionId,
													answer?.value,
												)
											}
										/>
									);
								}
							})
						}
					</div>
				</div>
			}
		</>
	);
};

export default OfferQuestion;
