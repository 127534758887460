import React from 'react';
import { useHistory } from 'react-router-dom';

const BigStepBanner = () => {
	const history = useHistory();

	return (
		<div className='journey-bg big-step-banner-bg-img'>
			<div className='big-step-layout'>
				<div className='big-step-text'>Lets make your big step easy</div>
				<button className='start-btn middle btn-pulse' onClick={() => history.push('/auth/signup')}>
					Start the journey
				</button>
			</div>
		</div>
	);
};

export default BigStepBanner;
