import { useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';

const CreateProfileMonthlyMortgagePayment = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 62 : 87);
	const [monthlyMortgagePayment, setMonthlyMortgagePayment] = useState(
		user?.application?.monthlyMortgagePayment || 0
	);

	// end of states that control form data
	const handleSubmit = () => {
		const currUser = { ...user };
		currUser.cases[0].application.monthlyMortgagePayment = monthlyMortgagePayment;

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		history.push('/user/create-profile-extend-mortgage');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title="What's your current monthly mortgage payment?"
					continueBtn
					onClick={handleSubmit}
					buttonDisabled={!monthlyMortgagePayment}
				>
					<Input
						value={monthlyMortgagePayment}
						required
						autoFocus={true}
						currency
						type='number'
						numberInput
						name='monthlyMortgagePayment'
						onChange={(e) => setMonthlyMortgagePayment(e)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileMonthlyMortgagePayment;
