import { useHistory } from 'react-router-dom';
import closeIcon from "assets/close-gray-icn.svg";

interface IJourneyNavLineOffer {
  showClose: boolean;
  /** accepts the offerHistory array */
  oHist: any;
  /** receives the setState of offerHistory state */
  funcOHist: any;
  /** accepts the PROGRESS value */
  progress: number;
  /** receives the setState of PROGRESS state */
  funcProgress: any;
  /** receives the setState of currentQuestion state */
  funcSetCurrentQuestion: any;
  /** accepts the full question object */
  allObject: any;
  /** accepts the value of progressHistory */
  progressHistory: any;
  /** receives the setState of progressHistory state */
  funcProgressHistory: any;
  currentQuestion: number;
}

const JourneyNavLineOffer = (props: IJourneyNavLineOffer) => {
  const history = useHistory();
  const backBtn = () => {
    if (props.oHist.length === 0) {
      props.funcSetCurrentQuestion('');
      props.funcProgress(0);
      // return to stages page
      history.goBack();
    } else {
      props.funcSetCurrentQuestion(props.currentQuestion - 1);
      props.oHist.splice(-1);

      // apply calculation for progress bar
      const progLast = props.progressHistory.length - 2;
      if (props.progressHistory[progLast] !== undefined) {
        props.funcProgress(props.progressHistory[progLast]);
      } else {
        props.funcProgress(3);
      }
      props.progressHistory.splice(-1);
    }
  };

  return (
    <div className="step-content-header">
      <div className="journey-navline">
        <button type="button" onClick={backBtn} className="previous-btn" />
        {props.showClose && (
          <button
            type="button"
            onClick={() => history.push('/user/stages')}
            className="close-btn journey-close"
          >
            <img src={closeIcon} alt="close-icon" />
          </button>
        )}
      </div>
    </div>
  );
};

export default JourneyNavLineOffer;
