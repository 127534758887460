import { ProgressBar, StepContent } from 'components/Widgets';
import RadioInputBoolean from './RadioInputBoolean';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfileWhyBorrowMoreMoney = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [whyBorrowMoreMoney, setWhyBorrowMoreMoney] = useState(
		user?.application?.whyBorrowMoreMoney
	);

	const saveToContextAndContinue = (answer) => {
		setWhyBorrowMoreMoney(answer);
		const currUser = { ...user };
		currUser.cases[0].application.whyBorrowMoreMoney = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			history.push('/user/create-profile-amount-to-borrow');
		}, 300);
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={0} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='Why do you want to borrow more money?'
					onClick={() => {}}
				>
					<RadioInputBoolean
						value='capitalRising'
						isChecked={whyBorrowMoreMoney === 'capitalRising'}
						SelectionText='Capital rising'
						name='whyBorrowMoreMoney'
						onClick={() => saveToContextAndContinue('capitalRising')}
					/>
					<RadioInputBoolean
						value='homeImprovements'
						isChecked={whyBorrowMoreMoney === 'homeImprovements'}
						SelectionText='Home Improvements'
						name='whyBorrowMoreMoney'
						onClick={() => saveToContextAndContinue('homeImprovements')}
					/>
					<RadioInputBoolean
						value='debtConsolidation'
						isChecked={whyBorrowMoreMoney === 'debtConsolidation'}
						SelectionText='Debt Consolidation'
						name='whyBorrowMoreMoney'
						onClick={() => saveToContextAndContinue('debtConsolidation')}
					/>
                    <RadioInputBoolean
						value='holiday'
						isChecked={whyBorrowMoreMoney === 'holiday'}
						SelectionText='Holiday'
						name='whyBorrowMoreMoney'
						onClick={() => saveToContextAndContinue('holiday')}
					/>

                    <RadioInputBoolean
						value='carPurchase'
						isChecked={whyBorrowMoreMoney === 'carPurchase'}
						SelectionText='Car Purchase'
						name='whyBorrowMoreMoney'
						onClick={() => saveToContextAndContinue('carPurchase')}
					/>
                    <RadioInputBoolean
						value='buyAnotherPropertyOrInvestment'
						isChecked={whyBorrowMoreMoney === 'buyAnotherPropertyOrInvestment'}
						SelectionText='Buy another property/Investment'
						name='whyBorrowMoreMoney'
						onClick={() => saveToContextAndContinue('buyAnotherPropertyOrInvestment')}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileWhyBorrowMoreMoney;
