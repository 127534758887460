import { Input, ProgressBar, StepContent } from 'components/Widgets';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfileCurrentMortgageValue = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [loanAmount, setLoanAmount] = useState(
		user?.cases?.[0]?.application?.loanAmount || 0
	);

	const handleSaveAndContinue = () => {
		const currUser = { ...user };
		currUser.cases[0].application.loanAmount = loanAmount;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push('/user/create-profile-property-worth');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={0} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='How much is your current mortgage value?'
					continueBtn
					onClick={handleSaveAndContinue}
					buttonDisabled={!loanAmount}
				>
					<Input
						type='number'
						value={loanAmount || 0}
						autoFocus={true}
						numberInput
						currency
						onChange={(value: number) => setLoanAmount(Number(value))}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileCurrentMortgageValue;
