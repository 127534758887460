import React from 'react';
import { Progress } from 'antd';
import 'css/ProgressBar.css';

interface ProgressBarProps {
	progress?: number;
	marginTop?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
	return (
		<div className='progressBar-container'>
			<Progress percent={props.progress} showInfo={false} status='active' />
		</div>
	);
};

export default ProgressBar;
