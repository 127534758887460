import { useEffect, useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, Loader, ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useHistory } from 'react-router-dom';
import { PATCHRequest } from '../../utils/AuthFunctions';
import { actionTypes } from '../../context-api/actionTypes';

const CreateProfilePartnerEmail = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(83.3);
	const [isSaving, setIsSaving] = useState(false);

	const [email, setEmail] = useState(user?.cases?.[0]?.application?.partner?.email || '');
	const [isComplete, setComplete] = useState(false);
	const partner = user?.cases?.[0]?.application?.partner;

	useEffect(() => {
		setComplete(!!email);
	}, [email]);

	// end of states that control form data
	const onSubmit = async () => {
		const currUser = { ...user };
		if (!currUser.cases[0].application.partner) {
			currUser.cases[0].application.partner = {};
		}

		currUser.cases[0].application.partner.email = email;
		setIsSaving(true);

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser
		});

		await PATCHRequest(
			`/users?attribute=application`,
			user.cases[0].application,
			history
		);

		history.push('/user/stages');
	};

	if (isSaving) return <Loader text='Saving...' />;

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} step={1}>
				<CreateProfileContent
					active
					title={partner ? `What is the email address of ${partner.firstName + ' ' + partner.lastName}?` : 'What is the second applicant email address?'}
					continueBtn
					onClick={onSubmit}
					buttonDisabled={!isComplete}
				>
					<Input
						value={email}
						normalInput
						autoFocus={true}
						type='email'
						required
						label='Email'
						placeholder='Email'
						onChange={(e) => setEmail(e.target.value)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfilePartnerEmail;
