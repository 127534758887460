import {
	BorderButton,
	EnhancedPostCodeInput,
	Input,
	JourneyNavLine,
	Loader,
	ProgressBar,
	SelectInput,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Bold, H4 } from 'typography';
import { PATCHRequest, readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import closeIcn from 'assets/close-gray-icn.svg';
import clsx from 'clsx';
import { countryList } from 'utils/DropDownList';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

const AnotherAddressForm = () => {
	const FProgress = Number(readFromLocalStorage('_FPersonalPageProgress')) || 0;

	const [progress, setProgress] = useState(FProgress);

	const history = useHistory();

	const [{ user }, dispatch] = useDataLayerValue();
	const { personal } = user || {};
	const { application } = user?.cases?.[0] || {};
	const { pastAddresses: contextPastAddresses } = personal;

	const [pastAddresses, setPastAddresses] = useState(
		contextPastAddresses?.length
			? contextPastAddresses
			: [
				{
					address: '',
					address2: '',
					dateMoved: '',
					street: '',
					city: '',
					postCode: '',
					country: '',
				},
			],
	);

	dayjs.extend(minMax);

	const [showAddAnotherBtn, setShowAddAnotherBtn] = useState(false);
	const [isComplete, setComplete] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const onSubmit = (e: any) => {
		setProgress(Math.floor(progress + 8.3));
		storeInLocalStorage('_FPersonalPageProgress', Math.floor(progress + 8.3));
		e?.preventDefault();

		personal.pastAddresses = pastAddresses;

		setIsSaving(true);
		// set current expenses details
		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, personal },
		});

		PATCHRequest(`/users?attribute=personal`, user.personal, history);
		setIsSaving(false);

		if (application.partner) {
			history.push('/user/personal/personal-second-applicant-has-addresshistory');
		} else {
			history.push('/user/stages');
		}
	};

	useEffect(() => {
		const fiveYearsAgo = dayjs().subtract(5, 'year');

		const dayjsPastDatesArr = pastAddresses?.map((address) => dayjs(address.dateMoved)) || [];
		const oldestDate = dayjs.min(dayjsPastDatesArr);

		if (oldestDate.isBefore(dayjs().subtract(100, 'year'))) {
			setShowAddAnotherBtn(false);
			return;
		}

		const isLongerThan5Years = oldestDate.isSameOrBefore(fiveYearsAgo);

		const validatedAddresses = pastAddresses.every(
			(addr) =>
				addr.postCode?.trim()?.length > 0 &&
				(addr.address?.trim()?.length > 0 || addr.address2?.trim()?.length > 0) &&
				addr.city?.trim()?.length > 0 &&
				addr.country?.trim()?.length > 0,
		);

		setShowAddAnotherBtn(!isLongerThan5Years);
		setComplete(isLongerThan5Years && validatedAddresses);
	}, [pastAddresses]);

	const handlePastAddressChange = (e, index) => {
		const { name, value } = e.target;

		const list = [...pastAddresses];
		list[index][name] = value;
		setPastAddresses(list);
	};

	const handleRemoveClick = (index) => {
		const list = [...pastAddresses];
		list.splice(index, 1);
		setPastAddresses(list);
	};

	const handleAddClick = () => {
		setPastAddresses(() => [
			...pastAddresses,
			{
				country: '',
				address: '',
				address2: '',
				dateMoved: '',
				street: '',
				city: '',
				postCode: '',
				homeNumber: '',
			},
		]);
	};

	const handleAddressCountry = (value, index) => {
		const list = [...pastAddresses];
		list[index].country = value;
		setPastAddresses(list);
	};

	if (isSaving) return <Loader text='Saving...' />;

	return (
		<div className='personal-container'>
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Previous address</H4>
					</div>
					<Bold isBold={false} color='#959DA5'>
						Please provide previous addresses, we will need 5 years’ address history
					</Bold>
				</div>
			</div>
			<div className='personal-details-body'>
				<form onSubmit={onSubmit}>
					{pastAddresses.map((x, i) => {
						return (
							<div key={i}>
								<Input
									value={pastAddresses[i]?.homeNumber || ''}
									normalInput
									label='Home number'
									name='homeNumber'
									type='text'
									placeholder='text'
									onChange={(e) => {
										const tmpAddr = [...pastAddresses];
										tmpAddr[i].homeNumber = e.target.value;
										setPastAddresses(tmpAddr);
									}}
									required
								/>

								<EnhancedPostCodeInput
									value={pastAddresses[i]?.postCode || ''}
									onChange={(e) => handlePastAddressChange(e, i)}
									onAddressUpdate={(e) => {
										const tmpAddr = [...pastAddresses];
										tmpAddr[i].city = e.city;
										tmpAddr[i].address = e.address;
										tmpAddr[i].country = e.country;
										setPastAddresses(tmpAddr);
									}}
								/>

								<div className={clsx('inner-group-input', pastAddresses.length > 1 && 'margin-top-20 big-mt')}>
									<SelectInput
										name={'Country'}
										options={countryList}
										defaultValue={pastAddresses[i]?.country || null}
										label='Country'
										showSearch
										onSelect={(e) => handleAddressCountry(e, i)}
									/>
									{pastAddresses.length !== 1 && i !== 0 ? (
										<button type='button' onClick={handleRemoveClick} className='border-less-btn'>
											<img src={closeIcn} alt='close' />
										</button>
									) : (
										''
									)}
								</div>

								<Input
									normalInput
									label='Address line 1'
									value={pastAddresses[i]?.address || ''}
									name='address'
									type='text'
									placeholder='text'
									onChange={(e) => handlePastAddressChange(e, i)}
									required
								/>
								<Input
									normalInput
									label='Address line 2'
									name='address2'
									value={pastAddresses[i]?.address2 || ''}
									type='text'
									placeholder='text'
									onChange={(e) => handlePastAddressChange(e, i)}
									required
								/>
								<Input
									normalInput
									label='City'
									name='city'
									type='text'
									value={pastAddresses[i]?.city || ''}
									placeholder='text'
									onChange={(e) => handlePastAddressChange(e, i)}
									required
								/>
								<Input
									label='Date moved into your current address?'
									name='dateMoved'
									type='text'
									placeholder='text'
									value={pastAddresses[i]?.dateMoved || ''}
									dateField
									onChange={(e) => handlePastAddressChange(e, i)}
									required
								/>
							</div>
						);
					})}

					<br />
					{showAddAnotherBtn && (
						<BorderButton
							isBold={false}
							border='0'
							value='Add another'
							boxShadow='transparent'
							onClick={handleAddClick}
						/>
					)}

					<div className='personal-details-btn'>
						<BorderButton
							value='Continue'
							width='368px'
							height='56px'
							backgroundColor='#0968E3'
							color='#fff'
							marginBottom='15%'
							onClick={onSubmit}
							disabled={!isComplete}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AnotherAddressForm;
