import { H6, SmallSubTitle } from 'typography';
import 'css/Settings.css';
import PhoneInput from 'components/Widgets/PhoneInput';
import { useDataLayerValue } from 'context-api/DataLayer';
const PhoneInputContent = () => {
  const [{ user }] = useDataLayerValue();

  return (
    <form>
      <div className="settings-modal-header ">
        <div className="settings-modal-title">
          <H6>Change your Phone number</H6>
          <div className="line" />
        </div>

        <div className="settings-modal-title-mobile">
          <SmallSubTitle>What’s your new phone number?</SmallSubTitle>
        </div>
        <PhoneInput defaultValue={user?.personal?.mobileNumber} />
      </div>
    </form>
  );
};

export default PhoneInputContent;
