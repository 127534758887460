import {
	CleanRadioInput,
	JourneyNavLine,
	ProgressBar,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { H4 } from 'typography';

const OnlyProperty = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [isTheOnlyProperty, setTheOnlyProperty] = useState(
		user?.property?.isTheOnlyProperty
	);
	const delay = 300;

	const setAnswer = (answer: boolean) => {
		setTheOnlyProperty(answer);

		setTimeout(() => {
			const currUser = { ...user };
			if (!currUser?.property) {
				currUser.property = {};
			}

			currUser.property.isTheOnlyProperty = answer;

			dispatch({
				type: actionTypes.SET_USER,
				user: currUser,
			});

			history.push('/user/property/purchase-property-deposit-source');
		}, delay);
	};

	return (
		<div className='personal-container'>
			<ProgressBar progress={78} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>Will this be the only property you own?</H4>
					</div>
				</div>
				<div className='personal-details-body'>
					<form className='central-form-group' onSubmit={(e) => e}>
						<CleanRadioInput
							name={'isTheOnlyProperty'}
							label={'Yes'}
							value={'yes'}
							onClick={() => setAnswer(true)}
							checked={
								typeof isTheOnlyProperty === 'boolean' && isTheOnlyProperty
							}
						/>

						<CleanRadioInput
							name={'isTheOnlyProperty'}
							label={'No'}
							onClick={() => setAnswer(false)}
							value={'no'}
							checked={
								typeof isTheOnlyProperty === 'boolean' && !isTheOnlyProperty
							}
						/>
					</form>
				</div>
			</div>
		</div>
	);
};

export default OnlyProperty;
