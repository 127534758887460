import { useState } from 'react';
import { CreateProfileContent } from '.';
import { ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';
import RadioInputBoolean from './RadioInputBoolean';

const CreateProfileProperty = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 58.3 : 87.5);
	const [isFirstProperty, setIsFirstProperty] = useState<boolean>(
		user?.cases?.[0]?.application?.isFirstTimeBuyer
	);
	
	const handleAnswerClick = (answer) => {
		setIsFirstProperty(answer);
		const currUser = { ...user };
		currUser.cases[0].application.isFirstTimeBuyer = answer;
	
		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});
	
		setTimeout(() => {
			history.push('/user/create-profile-property-kind');
		}, 300);
	}

	return (
		<div className="create-profile-container" style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title="Is this going to be your first property?"
					onClick={() => {}}
				>
					<RadioInputBoolean
						value="true"
						isChecked={typeof isFirstProperty === 'boolean' && isFirstProperty}
						SelectionText="Yes"
						name="grossAnnualSalary"
						onClick={() => handleAnswerClick(true)}
					/>
					<RadioInputBoolean
						value="false"
						isChecked={typeof isFirstProperty === 'boolean' && !isFirstProperty}
						SelectionText="No"
						name="grossAnnualSalary"
						onClick={() => handleAnswerClick(false)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileProperty;
