import {
	BorderButton,
	Input,
	ProgressBar,
	StepContent,
} from 'components/Widgets';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfileAmountToBorrow = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [amountToBorrow, setAmountToBorrow] = useState(
		user?.cases?.[0]?.application?.amountToBorrow || 0
	);

	const handleSaveAndContinue = () => {
		const currUser = { ...user };
		currUser.cases[0].application.amountToBorrow = amountToBorrow;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push('/user/create-profile-current-deal-ended');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={0} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='How much do you want to borrow?'
					onClick={handleSaveAndContinue}
				>
					<Input
						type='number'
						value={amountToBorrow || 0}
						numberInput
						currency
						onChange={(value: number) => setAmountToBorrow(Number(value))}
					/>
					<div>
						<div className='personal-details-btn '>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								color='#fff'
								disabled={!amountToBorrow}
							/>
						</div>
					</div>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileAmountToBorrow;
