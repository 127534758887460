import React from 'react';
import HeaderText from './HeaderText';
import DescriptionText from './DescriptionText';

const BrokerTwo = () => {
	return (
		<div className='journey-bg broker-two-bg'>
			<div className='eligibility-two-bg eligibility-three-bg'>
				<HeaderText>
					<div className='light-gold right-align'>
						over <span className='white'>200+</span>
					</div>
					<div className='light-gold right-align'>brokers</div>
					<div className='light-gold right-align'>with no</div>
					<div className='white right-align'>broker fee</div>
				</HeaderText>
				<DescriptionText>
					<p className='white right-align'>
						Our process is the best combination of convenience and affordability. We allow mortgagors to access 200+
						brokers to suit their individual needs. And, the best part is, there is no broker fee. 
					</p>
				</DescriptionText>
			</div>
		</div>
	);
};

export default BrokerTwo;
