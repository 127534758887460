/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import { useEffect, useState } from 'react';
import { OfferPage, OffersTable } from 'components/OfferPage';
import {
	Switch,
	Route,
	Redirect,
	useRouteMatch,
	useHistory,
	useLocation,
} from 'react-router-dom';
import { StagesPage } from 'components/Stages';
import { Financial } from 'components/Financial';
import { Expenses } from 'components/Expenses';
import {
	GETRequest,
	PATCHRequest,
	storeInLocalStorage,
} from 'utils/AuthFunctions';
import { Property } from 'components/Property';
import Personal from '../components/Personal/Personal';
import Liabilities from 'components/Liabilities/Liabilities';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import CreateProfileBirthday from '../components/CreateProfilePage/CreateProfileBirthday';
import CreateProfilePersonal from '../components/CreateProfilePage/CreateProfilePersonal';
import CreateProfileSalary from '../components/CreateProfilePage/CreateProfileSalary';
import CreateProfilePropertyValue from '../components/CreateProfilePage/CreateProfilePropertyValue';
import CreateProfileDeposit from '../components/CreateProfilePage/CreateProfileDeposit';
import CreateProfileMortgageYears from '../components/CreateProfilePage/CreateProfileMortgageYears';
import CreateProfileProperty from '../components/CreateProfilePage/CreateProfileProperty';
import CreateProfilePropertyKind from '../components/CreateProfilePage/CreateProfilePropertyKind';
import CreateProfilePartner from '../components/CreateProfilePage/CreateProfilePartner';
import CreateProfilePartnerPersonal from '../components/CreateProfilePage/CreateProfilePartnerPersonal';
import CreateProfilePartnerEmail from '../components/CreateProfilePage/CreateProfilePartnerEmail';
import { initialState } from 'context-api/initialState';
import CreateProfileWhatAreYouLookingFor from 'components/CreateProfilePage/CreateProfileWhatAreYouLookingFor';
import CreateProfileWhyRemortgage from 'components/CreateProfilePage/CreateProfileWhyRemortgage';
import CreateProfileCurrentDealEnded from 'components/CreateProfilePage/CreateProfileCurrentDealEnded';
import CreateProfileCurrentMortgageValue from 'components/CreateProfilePage/CreateProfileCurrentMortgageValue';
import CreateProfilePropertyWorth from 'components/CreateProfilePage/CreateProfilePropertyWorth';
import CreateProfileYearsLeftOnMortgage from 'components/CreateProfilePage/CreateProfileYearsLeftOnMortgage';
import CreateProfileMortgageLender from 'components/CreateProfilePage/CreateProfileMortgageLender';
import CreateProfileMonthlyMortgagePayment from 'components/CreateProfilePage/CreateProfileMonthlyMortgagePayment';
import CreateProfileExtendMortgage from 'components/CreateProfilePage/CreateProfileExtendMortgage';
import CreateProfilePreviousMortgagePaymentType from 'components/CreateProfilePage/CreateProfilePreviousMortgagePaymentType';
import CreateProfileMortgagePaymentType from 'components/CreateProfilePage/CreateProfileMortgagePaymentType';
import CreateProfileWhyBorrowMoreMoney from 'components/CreateProfilePage/CreateProfileWhyBorrowMoreMoney';
import CreateProfileAmountToBorrow from 'components/CreateProfilePage/CreateProfileAmountToBorrow';
import CreateProfileMortgageDueToEnd from 'components/CreateProfilePage/CreateProfileMortgageDueToEnd';
import CreateProfileErcToPay from 'components/CreateProfilePage/CreateProfileErcToPay';
import CreateProfileWillingToPay from 'components/CreateProfilePage/CreateProfileWillingToPay';
import { Loader } from 'components/Widgets';

const LoggedUserRoutes = () => {
	const { path } = useRouteMatch();
	const history = useHistory();
	const [{ user }, dispatch] = useDataLayerValue();
	const [isNotCalledOnce, setCalledOnce] = useState(true);

	// PATCH THE LAST VISITED PAGE
	const location = useLocation();
	useEffect(() => {
		// GET USER INFO ONCE
		if (isNotCalledOnce) {
			GETRequest(`/users`, history).then(
				(userInfoFeedback) => {
					if (userInfoFeedback.success) {
						// @ts-ignore
						const { data } = userInfoFeedback;
						const currData = { ...data };
						if (!currData?.expenses) currData.expenses = initialState.expenses;

						if (!user) {
							dispatch({
								type: actionTypes.SET_USER,
								user: currData,
							});
						}

						dispatch({
							type: actionTypes.SET_SECOND_APP_EXISTS,
							//@ts-ignore
							isPartnerExists: !!userInfoFeedback?.data?.application?.partner,
						});

						// write into local storage
						storeInLocalStorage(
							'_user',
							// @ts-ignore
							userInfoFeedback?.data
						);
					}
				}
			);

			// REGISTER LAST PAGE VISITED
			PATCHRequest(`/users?attribute=lastVisited`,
				{ url: location.pathname },
				history
			);

			setCalledOnce(false);
		}
	}, [isNotCalledOnce]);

	if (!user) {
		return (
			<div className='logged-routes-spinner'>
				<Loader />
			</div>
		);
	}

	return (
		<section className='user-journey-pages'>
			<Switch>
				<Route exact path={`${path}/create-profile-what-are-you-looking-for`}>
					<CreateProfileWhatAreYouLookingFor />
				</Route>

				<Route exact path={`${path}/create-profile`}>
					<CreateProfilePersonal />
				</Route>

				<Route exact path={`${path}/create-profile-birthday`}>
					<CreateProfileBirthday />
				</Route>

				<Route exact path={`${path}/create-profile-salary`}>
					<CreateProfileSalary />
				</Route>

				<Route exact path={`${path}/create-profile-property-cost`}>
					<CreateProfilePropertyValue />
				</Route>

				<Route exact path={`${path}/create-profile-deposit`}>
					<CreateProfileDeposit />
				</Route>

				<Route exact path={`${path}/create-profile-why-remortgage`}>
					<CreateProfileWhyRemortgage />
				</Route>

				<Route exact path={`${path}/create-profile-current-deal-ended`}>
					<CreateProfileCurrentDealEnded />
				</Route>

				<Route exact path={`${path}/create-profile-current-mortgage-value`}>
					<CreateProfileCurrentMortgageValue />
				</Route>

				<Route exact path={`${path}/create-profile-property-worth`}>
					<CreateProfilePropertyWorth />
				</Route>

				<Route exact path={`${path}/create-profile-years-left-on-mortgage`}>
					<CreateProfileYearsLeftOnMortgage />
				</Route>

				<Route exact path={`${path}/create-profile-extend-mortgage`}>
					<CreateProfileExtendMortgage />
				</Route>

				<Route exact path={`${path}/create-profile-previous-mortgage-payment-type`}>
					<CreateProfilePreviousMortgagePaymentType />
				</Route>

				<Route exact path={`${path}/create-profile-mortgage-payment-type`}>
					<CreateProfileMortgagePaymentType />
				</Route>

				<Route exact path={`${path}/create-profile-mortgage-lender`}>
					<CreateProfileMortgageLender />
				</Route>

				<Route exact path={`${path}/create-profile-monthly-mortgage-payment`}>
					<CreateProfileMonthlyMortgagePayment />
				</Route>

				<Route exact path={`${path}/create-profile-mortgage-due-to-end`}>
					<CreateProfileMortgageDueToEnd />
				</Route>

				<Route exact path={`${path}/create-profile-erc-to-pay`}>
					<CreateProfileErcToPay />
				</Route>

				<Route exact path={`${path}/create-profile-willing-to-pay`}>
					<CreateProfileWillingToPay />
				</Route>

				<Route exact path={`${path}/create-profile-why-borrow-more-money`}>
					<CreateProfileWhyBorrowMoreMoney />
				</Route>

				<Route exact path={`${path}/create-profile-amount-to-borrow`}>
					<CreateProfileAmountToBorrow />
				</Route>

				<Route exact path={`${path}/create-profile-mortgage`}>
					<CreateProfileMortgageYears />
				</Route>

				<Route exact path={`${path}/create-profile-property`}>
					<CreateProfileProperty />
				</Route>

				<Route exact path={`${path}/create-profile-property-kind`}>
					<CreateProfilePropertyKind />
				</Route>

				<Route exact path={`${path}/create-profile-partner`}>
					<CreateProfilePartner />
				</Route>

				<Route exact path={`${path}/create-profile-partner-personal`}>
					<CreateProfilePartnerPersonal />
				</Route>

				<Route exact path={`${path}/create-profile-partner-email`}>
					<CreateProfilePartnerEmail />
				</Route>

				<Route exact path={`${path}/mortgage`}>
					<OfferPage />
				</Route>

				<Route exact path={`${path}/mortgage/table`}>
					<OffersTable />
				</Route>

				<Route exact path={`${path}/stages`}>
					<StagesPage />
				</Route>

				<Route path={`${path}/financial`}>
					<Financial />
				</Route>
				<Route path={`${path}/personal`}>
					<Personal />
				</Route>

				<Route path={`${path}/liabilities`}>
					<Liabilities />
				</Route>

				<Route path={`${path}/expenses`}>
					<Expenses />
				</Route>
				<Route path={`${path}/property`}>
					<Property />
				</Route>
			</Switch>
		</section>
	);
};

export default LoggedUserRoutes;
