import { Loader, OfferSlab } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATCHRequest, POSTRequest } from 'utils/AuthFunctions';
import { sendReturnMessage } from 'utils/GeneralFunctions';

interface IMobileOfferCard {
  isBestOffer: boolean;
  lenderIcon: string;
  lenderName: string;
  offerId: string;
  initialRate: string;
  APR: string;
  monthlyRepayment: string;
}

const MobileOfferCard = (props: IMobileOfferCard) => {
  const history = useHistory();
  const [
    { correlationId, offerProductId, offersData, offerDetails },
    dispatch,
  ] = useDataLayerValue();
  const [collapseMore, setCollapseMore] = useState(true);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isApplying, setIsApplying] = useState(false);

  // MAKE API CALL TO SHOW MOBILE DETAILS
  const funcShowDetails = async (paramProductId: any) => {
    setCollapseMore(!collapseMore);
    setIsLoadingDetails(true);

    // set clicked productId will be used by modal to read details
    dispatch({
      type: actionTypes.SET_OFFER_PRODUCT_ID,
      offerProductId: paramProductId,
    });

    const detailsURL =
      'https://r3y1hmhg8d.execute-api.eu-west-2.amazonaws.com/product';

    const data = {
      productId: paramProductId,
      correlationId: correlationId,
    };

    const detailsFeedback = await POSTRequest(detailsURL, data, history);
    console.log('BEFORE isLoadingDetails: ', isLoadingDetails);
    console.log('detailsFeedback: ', detailsFeedback);
    // @ts-ignore
    if (detailsFeedback?.success) {
      dispatch({
        type: actionTypes.SET_OFFER_DETAILS,
        // @ts-ignore
        offerDetails: detailsFeedback.data,
      });

      setIsLoadingDetails(false);
      console.log('AFTER isLoadingDetails: ', isLoadingDetails);
      // console.log('AFTER offerDetails: ', offerDetails);
    }
    // TEMPORAL: this has to be removed after API returns valid response
    else {
      setIsLoadingDetails(!isLoadingDetails);
    }
  };

  // MAKE PATCH CALL ON APPLY BUTTON CLICK
  const funcMakePatchCall = async () => {
    setIsApplying(!isApplying);
    const patchURL = '/users?attribute=selectedProduct';

    const data = {
      productId: offerProductId,
      correlationId: correlationId,
      query: offersData,
    };

    let resp = await PATCHRequest(patchURL, data, history);

    if (resp.success) {
      sendReturnMessage('route_mortgage');
      setIsApplying(!isApplying);
      history.push('/user/stages');
      console.log('Patch Success:', data);
    } else {
      // @ts-ignore
      console.error('Error:', resp.error);
    }
  };

  return (
    <div
      className={
        props.isBestOffer
          ? 'mobile-offer-card toggle-mobile-best-offer'
          : 'mobile-offer-card'
      }
    >
      {props.isBestOffer && (
        <div className="mobile-best-offer">Best mortgage for you</div>
      )}
      <header className="mobile-offer-header">
        <img
          className="mobileLenderLogo"
          src={`${process.env.PUBLIC_URL}/bank-logos/${props.lenderIcon}.png`}
          alt=""
        />
        <div className="mobile-lender-name">{props?.lenderName}</div>
      </header>

      <section className="mobile-offers-content">
        <OfferSlab
          label="Initial Rate"
          value={props?.initialRate}
          tooltip={
            'The initial rate is the rate that is charged during the introductory rate of a mortgage'
          }
        />
        <OfferSlab
          label="APR"
          value={props?.APR}
          tooltip={
            'The Annual Percentage Rate (APR) is the cost you pay each year to borrow money, including fees, expressed as a percentage.'
          }
        />
        <OfferSlab
          label="Monthly Repayment"
          value={props.monthlyRepayment}
          tooltip={
            'Your monthly repayment during the initial period of the mortgage'
          }
        />

        {collapseMore && (
          <div
            onClick={() => funcShowDetails(props.offerId)}
            className="more-info-trigger"
          >
            More Info
          </div>
        )}

        {isLoadingDetails ? (
          <Loader />
        ) : (
          <aside
            className={
              collapseMore
                ? 'mobile-offers-collapsible toggle-height'
                : 'mobile-offers-collapsible'
            }
          >
            <OfferSlab
              label="Total Amount Payable"
              value={`£${offerDetails?.mortgageAmount?.toLocaleString()}`}
              tooltip={
                'The total amount you will have to repay to the lender if you keep your deal for the full length'
              }
            />

            <OfferSlab
              label="Follow up rate"
              value={`${offerDetails?.followUpRate}%`}
              tooltip={`something`}
            />

            <OfferSlab
              label="Initial monthly payment"
              value={`£${offerDetails?.initialMonthlyPayment?.toLocaleString()}`}
              tooltip={
                'Your monthly repayment during the initial period of the mortgage'
              }
            />

            <OfferSlab
              label="Follow up monthly payment"
              value={`£${offerDetails?.followUpMonthlyPayment?.toLocaleString()}`}
              tooltip={'Monthly payment after the initial period has ended'}
            />

            <OfferSlab
              label="Total fees"
              value={`£${offerDetails?.totalFees?.toLocaleString()}`}
              tooltip={
                'Lender fees such as valuation, admin and/or product fee'
              }
            />

            <div
              onClick={() => setCollapseMore(!collapseMore)}
              className="hide-info-trigger"
            >
              Hide Info
            </div>

            <div className="modal-button-holder">
              <button
                style={{
                  width: '100%',
                  maxWidth: '400px',
                }}
                onClick={funcMakePatchCall}
                className="btn-modal-act-btn"
              >
                {!isApplying && <span>Apply</span>}
                {isApplying && <div className="btn-spinner"></div>}
              </button>
            </div>
            <br />
          </aside>
        )}
      </section>
    </div>
  );
};

export default MobileOfferCard;
