import {useState} from "react";
import {CreateProfileContent} from ".";
import {ProgressBar, StepContent,} from "components/Widgets";
import {useDataLayerValue} from "context-api/DataLayer";
import {actionTypes} from "context-api/actionTypes";
import {useHistory} from "react-router-dom";
import RadioInputBoolean from "./RadioInputBoolean";

const CreateProfilePropertyKind = () => {
    const [{user, isPartnerExists}, dispatch] = useDataLayerValue();
    const history = useHistory();
    const [progress] = useState(!!isPartnerExists ? 66.6 : 92);
    const [propertyType, setPropertyType] = useState(user?.cases?.[0]?.application?.propertyType);

    const handleAnswerClick = (answer) => {
        setPropertyType(answer);
        const currUser = { ...user };
        currUser.cases[0].application.propertyType = answer

        dispatch({
            type: actionTypes.SET_USER,
            user: currUser,
        });

        setTimeout(() => {
            history.push("/user/create-profile-partner");
        }, 300);
    }

    return (
        <div className="create-profile-container" style={{minHeight: "100vh"}}>
            <ProgressBar progress={progress}/>
            <StepContent
                closeBtn={false}
                showBackBtn={true}
                step={1}
            >
                <CreateProfileContent
                    active={true}
                    title="What kind of property is it?"
                    onClick={() => {}}
                >
                    <RadioInputBoolean
                        value={"Flat"}
                        isChecked={propertyType === "Flat"}
                        SelectionText={'Flat'}
                        name={'propertyType'}
                        onClick={() => handleAnswerClick("Flat")}
                    />
                    <RadioInputBoolean
                        value={"House"}
                        isChecked={propertyType === "House"}
                        SelectionText={'House'}
                        name={'propertyType'}
                        onClick={() => handleAnswerClick("House")}
                    />
                </CreateProfileContent>
            </StepContent>
        </div>
    );
};

export default CreateProfilePropertyKind;
