import { Tooltip } from 'antd';
import { JourneyNavLine, CleanInput, SelectInput, BorderButton, ProgressBar, Input, Loader } from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { H4, Bold } from 'typography';
import { PATCHRequest } from 'utils/AuthFunctions';
import { FinancialSource } from 'utils/FinancialData';
import { randId } from 'utils/GeneralFunctions';

interface IOtherIncome {
	id: number;
	amount: number;
	source: string;
	frequency: string;
}

const Applicant2Financial8 = () => {
	const history = useHistory();

	const [{ user }, dispatch] = useDataLayerValue();
	const { financial2, financial } = user || {};

	const [isSaving, setIsSaving] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [otherIncome, setOtherIncome] = useState<IOtherIncome[]>(
		financial2?.otherIncome || [
			{
				id: randId(),
				amount: null,
				source: '',
				frequency: '',
			},
		]
	);

	useEffect(() => {
		if (otherIncome[0]['amount'] && otherIncome[0]['source'] && otherIncome[0]['frequency']) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [dispatch, otherIncome]);

	const funcSubmitFinancial8 = async (e: any) => {
		e?.preventDefault();

		const currFinancial = { ...financial2 };
		currFinancial['otherIncome'] = otherIncome;

		setIsSaving(true);
		dispatch({
			type: actionTypes.SET_USER,
			user: { ...user, financial2: currFinancial },
		});

		// make patch call
		await PATCHRequest(`/users?attribute=financial2`, currFinancial, history);
		await PATCHRequest(`/users?attribute=financial`, financial, history);
		setIsSaving(false);

		history.push('/user/stages');
	};

	const generateNewFields = () => {
		setOtherIncome((initIncome) => [
			...initIncome,
			{
				id: randId(),
				amount: null,
				source: '',
				frequency: '',
			},
		]);
	};

	const removeFieldSet = (income) => {
		setOtherIncome((initIncome) => initIncome.filter((x) => x.id !== income.id));
	};

	if (isSaving) return <Loader text='Saving...' />;

	return (
		<div className='financial-pages'>
			<ProgressBar progress={100} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H4>Please list the details below</H4>

				<form className='central-form-group' onSubmit={(e) => funcSubmitFinancial8(e)}>
					{otherIncome.map((income, index) => {
						return (
							<div className='fieldset-holder' key={`${income.id}-${index}`}>
								{index !== 0 && (
									<Tooltip title={'Remove set'} placement='bottomRight'>
										<div className='remove-fieldset' onClick={() => removeFieldSet(income)}></div>
									</Tooltip>
								)}
								<CleanInput
									label='Please specify the source'
									type='text'
									placeholder='text'
									name='source'
									value={income.source}
									required
									onChange={(e: any) => {
										const source = e.target.value;
										setOtherIncome((initIncome) => {
											return initIncome.map((otherIncome) =>
												otherIncome.id === income.id
													? {
															...otherIncome,
															source,
													  }
													: otherIncome
											);
										});
									}}
								/>

								<Input
									label='Income amount in £'
									numberInput
									currency
									placeholder='text'
									name='amount'
									value={income.amount}
									required
									onChange={(value: number) => {
										const amount = value;
										setOtherIncome((initIncome) => {
											return initIncome.map((otherIncome) =>
												otherIncome.id === income.id
													? {
															...otherIncome,
															amount,
													  }
													: otherIncome
											);
										});
									}}
								/>

								<div className='mt'>
									<SelectInput
										name={'frequency'}
										options={FinancialSource}
										label='Frequency'
										defaultValue={income?.frequency || null}
										onSelect={(value: string) => {
											const frequency = value;
											setOtherIncome((initIncome) => {
												return initIncome.map((otherIncome) =>
													otherIncome.id === income.id
														? {
																...otherIncome,
																frequency,
														  }
														: otherIncome
												);
											});
										}}
									/>
								</div>
							</div>
						);
					})}

					<div onClick={generateNewFields} className='financial-add-another'>
						<Bold isBold={false}>Add another</Bold>
					</div>

					<div className={`continue-btn ${!isComplete ? 'isDisabled' : ''}`}>
						<BorderButton
							value='Continue'
							width='368px'
							height='56px'
							backgroundColor='#0968E3'
							color='#fff'
							onClick={() => 'props.onClick'}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Applicant2Financial8;
