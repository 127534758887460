import countryList from 'countries-list/dist/countries.json';
import { Select } from 'antd';
import Input from 'components/Widgets/Input';
import 'css/PhoneInput.css';
import { Caption } from 'typography';

interface IPhoneInput {
  defaultValue?: string;
}

const PhoneInput = (props: IPhoneInput) => {
  const data = Object.values(countryList);
  const { Option } = Select;

  return (
    <form>
      <div className="phone-input-container">
        <div className="phone-input-country-code-wrapper">
          <div>
            <Caption isBold={false}>Country</Caption>
          </div>
          <Select bordered={false} placeholder="Please select store">
            {data.map((item) => (
              <Option key={item.phone} value={item.phone} label={item.phone}>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <label
                    style={{
                      flex: 1,
                    }}
                  >
                    {item.name}
                  </label>
                  +{item.phone}
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <Input
          name="phone"
          phone
          required
          defaultValue={props.defaultValue}
          label="Phone Number"
        />
      </div>
    </form>
  );
};

export default PhoneInput;
