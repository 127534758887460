import {
	BorderButton,
	Input,
	ProgressBar,
	StepContent,
} from 'components/Widgets';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfileErcToPay = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [ercToPay, setErcToPay] = useState(user?.application?.ercToPay || 0);

	const handleSaveAndContinue = () => {
		const currUser = { ...user };
		currUser.cases[0].application.ercToPay = ercToPay;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		history.push('/user/create-profile-willing-to-pay');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={0} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='What is the ERC to pay?'
					onClick={handleSaveAndContinue}
				>
					<Input
						type='number'
						value={ercToPay || 0}
						autoFocus={true}
						numberInput
						currency
						onChange={(value: number) => setErcToPay(Number(value))}
					/>
					<div>
						<div className='personal-details-btn'>
							<BorderButton
								value='Continue'
								width='368px'
								height='56px'
								backgroundColor='#0968E3'
								color='#fff'
								disabled={!ercToPay}
							/>
						</div>
					</div>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileErcToPay;
