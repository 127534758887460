import React from 'react';
import HeaderText from './HeaderText';
import DescriptionText from './DescriptionText';

const EligibilityThree = () => {
	return (
		<div className='journey-bg dark-blue-bg eligibility-three-bg-img'>
			<div className='eligibility-two-bg eligibility-three-bg'>
				<HeaderText>
					<div className='right-align light-grey'>Know your</div>
					<div className='right-align white'>mortgage</div>
					<div className='right-align white'>eligibility</div>
					<div className='right-align light-grey'>
						in <span className='white'>10</span> minutes
					</div>
				</HeaderText>
				<DescriptionText>
					<p className='right-align white'>
						Sounds unbelievable right? But with our eligibility calculator, it is possible to get your eligibility
						evaluation in just 10 minutes. 
					</p>
				</DescriptionText>
			</div>
		</div>
	);
};

export default EligibilityThree;
