import React from 'react';
import 'css/Welcome.css';
import Journey from './Journey';
import MortgageHassle from './MortgageHassle';
import Eligibility from './Eligibility';
import EligibilityTwo from './EligibilityTwo';
import EligibilityThree from './EligibilityThree';
import Broker from './Broker';
import BrokerTwo from './BrokerTwo';
import BigStepBanner from './BigStepBanner';
import Footer from './Footer';
import Reviews from './Reviews';

const Welcome = () => {
	return (
		<>
			<Journey />
			<MortgageHassle />
			<Eligibility />
			<EligibilityTwo />
			<EligibilityThree />
			<Broker />
			<BrokerTwo />
			<Reviews />
			<BigStepBanner />
			<Footer />
		</>
	);
};

export default Welcome;
