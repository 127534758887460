import { useEffect, useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';

const CreateProfileBirthday = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const { application } = user?.cases?.[0] || {}
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 16.6 : 25);
	const [birthday, setBirthday] = useState(user?.cases?.[0]?.application?.birthday || '');
	const [isComplete, setComplete] = useState<boolean>(false);

	useEffect(() => {
		setComplete(!!birthday);
	}, [birthday]);

	// end of states that control form data
	const onSubmit = () => {
		//FIXME: birthday should be attached to personal
		const currApplication = {...application}
		currApplication.birthday = birthday;

		user.cases[0].application = currApplication
		user.personal.birthDate = birthday

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		if (user?.cases[0]?.application?.isLookingTo === 'remortgage') {
			history.push('/user/create-profile-why-remortgage');
		} else {
			history.push('/user/create-profile-property-cost');
		}
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='What’s your date of birth?'
					continueBtn
					onClick={onSubmit}
					buttonDisabled={!isComplete}
				>
					<Input
						value={birthday}
						required
						autoFocus={true}
						label='Birthday'
						placeholder='Birthday'
						dateField
						onChange={(e) => setBirthday(e.target.value)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileBirthday;
