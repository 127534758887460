import moment from 'moment';

export const sendReturnMessage = (msg: any) => {
	window.parent.postMessage(msg, '*');
};

export const randId = () => {
	return Math.ceil(Math.random() * 1000);
};

export const TabTitle = (pageTitle: string) => {
	return (document.title = pageTitle);
};

/**
 * Formats date with the specified format in the second parameter
 * @param dateString
 * @param _format
 * @returns Formated Date Time (DD/MM/YYYY)
 */
export const FormatDate = (dateString, _format?) => {
	return typeof _format === 'string' && _format.length > 2
		? moment(dateString).format(_format)
		: moment(dateString).format('DD/MM/YYYY');
};

/**
 *
 * @returns A Simple Scare in console
 */
export const scareProduction = () => {
	return (
		process.env.NODE_ENV !== 'development' &&
		console.log(`
                                                
    .d8888b.  888                       888    
    d88P  Y88b 888                       888    
    Y88b.      888                       888    This is a browser feature intended for 
      "Y888b.   888888  .d88b.  88888b.   888    developers. If someone told you to copy-paste 
        "Y88b. 888    d88""88b 888 "88b  888    something here it's a scam. It will give them
          "888 888    888  888 888  888  Y8P    access to your computer instantly.
    Y88b  d88P Y88b.  Y88..88P 888 d88P         
      "Y8888P"   "Y888  "Y88P"  88888P"   888   
                                888              
                                888              
                                888              
  `)
	);
};
