import { Bold, H5 } from 'typography';
import 'css/Widgets.css';
import { Tooltip } from 'antd';
import infoicon from 'assets/info.svg';
import mobileinfoicon from 'assets/mobile-info.svg';

interface IOfferSlab {
	key?: string;
	label?: string;
	value?: string;
	tooltip?: string;
	tooltipWidth?: string;
}

/**
 * Returns a component with label and value
 * @param props key, label, value, tooltip, tooltipWidth
 * @example <OfferSlab key={key} value={'£5000'} label={'title of slab'} tooltip={'tooltip to show'} />
 * @returns component
 */
const OfferSlab = (props: IOfferSlab) => {
	return (
		<div className='offer-slab'>
			<div className='flex offer-label'>
				<Bold opacity={0.7}>{props.label}</Bold>
				{props.tooltip && (
					<Tooltip title={props.tooltip} placement='bottom'>
						<img src={infoicon} style={{ width: props.tooltipWidth }} alt='' />
					</Tooltip>
				)}
			</div>

			<div className='offer-value'>
				{props.tooltip && (
					<Tooltip title={props.tooltip} placement='bottomRight'>
						<img src={mobileinfoicon} alt='' />
					</Tooltip>
				)}
				<H5>
					{props.value?.includes('undefined') || props.value?.includes('NaN')
						? '—'
						: props.value}
				</H5>
			</div>
		</div>
	);
};

export default OfferSlab;
