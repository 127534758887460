import { useEffect, useState } from 'react';
import { CreateProfileContent } from '.';
import { Input, ProgressBar, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';

const CreateProfileSalary = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 24.9 : 37.5);
	const [salary, setSalary] = useState(
		user?.cases?.[0]?.application?.grossAnnualSalary || 0
	);
	const [isComplete, setComplete] = useState<boolean>(false);

	useEffect(() => {
		setComplete(!!salary && salary >= 0);
	}, [salary]);

	// end of states that control form data
	const onSubmit = () => {
		user.cases[0].application.grossAnnualSalary = salary;

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		history.push('/user/create-profile-property-cost');
	};

	return (
		<div className="create-profile-container" style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title="What is your gross annual salary?"
					continueBtn
					onClick={onSubmit}
					buttonDisabled={!isComplete}
				>
					<Input
						value={salary}
						autoFocus={true}
						required
						currency
						type="number"
						numberInput
						name="grossAnnualSalary"
						onChange={(e) => setSalary(e)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileSalary;
