import { useState } from 'react';
import { CreateProfileContent } from '.';
import { ProgressBar, SelectInput, StepContent } from 'components/Widgets';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';
import { useHistory } from 'react-router-dom';
import { landerNames } from 'utils/DropDownList';

const CreateProfileMortgageLender = () => {
	const [{ user, isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 50 : 75);
	const [mortgageLender, setMortgageLender] = useState(
		user?.cases?.[0].application?.mortgageLender || 0
	);

	// end of states that control form data
	const handleSaveAndContinue = () => {
		const currUser = { ...user };
		currUser.cases[0].application.mortgageLender = mortgageLender;

		dispatch({
			type: actionTypes.SET_USER,
			user: user,
		});

		history.push('/user/create-profile-monthly-mortgage-payment');
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='Who’s your current mortgage with?'
					continueBtn
					onClick={handleSaveAndContinue}
					buttonDisabled={!mortgageLender}
				>
					<div className='personal-details-body'>
						<SelectInput
							label='Lender Name'
							autoFocus={true}
							options={landerNames}
							onSelect={(value: string) => setMortgageLender(value)}
							defaultValue={mortgageLender || null}
							showSearch
						/>
					</div>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileMortgageLender;
