import { AccordionContentItem, AccordionInfoBox, AccordionTitle } from '.';
import personblueicon from 'assets/person-blue.svg';
import { FormatDate } from 'utils/GeneralFunctions';
import { PersonalProps } from 'types/PersonalDataType';

interface ApplicationProps {
	firstName?: string;
	lastName?: string;
	birthDate?: string;
	title?: string;
	birthday?: string;
}
interface IPersonalAccordion {
	name: string;
	personal: PersonalProps;
	application: ApplicationProps;
	progress: string;
	isSecondApplicant?: boolean;
	isOpen: boolean;
	handleToggle: any;
}

const PersonalAccordion = (props: IPersonalAccordion) => {
	const { personal, application, progress, isSecondApplicant } = props;
	const firstName = personal?.firstName || application?.firstName || '-';
	const lastName = personal?.lastName || application?.lastName || '-';
	let birthDate = '-';

	if (personal?.birthDate || application?.birthday) {
		birthDate = FormatDate(personal?.birthDate || application?.birthday);
	}

	const handleEditAbility = (page) => {
		console.log('PAGE TO EDIT: ', page);
	};

	return (
		<AccordionInfoBox
			icon={personblueicon}
			title={isSecondApplicant ? 'Personal 2nd' : 'Personal'}
			status={Number(progress) >= 99}
			name={props.name}
			isOpen={props.isOpen}
			handleToggle={props.handleToggle}
		>
			<AccordionContentItem
				question={'Title'}
				response={personal?.title || application?.title || '-'}
				editFunction={() => handleEditAbility(1)}
			/>

			<AccordionContentItem
				question={'First Name'}
				response={firstName || '-'}
				editFunction={() => handleEditAbility(1)}
			/>

			<AccordionContentItem
				question={'Last Name'}
				response={lastName || '-'}
				editFunction={() => handleEditAbility(1)}
			/>

			<AccordionContentItem
				question={'Date of Birth'}
				response={birthDate || '-'}
				editFunction={() => handleEditAbility(2)}
			/>

			<AccordionContentItem
				question={'Country of Residence'}
				response={personal?.residenceCountry || '-'}
				editFunction={() => handleEditAbility(2)}
			/>

			<AccordionContentItem
				question={'Nationality'}
				response={personal?.nationality || '-'}
				editFunction={() => handleEditAbility(2)}
			/>

			{/* FIXME we don't ask this data*/}
			{/*<AccordionContentItem*/}
			{/*	question={'Marital Status'}*/}
			{/*	response={contactDetails?.maritalStatus || '-'}*/}
			{/*/>*/}

			<AccordionContentItem
				question={'Mobile Number'}
				response={personal?.mobileNumber || '-'}
				editFunction={() => handleEditAbility(2)}
			/>

			<AccordionContentItem
				question={'Home Number'}
				response={personal?.homeNumber || '-'}
				editFunction={() => handleEditAbility(2)}
			/>

			<AccordionTitle title={'Dependants'} addSpacing />

			<AccordionContentItem
				question={'Is there anyone financially dependant on you?'}
				response={personal?.dependants?.length > 0 ? 'Yes' : 'No' || '-'}
				editFunction={() => handleEditAbility(1)}
			/>

			<AccordionContentItem
				question={'How many adult or children dependant do you have?'}
				response={personal?.dependants?.length || 'None'}
				editFunction={() => handleEditAbility(1)}
			/>

			{/* TO DO: Make these fields optional if those are provided */}

			{personal?.dependants?.map((dependant, id) => (
				<div key={id}>
					<AccordionContentItem
						question={'Relationship'}
						response={dependant?.relationship || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'First Name'}
						response={dependant?.firstName || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'Last Name'}
						response={dependant?.lastName || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'Gender'}
						response={dependant?.gender || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'Date of birth'}
						response={
							dependant?.birthday ? FormatDate(dependant?.birthday) : '-'
						}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'Financially dependant'}
						response={
							Boolean(dependant?.isFinanciallyDependent) ? 'Yes' : 'No' || '-'
						}
						editFunction={() => handleEditAbility(1)}
					/>
				</div>
			))}

			{personal?.presentCountry && (
				<>
					<AccordionTitle title={'Address'} addSpacing />
					{/* TO DO: Make these fields optional if those are provided */}

					<AccordionContentItem
						question={'Country'}
						response={personal?.presentCountry || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'Postcode'}
						response={personal?.presentPostCode || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'Address line 1'}
						response={personal?.presentAddress || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'Address line 2'}
						response={personal?.presentAddress2 || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'Street'}
						response={personal?.presentStreet || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'City'}
						response={personal?.presentCity || '-'}
						editFunction={() => handleEditAbility(1)}
					/>

					<AccordionContentItem
						question={'Date moved into this address?'}
						response={personal?.presentAddressSinceDate || '-'}
						editFunction={() => handleEditAbility(1)}
					/>
				</>
			)}
			{/* END OF OPTIONAL FIELDS */}

			<AccordionContentItem
				question={'Are you on the electoral there?'}
				response={personal?.isElectoralRoll || '-'}
				editFunction={() => handleEditAbility(1)}
			/>

			<AccordionContentItem
				question={'What type occupant are you?'}
				response={personal?.occupantType || '-'}
				editFunction={() => handleEditAbility(1)}
			/>
		</AccordionInfoBox>
	);
};

export default PersonalAccordion;
