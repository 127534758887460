import { ProgressBar, StepContent } from 'components/Widgets';
import RadioInputBoolean from './RadioInputBoolean';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfileWhyRemortgage = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const history = useHistory();

	const [whyRemortgage, setWhyRemortgage] = useState(
		user?.cases[0]?.application?.whyRemortgage
	);

	const handleBorrowMoney = () => {
		setWhyRemortgage('borrowMoney');
		saveToContextAndContinue(
			'borrowMoney',
			'/user/create-profile-why-borrow-more-money'
		);
	};

	const handleNonBorrowMoney = (answer: string) => {
		setWhyRemortgage(answer);
		saveToContextAndContinue(answer, '/user/create-profile-current-deal-ended');
	};

	const saveToContextAndContinue = (answer, link) => {
		const currUser = { ...user };
		currUser.cases[0].application.whyRemortgage = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			history.push(link);
		}, 300);
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={0} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='Why are you remortgaging?'
					onClick={() => {}}
				>
					<RadioInputBoolean
						value='endingDeal'
						isChecked={whyRemortgage === 'endingDeal'}
						SelectionText='My current deal is ending'
						name='whyRemortgage'
						onClick={() => handleNonBorrowMoney('endingDeal')}
					/>
					<RadioInputBoolean
						value='borrowMoney'
						isChecked={whyRemortgage === 'borrowMoney'}
						SelectionText='I want to borrow more money'
						name='whyRemortgage'
						onClick={handleBorrowMoney}
					/>
					<RadioInputBoolean
						value='other'
						isChecked={whyRemortgage === 'other'}
						SelectionText='Other'
						name='whyRemortgage'
						onClick={() => handleNonBorrowMoney('other')}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileWhyRemortgage;
