/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tooltip } from 'antd';
import {
	BorderButton,
	CleanInput,
	Loader,
	JourneyNavLine,
	ProgressBar,
	SelectInput,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Bold, H6 } from 'typography';
import { PATCHRequest } from 'utils/AuthFunctions';

interface IOtherLoans {
	total: string | number;
	monthly: string | number;
	type: string;
	endDate: string;
}

const Liabilities14 = () => {
	const history = useHistory();
	const [{ user }, dispatch] = useDataLayerValue();
	const [isComplete, setComplete] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [otherLoan, setOtherLoan] = useState<IOtherLoans[]>(
		user?.liabilities?.others || [
			{
				type: '',
				total: '',
				monthly: '',
				endDate: '',
			},
		]
	);

	useEffect(() => {
		setComplete(
			otherLoan?.length &&
				otherLoan.every(
					(e) =>
						e.total > 0 &&
						e.monthly > 0 &&
						e.type?.trim()?.length > 0 &&
						((e.type !== 'creditCard' && e.endDate?.trim()?.length > 0) ||
							e.type === 'creditCard')
				)
		);
	}, [otherLoan]);

	const options = [
		{ label: 'Credit card', value: 'creditCard' },
		{ label: 'Overdraft', value: 'Overdraft' },
		{
			value: 'hirePurchase',
			label: 'Hire purchase/Personal Contact Payment',
		},
		{ value: 'personalLoan', label: 'Personal loan' },
	];

	const funcSubmit = async (e: any) => {
		e?.preventDefault();

		const currUser = { ...user };

		if (!currUser?.liabilities) {
			currUser.liabilities = {};
		}

		currUser.liabilities.others = otherLoan;
		setIsSaving(true);
		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		await PATCHRequest(
			`/users?attribute=liabilities`,
			currUser.liabilities,
			history
		);
		setIsSaving(false);
		history.push('/user/stages');
	};

	const generateNewFields = () => {
		setOtherLoan((initIncome) => [
			...initIncome,
			{
				total: 0,
				monthly: 0,
				type: '',
				endDate: '',
			},
		]);
	};

	const removeFieldSet = (index) => {
		const arr = [...otherLoan];
		arr.splice(index, 1);

		setOtherLoan(arr);
	};

	const handleSelectInput = (value: any, index) => {
		setOtherLoan((arr) => {
			arr[index].type = value;
			return [...arr];
		});
	};

	if (isSaving) return <Loader text='Saving...' />;

	return (
		<div className='financial-pages'>
			<ProgressBar progress={98} />
			<JourneyNavLine closeBtn />
			<div className='central-holder'>
				<H6>Other loans and commitments</H6>
				<br />

				<form className='central-form-group' onSubmit={(e) => funcSubmit(e)}>
					{otherLoan?.length &&
						otherLoan.map((liability, index) => {
							return (
								<div className='fieldset-holder' key={index}>
									{index !== 0 && (
										<Tooltip title={'Remove set'} placement='bottomRight'>
											<div
												className='remove-fieldset'
												onClick={() => removeFieldSet(index)}
											></div>
										</Tooltip>
									)}
									<SelectInput
										name={'type'}
										options={options}
										defaultValue={liability?.type || null}
										label='Please specify'
										onSelect={(value: string) =>
											handleSelectInput(value, index)
										}
									/>
									<CleanInput
										label='Total outstanding amount'
										placeholder='text'
										name='total'
										type='number'
										required
										value={liability?.total}
										onChange={(e: any) => {
											const total = +e.target.value;

											if (total <= 0) return;

											setOtherLoan((others) => {
												others[index].total = total;
												return [...others];
											});
										}}
									/>

									<CleanInput
										label='Monthly payment amount'
										placeholder='text'
										name='monthly'
										type='number'
										required
										value={liability?.monthly}
										onChange={(e: any) => {
											const monthly = +e.target.value;
											if (monthly <= 0) return;
											setOtherLoan((others) => {
												others[index].monthly = Number(monthly);
												return [...others];
											});
										}}
									/>
									{liability.type !== 'creditCard' && (
										<CleanInput
											label='End Date'
											type='date'
											value={liability?.endDate}
											placeholder='text'
											name='date'
											required
											onChange={(e: any) => {
												const endDate = e.target.value;
												setOtherLoan((others) => {
													others[index].endDate = endDate;
													return [...others];
												});
											}}
										/>
									)}
									<br />
								</div>
							);
						})}

					<div onClick={generateNewFields} className='financial-add-another'>
						<Bold isBold={false}>Add another</Bold>
					</div>
					<br />

					<div className='personal-details-btn '>
						<BorderButton
							value='Continue'
							width='368px'
							height='56px'
							type='submit'
							disabled={!isComplete}
							backgroundColor='#0968E3'
							color='#fff'
							onClick={funcSubmit}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Liabilities14;
