/**
 * Capitalizes the first charater of string
 * @param str the string to capitalize frst character
 * @example 'something interesting' to 'Something interesting'
 * @returns First Charater Capitalized in String
 */
export const capitalizeFirstLetter = (str: string) => {
  if (!str) return '';

  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * @param str the camel cased string to be spaced out
 * @example 'firstName' to 'First Name'
 * @returns String with space as shown in the example
 */
export const convertCamelCaseToHumanReadable = (str: string) => {
  if (!str) return '';

  return capitalizeFirstLetter(str.replace(/([A-Z])/g, ' $1').trim());
};
