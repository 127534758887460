import {
	BorderButton,
	EnhancedPostCodeInput,
	Input,
	JourneyNavLine,
	Loader,
	ProgressBar,
	SelectInput,
} from 'components/Widgets';
import { actionTypes } from 'context-api/actionTypes';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Bold, H4 } from 'typography';
import { PATCHRequest, readFromLocalStorage, storeInLocalStorage } from 'utils/AuthFunctions';
import { countryList } from 'utils/DropDownList';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { capitalizeFirstLetter } from 'utils';
import isValid from 'uk-postcode-validator';

const SecondApplicantAddressHistory = () => {
	const FProgress = Number(readFromLocalStorage('_FPersonalPageProgress')) || 0;
	const [progress, setProgress] = useState(FProgress);
	const [isComplete, setIsComplete] = useState(false);
	const history = useHistory();
	dayjs.extend(isSameOrBefore);
	const [{ user }, dispatch] = useDataLayerValue();
	const { personal2 } = user || {};
	const { application } = user?.cases?.[0] || {};

	const [presentCity, setPresentCity] = useState(personal2?.presentCity);
	const [occupantType, setOccupantType] = useState(personal2?.occupantType || application?.isLookingTo === 'remortgage' ? 'Home owner' : '');
	const [presentPostCode, setPresentPostCode] = useState(
		personal2?.presentPostCode,
	);
	const [presentCountry, setPresentCountry] = useState(
		personal2?.presentCountry,
	);
	const [presentAddress, setPresentAddress] = useState(
		personal2?.presentAddress,
	);
	const [presentAddress2, setPresentAddress2] = useState(
		personal2?.presentAddress2,
	);
	const [isElectoralRoll, setElectoralRoll] = useState(
		personal2?.isElectoralRoll,
	);
	const [presentAddressSinceDate, setPresentAddressSinceDate] = useState(
		personal2?.presentAddressSinceDate,
	);

	const [presentHomeNumber, setHomeNumber] = useState(personal2?.presentHomeNumber);

	const [showAddAnotherBtn, setShowAddAnotherBtn] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const onSubmit = async (e: any) => {
		e?.preventDefault();
		setProgress(Math.floor(progress + 8.3));
		storeInLocalStorage('_FPersonalPageProgress', Math.floor(progress + 8.3));

		setIsSaving(true);
		const currPersonal2 = saveContext();

		await PATCHRequest(`/users?attribute=personal2`,
			currPersonal2,
			history,
		);
		setIsSaving(false);

		history.push('/user/stages');
	};

	const saveContext = () => {
		const currPersonal = { ...personal2 };
		currPersonal.presentAddress = presentAddress;
		currPersonal.presentHomeNumber = presentHomeNumber;
		currPersonal.presentAddress2 = presentAddress2;
		currPersonal.presentCity = presentCity;
		currPersonal.presentPostCode = presentPostCode;
		currPersonal.presentCountry = presentCountry;
		currPersonal.presentAddressSinceDate = presentAddressSinceDate;
		currPersonal.isElectoralRoll = isElectoralRoll;
		currPersonal.occupantType = occupantType;

		const currUser = { ...user, personal2: currPersonal };

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		return currPersonal;
	};

	useEffect(() => {
		if (
			presentAddress?.trim()?.length > 0 &&
			presentCity?.trim()?.length > 0 &&
			presentCountry?.trim()?.length > 0 &&
			presentAddressSinceDate?.trim()?.length > 0 &&
			isElectoralRoll !== undefined &&
			occupantType?.trim()?.length > 0 &&
			isValid(presentPostCode)
		) {
			setIsComplete(true);
		} else {
			setIsComplete(false);
		}
	}, [
		presentCity,
		occupantType,
		presentPostCode,
		presentCountry,
		presentAddress,
		presentAddress2,
		isElectoralRoll,
		presentAddressSinceDate,
	]);

	const addAnotherBtn = () => {
		saveContext();
		history.push(
			'/user/personal/personal-secondapp-addresshistory/add-another-address',
		);
	};

	useEffect(() => {
		const fiveYearsAgo = dayjs().subtract(5, 'year');

		if (
			dayjs(presentAddressSinceDate).isBefore(dayjs().subtract(100, 'year'))
		) {
			setShowAddAnotherBtn(false);
			return;
		}

		const isLongerThan5Years = dayjs(presentAddressSinceDate).isSameOrBefore(
			fiveYearsAgo,
		);
		setShowAddAnotherBtn(!isLongerThan5Years);
	}, [presentAddressSinceDate]);

	if (isSaving) return <Loader text='Saving...' />;

	return (
		<div className='personal-container'>
			<ProgressBar progress={progress} />
			<JourneyNavLine closeBtn />
			<div className='personal-box'>
				<div className='personal-details-header'>
					<div className='personal-main-title'>
						<H4>{`${capitalizeFirstLetter(
							application?.partner?.firstName,
						)}'s address history`}</H4>
					</div>
					<Bold isBold={false} color='#959DA5'>
						Please provide your address details for the last 5 years, starting
						with your current address
					</Bold>
				</div>
				<div className='personal-details-body'>
					<form>
						<Input
							value={presentHomeNumber}
							normalInput
							label='Home number'
							name='homeNumber'
							type='text'
							placeholder='text'
							onChange={(e) => setHomeNumber(e.target.value)}
							required
						/>

						<EnhancedPostCodeInput
							value={presentPostCode}
							onChange={(e) => setPresentPostCode(e.target.value)}
							onAddressUpdate={e => {
								!e.city || setPresentCity(e.city);
								!e.address || setPresentAddress(e.address);
								!e.postCode || setPresentPostCode(e.postCode)
								!e.country || setPresentCountry(e.country)
							}}
						/>
						<SelectInput
							name={'presentAddressCountry'}
							options={countryList}
							label={'Country'}
							value={presentCountry || null}
							showSearch
							onSelect={setPresentCountry}
						/>

						<Input
							value={presentAddress}
							normalInput
							label='Address line 1'
							name='presentAddress'
							type='text'
							placeholder='text'
							onChange={(e) => setPresentAddress(e.target.value)}
							required
						/>
						<Input
							value={presentAddress2}
							normalInput
							label='Address line 2'
							name='presentAddress2'
							type='text'
							placeholder='text'
							onChange={(e) => setPresentAddress2(e.target.value)}
							required
						/>
						<Input
							value={presentCity}
							normalInput
							label='City'
							name='presentCity'
							type='text'
							placeholder='text'
							onChange={(e) => setPresentCity(e.target.value)}
							required
						/>
						<Input
							value={presentAddressSinceDate}
							label='Date moved into your current address?'
							name='presentAddressSinceDate'
							type='text'
							placeholder='text'
							dateField
							onChange={(e) => setPresentAddressSinceDate(e.target.value)}
							required
						/>
						<div className='mt'>
							<SelectInput
								name={'isElectoralRoll'}
								options={[
									{ value: 'true', label: 'Yes' },
									{ value: 'false', label: 'No' },
								]}
								label='Are you on the electoral roll there?'
								onSelect={setElectoralRoll}
								value={isElectoralRoll || null}
							/>
						</div>

						<div className='mt'>
							<SelectInput
								name={'occupantType'}
								options={[
									{ value: 'Home owner', label: 'Home owner' },
									{ value: 'Tenant', label: 'Tenant' },
									{
										value: 'Living with parents',
										label: 'Living with parents/ partner / family',
									},
								]}
								value={occupantType}
								label='What is your residential status?'
								onSelect={setOccupantType}
							/>
						</div>

						<div className={'personal-details-btn'}>
							{!showAddAnotherBtn && (
								<BorderButton
									value='Continue'
									width='368px'
									height='56px'
									backgroundColor='#0968E3'
									color='#fff'
									marginBottom='15%'
									onClick={onSubmit}
									disabled={!isComplete}
								/>
							)}
							{showAddAnotherBtn && (
								<BorderButton
									value='Add Another'
									width='368px'
									height='56px'
									backgroundColor='#0968E3'
									color='#fff'
									marginBottom='15%'
									type='button'
									disabled={!isComplete}
									onClick={addAnotherBtn}
								/>
							)}
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SecondApplicantAddressHistory;
