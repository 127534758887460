import {
	BorderButton,
	Input,
	JourneyNavLine,
	ProgressBar,
	Loader,
} from 'components/Widgets';
import { useState } from 'react';
import { Bold, H4 } from 'typography';
import { useHistory } from 'react-router-dom';
import {
	PATCHRequest,
	readFromLocalStorage,
} from 'utils/AuthFunctions';
import { useDataLayerValue } from 'context-api/DataLayer';
import { actionTypes } from 'context-api/actionTypes';

const GeneralExpenses = () => {
	const [{ user }, dispatch] = useDataLayerValue();
	const FProgress = Number(readFromLocalStorage('_FExpensesPageProgress')) || 0;
	const history = useHistory();
	const [isSaving, setIsSaving] = useState(false);
	const [textInfo, setTextInfo] = useState(
		user?.expenses || {
			foodCost: 0,
			personalGoods: 0,
			clothing: 0,
			householdSpending: 0,
			mobilePhoneBill: 0,
			schoolingFees: 0,
			repairSpending: 0,
			holidaySpending: 0,
			otherLivingCost: 0,
		}
	);

	const onSubmit = async (e: any) => {
		e?.preventDefault();

		if (!user?.expenses){
			user.expenses = {}
		}

		user.expenses = {
			...user.expenses,
			...textInfo
		}
		setIsSaving(true);
		dispatch({
			type: actionTypes.SET_USER,
			user,
		});

		await PATCHRequest(
			`/users?attribute=expenses`,
			user.expenses,
			history
		);
		setIsSaving(false);

		history.push(`/user/stages`);
	};

	if (isSaving) return <Loader text='Saving...' />

	return (
		<div className="personal-container">
			<ProgressBar progress={FProgress} />
			<JourneyNavLine closeBtn />
			<div className="personal-box">
				<div className="personal-details-header">
					<div className="personal-main-title">
						<H4>General Expenses</H4>
					</div>
					<Bold isBold={false} color="#959DA5">
						We’ve given you national averages to help you. You can change them
						to reflect your monthly household expenses
					</Bold>
				</div>
				<div className="personal-details-body">
					<form onSubmit={onSubmit}>
						<Input
							value={textInfo.foodCost}
							type="number"
							numberInput
							currency
							label="Food/Washing"
							placeholder="Food/Washing"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									foodCost: Number(value),
								}))
							}
						/>
						<Input
							type="number"
							numberInput
							currency
							label={'Personal goods'}
							placeholder="Personal goods"
							value={textInfo.personalGoods}
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									personalGoods: Number(value),
								}))
							}
						/>

						<Input
							type="number"
							numberInput
							currency
							label={'Clothing'}
							placeholder="Clothing"
							value={textInfo.clothing}
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									clothing: Number(value),
								}))
							}
						/>
						<Input
							type="number"
							value={textInfo.householdSpending}
							numberInput
							currency
							label={'Household goods'}
							placeholder="Household goods"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									householdSpending: Number(value),
								}))
							}
						/>
						<Input
							type="number"
							numberInput
							currency
							label={'Mobile phone'}
							placeholder="Mobile phone"
							value={textInfo.mobilePhoneBill}
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									mobilePhoneBill: Number(value),
								}))
							}
						/>
						<Input
							type="number"
							numberInput
							currency
							label={'School/Child minding fees'}
							placeholder="School/Child minding fees"
							value={textInfo.schoolingFees}
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									schoolingFees: Number(value),
								}))
							}
						/>
						<Input
							type="number"
							value={textInfo.repairSpending}
							numberInput
							currency
							label={'Repairs'}
							placeholder="Repairs"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									repairSpending: Number(value),
								}))
							}
						/>
						<Input
							type="number"
							value={textInfo.holidaySpending}
							numberInput
							currency
							label={'Holiday/Entertainment'}
							placeholder="Holiday/Entertainment"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									holidaySpending: Number(value),
								}))
							}
						/>
						<Input
							type="number"
							value={textInfo.otherLivingCost}
							numberInput
							currency
							label={'Other Total'}
							placeholder="Other Total"
							onChange={(value: number) =>
								setTextInfo((prev) => ({
									...prev,
									otherLivingCost: Number(value),
								}))
							}
						/>

						<div>
							<div className="personal-details-btn ">
								<BorderButton
									value="Continue"
									width="368px"
									height="56px"
									backgroundColor="#0968E3"
									color="#fff"
									onClick={onSubmit}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default GeneralExpenses;
