import { createContext, useContext, useReducer } from 'react';

//@ts-ignore
export const DataLayerContext = createContext();

// prepare the datalayer
export const DataLayer = ({
  initialState,
  reducer,
  children,
}: any): JSX.Element => (
  <DataLayerContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </DataLayerContext.Provider>
);

// allow data pulling from DataLayer
export const useDataLayerValue = (): any => useContext(DataLayerContext);
