import 'css/Notification.css';
import NotifyIcon from 'types/notification';
import { SmallSubTitle } from 'typography';
import NotificationTile from './NotificationTile';

function NotificationBag() {
  return (
    <aside className="notification-bag">
      <div className="notification-header">
        <SmallSubTitle>Notifications</SmallSubTitle>
      </div>
      <section className="notification-content">
        <NotificationTile
          text={'Congratulations, your mortgage offer accepted!'}
          type={NotifyIcon.NotifyCongrats}
        />

        <NotificationTile
          text={'Your application is completed'}
          type={NotifyIcon.NotifyCompleted}
        />

        <NotificationTile
          text={'Your documents are uploaded'}
          type={NotifyIcon.NotityDocUploaded}
        />

        <NotificationTile
          text={'Please re-upload your ID!'}
          type={NotifyIcon.NotityReUpload}
        />
        <NotificationTile
          text={'Please upload your documents'}
          type={NotifyIcon.NotifyDocError}
        />

        <NotificationTile
          text={'Application is not completed'}
          type={NotifyIcon.NotityNotCompleted}
        />

        <NotificationTile
          text={'Setup your password'}
          type={NotifyIcon.NotityUser}
        />
      </section>
    </aside>
  );
}

export default NotificationBag;
