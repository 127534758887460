import { ProgressBar, StepContent } from 'components/Widgets';
import RadioInputBoolean from './RadioInputBoolean';
import CreateProfileContent from './CreateProfileContent';
import { useDataLayerValue } from 'context-api/DataLayer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from 'context-api/actionTypes';

const CreateProfileExtendMortgage = () => {
	const [{ user,isPartnerExists }, dispatch] = useDataLayerValue();
	const history = useHistory();
	const [progress] = useState(!!isPartnerExists ? 70 : 83);

	const [extendMortgage, setExtendMortgage] = useState(
		user?.application?.extendMortgage
	);

	const handleAnswer = (answer: boolean) => {
		setExtendMortgage(answer);
		const currUser = { ...user };
		currUser.cases[0].application.extendMortgage = answer;

		dispatch({
			type: actionTypes.SET_USER,
			user: currUser,
		});

		setTimeout(() => {
			history.push('/user/create-profile-previous-mortgage-payment-type');
		}, 300);
	};

	return (
		<div className='create-profile-container' style={{ minHeight: '100vh' }}>
			<ProgressBar progress={progress} />
			<StepContent closeBtn={false} showBackBtn step={1}>
				<CreateProfileContent
					active
					title='Would you like to extend your mortgage to effectively lower your monthly payments?'
					onClick={() => {}}
				>
					<RadioInputBoolean
						value='yes'
						isChecked={typeof extendMortgage === 'boolean' && extendMortgage}
						SelectionText='Yes'
						name='extendMortgage'
						onClick={() => handleAnswer(true)}
					/>
					<RadioInputBoolean
						value='no'
						isChecked={typeof extendMortgage === 'boolean' && !extendMortgage}
						SelectionText='No'
						name='extendMortgage'
						onClick={() => handleAnswer(false)}
					/>
				</CreateProfileContent>
			</StepContent>
		</div>
	);
};

export default CreateProfileExtendMortgage;
