import 'antd/dist/antd.min.css'; // ant css
import WorkSpaceRoutes from 'routes/WorkSpaceRoutes';

function WorkSpace() {
  return (
    <section className="workspace">
      <article className="centroid">
        <WorkSpaceRoutes />
      </article>
    </section>
  );
}

export default WorkSpace;
