import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
	BedroomCount,
	DepositSource,
	MainProperty,
	Occupants18Aged,
	Occupants18AgedDetalis,
	OnlyProperty,
	PreProperty,
	PropertyAddressDetails,
	PropertyExLocal,
	PropertyNonStandardConstruction,
	PropertyTenure,
	PropertyType,
	PropertyYears,
	SharedOwnerShip,
	SharedOwnerShipDetails,
	TenureRemaining,
} from '.';

const Expenses = () => {
	const { path } = useRouteMatch();
	return (
		<div>
			<Switch>
				<Route path={`${path}/purchase-property-address`} exact>
					<PropertyAddressDetails />
				</Route>

				<Route path={`${path}/purchase-property-bedroom-count`} exact>
					<BedroomCount />
				</Route>

				<Route path={`${path}/purchase-property-years`} exact>
					<PropertyYears />
				</Route>

				<Route path={`${path}/purchase-property-shared-ownership`} exact>
					<SharedOwnerShip />
				</Route>

				<Route
					path={`${path}/purchase-property-shared-ownership-details`}
					exact
				>
					<SharedOwnerShipDetails />
				</Route>

				<Route path={`${path}/purchase-property-type`} exact>
					<PropertyType />
				</Route>

				<Route path={`${path}/purchase-property-tenure`} exact>
					<PropertyTenure />
				</Route>

				<Route path={`${path}/purchase-property-lease`} exact>
					<TenureRemaining />
				</Route>

				<Route path={`${path}/purchase-property-main-residence`} exact>
					<MainProperty />
				</Route>

				<Route path={`${path}/purchase-property-ex-local`} exact>
					<PropertyExLocal />
				</Route>

				<Route
					path={`${path}/purchase-property-non-standard-construction`}
					exact
				>
					<PropertyNonStandardConstruction />
				</Route>

				<Route path={`${path}/purchase-property-only-property`} exact>
					<OnlyProperty />
				</Route>

				<Route path={`${path}/purchase-property-deposit-source`} exact>
					<DepositSource />
				</Route>

				<Route path={`${path}/purchase-property-have-any-occupants`} exact>
					<Occupants18Aged />
				</Route>

				<Route path={`${path}/purchase-property-occupants-details`} exact>
					<Occupants18AgedDetalis />
				</Route>

				<Route path={`${path}/pre-property`} exact>
					<PreProperty />
				</Route>

				<Route path={`${path}/`}>
					<PropertyAddressDetails />
				</Route>
			</Switch>
		</div>
	);
};

export default Expenses;
